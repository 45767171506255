import { useState } from 'react'
import { Form, Modal, Table, Button } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'

const CountryModal = ({ campaignName, tracking, campaignId, showSystemMessage, isOpen, setIsOpen }) => {
    const [data, setData] = useState(tracking)

    const { dataLoaded: cLDataLoaded, data: cLData } = useCallApi(`countryList`, 'GET', {}, [], showSystemMessage)

    const save = async () => {
        //save data to db
        const result = await callAPI(`campaign/${campaignId}/countries`, 'PUT', data)
        if (result.success) {
            showSystemMessage('success', 'Countries updated successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            {cLDataLoaded ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Country list for {campaignName}</Modal.Title>
                    </Modal.Header>
                    <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                        <Table striped bordered hover className="mb-0">
                            <tbody>
                                {cLData &&
                                    cLData.countries.map((country, index) => (
                                        <tr key={country.country_code}>
                                            <td>{country.country_name}</td>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={data.traffic.country_filtering.countries.findIndex((existingCountry) => existingCountry.country_code === country.country_code) > -1}
                                                    onChange={(e) => {
                                                        let countries = data.traffic.country_filtering.countries
                                                        if (e.target.checked) {
                                                            countries.push({ country_code: country.country_code })
                                                        } else {
                                                            let arrayLocation = countries.findIndex((existingCountry) => existingCountry.country_code === country.country_code)
                                                            if (arrayLocation > -1) {
                                                                countries.splice(arrayLocation, 1)
                                                            }
                                                        }
                                                        setData({ ...data, traffic: { ...data.traffic, country_filtering: { ...data.traffic.country_filtering, countries: countries } } })
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </div>
                    <Modal.Footer>
                        <Button variant="primary" onClick={save}>
                            Save
                        </Button>
                    </Modal.Footer>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    )
}

export default CountryModal
