import moment from 'moment'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Dollars from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import { useEffect, useState } from 'react'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import Advertiser from '../../../templates/Advertiser/Advertiser'

const Followups = ({ showSystemMessage, defaultAdminId }) => {
    const auth = useAuth()

    const [adminId, setAdminId] = useState(defaultAdminId)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, refetch } = useCallApi(`admin/${adminId}/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()

    const getReportOutput = () => {
        let columns =
            data.followups[0].affiliate_id > 0
                ? [
                      {
                          Header: 'Date/Time',
                          accessor: 'followup_date',
                          Cell: ({ cell }) => moment(cell.row.original.followup_date).utc().format('YYYY-MM-DD HH:mm:ss'),
                      },
                      {
                          Header: 'Results',
                          accessor: 'results',
                      },
                      {
                          Header: 'ID',
                          accessor: 'affiliate_id',
                      },
                      {
                          Header: 'Affiliate',
                          accessor: 'name',
                          Cell: ({ cell }) => <Affiliate aid={cell.row.original.affiliate_id} name={cell.row.original.name} status={cell.row.original.status} priority={cell.row.original.aff_priority} />,
                      },
                      {
                          Header: 'Next Followup',
                          accessor: 'next_followup_date',
                          Cell: ({ cell }) => moment(cell.row.original.next_followup_date).utc().format('YYYY-MM-DD'),
                      },
                      {
                          Header: 'Notes',
                          accessor: 'notes',
                      },
                  ]
                : [
                      {
                          Header: 'Date/Time',
                          accessor: 'followup_date',
                          Cell: ({ cell }) => moment(cell.row.original.followup_date).utc().format('YYYY-MM-DD HH:mm:ss'),
                      },
                      {
                          Header: 'Results',
                          accessor: 'results',
                      },
                      {
                          Header: 'ID',
                          accessor: 'merchant_id',
                      },
                      {
                          Header: 'Advertiser',
                          accessor: 'name',
                          Cell: ({ cell }) => <Advertiser id={cell.row.original.merchant_id} name={cell.row.original.name} status={cell.row.original.status} />,
                      },
                      {
                          Header: 'Next Followup',
                          accessor: 'next_followup_date',
                          Cell: ({ cell }) => moment(cell.row.original.next_followup_date).utc().format('YYYY-MM-DD'),
                      },
                      {
                          Header: 'Notes',
                          accessor: 'notes',
                      },
                  ]

        return (
            <>
                <ReportTable data={data.followups} columns={columns} />
            </>
        )
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="mb-h1-dark d-flex justify-content-start align-items-center">
                    <span className="d-flex mr-2 align-items-center">
                        <span className="mr-2">Followups</span>
                        <span className="ml-2 ">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role="" />
                        </span>
                    </span>
                    <span className="d-flex ml-2">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button
                            variant="warning"
                            size="sm"
                            onClick={() => {
                                history.push(`/admin/${adminId}/followups?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
                                refetch()
                            }}
                        >
                            Refresh
                        </Button>
                    </span>
                </h1>
            </Card.Header>
            <Card.Body className="p-0 m-0">{dataLoaded ? data.followups.length === 0 ? <div className="p-3">No followups found.</div> : getReportOutput() : <Loading />}</Card.Body>
        </Card>
    )
}

export default Followups
