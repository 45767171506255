import React from 'react'
import { Modal } from 'react-bootstrap'
import PostbackElement from './PostbackElement/PostbackElement'

const GeneratePixelModal = ({ campaignId, campaign, isOpen, setIsOpen }) => {
    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="lg">
            <Modal.Header closeButton>
                <h1 className="mb-h1-dark">Pixel / Postbacks for {campaign.name ? campaign.name : null}</h1>
            </Modal.Header>
            <Modal.Body>
                {campaign.rates.map((rate, index) => (
                    <div key={index}>
                        <h5>Rate Name: {rate.rate_name}</h5>
                        <PostbackElement campaign={campaign} rate={rate} campaignId={campaignId} />
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    )
}

export default GeneratePixelModal
