import { useEffect, useState } from 'react'
import { Form, Modal, Table, Button, Card } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'
import { ButtonDropdown } from 'reactstrap'

const TriggerModal = ({ events, campaignId, showSystemMessage, isOpen, closeModal, data, isCreate, refetch, kpiId, rateName }) => {
    // TODO: RENAME THIS COMPONENT TO TriggerModal, modify backend to accept mode for kpi vs rate
    const [formData, setFormData] = useState(data)

    useEffect(() => {
        if (isCreate) {
            setFormData({ ...data, event_id: events[0].id, related_event_id: events[0].id })
        } else {
            setFormData(data)
        }
        console.log('data', data)
    }, [data])

    const validate = () => {
        if (!(formData.event_id > 0)) {
            showSystemMessage('error', 'Event is required')
            return false
        } else if (!(formData.trigger_period > 0)) {
            showSystemMessage('error', 'Trigger Period must be greater than 0')
            return false
        } else if (formData.related_event_id === '') {
            showSystemMessage('error', 'Related Event is required')
            return false
        } else if (formData.monetary_value <= 0 || formData.monetary_value === '') {
            showSystemMessage('error', 'Monetary Value must be greater than 0')
            return false
        } else if (!(formData.event_mode > '')) {
            showSystemMessage('error', 'Event Mode is required')
            return false
        }
        return true
    }

    const save = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/${kpiId}/trigger/${data.trigger_id}?${rateName > '' ? `rate_name=${rateName}` : ''}`, 'PUT', formData)
            if (result.success) {
                showSystemMessage('success', 'Saved Mobile Event')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const create = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/${kpiId}/trigger?${rateName > '' ? `rate_name=${rateName}` : ''}`, 'POST', formData)
            if (result.success) {
                showSystemMessage('success', 'Created Trigger')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const deleteEvent = async () => {
        //save data to db
        const result = await callAPI(`campaign/${campaignId}/${kpiId}/trigger/${data.kpi_trigger_id}`, 'DELETE', formData)
        if (result.success) {
            showSystemMessage('success', 'Deleted Trigger')
            refetch()
            closeModal()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => closeModal()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isCreate ? 'Create' : 'Edit'} {kpiId > 0 ? 'KPI' : 'Rate'} Trigger
                </Modal.Title>
            </Modal.Header>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Event</span>
                <Form.Control as="select" value={formData.event_id} onChange={(e) => setFormData({ ...formData, event_id: e.target.value })} className="ml-2" disabled={!isCreate}>
                    {events?.length > 0 &&
                        events.map((event, index) => (
                            <option key={index} value={event.id}>
                                {event.name}
                            </option>
                        ))}
                </Form.Control>
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Wihin (Hours)</span>
                <Form.Control type="number" value={formData.trigger_period} onChange={(e) => setFormData({ ...formData, trigger_period: e.target.value })} className="ml-2" />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Of Event</span>
                <Form.Control as="select" value={formData.related_event_id} onChange={(e) => setFormData({ ...formData, related_event_id: e.target.value })} className="ml-2">
                    {events?.length > 0 &&
                        events.map((event, index) => (
                            <option key={index} value={event.id}>
                                {event.name}
                            </option>
                        ))}
                </Form.Control>
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Monetary Value</span>
                <Form.Control type="number" value={formData.monetary_value} onChange={(e) => setFormData({ ...formData, monetary_value: e.target.value })} className="ml-2" />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Mode</span>
                <Form.Control as="select" value={formData.event_mode} onChange={(e) => setFormData({ ...formData, event_mode: e.target.value })} className="ml-2">
                    <option value="SUM">Sum values of all subsequent events</option>
                    <option value="IND">One time value</option>
                </Form.Control>
            </div>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="success" onClick={create}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="success" onClick={save}>
                            Save
                        </Button>
                        {formData.delete_status === 'Y' && (
                            <Button variant="danger" onClick={deleteEvent}>
                                Delete
                            </Button>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default TriggerModal
