import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const CampaignDropdown = (props) => {
    const auth = useAuth()
    const showCampaignCategories = useProtectedContent(auth.permissions, 'campaign_categories')
    const showCampaignApprovalQuestions = useProtectedContent(auth.permissions, 'campaign_approval_questions')
    const showManageKeywords = useProtectedContent(auth.permissions, 'campaign_keywords')
    const showMissingScreenshots = useProtectedContent(auth.permissions, 'campaigns_missing_screenshots')
    const showCountryRedirectSets = useProtectedContent(auth.permissions, 'campaign_country_redirect_sets')
    const showSuppressionReport = useProtectedContent(auth.permissions, 'campaign_suppression_report')
    const showCampaignWorksheet = useProtectedContent(auth.permissions, 'campaign_worksheet_report')
    const showCampaignHighlights = useProtectedContent(auth.permissions, 'campaign_highlight')
    const showMobileParameters = useProtectedContent(auth.permissions, 'campaign_mobile_parameters')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle">
                Campaigns
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-dark">
                <Link className="dropdown-item" to="/campaigns/search" onClick={toggle}>
                    Search Campaigns
                </Link>
                {showCampaignHighlights && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/campaigns/highlighted" onClick={toggle}>
                            Highlight Campaigns on Aff Dash
                        </Link>
                    </>
                )}

                <DropdownItem divider className="m-0 dropdown-separator" />

                <Link className="dropdown-item" to="/reports/stats/campaigns" onClick={toggle}>
                    Campaign Earnings
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/reports/campaignTrend" onClick={toggle}>
                    Campaign Trend Report
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/campaigns/newCampaigns" onClick={toggle}>
                    New Campaigns
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/campaigns/top" onClick={toggle}>
                    Top Campaigns
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/campaigns/topCampaignsByCountry" onClick={toggle}>
                    Top Campaigns By Country
                </Link>

                <DropdownItem divider className="m-0 dropdown-separator" />

                {showCampaignCategories && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/categories" onClick={toggle}>
                            Manage Categories
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}

                {showCampaignApprovalQuestions && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/approvalQuestions" onClick={toggle}>
                            Manage Approval Questions
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}

                {showManageKeywords && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/keywords" onClick={toggle}>
                            Manage Keywords
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}

                {showMobileParameters && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/mobileParameters" onClick={toggle}>
                            Mobile Parameters
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}

                {showCountryRedirectSets && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/countryRedirectSets" onClick={toggle}>
                            Country Redirect Sets
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}

                <DropdownItem divider className="m-0 dropdown-separator" />

                {showMissingScreenshots && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/missingScreenshots" onClick={toggle}>
                            Missing Screenshots
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                {showSuppressionReport && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/suppressionReport" onClick={toggle}>
                            Suppression List Report
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                {showCampaignWorksheet && (
                    <>
                        <Link className="dropdown-item" to="/campaigns/worksheetReport" onClick={toggle}>
                            Campaign Worksheet
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}

                <>
                    <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    <Link className="dropdown-item" to="/reports/reversals/campaigns" onClick={toggle}>
                        Campaign Reversal Report
                    </Link>
                </>
            </DropdownMenu>
        </Dropdown>
    )
}

export default CampaignDropdown
