import { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Trash2 } from 'react-feather'

import Loading from '../../../templates/UI/Loading/Loading'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import callAPI from '../../../helpers/callAPI'

// FIXME: Allows same offer to be added multiple times. Cuases key & deletion issues

const OpenCampaignPromises = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [campaign, setCampaign] = useState()
    const [selectedOffer, setSelectedOffer] = useState()
    const [focusedOffer, setFocusedOffer] = useState()
    const [promiseList, setPromiseList] = useState([])

    const { showSystemMessage, affiliateId } = props

    useEffect(() => {
        const startDate = `2020-04-01`
        const endDate = `${moment().format('YYYY-MM-DD HH:mm:ss')}`

        let url = `affiliate/${affiliateId}/ptr?startDate=${startDate}&endDate=${endDate}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setPromiseList(result.promise_list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error while fetching the data. Please try again')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in Open Campaign Promises: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }, [dataLoaded])

    useEffect(() => {
        if (campaign) {
            handleSaveOffer(campaign.id)
        }
    }, [campaign])

    const handleSaveOffer = (offerId) => {
        setDataLoaded(false)

        let url = `affiliate/${affiliateId}/ptr`

        callAPI(url, 'POST', { promiseOfferId: offerId })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while saving pitch: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }

    const handleMouseEnter = (value) => {
        setFocusedOffer(value)
    }

    const handleMouseLeave = () => {
        setFocusedOffer()
        setSelectedOffer()
    }

    const handleDelete = (index) => {
        setSelectedOffer(index)
    }

    const handleDeleteConfirmation = () => {
        setDataLoaded(false)

        let url = `affiliate/${affiliateId}/ptr`

        callAPI(url, 'DELETE', { offerId: selectedOffer })
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while deleting campaign promise')
            })
    }

    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Open Campaign Promises (Last 3)</div>
                <div className="float-right">
                    <Link to={`/reports/custom/campaignPromiseReport?affiliateId=${affiliateId}&navigation=affiliate`} className="text-primary">
                        List All
                    </Link>
                </div>
            </div>
            <div className="card-body p-0">
                <div className="m-2">
                    <CampaignSearch setCampaign={setCampaign} order={2} />
                </div>
                <div>
                    {dataLoaded ? (
                        promiseList.length === 0 ? (
                            <div className="p-2">No stats available</div>
                        ) : (
                            <table className="table table-striped table-card table-hover">
                                <tbody>
                                    {promiseList.slice(0, 3).map((item) => {
                                        let element

                                        if (focusedOffer === item.offer_id && !selectedOffer) {
                                            element = <Trash2 key={focusedOffer} size={16} color="red" onClick={() => handleDelete(item.offer_id)} />
                                        } else if (focusedOffer === item.offer_id && selectedOffer === item.offer_id) {
                                            element = (
                                                <button type="button" className="btn btn-sm btn-outline-danger" onClick={handleDeleteConfirmation}>
                                                    Confirm
                                                </button>
                                            )
                                        }

                                        return (
                                            <tr key={item.offer_id} onMouseEnter={() => handleMouseEnter(item.offer_id)} onMouseLeave={() => handleMouseLeave(item.offer_id)} className="align-items-center">
                                                <td>
                                                    <Link to={`/campaign/${item.offer_id}`} className="mr-2">
                                                        {item.offer_name}
                                                    </Link>{' '}
                                                    {element}
                                                </td>
                                                <td className="text-right">{moment(item.promise_date).format('YYYY/MM/DD')}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )
                    ) : (
                        <Loading></Loading>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OpenCampaignPromises
