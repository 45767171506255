import React, { useEffect, useState } from 'react'
import callAPI from '../../../../../../helpers/callAPI'

const PhoneCheck = ({ mobile, phone, showSystemMessage }) => {
    // check phone against IPQS
    // Fetch accounts with same phone numbers

    const [dataLoaded, setDataLoaded] = useState(false)
    const [ipqsMobileDetails, setIpqsMobileDetails] = useState({})
    const [ipqsPhoneDetails, setIpqsPhoneDetails] = useState({})

    useEffect(async () => {
        let mobileUrl = `ipqs/phone/${mobile}`
        let phoneUrl = `ipqs/phone/${phone}`

        let mobileResult, phoneResult

        if (mobile !== '') {
            mobileResult = await callAPI(mobileUrl, 'GET')

            if (mobileResult.success === false) {
                showSystemMessage('error', 'There was an error while fetching the data. Please try again')
            }
            setIpqsMobileDetails(mobileResult.ipqs_details)
        }

        if (phone !== '') {
            phoneResult = await callAPI(phoneUrl, 'GET')

            if (phoneResult.success === false) {
                showSystemMessage('error', 'There was an error while fetching the data. Please try again')
            }
            setIpqsPhoneDetails(phoneResult.ipqs_details)
        }
    }, [])

    return (
        <div>
            <span>
                Mobile Number: {mobile} &nbsp; IPQS message: {ipqsMobileDetails.message} &nbsp; Fraud Score: {ipqsMobileDetails.fraud_score} &nbsp; Country: {ipqsMobileDetails.country} &nbsp; Region: {ipqsMobileDetails.region}
            </span>
            <br />
            {phone !== '' && (
                <span>
                    Phone Number: {phone} &nbsp; IPQS message: {ipqsPhoneDetails.message} &nbsp; Fraud Score: {ipqsPhoneDetails.fraud_score} &nbsp; Country: {ipqsPhoneDetails.country} &nbsp; Region: {ipqsPhoneDetails.region}
                </span>
            )}
        </div>
    )
}

export default PhoneCheck
