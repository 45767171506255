import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import Loading from '../../../templates/UI/Loading/Loading'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import callAPI from '../../../helpers/callAPI'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'
import IpDetailsContent from './IpDetails/IpDetailsContent'

const AffiliateLoginHistory = ({ affiliateId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [report, setReport] = useState([])
    const [affiliate, setAffiliate] = useState({})
    const [showIpDetailsModal, setShowIpDetailsModal] = useState(false)
    const [ipAddress, setIpAddress] = useState('')
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    document.title = `${dataLoaded ? `${affiliate.name}: ` : ''}Login History`

    useEffect(() => {
        getReport()        
    }, [location])

    const handleIpClick = (ipAddress) => {
        setShowIpDetailsModal(true)
        setIpAddress(ipAddress)
    }

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = `/affiliate/${affiliateId}/loginHistory?startDate=${selectedStartDate}&endDate=${selectedEndDate}`

        history.push(url)
    }

    const getReport = async () => {
        setDataLoaded(false)

        let url = `affiliate/${affiliateId}/loginHistory?startDate=${initStartDate}&endDate=${initEndDate}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliate(result.affiliate)
                    setReport(result.report)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching affiliate login history: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching affiliate login history')
            })
    }

    const handleClose = () => {
        setShowIpDetailsModal(false)
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Login Date',
                accessor: 'login_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'IP Address',
                accessor: 'remote_host',
                Cell: (tableData) => {
                    return (
                        <>
                            <button className="btn btn-sm btn-link" onClick={() => handleIpClick(tableData.data[tableData.row.index].remote_host)}>
                                {tableData.data[tableData.row.index].remote_host}
                            </button>{' '}
                            ({tableData.data[tableData.row.index].ip_country})
                        </>
                    )
                },
            },
            {
                Header: 'User Agent',
                accessor: 'user_agent',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'LFD',
                accessor: 'login_from_device_count',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Original Aff Id',
                accessor: 'original_affiliate_id',
                Cell: (tableData) => {
                    return <>{tableData.data[tableData.row.index].original_affiliate_id !== parseInt(affiliateId) ? tableData.data[tableData.row.index].original_affiliate_id : ''}</>
                },
            },
            {
                Header: 'Source',
                accessor: 'login_type',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const CSVHeaders = ['Login Date', 'IP Address', 'Country', 'User Agent', 'LFD', 'Original Aff ID', 'Source']
    const CSVDataLabels = ['login_date', 'remote_host', 'ip_country', 'user_agent', 'login_from_device_count', 'original_affiliate_id', 'login_type']

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Login History</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="card">
                <Modal show={showIpDetailsModal} size="md" onHide={handleClose}>
                    <IpDetailsContent ipAddress={ipAddress} handleClose={handleClose} isModal={true} />
                </Modal>
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliate ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.aff_priority} status={affiliate.status} /> : 'Affiliate Not Found' : spinner}</h1>
                            </div>
                            <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                        {affiliate && <DownloadCSV filename={`Login History - ${affiliate.name} (${affiliateId}) - ${initStartDate}-${initEndDate}`} headerLabels={CSVHeaders} data={report} dataLabels={CSVDataLabels} />}
                    </div>
                    {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                </div>
            </div>
        </div>
    )
}

export default AffiliateLoginHistory
