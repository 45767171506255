import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'

const CampaignInfo = ({ offerId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [campaign, setCampaign] = useState({})

    useEffect(() => {
        getOfferInfo()
    }, [offerId])

    const getOfferInfo = () => {
        let url = `campaign/${offerId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setCampaign(result.campaign)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching campaign info')
                setDataLoaded(true)
            })
    }

    return (
        <div className="card mb-3">
            <div className="card-header">Campaign Info</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        <div className="border-bottom align-items-center p-2">
                            <Link to={`/campaign/${offerId}`}>
                                <h5 className="font-weight-bold">{campaign.name}</h5>
                            </Link>
                            <Link to={`/advertiser/${campaign.advertiser.id}`}>{campaign.advertiser.name}</Link>
                        </div>

                        <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                            <div>
                                {campaign.tracking.traffic_typesallow_solo_emails_flag ? <Pill text="E-Mail" /> : ''}
                                {campaign.tracking.traffic_typescontextual_flag ? <Pill text="Contextual" /> : ''}
                                {campaign.tracking.traffic_typessite_traffic_flag ? <Pill text="Display" /> : ''}
                                {campaign.tracking.traffic_typessearch_flag ? <Pill text="Search" /> : ''}
                                {campaign.tracking.traffic_typesallow_incentive_flag ? <Pill text="Incentive" /> : ''}
                                {campaign.tracking.traffic_typessocial_media_flag ? <Pill text="Social" /> : ''}
                                {campaign.tracking.traffic_typesnative_flag ? <Pill text="Native" /> : ''}
                                {campaign.tracking.traffic_typesmobile_flag ? <Pill text="Mobile Ads" /> : ''}
                            </div>
                        </div>
                        <div className="p-2">
                            {campaign.campaign_approval.approval_preface ||
                                (campaign.campaign_approval.approval_criteria && (
                                    <table>
                                        <tbody style={{ lineHeight: '20px' }}>
                                            {campaign.campaign_approval.approval_preface && (
                                                <tr>
                                                    <td>
                                                        <span className="d-block text-secondary">Approval Preface</span>
                                                        <span style={{ backgroundColor: 'red' }}>{campaign.campaign_approval.approval_preface}</span>
                                                    </td>
                                                </tr>
                                            )}
                                            {campaign.campaign_approval.approval_criteria && (
                                                <tr>
                                                    <td>
                                                        <span className="d-block text-secondary">Approval Criteria</span>
                                                        <span>{campaign.campaign_approval.approval_criteria}</span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                ))}
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default CampaignInfo
