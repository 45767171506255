import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useCallApi from '../../../../../hooks/useCallApi'

export default function MBStatus({ showSystemMessage }) {
    const [ws1Status, setWs1Status] = useState(true)

    const options = {
        method: 'GET',
        headers: {
            Origin: 'https://ott.maxbounty.com',
        },
    }

    // fetch(`https://209.15.253.217/`, options)
    //         .then((res) => res.json())
    //         .then((result) => { console.log(result); return result})
    //         .catch((err) => { console.log(err.message); return { success: false, errors: { message: err.message } } } )

    return (
        <div className="card mb-2">
            <div className="card-header text-danger">MB Status - NOT WORKING YET</div>
            <div>
                <div className="d-flex p-2 justify-content-between border-bottom">
                    <div>
                        <FontAwesomeIcon className="text-success" icon="circle" /> WS 1 (ONLINE)
                    </div>
                    <div>
                        <FontAwesomeIcon className="text-success" icon="circle" /> WS 2 (ONLINE)
                    </div>
                </div>
                <div className="d-flex p-2 justify-content-between">
                    <div>
                        <FontAwesomeIcon className="text-success" icon="circle" /> Admin (ONLINE)
                    </div>
                    <div>
                        <FontAwesomeIcon className="text-success" icon="circle" /> DB (ONLINE)
                    </div>
                </div>
            </div>
        </div>
    )
}
