import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import callAPI from '../../helpers/callAPI'
import Affiliate from '../../templates/Affiliate/Affiliate'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Campaign from '../../templates/Campaign/Campaign'
import Loading from '../../templates/UI/Loading/Loading'
import ReviewRequest from '../ReviewRequest/ReviewRequest'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'

const CampaignRequestsSummary = ({ affiliateId, campaignId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState(0)
    const [campaign, setCampaign] = useState(0)
    const [list, setList] = useState([])
    const [requestId, setRequestId] = useState()
    const [selectedOfferId, setSelectedOfferId] = useState()
    const [selectedAffiliateId, setSelectedAffiliateId] = useState()
    const [showReviewModal, setShowReviewModal] = useState(false)
    const location = useLocation()

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    useEffect(() => {
        getReport()
    }, [location])

    const getReport = () => {
        setDataLoaded(false)

        let url = affiliateId ? `affiliate/${affiliateId}/campaignRequests` : `campaign/${campaignId}/campaignRequests`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    affiliateId ? setAffiliate(result.affiliate) : setCampaign(result.campaign)
                    document.title = `${affiliateId ? result.affiliate.name : result.campaign.name}: Campaign Requests`
                    setList(result.list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    document.title = 'Campaign Requests'
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching campaign requests: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching campaign requests')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'item_id',
                Cell: ({ value }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'item_name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign oid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].item_name} status={tableData.data[tableData.row.index].item_status} /> : <Affiliate aid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].item_name} status={tableData.data[tableData.row.index].item_status} priority={tableData.data[tableData.row.index].priority} />
                },
            },
            {
                Header: affiliateId ? 'Campaign Status' : 'Affiliate Status',
                accessor: 'item_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Advertiser Manager' : 'Affiliate Manager',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Request Date',
                accessor: 'request_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Request Status',
                accessor: 'req_status',
                Cell: (tableData) => {
                    let status = tableData.data[tableData.row.index].req_status
                    let reason = tableData.data[tableData.row.index].reject_reason

                    if (status.toLowerCase() === 'denied') {
                        return (
                            <a href={`/campaigns/approvalRequests/${tableData.data[tableData.row.index].request_id}?affiliateId=${affiliateId ? affiliateId : tableData.data[tableData.row.index].item_id}&campaignId=${affiliateId ? tableData.data[tableData.row.index].item_id : campaignId}`} onClick={(e) => handleReviewRequest(tableData.data[tableData.row.index].request_id, tableData.data[tableData.row.index].item_id, e)}>
                                {status} - {reason}
                            </a>
                        )
                    } else {
                        if (status.toLowerCase() === 'deleted') {
                            return (
                                <a href={`/campaigns/approvalRequests/${tableData.data[tableData.row.index].request_id}?affiliateId=${affiliateId ? affiliateId : tableData.data[tableData.row.index].item_id}&campaignId=${affiliateId ? tableData.data[tableData.row.index].item_id : campaignId}`} className="text-danger" onClick={(e) => handleReviewRequest(tableData.data[tableData.row.index].request_id, tableData.data[tableData.row.index].item_id, e)}>
                                    {status}
                                </a>
                            )
                        }

                        return (
                            <a href={`/campaigns/approvalRequests/${tableData.data[tableData.row.index].request_id}?affiliateId=${affiliateId ? affiliateId : tableData.data[tableData.row.index].item_id}&campaignId=${affiliateId ? tableData.data[tableData.row.index].item_id : campaignId}`} onClick={(e) => handleReviewRequest(tableData.data[tableData.row.index].request_id, tableData.data[tableData.row.index].item_id, e)}>
                                {status}
                            </a>
                        )
                    }
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={list} columns={columns} />
            </React.Fragment>
        )
    }

    const handleReviewRequest = (requestId, itemId, e) => {
        e.preventDefault()
        setRequestId(requestId)
        affiliateId ? setSelectedOfferId(itemId) : setSelectedAffiliateId(itemId)
        setShowReviewModal(true)
    }

    const handleClose = (isEdited = false) => {
        setShowReviewModal(false)

        if (isEdited) {
            getReport()
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Requests Summary</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="card">
                <Modal show={showReviewModal} onHide={handleClose} size="xl">
                    <ReviewRequest affiliateId={affiliateId} campaignId={campaignId} requestId={requestId} selectedOfferId={affiliateId ? selectedOfferId : null} selectedAffiliateId={campaignId ? selectedAffiliateId : null} handleClose={handleClose} showSystemMessage={showSystemMessage} />
                </Modal>
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                            </div>
                        </div>
                    </div>
                    {dataLoaded ? list.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default CampaignRequestsSummary
