import React, { useState } from 'react'

import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import Loading from '../../../../templates/UI/Loading/Loading'
import AamSummary from '../AamSummary/AamSummary'
import FollowupQueue from '../AdvertiserFollowupQueue/AdvertiserFollowupQueue'
import APILeadsReview from '../APILeadsReview/APILeadsReview'
import CampaignCapWatch from '../CampaignCapWatch/CampaignCapWatch'
import CampaignRequests from '../CampaignRequests/CampaignRequests'
import CampaignsInSetup from '../CampaignsInSetup/CampaignsInSetup'
import CreditChecks from '../CreditChecks/CreditChecks'
import LandingPagesReview from '../LandingPagesReview/LandingPagesReview'
import OpenIssues from '../OpenIssues/OpenIssues'
import ThresholdAdvertiserBalances from '../ThresholdAdvertiserBalances/ThresholdAdvertiserBalances'
import TopCampaignsOfTheWeek from '../TopCampaignsOfTheWeek/TopCampaignsOfTheWeek'
import YesterdaysCapHits from '../YesterdaysCapHits/YesterdaysCapHits'

export default function AdvertiserManager({ manager, setManager, showSystemMessage }) {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'aam_dashboard')

    const [dataLoaded, setDataLoaded] = useState(true)

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    <div className="page-header d-flex align-items-center">
                        <h1 className="mb-h1">Advertiser Manager</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <AamSummary manager={manager} setManager={setManager} showSetManager={true} />
                            <FollowupQueue manager={manager} type="advertiser" />
                            <OpenIssues manager={manager} showSystemMessage={showSystemMessage} />
                            <CreditChecks manager={manager} showSystemMessage={showSystemMessage} />
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-lg-6">
                                    <CampaignCapWatch manager={manager} showSystemMessage={showSystemMessage} />
                                    <YesterdaysCapHits manager={manager} showSystemMessage={showSystemMessage} />
                                    <CampaignRequests manager={manager} showSystemMessage={showSystemMessage} />
                                    <TopCampaignsOfTheWeek showSystemMessage={showSystemMessage} />
                                </div>
                                <div className="col-lg-6">
                                    <ThresholdAdvertiserBalances manager={manager} showSystemMessage={showSystemMessage} from="manager" />
                                    <CampaignsInSetup manager={manager} showSystemMessage={showSystemMessage} />
                                    <APILeadsReview manager={manager} showSystemMessage={showSystemMessage} />
                                    <LandingPagesReview manager={manager} showSystemMessage={showSystemMessage} limit={10} />
                                    <div>&nbsp;</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}
