import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

import Loading from '../../../templates/UI/Loading/Loading'

const ReportMargin = (props) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'report_margin')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const { startDate, endDate, mgrId, showSystemMessage } = props
    const history = useHistory()

    const urlParams = queryString.parse(history.location.search)
    let payable = urlParams.payable ? urlParams.payable === 'true' : false
    let waiting = urlParams.waiting ? urlParams.waiting === 'true' : false
    let revshare = urlParams.revshare ? urlParams.revshare === 'true' : false
    let perlead = urlParams.perlead ? urlParams.perlead === 'true' : false

    useEffect(() => {
        setDataLoaded(false)

        const mbToken = localStorage.getItem('adm-auth-token')

        let fetchUrl = `${process.env.REACT_APP_API_URL}/reports/marginreport?startDate=${startDate}&endDate=${endDate}&page=all`

        if (mgrId > 0) {
            fetchUrl += `&mgrid=${mgrId}`
        }

        if (payable === true) {
            fetchUrl += `&payable=true`
        }

        if (waiting === true) {
            fetchUrl += `&waiting=true`
        }

        if (revshare === true) {
            fetchUrl += `&revshare=true`
        }

        if (perlead === true) {
            fetchUrl += `&perlead=true`
        }

        fetch(fetchUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true && result.report) {
                    setDataLoaded(true)
                    setReport(result.report)
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error fetching the data. Please try again.')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in MarginReport: ', error)
                showSystemMessage('error', 'Connection error.')
            })
    }, [startDate, endDate, payable, revshare, waiting, perlead, showSystemMessage])

    const getReportOutput = () => {
        let netEarningsAccessor1 = ''
        let netEarningsAccessor2 = ''
        let affEarningsAccessor1 = ''
        let affEarningsAccessor2 = ''
        let marginAccessor1 = ''
        let marginAccessor2 = ''
        let pctMarginAccessor1 = ''
        let pctMarginAccessor2 = ''
        let grandTotalNet1 = 0
        let grandTotalNet2 = 0
        let grandTotalAff1 = 0
        let grandTotalAff2 = 0
        let grandTotalMargin1 = 0
        let grandTotalMargin2 = 0
        let grandPctMargin1 = 0
        let grandPctMargin2 = 0

        /*
         * The keys in JSON response will be different for different combinations of Payable, Waiting, Revshare and Perlead.  Creating various accessors for those keys.
         */

        if (payable === true && waiting === true) {
            if (revshare === true && perlead === false) {
                netEarningsAccessor1 = 'pct_network_earnings_payable'
                netEarningsAccessor2 = 'pct_network_earnings_waiting'
                affEarningsAccessor1 = 'pct_affiliate_earnings_payable'
                affEarningsAccessor2 = 'pct_affiliate_earnings_waiting'
                marginAccessor1 = 'pct_margin_payable'
                marginAccessor2 = 'pct_margin_waiting'
                pctMarginAccessor1 = 'pct_margin_percentage_payable'
                pctMarginAccessor2 = 'pct_margin_percentage_waiting'
            } else if (revshare === false && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings_payable'
                netEarningsAccessor2 = 'flat_network_earnings_waiting'
                affEarningsAccessor1 = 'flat_affiliate_earnings_payable'
                affEarningsAccessor2 = 'flat_affiliate_earnings_waiting'
                marginAccessor1 = 'flat_margin_payable'
                marginAccessor2 = 'flat_margin_waiting'
                pctMarginAccessor1 = 'flat_margin_percentage_payable'
                pctMarginAccessor2 = 'flat_margin_percentage_waiting'
            } else {
                netEarningsAccessor1 = 'network_earnings_payable'
                netEarningsAccessor2 = 'network_earnings_waiting'
                affEarningsAccessor1 = 'affiliate_earnings_payable'
                affEarningsAccessor2 = 'affiliate_earnings_waiting'
                marginAccessor1 = 'total_margin_payable'
                marginAccessor2 = 'total_margin_waiting'
                pctMarginAccessor1 = 'margin_percentage_payable'
                pctMarginAccessor2 = 'margin_percentage_waiting'
            }
        } else if (payable === true && waiting === false) {
            if (revshare === true && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings_payable'
                netEarningsAccessor2 = 'pct_network_earnings_payable'
                affEarningsAccessor1 = 'flat_affiliate_earnings_payable'
                affEarningsAccessor2 = 'pct_affiliate_earnings_payable'
                marginAccessor1 = 'flat_margin_payable'
                marginAccessor2 = 'pct_margin_payable'
                pctMarginAccessor1 = 'flat_margin_percentage_payable'
                pctMarginAccessor2 = 'pct_margin_percentage_payable'
            } else if (revshare === true && perlead === false) {
                netEarningsAccessor1 = 'pct_network_earnings_payable'
                affEarningsAccessor1 = 'pct_affiliate_earnings_payable'
                marginAccessor1 = 'pct_margin_payable'
                pctMarginAccessor1 = 'pct_margin_percentage_payable'
            } else if (revshare === false && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings_payable'
                affEarningsAccessor1 = 'flat_affiliate_earnings_payable'
                marginAccessor1 = 'flat_margin_payable'
                pctMarginAccessor1 = 'flat_margin_percentage_payable'
            } else {
                netEarningsAccessor1 = 'network_earnings_payable'
                affEarningsAccessor1 = 'affiliate_earnings_payable'
                marginAccessor1 = 'total_margin_payable'
                pctMarginAccessor1 = 'margin_percentage_payable'
            }
        } else if (payable === false && waiting === true) {
            if (revshare === true && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings_waiting'
                netEarningsAccessor2 = 'pct_network_earnings_waiting'
                affEarningsAccessor1 = 'flat_affiliate_earnings_waiting'
                affEarningsAccessor2 = 'pct_affiliate_earnings_waiting'
                marginAccessor1 = 'flat_margin_waiting'
                marginAccessor2 = 'pct_margin_waiting'
                pctMarginAccessor1 = 'flat_margin_percentage_waiting'
                pctMarginAccessor2 = 'pct_margin_percentage_waiting'
            } else if (revshare === true && perlead === false) {
                netEarningsAccessor1 = 'pct_network_earnings_waiting'
                affEarningsAccessor1 = 'pct_affiliate_earnings_waiting'
                marginAccessor1 = 'pct_margin_waiting'
                pctMarginAccessor1 = 'pct_margin_percentage_waiting'
            } else if (revshare === false && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings_waiting'
                affEarningsAccessor1 = 'flat_affiliate_earnings_waiting'
                marginAccessor1 = 'flat_margin_waiting'
                pctMarginAccessor1 = 'flat_margin_percentage_waiting'
            } else {
                netEarningsAccessor1 = 'network_earnings_waiting'
                affEarningsAccessor1 = 'affiliate_earnings_waiting'
                marginAccessor1 = 'total_margin_waiting'
                pctMarginAccessor1 = 'margin_percentage_waiting'
            }
        } else {
            if (revshare === true && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings'
                netEarningsAccessor2 = 'pct_network_earnings'
                affEarningsAccessor1 = 'flat_affiliate_earnings'
                affEarningsAccessor2 = 'pct_affiliate_earnings'
                marginAccessor1 = 'flat_margin'
                marginAccessor2 = 'pct_margin'
                pctMarginAccessor1 = 'flat_margin_percentage'
                pctMarginAccessor2 = 'pct_margin_percentage'
            } else if (revshare === true && perlead === false) {
                netEarningsAccessor1 = 'pct_network_earnings'
                affEarningsAccessor1 = 'pct_affiliate_earnings'
                marginAccessor1 = 'pct_margin'
                pctMarginAccessor1 = 'pct_margin_percentage'
            } else if (revshare === false && perlead === true) {
                netEarningsAccessor1 = 'flat_network_earnings'
                affEarningsAccessor1 = 'flat_affiliate_earnings'
                marginAccessor1 = 'flat_margin'
                pctMarginAccessor1 = 'flat_margin_percentage'
            } else {
                netEarningsAccessor1 = 'network_earnings'
                affEarningsAccessor1 = 'affiliate_earnings'
                marginAccessor1 = 'total_margin'
                pctMarginAccessor1 = 'margin_percentage'
            }
        }

        let subHeaders = ''

        if (payable === true && waiting === true) {
            subHeaders = (
                <tr>
                    <th>Date</th>
                    <th className="text-center">Payable</th>
                    <th className="text-center">Waiting</th>
                    <th className="text-center">Payable</th>
                    <th className="text-center">Waiting</th>
                    <th className="text-center">Payable</th>
                    <th className="text-center">Waiting</th>
                    <th className="text-center">Payable</th>
                    <th className="text-center">Waiting</th>
                </tr>
            )
        } else if (revshare === true && perlead === true) {
            subHeaders = (
                <tr>
                    <th>Date</th>
                    <th className="text-center">$</th>
                    <th className="text-center">%</th>
                    <th className="text-center">$</th>
                    <th className="text-center">%</th>
                    <th className="text-center">$</th>
                    <th className="text-center">%</th>
                    <th className="text-center">$</th>
                    <th className="text-center">%</th>
                </tr>
            )
        }

        let headerClass = '',
            tableClasses = `table table-striped table-card table-hover mb-0 text-small`

        if ((payable === true && waiting === true) || (revshare === true && perlead === true)) {
            headerClass = 'text-center'
            tableClasses += ` table-bordered`
        }

        return (
            <table className={tableClasses}>
                <thead>
                    <tr className={headerClass}>
                        <th scope="col">{(payable === true && waiting === true) || (revshare === true && perlead === true) ? '' : 'Date'}</th>
                        <th scope="col" className="text-right" colSpan={netEarningsAccessor2 !== '' ? '2' : '1'}>
                            Network Earnings
                        </th>
                        <th scope="col" className="text-right" colSpan={affEarningsAccessor2 !== '' ? '2' : '1'}>
                            Affiliate Earnings
                        </th>
                        <th scope="col" className="text-right" colSpan={marginAccessor2 !== '' ? '2' : '1'}>
                            Margin
                        </th>
                        <th scope="col" className="text-right" colSpan={pctMarginAccessor2 !== '' ? '2' : '1'}>
                            Margin %
                        </th>
                    </tr>
                    {subHeaders}
                </thead>
                <tbody>
                    {report.map((row) => {
                        // Calculating for totals
                        grandTotalNet1 += row[netEarningsAccessor1]
                        grandTotalNet2 += row[netEarningsAccessor2]
                        grandTotalAff1 += row[affEarningsAccessor1]
                        grandTotalAff2 += row[affEarningsAccessor2]
                        grandTotalMargin1 += row[marginAccessor1]
                        grandTotalMargin2 += row[marginAccessor2]
                        grandPctMargin1 += row[pctMarginAccessor1]
                        grandPctMargin2 += row[pctMarginAccessor2]

                        return (
                            <tr key={row.rev_date}>
                                <td>
                                    <Link to={`/reports/stats/campaigns?startDate=${moment.utc(row.rev_date).format('YYYY/MM/DD')}&endDate=${moment.utc(row.rev_date).format('YYYY/MM/DD')}`}>
                                        {moment.utc(row.rev_date).format('YYYY/MM/DD')} ({moment(row.rev_date).format('dddd')})
                                    </Link>
                                </td>
                                <td className="text-right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row[netEarningsAccessor1])}</td>
                                {netEarningsAccessor2 !== '' && <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row[netEarningsAccessor2])}</td>}
                                <td className="text-right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row[affEarningsAccessor1])}</td>
                                {affEarningsAccessor2 !== '' && <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row[affEarningsAccessor2])}</td>}
                                <td className="text-right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row[marginAccessor1])}</td>
                                {marginAccessor2 !== '' && <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(row[marginAccessor2])}</td>}
                                <td className="text-right">{(row[pctMarginAccessor1] * 100).toFixed(1)}%</td>
                                {pctMarginAccessor2 !== '' && <td>{(row[pctMarginAccessor2] * 100).toFixed(1)}%</td>}
                            </tr>
                        )
                    })}
                    <tr className="font-weight-bold">
                        <td>&nbsp;</td>
                        <td className="text-right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(grandTotalNet1)}</td>
                        {netEarningsAccessor2 !== '' && <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(grandTotalNet2)}</td>}
                        <td className="text-right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(grandTotalAff1)}</td>
                        {affEarningsAccessor2 !== '' && <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(grandTotalAff2)}</td>}
                        <td className="text-right">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(grandTotalMargin1)}</td>
                        {marginAccessor2 !== '' && <td>${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(grandTotalMargin2)}</td>}
                        <td className="text-right">
                            {`${!isNaN(grandTotalMargin1 / grandTotalNet1) ? ((grandTotalMargin1 / grandTotalNet1) * 100).toFixed(1) : '-'}`}
                            {!isNaN(grandTotalMargin1 / grandTotalNet1) ? '%' : ''}
                        </td>
                        {pctMarginAccessor2 !== '' && (
                            <td className="text-right">
                                {`${!isNaN(grandTotalMargin2 / grandTotalNet2) ? ((grandTotalMargin2 / grandTotalNet2) * 100).toFixed(1) : '-'}`}
                                {!isNaN(grandTotalMargin2 / grandTotalNet2) ? '%' : ''}
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        )
    }

    return (
        <div>
            <hr className="mt-0 mb-3"></hr>
            {/* <div className="d-flex mx-2">
                {property.propertyId > 0 &&
                    <DrilldownItem for="Property" forUrl={`/avatar/property/${property.propertyId}`} forText={property.name} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=0&siteId=0&surveyId=0&affiliateId=${affiliate.affiliateId}&offerId=${offer.offerid}`}></DrilldownItem>
                }
                {site.siteId > 0 &&
                    <DrilldownItem for="Site" forUrl={`/avatar/site/${site.siteId}`} forText={site.name} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=0&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=${offer.offerid}`}></DrilldownItem>
                }
                {survey.surveyId > 0 &&
                    <DrilldownItem for="Survey" forUrl={`/avatar/survey/${survey.surveyId}`} forText={survey.name} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=0&affiliateId=${affiliate.affiliateId}&offerId=${offer.offerid}`}></DrilldownItem>
                }
                {affiliate.affiliateId > 0 &&
                    <DrilldownItem for="Affiliate" forUrl={`/affiliate/${affiliateId}`} forText={affiliate.name} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=0&offerId=${offer.offerid}`}></DrilldownItem>
                }
                {offer.offerId > 0 &&
                    <DrilldownItem for="Offer" forUrl={`/offer/${offerId}`} forText={offer.name} cancelDrilldownUrl={`/avatar/reports/affiliate?startDate=${props.startDate}&endDate=${props.endDate}&propertyId=${property.propertyId}&siteId=${site.siteId}&surveyId=${survey.surveyId}&affiliateId=${affiliate.affiliateId}&offerId=0`}></DrilldownItem>
                }
            </div> */}
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
        </div>
    )
}

export default ReportMargin
