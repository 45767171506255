import React, { useState, useEffect } from 'react'
import { Modal } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

import Property from '../Templates/Property/Property'
import Loading from '../../../templates/UI/Loading/Loading'
import callAPI from '../../../helpers/callAPI'

export default function AvatarPropertyManager({ showSystemMessage }) {

    document.title = 'Avatar Property Manager'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'avatar')

    const [dataLoaded, setDataLoaded] = useState(false)
    const [createPropertyModalOpen, setCreatePropertyModalOpen] = useState(false)
    const [createPropertyLoaded, setCreatePropertyLoaded] = useState(true)
    const [properties, setProperties] = useState([])
    const [newPropertyName, setNewPropertyName] = useState('')

    useEffect(() => {
        let url = 'avatar/properties'

        setDataLoaded(false)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setDataLoaded(true)
                    setProperties(result.properties)
                } else if (result['success'] === false) {
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Property Manager: ', error.message)
                showSystemMessage('error', 'Connection error.')
                setDataLoaded(true)
            })
    }, [])

    const closePropertyModal = () => {
        setCreatePropertyModalOpen(!createPropertyModalOpen)
    }

    const saveProperty = () => {
        setCreatePropertyLoaded(false)

        const url = 'avatar/properties'

        callAPI(url, 'GET', { domain: newPropertyName })
            .then((result) => {
                if (result['success'] === true) {
                    setCreatePropertyLoaded(true)
                    setCreatePropertyModalOpen(false)
                    setProperties(result.properties)
                } else if (result['success'] === false) {
                    setCreatePropertyLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setCreatePropertyLoaded(true)
                }
            })
            .catch((error) => {
                console.log('Error on Avatar Property Create: ', error.message)
                showSystemMessage('error', 'Connection error.')
                setDataLoaded(true)
            })
    }

    return (
        <React.Fragment>
            <div className="page-header">
                <h1 className="mb-h1">Avatar Property Manager</h1>
            </div>
            {dataLoaded ? (
                <React.Fragment>
                    <Modal isOpen={createPropertyModalOpen} toggle={closePropertyModal} className="modal-md">
                        <div className="modal-header">
                            <h5 className="modal-title">Create New Property</h5>
                            <button type="button" className="close" onClick={closePropertyModal}>
                                <span aria-hidden="true">x</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label htmlFor="propertyDomain">Enter the domain for this property:</label>
                            <input
                                name="propertyDomain"
                                type="text"
                                className="form-control"
                                placeholder="https://example.com"
                                required
                                onChange={(e) => {
                                    setNewPropertyName(e.target.value)
                                }}
                            />
                            <br></br>
                            {createPropertyLoaded ? (
                                <input type="submit" className="btn btn-primary btn-block" value="Create Property" onClick={saveProperty} />
                            ) : (
                                <button className="btn btn-primary btn-block" disabled>
                                    <FontAwesomeIcon icon="spinner" spin />
                                </button>
                            )}
                        </div>
                    </Modal>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="p-3 d-flex justify-content-between align-items-center" style={{ height: '50px' }}>
                                    <h1 className="mb-h1 text-success">Active Properties</h1>
                                </div>
                            </div>
                            <div className="py-3">
                                {properties
                                    .filter((property) => property.status.toLowerCase() === 'active')
                                    .map((property) => (
                                        <Property key={property.propertyId} data={property} showEdit />
                                    ))}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="p-3 d-flex justify-content-between align-items-center ">
                                    <h1 className="mb-h1 text-danger">Inactive Properties</h1>
                                    <button className="btn btn-sm btn-warning" onClick={closePropertyModal}>
                                        + New Property
                                    </button>
                                </div>
                            </div>
                            <div className="pt-3">
                                {properties
                                    .filter((property) => property.status.toLowerCase() === 'inactive')
                                    .map((property) => (
                                        <Property key={property.propertyId} data={property} showEdit />
                                    ))}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div style={{ marginTop: '4rem' }}>
                    <Loading />
                </div>
            )}
        </React.Fragment>
    )
}
