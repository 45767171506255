import React from 'react'
import { Mail, Smartphone } from 'react-feather'
import { FaSkype, FaExternalLinkAlt } from 'react-icons/fa'
import convertUrl from '../../../../helpers/convertUrl'

const AffiliateContactInfo = (props) => {
    return (
        <div className="d-flex align-items-center justify-content-lg-between flex-wrap p-2">
            <div className="px-1">
                <FaSkype size={18} color="#007bff" />
                &nbsp;
                <a href={`skype:${props.imHandle}?chat`}>{props.imHandle}</a>
            </div>
            <div className="px-1">
                <Smartphone size={18} />
                {props.mobile ? props.mobile : 'N/A'}
            </div>
            <div className="px-1">
                <a href={`mailto:${props.email}`}>
                    <Mail size={20} style={{ paddingRight: '5px' }} />
                    Email
                </a>
            </div>
            <div className="px-1">
                <a href={`${convertUrl(props.url)}`} rel="noopener noreferrer" target="_blank">
                    Visit Website &nbsp;
                    <FaExternalLinkAlt color="#007bff" />
                </a>
            </div>
        </div>
    )
}

export default AffiliateContactInfo
