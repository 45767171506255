import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { User } from 'react-feather'
import { Modal } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'

import Loading from '../../../../templates/UI/Loading/Loading'
import callAPI from '../../../../helpers/callAPI'
import ReviewApplication from './ReviewApplication/ReviewApplication'
import DuplicateModal from './DuplicateModal/DuplicateModal'
import VerifyApplication from './VerifyApplication/VerifyApplication'

// TODO: Need to fix card for smaller screens; table stretches out of card

const Applications = ({ manager, showSystemMessage, mode }) => {
    const [applications, setApplications] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliateId, setAffiliateId] = useState() // For Modal
    const [showApplicationModal, setShowApplicationModal] = useState(false)
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)

    let dependency = [manager]

    useEffect(() => {
        setDataLoaded(false)
        getApplications()
    }, dependency)

    const getApplications = () => {
        let url = ''

        if (mode === 'affiliate_manager') {
            url = `affiliates/applications?adminId=${manager.id}`
            dependency = [manager]
        } else if (mode === 'compliance') {
            url = `compliance/affiliates/applications`
        }

        setDataLoaded(false)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setApplications(result.applications)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching affiliate applications')
            })
    }

    const handleClick = (e, affiliateId) => {
        e.preventDefault()
        setAffiliateId(affiliateId)
        setShowApplicationModal(true)
    }

    const toggleModal = () => {
        setShowApplicationModal(false)
        setShowDuplicateModal(false)
    }

    const handleDupClick = (affiliateId) => {
        setAffiliateId(affiliateId)
        setShowDuplicateModal(true)
    }

    const handleClose = () => {
        setShowApplicationModal(false)
        getApplications()
    }

    let applicationModal

    if (mode === 'affiliate_manager') {
        applicationModal = (
            <Modal isOpen={showApplicationModal} toggle={toggleModal} className="modal-lg">
                <ReviewApplication showSystemMessage={showSystemMessage} affiliateId={affiliateId} handleReviewClose={handleClose} manager={manager} />
            </Modal>
        )
    } else if (mode === 'compliance') {
        applicationModal = (
            <Modal isOpen={showApplicationModal} toggle={toggleModal} className="modal-lg">
                <VerifyApplication showSystemMessage={showSystemMessage} affiliateId={affiliateId} handleReviewClose={handleClose} />
            </Modal>
        )
    }

    return (
        <div className="card mb-3 w-100 overflow-auto">
            {applicationModal}
            {showDuplicateModal && <DuplicateModal showSystemMessage={showSystemMessage} affiliateId={affiliateId} handleClose={toggleModal} isOpen={showDuplicateModal} />}

            <div className="card-header justify-content-between d-flex">
                <div>Applications</div>
                <div>
                    In queue: <span>{mode === 'affiliate_manager' ? <Link to={`/affiliates/applications?adminId=${manager.id}`}>{applications.length}</Link> : <Link to={`/compliance/applications?adminId=${manager.id}`}>{applications.length}</Link>}</span>
                </div>
            </div>
            <div className="card-body p-0" style={{ fontSize: '0.9rem' }}>
                {dataLoaded ? (
                    applications.length === 0 ? (
                        <div className="p-2">No stats available</div>
                    ) : (
                        <table className="table table-striped table-hover table-responsive-sm m-0">
                            <thead className="text-secondary">
                                <tr>
                                    <th>ID</th>
                                    <th>Affiliate</th>
                                    <th width="100px">Exp</th>
                                    <th width="100px">Country</th>
                                    <th width="125px">Local/Best Time</th>
                                    <th>Date</th>
                                    {mode === 'compliance' && <th>Dup</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {applications.slice(0, 20).map((app) => {
                                    let countryClass = app.country_tier === 1 ? 'text-success font-weight-bold' : ''
                                    let experienceClass = app.experience_level.value === 3 ? 'text-success font-weight-bold' : ''

                                    return (
                                        <tr key={app.affiliate.id} className={app.experience_level.value === 3 || (app.experience_level.value === 1 && app.country_tier === 1) ? 'bgSuccess25' : ''}>
                                            <td>{app.affiliate.id}</td>
                                            <td>
                                                <FontAwesomeIcon icon={faAddressCard} className="text-primary mr-1" />
                                                <Link to={{ pathname: '' }} onClick={(e) => handleClick(e, app.affiliate.id)}>
                                                    {app.affiliate.name}
                                                </Link>
                                                {app.experience_level.value === 3 && app.country_tier === 1 ? <span className="ml-2">⭐</span> : ''}
                                            </td>
                                            <td>
                                                <span className={experienceClass}>{app.experience_level.text}</span>
                                            </td>
                                            <td>
                                                <span className={countryClass}>{app.country}</span>
                                            </td>
                                            <td>
                                                {app.affiliate_time}, {app.contact_time}
                                            </td>
                                            <td>{moment(app.application_date).format('YYYY/MM/DD')}</td>
                                            {mode === 'compliance' && (
                                                <td className="text-center">
                                                    {app.affiliate.status.value === 'D' ? (
                                                        <button className="btn btn-link shadow-none" onClick={() => handleDupClick(app.affiliate.id)}>
                                                            <User size={14} color={'red'} fill={'red'} />
                                                        </button>
                                                    ) : null}
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </div>
    )
}

export default Applications
