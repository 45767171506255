import { Card, Table, OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import '../../../../theme/classes.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import CreditChecksModal from '../CreditChecksModal/CreditChecksModal'
import Advertiser from '../../../../templates/Advertiser/Advertiser'

const CreditChecks = ({ manager, showSystemMessage }) => {
    //TODO adminuser use manager then fix the query for insert and update in api
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(false)
    const [modalData, setModalData] = useState({
        isOpen: false,
        id: null,
        type: null,
    })

    useEffect(() => {
        const fetchData = async () => {
            const data = await callAPI(`advertisers/creditChecks`, 'GET', null)
            if (data.success) {
                setData(data)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchData()
    }, [manager, modalData.isOpen])

    const openModal = (type, id) => {
        setModalData((prevState) => ({ ...prevState, id: id, type: type }))
    }

    return (
        <Card className="mb-3">
            <CreditChecksModal manager={manager} modalData={modalData} setModalData={setModalData} showSystemMessage={showSystemMessage} />
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Credit Checks</span>
                </span>
                <Button variant="warning" size="sm" onClick={() => openModal('create')}>
                    Create Credit Check
                </Button>
            </Card.Header>
            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>Priority</th>
                            <th>Opened</th>
                            <th>Updated</th>
                            <th>Assigned</th>
                            <th>Advertiser</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data || data.length === 0 ? (
                                    <span>
                                        <div colSpan={5}>No data.</div>
                                    </span>
                                ) : (
                                    <>
                                        {data.credit_checks.map((creditCheck) => {
                                            return (
                                                <tr key={creditCheck.id}>
                                                    <td xs={1}>{creditCheck.priority}</td>
                                                    <td xs={4}>{creditCheck.opened.date}</td>
                                                    <td xs={5}>{creditCheck.updated}</td>
                                                    <td xs={2}>{creditCheck.assigned.admin.name}</td>
                                                    <td xs={2}>
                                                        <div className="d-flex justify-content-between">
                                                            <Advertiser id={creditCheck.advertiser.id} name={creditCheck.advertiser.name} />
                                                            <button style={{ color: 'orange', marginRight: '15px' }} className="btn btn-sm btn-lnk" onClick={() => openModal('edit', creditCheck.id)}>
                                                                <FontAwesomeIcon icon={faPen} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default CreditChecks
