import { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import queryString from 'query-string'

import SearchOptions from './SearchOptions'
import SearchResults from './SearchResults'

const SearchAffiliates = ({ affiliateId, showSystemMessage }) => {
    document.title = 'Affiliate Search'

    const [open, setOpen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState()
    const [searchResults, setSearchResults] = useState([])

    const location = useLocation()
    const history = useHistory()

    let urlParams = queryString.parse(location.search)

    const [status, setStatus] = useState(urlParams.status ? urlParams.status : '')

    const [adminId, setAdminId] = useState(0)
    const [country, setCountry] = useState(urlParams.country ? urlParams.country : '')
    const [flagId, setFlagId] = useState(urlParams.flagId ? urlParams.flagId : '')
    const [priority, setPriority] = useState(urlParams.priority ? urlParams.priority : '')
    const [prevPriority, setPrevPriority] = useState(urlParams.prevPriority ? urlParams.prevPriority : '')
    const [maxPriority, setMaxPriority] = useState(urlParams.maxPriority ? urlParams.maxPriority : '')
    const [incentive, setIncentive] = useState(urlParams.incentive ? (urlParams.incentive === 'true' ? true : false) : false)
    const [network, setNetwork] = useState(urlParams.network ? (urlParams.network === 'true' ? true : false) : false)
    const [trafficNotes, setTrafficNotes] = useState(urlParams.trafficNotes ? (urlParams.trafficNotes === 'true' ? true : false) : false)

    const buildUrl = (url) => {
        if (status !== '') {
            url += `&status=${status}`
        }

        if (adminId !== 0 && adminId !== '') {
            url += `&adminId=${adminId}`
        }

        if (country !== '') {
            url += `&country=${country}`
        }

        if (flagId !== '') {
            url += `&flagId=${flagId}`
        }

        if (priority !== '') {
            url += `&priority=${priority}`
        }

        if (prevPriority !== '') {
            url += `&prevPriority=${prevPriority}`
        }

        if (maxPriority !== '') {
            url += `&maxPriority=${maxPriority}`
        }

        if (incentive) {
            url += `&incentive=true`
        }

        if (network) {
            url += `&network=true`
        }

        if (trafficNotes) {
            url += `&trafficNotes=true`
        }

        return url
    }

    useEffect(() => {
        setDataLoaded(false)

        // Grab the settings from the URL Bar if location changes
        setStatus(urlParams.status ? urlParams.status : status ? status : '')
        setAdminId(urlParams.adminId && parseInt(urlParams.adminId) > 0 ? parseInt(urlParams.adminId) : parseInt(adminId) > 0 ? adminId : 0)
        setCountry(urlParams.country ? urlParams.country : country ? country : '')
        setFlagId(urlParams.flagId ? urlParams.flagId : flagId ? flagId : '')
        setPriority(urlParams.priority ? urlParams.priority : priority ? priority : '')
        setPrevPriority(urlParams.prevPriority ? urlParams.prevPriority : prevPriority ? prevPriority : '')
        setMaxPriority(urlParams.maxPriority ? urlParams.maxPriority : maxPriority ? maxPriority : '')
        setIncentive(urlParams.incentive ? urlParams.incentive : incentive ? incentive : false)
        setNetwork(urlParams.network ? urlParams.network : network ? network : false)
        setTrafficNotes(urlParams.trafficNotes ? urlParams.trafficNotes : trafficNotes ? trafficNotes : false)

        getResults(urlParams.searchTerm ? urlParams.searchTerm : '')
    }, [location])

    const getResults = (searchTerm) => {
        if (location.search.length > 0) {
            // Only if there are query params

            let url = buildUrl(`affiliates/search?searchTerm=${searchTerm}`)

            callAPI(url, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setSearchResults(result.search_results)
                        setDataLoaded(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                        setDataLoaded(true)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', 'An error occurred while getting searching affiliates')
                    // setDataLoaded(true)
                })
        } else {
            setDataLoaded(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let url = buildUrl(`/affiliates/search?searchTerm=${e.target.search_term.value}`)
        history.push(url)
    }

    let results

    if (dataLoaded === false) {
        results = <Loading />
    } else {
        results = <SearchResults searchResults={searchResults} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Affiliate Search</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <form className="form-group mb-0" onSubmit={handleSubmit}>
                        <div className="d-flex p-3 align-items-center justify-content-center" style={{ backgroundColor: '#f4f4f4' }}>
                            <input className="form-control mr-2 w-75" type="text" name="search_term" placeholder="Affiliate's Name, email, company, etc." autoComplete="off" defaultValue={urlParams.searchTerm ? urlParams.searchTerm : ''} />
                            <button type="submit" className="btn btn-primary mr-2">
                                Search
                            </button>
                            <button type="button" className="btn btn-link no-shadow" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
                                Search Options
                            </button>
                        </div>
                        <Collapse in={open}>
                            {/* Must wrap the component in <div> to make Collapse work*/}
                            <div>
                                <SearchOptions status={status} setStatus={setStatus} adminId={adminId} setAdminId={setAdminId} country={country} setCountry={setCountry} flagId={flagId} setFlagId={setFlagId} priority={priority} setPriority={setPriority} prevPriority={prevPriority} setPrevPriority={setPrevPriority} maxPriority={maxPriority} setMaxPriority={setMaxPriority} incentive={incentive} network={network} trafficNotes={trafficNotes} setIncentive={setIncentive} setNetwork={setNetwork} setTrafficNotes={setTrafficNotes} showSystemMessage={showSystemMessage} />
                            </div>
                        </Collapse>
                    </form>
                    {results}
                </div>
            </div>
        </div>
    )
}

export default SearchAffiliates
