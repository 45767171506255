import { Button, Card, Form } from 'react-bootstrap'
import { useState } from 'react'
import callAPI from '../../../helpers/callAPI'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const SendPaymentNotices = ({ showSystemMessage }) => {

    document.title = 'Send Affiliate Payment Notices'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'send_aff_payment_notices')

    const [formData, setFormData] = useState({
        content: '',
        send: false,
    })
    const sendEmail = async () => {
        if (formData.send) {
            const result = await callAPI('affiliates/paymentNotices', 'POST', formData)
            if (result.success) {
                showSystemMessage('success', 'Emails Sent')
            } else {
                showSystemMessage('error', 'Emails Failed')
            }
        } else {
            showSystemMessage('error', 'Please check the box to send emails')
        }
    }

    return (
        <div className="row">
            <div className="col-lg-6">
                <Card className="p-0">
                    <Card.Header>
                        <div className="mb-h1-dark">Send Payment Notices</div>
                    </Card.Header>
                    <Card.Body>
                        <div className="m-3">
                            Extra Content
                            <Form.Control as="textarea" style={{ height: '100px' }} value={formData.content} onChange={(e) => setFormData((prev) => ({ ...prev, content: e.target.value }))} />
                        </div>
                        <div className="m-3">
                            Okay to Send?
                            <input className="ml-3" type="checkbox" value={formData.send} onChange={(e) => setFormData((prev) => ({ ...prev, send: e.target.checked }))} />
                        </div>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                        <Button className="btn btn-warning" onClick={sendEmail}>
                            Send Email
                        </Button>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default SendPaymentNotices
