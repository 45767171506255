import { Card, Row, Col, Table, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import TimeScalePicker from '../../../../components/TimeScalePicker/TimeScalePicker'
import { colors } from '../../../../theme/variables'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRegular, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import TransferAffiliateModal from '../TransferAffiliateModal/TransferAffiliateModal'
import useCallApi from '../../../../hooks/useCallApi'

const HMTransferToSrAM = ({ manager, showSystemMessage }) => {
    const [affTransferModalData, setAffTransferModalData] = useState({
        isOpen: false,
        affiliate: {
            id: null,
            name: null,
            priority: null,
        },
        adminId: null,
    })

    const { dataLoaded, data, refetch } = useCallApi(`affiliates/highMediumToTransfer`, 'GET', null, [])

    useEffect(() => {
        refetch()
    }, [])

    return (
        <Card className="mb-3">
            <TransferAffiliateModal pageReload={refetch} affTransferModalData={affTransferModalData} setAffTransferModalData={setAffTransferModalData} showSystemMessage={showSystemMessage} />
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>High/Medium To Transfer To Senior AM's</span>
            </Card.Header>

            <Card.Body className="p-0 overflow-auto">
                <Table striped hover className="w-100 m-0">
                    <thead>
                        <tr className="text-secondary">
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th style={{ textAlign: 'right' }}>Earnings this month</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            <>
                                {!data.affiliates || data.affiliates.length === 0 ? (
                                    <tr>
                                        <td colSpan={12}>No data.</td>
                                    </tr>
                                ) : (
                                    data.affiliates.map((item) => (
                                        <tr key={item.affiliate.id}>
                                            <td>{item.affiliate.id}</td>
                                            <td>
                                                <span className={`mb-element ${item.affiliate.status === 'C' ? 'cancelled' : null} ${item.affiliate.status <= 2 ? 'highlight' : null}`}>
                                                    <Affiliate aid={`${item.affiliate.id}`} name={`${item.affiliate.name}`} status={`${item.affiliate.status}`} priority={`${item.affiliate.priority}`} />
                                                </span>
                                            </td>
                                            <td align="right">
                                                <Dollars value={item.stats.earnings_this_month} />
                                            </td>
                                            <td align="right">
                                                <button className="btn btn-sm btn-lnk" onClick={() => setAffTransferModalData((prevState) => ({ ...prevState, isOpen: true, affiliate: { id: item.affiliate.id, name: item.affiliate.name, priority: item.affiliate.priority } }))}>
                                                    <FontAwesomeIcon icon={faChevronRight} style={{ color: colors.orange }} size={14} />
                                                    <FontAwesomeIcon icon={faChevronRight} style={{ color: colors.orange }} size={14} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </>
                        ) : (
                            <tr>
                                <td colSpan={5} align="center">
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default HMTransferToSrAM
