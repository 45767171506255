import React, { useState } from 'react'

import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import CreateFlag from './CreateFlag/CreateFlag'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const FlagTypes = ({ showSystemMessage }) => {

    document.title = 'Manage Flag Types'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'manage_flags')

    const { dataLoaded, data, refetch } = useCallApi(`admin/flags`, 'GET', {}, [])

    const [selectedFlagId, setSelectedFlagId] = useState(0)
    const [showCreateFlagModal, setShowCreateFlagModal] = useState(false)

    const handleDelete = (flagId) => {
        setSelectedFlagId(flagId)
    }

    const confirmDelete = (e) => {
        e.preventDefault()

        const deleteBody = {
            flag_id: selectedFlagId,
        }

        callAPI(`admin/flags`, 'DELETE', deleteBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    refetch()
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        showSystemMessage('error', 'There was an error while deleting the flag. Please try again')
                    }
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error in FlagSettings: ', error)
                showSystemMessage('error', 'Connection Error')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Flag ID',
                accessor: 'flag_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Flag Name',
                accessor: 'flag_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'System Flag',
                accessor: 'system_flag',
                Cell: ({ cell: { value } }) => {
                    return <>{value === 'Y' ? 'Yes' : 'No'}</>
                },
            },
            {
                Header: 'Flag Target',
                accessor: 'flag_target',
            },
            {
                Header: '',
                accessor: 'flags',
                Cell: (tableData) => {
                    let flagId = tableData.data[tableData.row.index].flag_id

                    return (
                        <form>
                            {tableData.data[tableData.row.index].system_flag === 'N' ? (
                                flagId === selectedFlagId ? (
                                    <button type="button" className="btn btn-danger btn-sm" onClick={confirmDelete}>
                                        Confirm
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(flagId)}>
                                        Delete
                                    </button>
                                )
                            ) : (
                                <></>
                            )}
                        </form>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.flags} columns={columns} />
            </React.Fragment>
        )
    }

    const handleClose = () => {
        setShowCreateFlagModal(false)
    }

    return (
        <div>
            {showCreateFlagModal && <CreateFlag isOpen={showCreateFlagModal} handleClose={handleClose} showSystemMessage={showSystemMessage} refetch={refetch} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Flag Types</h1>
            </div>
            <div className="card">
                <div className="d-flex justify-content-between p-3 align-items-center">
                    <h1 className="mb-h1-dark mr-2">Flag Types</h1>
                    <button type="button" className="btn btn-warning btn-sm" onClick={() => setShowCreateFlagModal(true)}>
                        Create Flag
                    </button>
                </div>
                {dataLoaded ? data.flags.length === 0 ? <div className="p-3">No flags</div> : getReportOutput() : <Loading />}
            </div>
        </div>
    )
}

export default FlagTypes
