import React from 'react'
import { Mail, Smartphone, User } from 'react-feather'
import { FaSkype } from 'react-icons/fa'

const AdvertiserContactInfo = ({ contactInfo, email, contact }) => {
    return (
        <div className="d-flex align-items-center justify-content-lg-between flex-wrap p-2">
            <div className="px-1 align-items-center d-flex">
                <User size={13} fill="black" />
                &nbsp;
                {contact}
            </div>
            <div className="px-1 align-items-center d-flex">
                <FaSkype size={18} color="#007bff" />
                &nbsp;
                {contactInfo.im_handle ? <a href={`skype:${contactInfo.im_handle}?chat`}>{contactInfo.im_handle}</a> : 'N/A'}
            </div>
            <div className="px-1 align-items-center d-flex">
                <Smartphone size={18} />
                {contactInfo.mobile ? contactInfo.mobile : contactInfo.phone ? contactInfo.phone : 'N/A'}
            </div>
            <div className="px-1 d-flex align-items-center">
                <a href={`mailto:${email}`}>
                    <Mail size={20} style={{ paddingRight: '5px' }} />
                    Email
                </a>
            </div>
        </div>
    )
}

export default AdvertiserContactInfo
