import { useState, useEffect } from 'react'
import queryString from 'query-string'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

export default function useDateRangeFilter(initType) {
    /*
        Defaults to this month
        initTypes: today
    */

    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    const initDates = (urlParams) => {
        let initStartDate = urlParams.startDate ? moment(urlParams.startDate).format('YYYY-MM-DD') : initType == 'today' ? moment().startOf('day').format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD')
        let initEndDate = urlParams.endDate ? moment(urlParams.endDate).format('YYYY-MM-DD') : initType == 'today' ? moment().startOf('day').format('YYYY-MM-DD') : moment().startOf('day').format('YYYY-MM-DD')
        let initQuickDate = initType == 'today' ? 'today' : 'month'

        if (!urlParams.startDate && !urlParams.endDate) {
            // Use the defaults
        } else if (urlParams.startDate === moment().startOf('month').format('YYYY-MM-DD') && urlParams.endDate === moment().endOf('day').format('YYYY-MM-DD')) {
            initQuickDate = 'month'
        } else if (urlParams.startDate === moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') && urlParams.endDate === moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')) {
            initQuickDate = 'lastMonth'
        } else if (urlParams.startDate === moment().startOf('day').format('YYYY-MM-DD') && urlParams.endDate === moment().startOf('day').format('YYYY-MM-DD')) {
            initQuickDate = 'today'
        } else if (urlParams.startDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD') && urlParams.endDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD')) {
            initQuickDate = 'yesterday'
        } else if (urlParams.startDate === moment().day(1).subtract(1, 'day').format('YYYY-MM-DD') && urlParams.endDate === moment().day(1).add(5, 'days').format('YYYY-MM-DD')) {
            initQuickDate = 'thisPayPeriod'
        } else if (urlParams.startDate === moment().day(1).subtract(8, 'days').format('YYYY-MM-DD') && urlParams.endDate === moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')) {
            initQuickDate = 'thisPayPeriod'
        } else {
            initQuickDate = 'custom'
        }

        // If using as second date range, init is always the same
        let initStartDate2 = urlParams.startDate2 ? moment(urlParams.startDate2).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD')
        let initEndDate2 = urlParams.endDate2 ? moment(urlParams.endDate2).format('YYYY-MM-DD') : moment().startOf('day').format('YYYY-MM-DD')
        let initQuickDate2 = 'month'

        if (!urlParams.startDate2 && !urlParams.endDate2) {
            // Use the defaults
        } else if (urlParams.startDate2 === moment().startOf('month').format('YYYY-MM-DD') && urlParams.endDate2 === moment().endOf('day').format('YYYY-MM-DD')) {
            initQuickDate2 = 'month'
        } else if (urlParams.startDate2 === moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') && urlParams.endDate2 === moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')) {
            initQuickDate2 = 'lastMonth'
        } else if (urlParams.startDate2 === moment().startOf('day').format('YYYY-MM-DD') && urlParams.endDate2 === moment().startOf('day').format('YYYY-MM-DD')) {
            initQuickDate2 = 'today'
        } else if (urlParams.startDate2 === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD') && urlParams.endDate2 === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD')) {
            initQuickDate2 = 'yesterday'
        } else if (urlParams.startDate2 === moment().day(1).subtract(1, 'day').format('YYYY-MM-DD') && urlParams.endDate2 === moment().day(1).add(5, 'days').format('YYYY-MM-DD')) {
            initQuickDate2 = 'thisPayPeriod'
        } else if (urlParams.startDate2 === moment().day(1).subtract(8, 'days').format('YYYY-MM-DD') && urlParams.endDate2 === moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')) {
            initQuickDate2 = 'thisPayPeriod'
        } else {
            initQuickDate2 = 'custom'
        }

        return { initStartDate: initStartDate, initEndDate: initEndDate, initQuickDate: initQuickDate, initStartDate2: initStartDate2, initEndDate2: initEndDate2, initQuickDate2: initQuickDate2 }
    }

    const { initStartDate, initEndDate, initQuickDate } = initDates(urlParams)
    const [selectedStartDate, setSelectedStartDate] = useState(initStartDate)
    const [selectedEndDate, setSelectedEndDate] = useState(initEndDate)
    const [quickDate, setQuickDate] = useState(initQuickDate)

    const { initStartDate2, initEndDate2, initQuickDate2 } = initDates(urlParams)
    const [selectedStartDate2, setSelectedStartDate2] = useState(initStartDate2)
    const [selectedEndDate2, setSelectedEndDate2] = useState(initEndDate2)
    const [quickDate2, setQuickDate2] = useState(initQuickDate2)

    useEffect(() => {
        const { initStartDate, initEndDate, initQuickDate, initStartDate2, initEndDate2, initQuickDate2 } = initDates(urlParams)

        setSelectedStartDate(initStartDate)
        setSelectedEndDate(initEndDate)
        setQuickDate(initQuickDate)
        setSelectedStartDate2(initStartDate2)
        setSelectedEndDate2(initEndDate2)
        setQuickDate2(initQuickDate2)
    }, [location])

    const handleChangeStart = (date) => {
        setSelectedStartDate(moment(date).format('YYYY-MM-DD'))
    }

    const handleChangeEnd = (date) => {
        setSelectedEndDate(moment(date).format('YYYY-MM-DD'))
    }

    const handleChangeStart2 = (date) => {
        setSelectedStartDate2(moment(date).format('YYYY-MM-DD'))
    }

    const handleChangeEnd2 = (date) => {
        setSelectedEndDate2(moment(date).format('YYYY-MM-DD'))
    }

    const handleQuickDate = (event) => {
        let selectedStart = selectedStartDate
        let selectedEnd = selectedEndDate

        switch (event.target.value) {
            case 'month':
                selectedStart = moment().startOf('month').format('YYYY-MM-DD')
                selectedEnd = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'lastMonth':
                selectedStart = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                selectedEnd = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            case 'today':
                selectedStart = moment().startOf('day').format('YYYY-MM-DD')
                selectedEnd = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'yesterday':
                selectedStart = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
                selectedEnd = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
                break
            case 'thisPayPeriod':
                selectedStart = moment().day(1).subtract(1, 'day').format('YYYY-MM-DD')
                selectedEnd = moment().day(1).add(5, 'days').format('YYYY-MM-DD')
                break
            case 'lastPayPeriod':
                selectedStart = moment().day(1).subtract(8, 'days').format('YYYY-MM-DD')
                selectedEnd = moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')
                break
            default:
                break
        }

        setQuickDate(event.target.value)
        setSelectedStartDate(selectedStart)
        setSelectedEndDate(selectedEnd)
    }

    const handleQuickDate2 = (event) => {
        let selectedStart2 = selectedStartDate2
        let selectedEnd2 = selectedEndDate2

        switch (event.target.value) {
            case 'month':
                selectedStart2 = moment().startOf('month').format('YYYY-MM-DD')
                selectedEnd2 = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'lastMonth':
                selectedStart2 = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                selectedEnd2 = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            case 'today':
                selectedStart2 = moment().startOf('day').format('YYYY-MM-DD')
                selectedEnd2 = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'yesterday':
                selectedStart2 = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
                selectedEnd2 = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
                break
            case 'thisPayPeriod':
                selectedStart2 = moment().day(1).subtract(1, 'day').format('YYYY-MM-DD')
                selectedEnd2 = moment().day(1).add(5, 'days').format('YYYY-MM-DD')
                break
            case 'lastPayPeriod':
                selectedStart2 = moment().day(1).subtract(8, 'days').format('YYYY-MM-DD')
                selectedEnd2 = moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')
                break
            default:
                break
        }

        setQuickDate2(event.target.value)
        setSelectedStartDate2(selectedStart2)
        setSelectedEndDate2(selectedEnd2)
    }

    return { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initStartDate2, initEndDate2, selectedStartDate2, selectedEndDate2, quickDate2, handleChangeStart2, handleChangeEnd2, handleQuickDate2, setSelectedStartDate, setSelectedEndDate, setQuickDate }
}
