import React from 'react'

import AffiliateContactInfo from './AffiliateContactInfo/AffiliateContactInfo'
import AffiliateStats from './AffiliateStats/AffiliateStats'
import Pill from '../../../templates/UI/Pill/Pill'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import LoginAsAffiliate from '../LoginAsAffiliate/LoginAsAffiliate'

const AccountInfo = (props) => {
    // name, manager name, status, priority
    // network, Incentive,
    // IM handle, mobile, Email, website (link)
    // Today, Best Month
    const affiliateInfo = props.affiliateInfo
    const { showSystemMessage, setIsOpen } = props

    return (
        <div className="card pt-2 mb-2">
            <div className="d-flex justify-content-between border-bottom p-2 align-items-center">
                <div className="d-flex">
                    <h5 className="font-weight-bold">
                        <Link to={`/affiliate/${affiliateInfo.affiliate_id}/edit`}>{affiliateInfo.name}</Link>
                    </h5>
                    <div className="float-left text-secondary"> &nbsp;/ {affiliateInfo.manager_name}</div>
                </div>

                <LoginAsAffiliate affiliateId={affiliateInfo.affiliate_id} showSystemMessage={showSystemMessage} />
            </div>

            <div className="d-flex align-items-center justify-content-between p-2 border-bottom">
                {/* props for <Pill /> : borderColor, textColor */}

                <div style={{ flex: 1 }}>
                    <Pill text={affiliateInfo.status} borderColor={affiliateInfo.status === 'Cancelled' ? '#dc3545' : ''} textColor={affiliateInfo.status === 'Cancelled' ? '#dc3545' : ''} />
                    <Pill text={affiliateInfo.aff_priority} borderColor="#2fb62f" textColor="#2fb62f" />
                </div>
                <div style={{ flex: 1 }} className="align-middle text-secondary text-center">
                    {affiliateInfo.contact} <br></br>
                    {affiliateInfo.state}, {affiliateInfo.country} <br></br>
                    {affiliateInfo.time_zone} ({affiliateInfo.contact_time.text})
                </div>
                <div style={{ flex: 1, textAlign: 'right' }}>
                    {affiliateInfo.is_network_flag === 'Y' ? <Pill text="Network" /> : ''}
                    {affiliateInfo.incentive_flag === 'Y' ? <Pill text="Incentive" /> : ''}
                </div>
            </div>
            <AffiliateContactInfo imType={affiliateInfo.im_type} imHandle={affiliateInfo.im_handle} mobile={affiliateInfo.mobile_phone} email={affiliateInfo.e_mail} url={affiliateInfo.url} showSystemMessage={showSystemMessage} />
            <AffiliateStats affiliateId={affiliateInfo.affiliate_id} showSystemMessage={showSystemMessage} />
            {/* <Followups id={affiliateInfo.affiliate_id} showSystemMessage={showSystemMessage} managerName={affiliateInfo.manager_name} managerId={affiliateInfo.manager_id} type="affiliate" setIsOpen={setIsOpen} /> */}
        </div>
    )
}
export default AccountInfo
