import { useState } from 'react'
import linkifyHtml from 'linkify-html'

import callAPI from '../../../helpers/callAPI'

const TrafficNotes = (props) => {
    const [editing, setEditing] = useState(false)
    const [trafficInfo, setTrafficInfo] = useState(props.trafficNotes)
    const [tempTrafficInfo, setTempTrafficInfo] = useState(props.trafficNotes)

    const trafficNotes = trafficInfo.replace(/(\r\n|\n|\r)/gm, '<br />')
    const linkedTrafficNotes = linkifyHtml(trafficNotes)

    const { showSystemMessage, affiliateId } = props

    const submitNotes = (event) => {
        event.preventDefault()
        let url = `affiliate/${affiliateId}/trafficNotes`

        callAPI(url, 'POST', { trafficInfo: tempTrafficInfo })
            .then((result) => {
                if (result['success'] === true) {
                    setTrafficInfo(tempTrafficInfo)
                    showSystemMessage('success', result.message)
                    setEditing(false)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while saving traffic notes: ', error)
                showSystemMessage('error', 'An error occurred while updating traffic notes')
            })
    }

    const handleInput = (event) => {
        setTempTrafficInfo(event.target.value)
    }

    return (
        <div className="card mb-2">
            <div className="d-flex justify-content-between align-items-center p-2 card-header">
                <div className="font-weight-bold">Traffic Notes</div>
                <div>
                    {!editing ? (
                        <button className="btn btn-sm btn-warning" onClick={() => setEditing(true)}>
                            Edit
                        </button>
                    ) : (
                        <div>
                            <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={() => setEditing(false)}>
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-sm" type="submit" onClick={submitNotes}>
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className="p-2">{!editing ? <div className="overflow-auto p-2 border min-vh-50" style={{ maxHeight: '350px', minHeight: '250px' }} dangerouslySetInnerHTML={{ __html: linkedTrafficNotes }} name="notes" defaultValue={linkedTrafficNotes}></div> : <textarea name="traffic-notes" className="form-control" defaultValue={trafficInfo} onChange={handleInput} style={{ maxHeight: '350px', minHeight: '200px' }} maxLength="1024" />}</div>
        </div>
    )
}

export default TrafficNotes
