import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteDropdown from '../../../Templates/SiteDropdown/SiteDropdown'
import SurveyDropdown from '../../../Templates/SurveyDropdown/SurveyDropdown'
import callAPI from '../../../../../helpers/callAPI'

class PropertySetModal extends Component {
    state = {
        setSubmitLoaded: true,
        siteId: 0,
        surveyId: 0,
        status: 'A',
    }

    closeModal = () => {
        this.setState({
            ...this.state,
            createPropertyModalOpen: !this.state.createPropertyModalOpen,
        })
    }

    createSet = async () => {
        // Create the set
        console.log('Creating set...')
        this.setState({
            ...this.state,
            setSubmitLoaded: false,
        })

        const authtoken = localStorage.getItem('adm-auth-token')
        const propertyId = this.props.propertyId

        const result = await callAPI(`avatar/property/${propertyId}/propertySet`, 'POST', {
            siteId: this.state.siteId,
            surveyId: this.state.surveyId,
            status: this.state.status,
        })
        if (result['success'] === true) {
            this.props.showSystemMessage('success', 'Property set has been added.')
            // Call a props function to push the set to AvatarProperty's state upon success.
            this.setState({
                setSubmitLoaded: true,
            })
            this.props.closeModal()
            this.props.refreshProperty()
        } else if (result['success'] === false) {
            this.props.showSystemMessage('error', result.errors.message)
            this.setState({
                setSubmitLoaded: true,
            })
        } else {
            this.props.showSystemMessage('error', result.errors.message)
            this.setState({
                setSubmitLoaded: true,
            })
        }
    }

    handleSiteChange = (e) => {
        this.setState({
            ...this.state,
            siteId: e.target.value,
        })
    }

    handleSurveyChange = (e) => {
        this.setState({
            ...this.state,
            surveyId: e.target.value,
        })
    }

    handleStatusChange = (e) => {
        this.setState({
            ...this.state,
            status: e.target.value,
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.closeModal} className="modal-md">
                <div className="modal-header">
                    <h5 className="modal-title">Add a new site/survey set</h5>
                    <button type="button" className="close" onClick={this.props.closeModal}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">
                    <label htmlFor="site-dropdown">Choose a site:</label>
                    <SiteDropdown handleSiteChange={this.handleSiteChange} />

                    <label htmlFor="site-dropdown" className="mt-2">
                        Choose a Survey:
                    </label>
                    <SurveyDropdown handleSurveyChange={this.handleSurveyChange} />

                    <label htmlFor="site-dropdown" className="mt-2">
                        Status:
                    </label>
                    <select className="form-control" onChange={this.handleStatusChange} required>
                        <option value="A">Active</option>
                        <option value="C">Disabled</option>
                    </select>

                    {this.state.setSubmitLoaded ? (
                        <button className="btn btn-primary btn-sm mt-3" onClick={this.createSet}>
                            Create Set
                        </button>
                    ) : (
                        <button className="btn btn-secondary btn-sm mt-3" disabled>
                            <FontAwesomeIcon icon="spinner" spin />
                        </button>
                    )}
                </div>
            </Modal>
        )
    }
}

export default PropertySetModal
