import React, { useState } from 'react'
import moment from 'moment'

import Pill from '../../../templates/UI/Pill/Pill'
import getFollowupResult from '../../../helpers/getFollowupResult'
import getAdvertiserFollowupResult from '../../../helpers/getAdvertiserFollowupResult'

const FollowupListItem = (props) => {
    return (
        <div>
            <div>
                <span className="font-weight-bold">{moment(props.followup.followup_date).utc().format('YYYY-MM-DD HH:mm')}</span>
                <span className="text-secondary ml-3">{props.followup.full_name}</span>
                <span className="float-right">{props.type === 'affiliate' ? <Pill text={getFollowupResult(props.followup.results)} borderColor="#2fb62f" /> : <Pill text={getAdvertiserFollowupResult(props.followup.results)} borderColor="#2fb62f" />}</span>
            </div>
            <div>
                <span>{props.followup.notes}</span>
            </div>
            <hr />
        </div>
    )
}

export default FollowupListItem
