import { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import useCallApi from '../../../../hooks/useCallApi'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Graph from '../../../../components/Graph/Graph'
import styles from './MarginSummary.module.scss'
import TimeScalePicker from '../../../../components/TimeScalePicker/TimeScalePicker'
import Dollars from '../../../../templates/Dollars/Dollars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'

const MarginSummary = ({ showSystemMessage, manager }) => {
    const [graphLoaded, setGraphLoaded] = useState(false)
    const [scale, setScale] = useState('Monthly')
    const [earningsReport, setEarningsReport] = useState([])

    let todayDate = moment().startOf('day').format('YYYY-MM-DD')
    let monthStart = moment().startOf('month').format('YYYY-MM-DD')
    let monthEnd = moment().endOf('month').format('YYYY-MM-DD')
    let yearStart = moment().startOf('year').format('YYYY-MM-DD')
    let yearEnd = moment().endOf('year').format('YYYY-MM-DD')

    let { data: today, dataLoaded: todayDataLoaded } = useCallApi(`reports/earnings/totals?source=stats&startDate=${todayDate}&endDate=${todayDate}`, 'GET', {}, [])
    let { data: month, dataLoaded: monthDataLoaded } = useCallApi(`reports/earnings/totals?source=stats&startDate=${monthStart}&endDate=${monthEnd}`, 'GET', {}, [])
    let { data: year, dataLoaded: yearDataLoaded } = useCallApi(`reports/earnings/totals?source=stats&startDate=${yearStart}&endDate=${yearEnd}`, 'GET', {}, [])
    let { data: advertiserCount, dataLoaded: advertiserCountDataLoaded } = useCallApi(`advertisers?status=A`, 'GET', {}, [])
    let { data: advertiserWithCampaignsCount, dataLoaded: advertiserWithCampaignsCountDataLoaded } = useCallApi(`advertisers?status=A&withCampaigns=true`, 'GET', {}, [])
    let { data: campaignCount, dataLoaded: campaignCountDataLoaded } = useCallApi(`campaigns/search?status=A,B,H`, 'GET', {}, [])

    useEffect(async () => {
        setGraphLoaded(false)

        let graphResult = await callAPI(`reports/earnings/${scale}`, 'GET')

        if (!graphResult.success) {
            showSystemMessage('error', 'There was an error while fetching the data. Please try again.')
        }

        if (graphResult.success) {
            setEarningsReport(graphResult.report)
            setGraphLoaded(true)
        }
    }, [scale])

    let graphData

    let labels = []
    let data = []

    earningsReport.forEach((stat) => {
        scale.toLowerCase() === 'daily' ? labels.push(moment(stat.date).format('ddd MM-DD')) : labels.push(stat.date)
        data.push(stat.margin.toFixed(2))
    })

    graphData = {
        labels: labels,
        datasets: [
            {
                data: data,
                fill: true,
                backgroundColor: 'rgba(16, 159, 182, 0.2)',
                borderColor: 'rgba(16, 159, 182, 1)',
            },
        ],
    }

    let todayDate2 = moment()
    let todayStart2 = moment().startOf('day')
    let monthStart2 = moment().startOf('month')
    let yearStart2 = moment().startOf('year')

    let secsToday = todayDate2.diff(todayStart2, 'seconds')
    let secsThisMonth = todayDate2.diff(monthStart2, 'seconds')
    let secsThisYear = todayDate2.diff(yearStart2, 'seconds')
    let todayRatio = 86400 / secsToday
    let monthRatio = (moment(todayDate2).daysInMonth() * 86400) / secsThisMonth
    let yearRatio = (365 * 86400) / secsThisYear

    return (
        <div className="card mb-3">
            <div className={`m-3 d-flex ${styles.customFlexDirection}`}>
                <div className={`font-weight-bold text-secondary ${styles.fontSize125}`}>
                    Hello <span className="text-dark">{manager.name.split(' ')[0]}</span>
                </div>
            </div>
            <hr className="my-0" />
            <div style={{ fontSize: '.9rem' }}>
                <div className="p-2 align-items-center d-flex">
                    <div className="d-flex align-items-center">
                        <span className="font-weight-bold mr-2">Margin</span>
                        <TimeScalePicker timeScale={scale} setTimeScale={setScale} options={['Monthly', 'Daily']} />
                    </div>
                </div>
                <div className="align-items-center d-flex" style={{ minHeight: '150px' }}>
                    {graphLoaded ? <Graph data={graphData} /> : <Loading />}
                </div>
                <table className="table mb-0">
                    <tbody>
                        <tr className="border-top-0">
                            <td>Today</td>
                            <td className="text-right">
                                <span className="mb-element active">
                                    <span className="name font-weight-bold">
                                        {todayDataLoaded ? (
                                            <Link to={`/reports/margin?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().endOf('day').format('YYYY-MM-DD')}`}>
                                                <Dollars value={today.report.margin} />
                                            </Link>
                                        ) : (
                                            <FontAwesomeIcon icon="spinner" size="1x" spin />
                                        )}
                                    </span>
                                </span>
                            </td>
                            <td className="text-right">Projected</td>
                            <td className="text-right">
                                <span className="mb-element active">
                                    <span className="name font-weight-bold">{todayDataLoaded ? <Dollars value={today.report.margin * todayRatio} /> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                                </span>
                            </td>
                            <td className="text-right">NPM</td>
                            <td className="text-right">
                                <span className="mb-element active">
                                    <span className="name font-weight-bold">{todayDataLoaded ? <>{(today.report.margin_ratio * 100).toFixed(2)}%</> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                                </span>
                            </td>
                        </tr>
                        <tr className="border-bottom">
                            <td>Month</td>
                            <td className="text-right">
                                <span>
                                    <span className="name font-weight-bold text-primary">
                                        {monthDataLoaded ? (
                                            <Link to={`/reports/margin?startDate=${moment().startOf('month').format('YYYY-MM-DD')}&endDate=${moment().endOf('month').format('YYYY-MM-DD')}`}>
                                                <Dollars value={month.report.margin} />
                                            </Link>
                                        ) : (
                                            <FontAwesomeIcon icon="spinner" size="1x" spin />
                                        )}
                                    </span>
                                </span>
                            </td>
                            <td className="text-right">Projected</td>
                            <td className="text-right">
                                <span className="font-weight-bold">{monthDataLoaded ? <Dollars value={month.report.margin * monthRatio} /> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                            </td>
                            <td className="text-right">NPM</td>
                            <td className="text-right">
                                <span className="font-weight-bold">{monthDataLoaded ? <>{(month.report.margin_ratio * 100).toFixed(2)}%</> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>Year</td>
                            <td className="text-right">
                                <span className="name font-weight-bold">{yearDataLoaded ? <Dollars value={year.report.margin} /> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                            </td>
                            <td className="text-right">Projected</td>
                            <td className="text-right">
                                <span className="name font-weight-bold">{yearDataLoaded ? <Dollars value={year.report.margin * yearRatio} /> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                            </td>
                            <td className="text-right">NPM</td>
                            <td className="text-right">
                                <span className="name font-weight-bold">{yearDataLoaded ? <>{(year.report.margin_ratio * 100).toFixed(2)}%</> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="d-flex border-top">
                    <div className="w-50 p-3 border-right text-center">
                        Active Advertisers &nbsp;
                        <span className="font-weight-bold">
                            <ReactTooltip />
                            {advertiserCountDataLoaded ? (
                                <Link to={`/advertisers?status=A`} data-tip="Total">
                                    {advertiserCount.advertisers.length}
                                </Link>
                            ) : (
                                <FontAwesomeIcon icon="spinner" size="1x" spin />
                            )}
                            {advertiserWithCampaignsCountDataLoaded ? (
                                <span>
                                    {' '}
                                    /{' '}
                                    <Link to={`/advertisers?withCampaigns=true`} data-tip="With Active Campaigns">
                                        {advertiserWithCampaignsCount.advertisers.length}
                                    </Link>
                                </span>
                            ) : (
                                <FontAwesomeIcon icon="spinner" size="1x" spin />
                            )}
                        </span>
                    </div>
                    <div className="w-50 p-3 text-center">
                        Active Campaigns &nbsp;
                        <span className="font-weight-bold">{campaignCountDataLoaded ? <Link to={`/campaigns/search?z=1&status=A,B,H`}>{Intl.NumberFormat('en-GB').format(campaignCount.search_results.length)}</Link> : <FontAwesomeIcon icon="spinner" size="1x" spin />}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarginSummary
