import { useEffect, useState } from 'react'
import { Form, Modal, Table, Button, Card } from 'react-bootstrap'
import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Loading from '../../../../../templates/UI/Loading/Loading'
import { ButtonDropdown } from 'reactstrap'
import MobileKPI from './MobileKPI'
import TriggerModal from './TriggerModal'

const KPIModal = ({ campaignId, showSystemMessage, isOpen, closeModal, data, isCreate, refetch, events }) => {
    const [formData, setFormData] = useState(data)

    useEffect(() => {
        setFormData(data)
    }, [data])

    const { data: trackingData, dataLoaded: trackingDataLoaded, refetch: trackingRefetch } = useCallApi(`campaign/${campaignId}/triggers?mode=kpi&kpiId=${data.id}`, 'GET', {}, [campaignId, data])
    const [triggerModalData, setTriggerModalData] = useState({ isOpen: false, data: { event_mode: '', kpi_trigger_id: '', monetary_value: '', related_event_name: '', related_event_period: '', required_event_name: '' }, isCreate: false })

    const validate = () => {
        if (formData.name === '') {
            showSystemMessage('error', 'Name is required')
            return false
        }
        if (formData.description === '') {
            showSystemMessage('error', 'Description is required')
            return false
        }
        if (formData.goal_value <= 0 || formData.goal_value === '' || formData.goal_value === undefined) {
            showSystemMessage('error', 'Goal Value must be greater than 0')
            return false
        }
        if (formData.goal_type === 'p' && formData.goal_value > 100) {
            showSystemMessage('error', 'Goal Value must be less than 100 when Goal Type is Percentage')
            return false
        }
        if (formData.threshold <= 0 || formData.threshold === '' || formData.threshold === undefined) {
            showSystemMessage('error', 'Threshold must be greater than 0')
            return false
        }
        return true
    }

    const save = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/kpi`, 'PUT', formData)
            if (result.success) {
                showSystemMessage('success', 'Saved Mobile Event')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const create = async () => {
        //save data to db
        if (validate()) {
            const result = await callAPI(`campaign/${campaignId}/kpi`, 'POST', formData)
            if (result.success) {
                showSystemMessage('success', 'Created KPI')
                refetch()
                closeModal()
            } else {
                showSystemMessage('error', result.errors.message)
            }
        }
    }

    const deleteEvent = async () => {
        //save data to db
        const result = await callAPI(`campaign/${campaignId}/mobileEvent`, 'DELETE', formData)
        if (result.success) {
            showSystemMessage('success', 'Deleted Mobile Event')
            refetch()
            closeModal()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => closeModal()}>
            <TriggerModal events={events} refetch={trackingRefetch} campaignId={campaignId} showSystemMessage={showSystemMessage} isOpen={triggerModalData.isOpen} closeModal={() => setTriggerModalData({ ...triggerModalData, isOpen: false })} data={triggerModalData.data} isCreate={triggerModalData.isCreate} kpiId={data.id} />
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} KPI</Modal.Title>
            </Modal.Header>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Name</span>
                <Form.Control type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} className="ml-2" disabled={!isCreate} />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Description</span>
                <Form.Control type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className="ml-2" />
            </div>
            <br />
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Goal Value</span>
                <Form.Control type="number" value={formData.goal_value} onChange={(e) => setFormData({ ...formData, goal_value: e.target.value })} className="ml-2" />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Goal Type</span>
                <Form.Control as="select" value={formData.goal_type} onChange={(e) => setFormData({ ...formData, goal_type: e.target.value })} className="ml-2">
                    <option value="p">Percentage</option>
                    <option value="f">Fixed</option>
                </Form.Control>
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">KPI Threshold</span>
                <Form.Control type="number" value={formData.threshold} onChange={(e) => setFormData({ ...formData, threshold: e.target.value })} className="ml-2" />
            </div>
            <div className="d-flex align-items-center m-2">
                <span className="w-25">Hard KPI</span>
                <Form.Check type="checkbox" checked={formData.is_hard_kpi} onChange={(e) => setFormData({ ...formData, is_hard_kpi: e.target.checked })} className="ml-2" />
            </div>
            {!isCreate && (
                <Card className="m-2">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Triggers
                        <Button
                            variant="warning"
                            size="sm"
                            onClick={() => {
                                setTriggerModalData({ isOpen: true, data: { event_mode: 'SUM', event_id: 0, monetary_value: '', related_event_id: 0, trigger_period: '' }, isCreate: true })
                            }}
                        >
                            Add Trigger
                        </Button>
                    </Card.Header>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th>Hours after</th>
                                <th>Event</th>
                                <th>Monetary Value</th>
                                <th>Mode</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trackingData?.triggers?.length > 0 &&
                                trackingData.triggers.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.required_event_name}</td>
                                        <td>{item.trigger_period}</td>
                                        <td>{item.related_event_name}</td>
                                        <td>{item.monetary_value}</td>
                                        <td>{item.event_mode === 'SUM' ? 'Sum' : 'Individual'}</td>
                                        <td>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                onClick={() => {
                                                    setTriggerModalData({ isOpen: true, data: item, isCreate: false })
                                                    console.log(item)
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Card>
            )}
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="success" onClick={create}>
                        Create
                    </Button>
                ) : (
                    <>
                        <Button variant="success" onClick={save}>
                            Save
                        </Button>
                        {formData.delete_status === 'Y' && (
                            <Button variant="danger" onClick={deleteEvent}>
                                Delete
                            </Button>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default KPIModal
