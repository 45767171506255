import React, { useState } from 'react'
import moment from 'moment'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import { Modal } from 'react-bootstrap'
import ApplicationModalContent from './EditApplication/ApplicationModalContent/ApplicationModalContent'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'

const AdvertiserApplications = ({ advertiserId, showSystemMessage }) => {
    document.title = 'Advertiser Applications'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertiser_applications')

    const [showApplicationModal, setShowApplicationModal] = useState(false)
    const [applicationId, setApplicationId] = useState(0)
    const [status, setStatus] = useState('P')

    const { data, dataLoaded, refetch } = useCallApi(`advertisers/applications?status=${status}`, 'GET', {}, [status])

    const handleApplicationClick = (e, id) => {
        e.preventDefault()
        setApplicationId(id)
        setShowApplicationModal(true)
    }

    const handleClose = (isEdited = false, actionType = null) => {
        setShowApplicationModal(false)

        if (isEdited) {
            refetch()
        }
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Contact',
                accessor: 'name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Company Name',
                accessor: 'company',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign Name',
                accessor: 'campaign_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: '',
                accessor: 'id',
                Cell: (tableData) => {
                    return (
                        <>
                            <a href={`/advertisers/applications/${tableData.data[tableData.row.index].id}`} className="btn btn-warning" onClick={(e) => handleApplicationClick(e, tableData.data[tableData.row.index].id)}>
                                Edit
                            </a>
                        </>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.applications} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <Modal show={showApplicationModal} onHide={handleClose} size="lg">
                <ApplicationModalContent applicationId={applicationId} showSystemMessage={showSystemMessage} handleClose={handleClose} refetch={refetch} />
            </Modal>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertiser Applications</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex justify-content-between p-3">
                        <div className="d-flex">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark mr-2">List applications with status: </h1>
                            </div>

                            <div>
                                <select className="form-control form-control-sm" name="status" onChange={(e) => setStatus(e.target.value)}>
                                    <option value="P">Pending</option>
                                    <option value="C">Created</option>
                                    <option value="R">Rejected</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {dataLoaded ? data.applications.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                </div>
            </div>
        </div>
    )
}

export default AdvertiserApplications
