import { Card, Form, Table } from 'react-bootstrap'

const AvatarSettings = ({ data, setData, setIsDisabled }) => {
    return (
        <Card className="mb-2">
            <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="mb-h1-dark">Avatar Settings</div>
                </div>
            </Card.Header>
            <Table striped bordered>
                <tbody>
                    <tr>
                        <td>Lead Scrub Threshhold</td>
                        <td>
                            <Form.Control
                                type="number"
                                value={data.tracking.avatar.lead_scrub_threshold}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, avatar: { ...data.tracking.avatar, lead_scrub_threshold: e.target.value } } })
                                }}
                                max="10"
                            />
                        </td>
                        <td>Lead Scrub Rate</td>
                        <td>
                            <Form.Control
                                type="number"
                                value={data.tracking.avatar.lead_scrub_rate}
                                onChange={(e) => {
                                    setIsDisabled(false)
                                    setData({ ...data, tracking: { ...data.tracking, avatar: { ...data.tracking.avatar, lead_scrub_rate: e.target.value, lead_scrub_threshold: e.target.value } } })
                                }}
                                max="10"
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    )
}

export default AvatarSettings
