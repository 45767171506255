import ImType from '../../../../templates/FormElements/ImType'
import Countries from '../../../Avatar/AvatarSurvey/Survey/templates/Countries'
import States from '../../../Avatar/AvatarSurvey/Survey/templates/States'

const AdvertiserContactInfo = ({ contactInfo, location, setLocation, setIsDisabled }) => {
    const getNonState = (countryCode) => {
        let nonstate
        if (countryCode !== 'US' && countryCode !== 'CA') {
            nonstate = contactInfo.state
        } else {
            nonstate = ''
        }
        return nonstate
    }

    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Contact Information</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="border-right text-right">
                                Email
                            </td>
                            <td className="border-right" colSpan={3}>
                                <input name="email" className="form-control" type="text" defaultValue={contactInfo.e_mail} onChange={inputChangeHandler} required></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Contact</td>
                            <td className="border-right">
                                <input name="contact" className="form-control" type="text" defaultValue={contactInfo.contact} onChange={inputChangeHandler} />
                            </td>
                            <td className="border-right text-right">Contact Title</td>
                            <td className="border-right">
                                <input name="title" className="form-control" type="text" defaultValue={contactInfo.title} onChange={inputChangeHandler} />
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Street Address</td>
                            <td className="border-right">
                                <input name="address1" className="form-control" type="text" defaultValue={contactInfo.street_address_1} onChange={inputChangeHandler} placeholder="Street Address 1"></input>
                                <input name="address2" className="form-control mt-1" type="text" defaultValue={contactInfo.street_address_2} onChange={inputChangeHandler} placeholder="Street Address 2"></input>
                            </td>
                            <td className="text-right border-right">City</td>
                            <td>
                                <input name="city" className="form-control" type="text" defaultValue={contactInfo.city} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Country</td>
                            <td className="border-right">
                                <select
                                    name="country"
                                    className="form-control"
                                    defaultValue={location.country}
                                    onChange={(e) => {
                                        setLocation({ ...location, country: e.target.value })
                                        setIsDisabled(false)
                                    }}
                                    required
                                >
                                    <option value=""></option>
                                    <Countries />
                                </select>
                            </td>
                            <td className="text-right border-right">Zip Code</td>
                            <td>
                                <input name="zipcode" className="form-control" type="text" defaultValue={contactInfo.zip_code} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">USA State/Canadian Province</td>
                            <td className="border-right">
                                <select
                                    className="form-control"
                                    defaultValue={location.state}
                                    name="state"
                                    onChange={(e) => {
                                        setIsDisabled(false)
                                    }}
                                    disabled={location.country !== 'CA' && location.country !== 'US'}
                                >
                                    <option value=""></option>
                                    <States />
                                </select>
                            </td>
                            <td className="text-right border-right">
                                Non-USA State/
                                <br />
                                Non-Canadian Province
                            </td>
                            <td>
                                <input name="non_state" className="form-control" type="text" defaultValue={getNonState(contactInfo.country_code)} onChange={inputChangeHandler} disabled={location.country === 'CA' || location.country === 'US'} />
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Mobile Phone</td>
                            <td className="border-right">
                                <input name="mobile" className="form-control" type="text" defaultValue={contactInfo.raw_mobile_phone} onChange={inputChangeHandler}></input>
                            </td>
                            <td className="text-right border-right">Phone</td>
                            <td>
                                <input name="phone" className="form-control" type="text" defaultValue={contactInfo.raw_phone} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Fax</td>
                            <td className="border-right" colSpan="3">
                                <input name="fax" className="form-control w-50" type="text" defaultValue={contactInfo.fax} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">IM Type</td>
                            <td className="border-right">
                                <select name="imtype" defaultValue={contactInfo.im_type} className="form-control" onChange={inputChangeHandler}>
                                    <ImType />
                                </select>
                            </td>
                            <td className="text-right border-right">IM handle</td>
                            <td>
                                <input name="imhandle" className="form-control" type="text" defaultValue={contactInfo.im_handle} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdvertiserContactInfo
