import { Modal } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Loading from '../../templates/UI/Loading/Loading'
import CampaignSearch from '../CampaignSearch/CampaignSearch'
import callAPI from '../../helpers/callAPI'

export default function AffiliateTestCallbackModal({ show, hide, affiliate, showSystemMessage }) {
    const [campaign, setCampaign] = useState({ id: 0, name: '' })
    const [rates, setRates] = useState([])
    const [campaignTracking, setCampaignTracking] = useState(false)
    const [callbacks, setCallbacks] = useState([])
    const [globalCallback, setGlobalCallback] = useState({ type: '', code: '' })
    const [selectedRate, setSelectedRate] = useState(false)
    const [subid1, setSubid1] = useState('')
    const [subid2, setSubid2] = useState('')
    const [subid3, setSubid3] = useState('')
    const [subid4, setSubid4] = useState('')
    const [subid5, setSubid5] = useState('')
    const [saleAmount, setSaleAmount] = useState(0)

    useEffect(async () => {
        if (campaign.id > 0) {
            // Fetch the rates
            const result = await callAPI(`campaign/${campaign.id}`, 'GET')

            if (result['success'] === true) {
                setRates(result.campaign.rates)
                setCampaignTracking(result.campaign.tracking)
            } else if (result['success'] === false) {
                console.log('Error fetching campaign rates.')
            } else {
                console.log('Error fetching campaign rates.')
            }
        }

        if (affiliate && affiliate.id > 0) {
            const callbackResult = await callAPI(`affiliate/${affiliate.id}/callbacks`, 'GET')

            if (callbackResult['success'] === true) {
                setCallbacks(callbackResult.callbacks)
            } else if (callbackResult['success'] === false) {
                console.log('Error fetching affiliate callbacks.')
            } else {
                console.log('Error fetching affiliate callbacks.')
            }

            const affiliateResult = await callAPI(`affiliate/${affiliate.id}`, 'GET')
            if (affiliateResult['success'] === true) {
                setGlobalCallback(affiliateResult.affiliate.tracking.global_callback)
            } else if (affiliateResult['success'] === false) {
                console.log('Error fetching affiliate global callback.')
            } else {
                console.log('Error fetching affiliate global callback.')
            }
        }
    }, [campaign, affiliate])

    const getCallback = (campaignTracking, callbacks, globalCallback) => {
        if (campaignTracking && callbacks && globalCallback) {
            let callbackType = false
            let callbackCode = false

            if (campaignTracking.type.value === 'S' || campaignTracking.type.value === 'G' || campaignTracking.type.value === 'A') {
                let campaignSpecificCallback = callbacks.findIndex((callback) => callback.id === campaign.id && (callback.callback.type === 'L' || callback.callback.type === 'S'))

                if (campaignSpecificCallback >= 0) {
                    // We have a campaign specific callback.
                    if (callbacks[campaignSpecificCallback].type === 'S' || callbacks[campaignSpecificCallback].type === 'L') {
                        callbackType = 'Campaign Specific'
                        callbackCode = callbacks[campaignSpecificCallback].code
                    }
                } else {
                    // Check if there is a global callback.
                    if (globalCallback.type === 'S' || globalCallback.type === 'L') {
                        callbackType = 'Global'
                        callbackCode = globalCallback.code
                    }
                }
            }

            if (callbackType && callbackCode) {
                return (
                    <div>
                        <hr></hr>
                        <div>Callback for this lead: ({callbackType}):</div>
                        <div className="alert alert-secondary mt-2">{callbackCode}</div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <hr></hr>
                        <div>No callback set.</div>
                    </div>
                )
            }
        }
    }

    const submitTest = async (e) => {
        e.preventDefault()

        if (campaign && affiliate && selectedRate) {
            let postBody = {
                affiliate_id: affiliate.id,
                rate_name: selectedRate.rate_name,
                offer_type: selectedRate.rate_type,
                leads: 1,
                confirmLeads: 1,
                sale_amount: selectedRate.rate_type === 'P' || selectedRate.rate_type === 'M' ? saleAmount : 0,
                subid1: subid1,
                subid2: subid2,
                subid3: subid3,
                subid4: subid4,
                subid5: subid5,
            }

            let result = await callAPI(`campaign/${campaign.id}/addLeads`, 'POST', postBody)

            if (result) {
                if (result.success) {
                    showSystemMessage('success', 'Lead created - Remember to reverse this lead.')
                } else {
                    showSystemMessage('error', result.errors.message)
                }
            } else {
                showSystemMessage('error', 'Failed to create lead')
            }

            hide()
        } else {
            showSystemMessage('error', 'You must select a campaign and a rate.')
        }
    }

    return (
        <Modal show={show} onHide={() => hide()} size={'lg'}>
            {affiliate ? (
                <>
                    <Modal.Header closeButton>
                        <div className="mb-h1-dark">
                            Test a lead and callback for: <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group form-inline">
                            <div className="text-left w-25">Campaign: </div>
                            <div className="w-50">
                                <CampaignSearch campaign={campaign} setCampaign={setCampaign} />
                            </div>
                        </div>

                        {campaign.id > 0 && (
                            <div>
                                <form>
                                    <div className="form-group form-inline">
                                        <div className="text-left w-25">Rate: </div>
                                        <div className="w-50">
                                            <select className="form-control" required>
                                                <option value="" onClick={() => setSelectedRate(false)}></option>
                                                {rates.map((rate) => {
                                                    return (
                                                        <option key={rate.rate_name} value={`${rate.rate_name}`} onClick={() => setSelectedRate(rate)}>
                                                            {rate.rate_name} ({rate.affiliate_rate_text} / {rate.network_rate_text})
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {selectedRate && (selectedRate.rate_type === 'M' || selectedRate.rate_type === 'P') && (
                                        <div className="form-group form-inline">
                                            <div className="text-left w-25">Sale Amount </div>
                                            <div className="w-50">
                                                <input type="text" className="form-control" onChange={(e) => setSaleAmount(e.target.value)}></input>
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group form-inline">
                                        <div className="text-left w-25">Subid 1: </div>
                                        <div className="w-50">
                                            <input type="text" className="form-control" onChange={(e) => setSubid1(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="form-group form-inline">
                                        <div className="text-left w-25">Subid 2: </div>
                                        <div className="w-50">
                                            <input type="text" className="form-control" onChange={(e) => setSubid2(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="form-group form-inline">
                                        <div className="text-left w-25">Subid 3: </div>
                                        <div className="w-50">
                                            <input type="text" className="form-control" onChange={(e) => setSubid3(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="form-group form-inline">
                                        <div className="text-left w-25">Subid 4: </div>
                                        <div className="w-50">
                                            <input type="text" className="form-control" onChange={(e) => setSubid4(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="form-group form-inline">
                                        <div className="text-left w-25">Subid 5: </div>
                                        <div className="w-50">
                                            <input type="text" className="form-control" onChange={(e) => setSubid5(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="btn btn-primary" onClick={(e) => submitTest(e)}>
                                            Create Lead
                                        </button>
                                    </div>
                                </form>
                                {getCallback(campaignTracking, callbacks, globalCallback)}
                            </div>
                        )}
                    </Modal.Body>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    )
}
