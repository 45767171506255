import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { HelpCircle } from 'react-feather'
import ReactTooltip from 'react-tooltip'

import callAPI from '../../../../helpers/callAPI'
import SecurityQuestions from '../../../../templates/FormElements/SecurityQuestions'
import TerminateAffiliate from './TerminateAffiliate/TerminateAffiliate'

import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const AccountSettings = ({ affiliateId, affiliate, showSystemMessage, refetch, setIsDisabled }) => {
    const auth = useAuth()
    const showTerminateButton = useProtectedContent(auth.permissions, 'affiliate_terminate')
    const showReopenButton = useProtectedContent(auth.permissions, 'affiliate_reopen')

    const [showTerminationModal, setShowTerminationModal] = useState(false)

    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    const handleClose = () => {
        setShowTerminationModal(false)
    }

    const reopenAccount = (e) => {
        e.preventDefault()
        let url = `affiliate/${affiliateId}/reactivate`

        callAPI(url, 'PUT', {})
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    refetch()
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    refetch()
                }
            })
            .catch((error) => {
                console.log(`Error while reopening account: ${error.message}`)
                showSystemMessage('error', `Connection Error - ${error.message}`)
                refetch()
            })
    }

    return (
        <div className="card">
            {showTerminateButton && (
                <Modal show={showTerminationModal} size="md" onHide={handleClose}>
                    <TerminateAffiliate affiliateId={affiliateId} affiliateName={affiliate.name} showSystemMessage={showSystemMessage} handleClose={handleClose} refetch={refetch} priority={affiliate.priority.value} status={affiliate.status.value} />
                </Modal>
            )}
            <ReactTooltip />
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Account Settings</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="border-right text-right">
                                Disable Logins
                            </td>
                            <td className="border-right">
                                <select name="disableLogins" defaultValue={affiliate.account.disable_logins ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                            <td className="border-right text-right">
                                Reset 2-FA token <HelpCircle className="text-primary" size={14} data-tip="Selecting 'Yes' will remove this affiliate's 2-factor authentication. They can then set up a new one upon logging in." />
                            </td>
                            <td>
                                <select name="resetOtp" defaultValue="No" className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">
                                Receive General Updates? <HelpCircle className="text-primary" size={14} data-tip="Emails from batch mailer." />
                            </td>
                            <td className="border-right">
                                <select name="receiveUpdates" defaultValue={affiliate.mailers.general ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                            <td className="border-right text-right">
                                Receive Offer Notices? <HelpCircle className="text-primary" size={14} data-tip="Emails for campaign cancellations, rate changes, etc." />
                            </td>
                            <td>
                                <select name="receiveOfferUpdates" defaultValue={affiliate.mailers.campaign_updates ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Reset Password Flag?</td>
                            <td className="border-right">
                                <select name="resetPassword" defaultValue={affiliate.account.reset_password ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                            <td className="border-right text-right">Account</td>
                            <td>
                                {affiliate.status.name === 'Cancelled' ? (
                                    showReopenButton ? (
                                        <button type="button" className="btn btn-sm btn-primary" onClick={reopenAccount}>
                                            Reopen Account
                                        </button>
                                    ) : (
                                        <div>{affiliate.status.name}</div>
                                    )
                                ) : showTerminateButton ? (
                                    <button type="button" className="btn btn-sm btn-danger" onClick={() => setShowTerminationModal(true)}>
                                        Terminate Account
                                    </button>
                                ) : (
                                    <div>{affiliate.status.name}</div>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">
                                ID Number <HelpCircle className="text-primary" size={14} data-tip="ID number of the identification provided upon registration." />
                            </td>
                            <td>
                                <input name="idNumber" className="form-control" type="text" defaultValue={affiliate.account.government_id} onChange={inputChangeHandler}></input>
                            </td>
                            <td width="25%" className="border-right text-right">
                                Force Logout
                            </td>
                            <td className="border-right">
                                <select name="forceLogout" defaultValue={affiliate.account.force_logout ? 'Y' : 'N'} className="form-control" onChange={inputChangeHandler} required>
                                    <option value="N">No</option>
                                    <option value="Y">Yes</option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td className="border-right text-right">Security Question 1</td>
                            <td colSpan="3">
                                <select name="security_q1" defaultValue={affiliate.account.security_questions.question1.question_id} className="form-control" onChange={inputChangeHandler}>
                                    <option value=""></option>
                                    <SecurityQuestions selectedValue={affiliate.account.security_questions.question1.question_id} questionGroup={1} />
                                </select>
                                <input name="security_a1" className="form-control  mt-1" type="text" defaultValue={affiliate.account.security_questions.question1.answer} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Security Question 2</td>
                            <td colSpan="3">
                                <select name="security_q2" defaultValue={affiliate.account.security_questions.question2.question_id} className="form-control" onChange={inputChangeHandler}>
                                    <option value=""></option>
                                    <SecurityQuestions selectedValue={affiliate.account.security_questions.question2.question_id} questionGroup={2} />
                                </select>
                                <input name="security_a2" className="form-control mt-1" type="text" defaultValue={affiliate.account.security_questions.question2.answer} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AccountSettings
