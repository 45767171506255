import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

// TODO: Need to write backend for this

const TopTrafficTypes = (props) => {
    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Top Traffic Types</div>
            </div>
            <div className="p-2">No stats available</div>
        </div>
    )
}

export default TopTrafficTypes
