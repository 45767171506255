import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import callAPI from '../../../helpers/callAPI'

export default function RequestVacationModal({ adminId, setShowModal, refetch, showSystemMessage }) {
    const [data, setData] = useState({ adminId: adminId, startDate: moment().format('YYYY-MM-DD'), days: '', notes: '' })
    const [dataLoaded, setDataLoaded] = useState(true)

    const submitRequest = async (e) => {
        e.preventDefault()
        setDataLoaded(false)

        let result = await callAPI('admin/vacation', 'POST', data)

        if (result.success) {
            refetch()
            setShowModal(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }

        setDataLoaded(true)
    }

    return (
        <div className="card">
            <div className="card-header">Request Vacation</div>
            <div className="card-body">
                <form onSubmit={(e) => submitRequest(e)}>
                    <div className="form-group d-flex align-items-center">
                        <label style={{ width: '150px' }}>Start Date: </label>
                        <input
                            type="date"
                            min={moment().format('YYYY-MM-DD')}
                            required={true}
                            onChange={(e) => {
                                setData({ ...data, startDate: e.target.value })
                            }}
                            className="form-control ml-2"
                            defaultValue={data.startDate}
                        ></input>
                    </div>
                    <div className="form-group d-flex align-items-center">
                        <label style={{ width: '150px' }}>
                            Number of <u>BUSINESS</u> days:{' '}
                        </label>
                        <input
                            type="number"
                            required={true}
                            onChange={(e) => {
                                setData({ ...data, days: parseInt(e.target.value) })
                            }}
                            className="form-control ml-2"
                            defaultValue={data.days}
                        ></input>
                    </div>
                    <div className="form-group d-flex align-items-center">
                        <label style={{ width: '150px' }}>Notes: </label>
                        <input
                            type="text"
                            onChange={(e) => {
                                setData({ ...data, notes: e.target.value })
                            }}
                            className="form-control ml-2"
                            defaultValue={data.notes}
                        ></input>
                    </div>
                    <div className="mt-2 text-right">
                        <button
                            className="btn btn-link text-danger"
                            onClick={(e) => {
                                e.preventDefault()
                                setShowModal(false)
                            }}
                        >
                            Cancel
                        </button>
                        {dataLoaded ? (
                            <button className="btn btn-primary ml-2">Submit Request</button>
                        ) : (
                            <button className="btn btn-primary ml-2" disabled>
                                <FontAwesomeIcon icon="spinner" spin /> Submit Request
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
