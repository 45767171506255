import ImType from '../../../../templates/FormElements/ImType'

const AdvertiserInvoiceInfo = ({ invoiceInfo, setIsDisabled }) => {
    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Invoice Information</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td className="border-right text-right">Invoice Frequency</td>
                            <td className="border-right">
                                <select name="invoice_frequency" className="form-control" defaultValue={invoiceInfo.invoice_frequency} onChange={inputChangeHandler}>
                                    <option value="U">Unknown</option>
                                    <option value="W">Weekly</option>
                                    <option value="S">Semi-Monthly</option>
                                    <option value="M">Monthly</option>
                                </select>
                            </td>
                            <td className="border-right text-right">Invoice Terms</td>
                            <td className="border-right">
                                <select name="payment_terms" className="form-control" defaultValue={invoiceInfo.payment_terms} onChange={inputChangeHandler}>
                                    <option value="0">Unknown</option>
                                    <option value="-1">Pre-payment</option>
                                    <option value="1">Net 1</option>
                                    <option value="15">Net 15</option>
                                    <option value="30">Net 30</option>
                                    <option value="45">Net 45</option>
                                    <option value="60">Net 60</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Weekly Invoice Day of Week</td>
                            <td className="border-right">
                                <select name="weekly_invoice_day_of_week" className="form-control" defaultValue={invoiceInfo.weekly_invoice_day_of_week} onChange={inputChangeHandler}>
                                    <option value="-1">Unknown</option>
                                    <option value="0">Sunday</option>
                                    <option value="1">Monday</option>
                                    <option value="2">Tuesday</option>
                                    <option value="3">Wednesday</option>
                                    <option value="4">Thursday</option>
                                    <option value="5">Friday</option>
                                    <option value="6">Saturday</option>
                                </select>
                            </td>
                            <td className="border-right text-right">Prepay/Limited Credit</td>
                            <td className="border-right">
                                <select name="limited_credit_flag" className="form-control" defaultValue={invoiceInfo.limited_credit_flag} onChange={inputChangeHandler}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Contract Details</td>
                            <td className="border-right">
                                <select name="contract_owner" className="form-control" defaultValue={invoiceInfo.contract_owner} onChange={inputChangeHandler}>
                                    <option value="U">Unknown</option>
                                    <option value="M">Advertiser's Terms</option>
                                    <option value="O">Our Old Terms</option>
                                    <option value="N">Our New Terms</option>
                                </select>
                            </td>
                            <td className="border-right text-right">Maximum Credit</td>
                            <td className="border-right">
                                <input name="balance" className="form-control" type="text" defaultValue={invoiceInfo.balance} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Stats Details</td>
                            <td className="border-right">
                                <select name="stats_flag" className="form-control" defaultValue={invoiceInfo.stats_flag} onChange={inputChangeHandler}>
                                    <option value="U">Unknown</option>
                                    <option value="O">Use Our Stats</option>
                                    <option value="L">Login for Stats</option>
                                    <option value="M">Get Stats from Advertiser</option>
                                </select>
                            </td>
                            <td className="border-right text-right">Reversal Details</td>
                            <td className="border-right">
                                <select name="reversals_flag" className="form-control" defaultValue={invoiceInfo.reversals_flag} onChange={inputChangeHandler}>
                                    <option value="U">Unknown</option>
                                    <option value="N">No Reversals</option>
                                    <option value="L">Login for Reversals</option>
                                    <option value="M">Get Reversals from Advertiser</option>
                                    <option value="F">Fraud Pubs Only. Provided Ad-Hoc</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Stats Notes</td>
                            <td className="border-right">
                                <textarea name="stats_details" cols={40} rows={6} className="form-control" defaultValue={invoiceInfo.stats_details} onChange={inputChangeHandler} />
                            </td>
                            <td className="border-right text-right">Reversal Notes</td>
                            <td className="border-right">
                                <textarea name="reversals_details" cols={40} rows={6} className="form-control" defaultValue={invoiceInfo.reversals_details} onChange={inputChangeHandler} />
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Accountant Name</td>
                            <td className="border-right" colSpan={3}>
                                <input name="accounting_name" className="form-control w-50" type="text" defaultValue={invoiceInfo.accounting_name} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Accountant E-Mail</td>
                            <td className="border-right">
                                <input name="accounting_email" className="form-control" type="text" defaultValue={invoiceInfo.accounting_email} onChange={inputChangeHandler}></input>
                            </td>
                            <td className="border-right text-right">Accountant Phone</td>
                            <td className="border-right">
                                <input name="accounting_phone" className="form-control" type="text" defaultValue={invoiceInfo.accounting_phone} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-right border-right">Accountant IM Type</td>
                            <td className="border-right">
                                <select name="accounting_imtype" defaultValue={invoiceInfo.accounting_im_type} className="form-control" onChange={inputChangeHandler} required>
                                    <ImType />
                                </select>
                            </td>
                            <td className="text-right border-right">Accountant IM handle</td>
                            <td>
                                <input name="accounting_imhandle" className="form-control" type="text" defaultValue={invoiceInfo.accounting_im_handle} onChange={inputChangeHandler}></input>
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Invoicing Notes</td>
                            <td className="border-right" colSpan={3}>
                                <textarea name="invoicing_notes" cols={80} rows={8} className="form-control" defaultValue={invoiceInfo.invoicing_notes} onChange={inputChangeHandler} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdvertiserInvoiceInfo

// {
//     "invoice_frequency": "M",
//     "payment_terms": 30,
//     "weekly_invoice_day_of_week": 1,
//     "limited_credit_flag": "N",
//     "contract_owner": "M",
//     "balance": 0,
//     "stats_flag": "L",
//     "reversals_flag": "L",
//     "stats_details": "Impact",
//     "accounting_name": "Matt Bell",
//     "accounting_email": "matt@bellinteractive.com",
//     "accounting_phone": "",
//     "accounting_im_type": "N",
//     "accounting_im_id": "",
//     "invoicing_notes": "Invoice can be sent one month after the end of the month leads are tracked. Ex: January conversions invoice can be created and sent on March 1st. Payments will come 33 days after that (April 4)."
// }
