import { useCallback } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'

const DomainTable = ({ dataLoaded, domains, showSystemMessage, setFormData, formData }) => {
    const handleChange = useCallback(
        (e) => {
            setFormData({ ...formData, selectedDomain: domains[parseInt(e.target.dataset.index)].domain_name })
        },
        [formData]
    )

    const totalClicks = useCallback(() => {
        const total = domains.reduce((acc, domain) => {
            return acc + domain.click_count
        }, 0)
        return total.toLocaleString()
    }, [domains])

    return (
        <Card style={{ maxHeight: '100%' }}>
            {dataLoaded && (
                <Card.Body className="p-0">
                    <Table responsive hover striped className="m-0">
                        <thead>
                            <tr>
                                <th>Domain URL</th>
                                <th>Status</th>
                                <th>Priority</th>
                                <th>Log Traffic</th>
                                <th>Cookie Support</th>
                                <th className="text-right">Click Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {domains.map((domain, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <Form.Check>
                                                <Form.Check.Input type="radio" name="domains" id={`domain_${index}`} data-index={index} checked={formData.selectedDomain === domain.domain_name} onChange={handleChange} />
                                                <Form.Check.Label className="ml-2" htmlFor={`domain_${index}`}>
                                                    {domain.domain_name}
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </td>
                                        {/* Left as codes as per AT */}
                                        <td>{domain.status}</td>
                                        <td>{domain.priority}</td>
                                        <td>{domain.log_traffic === 'Y' ? 'Yes' : ''}</td>
                                        <td>{domain.cookies_supported === 'Y' ? 'Yes' : ''}</td>
                                        <td className="text-right">{domain.click_count.toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td colSpan="6" className="text-right font-weight-bold">
                                    {totalClicks()}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            )}
        </Card>
    )
}

export default DomainTable
