import React, { useState, useEffect } from 'react'
import moment from 'moment'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'
import { Modal } from 'react-bootstrap'
import RequestVacationModal from './RequestVacationModal/RequestVacationModal'
import RequestSickDayModal from './RequestSickDayModal/RequestSickDayModal'
import { useAuth, useEditableContent, useSetManager } from '../../context/AuthContext'
import AdminPicker from '../../components/AdminPicker/AdminPicker'
import callAPI from '../../helpers/callAPI'

export default function Vacation({ showSystemMessage }) {

    document.title = 'Vacation'

    const auth = useAuth()
    const editAllowed = useEditableContent(auth.permissions, 'vacation_settings')
    const setManagerAllowed = useSetManager(auth.permissions, 'vacation_settings')
    const masterEdit = useEditableContent(auth.permissions, 'vacation_settings_master')

    const [showModal, setShowModal] = useState(false)
    const [showSickModal, setShowSickModal] = useState(false)
    const [adminId, setAdminId] = useState(auth.user.id)
    const [year, setYear] = useState(moment().year())

    const { data, dataLoaded, refetch } = useCallApi(`admin/vacation?adminId=${adminId}`, 'GET', {}, [], showSystemMessage)

    let roles = false
    if (auth.user.role === 'COMPLIANCE_DIRECTOR') {
        roles = ['COMPLIANCE_DIRECTOR', 'COMPLIANCE']
    } else if (auth.user.role === 'ADVERTISER_DIRECTOR') {
        roles = ['MERMGR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD']
    } else if (auth.user.role === 'AFFILIATE_DIRECTOR') {
        roles = ['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']
    }

    useEffect(() => {
        refetch()
    }, [adminId])

    let yearList = []

    for (const year in data.vacation) {
        yearList.push(year)
    }

    yearList.reverse()

    const actionRequest = async (e, action, entryId) => {
        e.preventDefault()

        let result = await callAPI(`admin/vacation?action=${action}`, 'PUT', { entryId: entryId })

        if (result.success) {
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Vacation</h1>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <Modal show={showModal} size="md" onHide={() => setShowModal(false)}>
                                <RequestVacationModal adminId={adminId} setShowModal={setShowModal} refetch={refetch} showSystemMessage={showSystemMessage} />
                            </Modal>
                            <Modal show={showSickModal} size="md" onHide={() => setShowSickModal(false)}>
                                <RequestSickDayModal adminId={adminId} setShowModal={setShowSickModal} refetch={refetch} showSystemMessage={showSystemMessage} />
                            </Modal>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    {setManagerAllowed && (
                                        <div className="mr-2">
                                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={roles} />
                                        </div>
                                    )}
                                    <select className="form-control form-control-sm" onChange={(e) => setYear(e.target.value)}>
                                        {
                                            // If there are no records this current year, initialize it.
                                            yearList.findIndex((year) => {
                                                return parseInt(year) === parseInt(moment().year())
                                            }) < 0 ? (
                                                <option value={moment().year()}>{moment().year()}</option>
                                            ) : (
                                                ''
                                            )
                                        }

                                        {yearList.map((year) => {
                                            return <option value={year}>{year}</option>
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <button className="btn btn-warning" onClick={() => setShowModal(!showModal)}>
                                        Request Vacation
                                    </button>
                                    {(masterEdit || (editAllowed && dataLoaded && data.admin.manager.id === auth.user.id)) && (
                                        <button className="btn btn-warning ml-2" onClick={() => setShowSickModal(!showSickModal)}>
                                            Enter Sick Day
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="mt-3">
                                {dataLoaded ? (
                                    <table className="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Days</th>
                                                <th>Type</th>
                                                <th>Status</th>
                                                <th>Balance</th>
                                                <th>Note</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{moment({ year: year }).startOf('year').format('YYYY-MM-DD')}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="font-weight-bold">{data.vacation[year - 1] ? <span>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.vacation[year - 1][data.vacation[year - 1].length - 1].balance)}</span> : <span>{data.initial_vacation}</span>}</td>
                                                <td>Balance In</td>
                                                <td></td>
                                            </tr>
                                            {data.vacation[year] &&
                                                data.vacation[year].map((entry) => {
                                                    return (
                                                        <tr>
                                                            <td>{entry.date}</td>
                                                            <td>{entry.days}</td>
                                                            <td>{entry.type.name}</td>
                                                            <td>{entry.status.name}</td>
                                                            <td>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(entry.balance)}</td>
                                                            <td>{entry.notes}</td>
                                                            <td>
                                                                {entry.status.name === 'Requested' && (masterEdit || (editAllowed && dataLoaded && data.admin.manager.id === auth.user.id)) && (
                                                                    <div className="text-right">
                                                                        <button
                                                                            className="btn btn-success btn-sm"
                                                                            onClick={(e) => {
                                                                                actionRequest(e, 'approve', entry.id)
                                                                            }}
                                                                        >
                                                                            Approve
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger btn-sm ml-2"
                                                                            onClick={(e) => {
                                                                                actionRequest(e, 'reject', entry.id)
                                                                            }}
                                                                        >
                                                                            Reject
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger btn-sm ml-2"
                                                                            onClick={(e) => {
                                                                                actionRequest(e, 'cancel', entry.id)
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            <tr>
                                                <td>{moment({ year: year }).endOf('year').format('YYYY-MM-DD')}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className="font-weight-bold">{data.vacation[year] ? <span>{Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.vacation[year][data.vacation[year].length - 1].balance)}</span> : data.vacation[year - 1] ? <span>{data.vacation[year - 1][data.vacation[year - 1].length - 1].balance}</span> : <span>0</span>}</td>
                                                <td>Balance Out</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ) : (
                                    <Loading />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
