import React, { useState } from 'react'

import Loading from '../../../templates/UI/Loading/Loading'
import ContactInfo from './ContactInfo/ContactInfo'
import GeneralInfo from './GeneralInfo/GeneralInfo'
import SiteTrafficInfo from './SiteTrafficInfo/SiteTrafficInfo'

import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import CustomButton from '../../../templates/UI/CustomButton/CustomButton'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import AccountSettings from './AccountSettings/AccountSettings'
import './AffiliateSettings.css'
import Notes from './Notes/Notes'
import PaymentInfo from './PaymentInfo/PaymentInfo'
import TrackingSettings from './TrackingSettings/TrackingSettings'

const AffiliateSettings = ({ affiliateId, showSystemMessage }) => {
    const { data, dataLoaded, refetch } = useCallApi(`affiliate/${affiliateId}`, 'GET', {}, [affiliateId])

    document.title = `${dataLoaded ? `${data.affiliate.name}: ` : ''}Settings`

    const [isDisabled, setIsDisabled] = useState(true)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        setSubmitting(true)

        let generalPutBody = {
            name: e.target.name.value,
            status: e.target.status.value,
        }

        let accountPutBody = {
            security_q1: e.target.security_q1.value,
            security_a1: e.target.security_a1.value,
            security_q2: e.target.security_q2.value,
            security_a2: e.target.security_a2.value,
            disableLogins: e.target.disableLogins.value,
            forceLogout: e.target.forceLogout.value,
            resetPassword: e.target.resetPassword.value,
            receiveUpdates: e.target.receiveUpdates.value,
            receiveOfferUpdates: e.target.receiveOfferUpdates.value,
            idNumber: e.target.idNumber.value,
            resetOtp: e.target.resetOtp.value,
        }

        let trackingPutBody = {
            disableRedirects: e.target.disableRedirects.value,
            trafficBlocked: e.target.traffic.value,
            ignoreStatsForEpc: e.target.ignoreStatsForEpc.value,
            globalFilterOverride: e.target.globalFilterOverride.value,
            disableSubId: e.target.disableSubId.value,
            brandBidCount: e.target.brandBidCount.value,
            trkToken: e.target.trkToken.value,
        }

        const notesPutBody = {
            notes: e.target.notes.value,
        }

        let inputPaymentPlatform = e.target.paymentPlatform.value
        let inputPaymentMethod = e.target.paymentMethod.value
        let inputMinimumPayout = e.target.minimumPayout.value

        if (inputPaymentPlatform === 'tipalti' && inputPaymentMethod === 'W') {
            if (inputMinimumPayout < 1000) {
                inputMinimumPayout = 1000
            }
        } else if (inputPaymentPlatform === 'tipalti' && inputPaymentMethod === 'E') {
            if (inputMinimumPayout < 500) {
                inputMinimumPayout = 500
            }
        }

        if (inputPaymentPlatform === 'payoneer' && inputPaymentMethod === 'W') {
            if (inputMinimumPayout < 100) {
                inputMinimumPayout = 100
            }
        }

        let paymentInfoPutBody = {
            paymentPlatform: inputPaymentPlatform,
            paymentMethod: inputPaymentMethod,
            minimumPayout: inputMinimumPayout,
            data1: e.target.data1.value,
            data2: e.target.data2.value,
            data3: e.target.data3.value,
            mmRedemptionLimit: e.target.mmRedemptionLimit.value,
        }

        let putContactBody = {
            email: e.target.email.value,
            contact: e.target.contact.value,
            title: e.target.title.value,
            address1: e.target.address1.value,
            address2: e.target.address2.value,
            city: e.target.city.value,
            state: e.target.state.value,
            non_state: e.target.nonState.value,
            country: e.target.country.value,
            zipcode: e.target.zipcode.value,
            mobile: e.target.mobile.value,
            phone: e.target.phone.value,
            timezone: e.target.timezone.value,
            contact_time: e.target.bestTimeToCall.value,
            im_type: e.target.imtype.value,
            im_handle: e.target.imhandle.value,
            additional_contacts: e.target.additionalContacts.value,
        }

        let putSiteTrafficInfobody = {
            websiteUrl: e.target.website_url.value,
            experienceDesc: e.target.experience_desc.value,
            promotionDesc: e.target.promotion_desc.value,
            incentiveFlag: e.target.incentive_flag.value,
            incentiveDesc: e.target.incentive_desc.value,
            callbackType: e.target.callback_type.value,
            callbackCode: e.target.callback_code.value,
            networkFlag: e.target.network_flag.value,
            cancelledCampaignCallback: e.target.cancelled_campaign_callback.value,
            campaignRateChange: e.target.campaign_ratechange.value,
        }

        const generalInfoUpdateResult = await callAPI(`affiliate/${affiliateId}/generalInfo`, 'PUT', generalPutBody)

        const accountInfoUpdateResult = await callAPI(`affiliate/${affiliateId}/accountSettings`, 'PUT', accountPutBody)

        const trackingInfoUpdateResult = await callAPI(`affiliate/${affiliateId}/trackingSettings`, 'PUT', trackingPutBody)

        const notesUpdateResult = await callAPI(`affiliate/${affiliateId}/notes`, 'PUT', notesPutBody)

        const paymentInfoUpdateResult = await callAPI(`affiliate/${affiliateId}/paymentInfo`, 'PUT', paymentInfoPutBody)

        const contactInfoUpdateResult = await callAPI(`affiliate/${affiliateId}/contactInfo`, 'PUT', putContactBody)

        const siteTrafficInfoUpdateResult = await callAPI(`affiliate/${affiliateId}/siteTrafficInfo`, 'PUT', putSiteTrafficInfobody)

        setSubmitting(false)

        if (!generalInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating general information')
            return
        }

        if (!accountInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating account information')
            return
        }

        if (!trackingInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating tracking information')
            return
        }

        if (!notesUpdateResult.success) {
            showSystemMessage('error', 'Error while updating notes')
            return
        }

        if (!paymentInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating payment information')
            return
        }

        if (!contactInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating contact information')
            return
        }

        if (!siteTrafficInfoUpdateResult.success) {
            showSystemMessage('error', 'Error while updating site traffic information')
            return
        }

        showSystemMessage('success', 'Affiliate settings saved.')
        setIsDisabled(true)
        refetch()
    }

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">{dataLoaded && data.affiliate.name}</h1>
                <div className="ml-5">{dataLoaded && <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            {dataLoaded ? (
                <React.Fragment>
                    <form onSubmit={handleSubmit}>
                        <div className="card mb-2 text-right">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <p className="mb-h1-dark">Affiliate Settings</p>
                                <CustomButton name="Save" variant="success" type="submit" disabled={isDisabled} spin={submitting} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div>
                                    <GeneralInfo affiliate={data.affiliate} affiliateId={affiliateId} showSystemMessage={showSystemMessage} refetch={refetch} setIsDisabled={setIsDisabled} />
                                    <br></br>
                                    <AccountSettings affiliate={data.affiliate} showSystemMessage={showSystemMessage} affiliateId={affiliateId} refetch={refetch} setIsDisabled={setIsDisabled} />
                                    <br></br>
                                    <TrackingSettings affiliate={data.affiliate} setIsDisabled={setIsDisabled} />
                                    <br></br>
                                    <Notes affiliate={data.affiliate} showSystemMessage={showSystemMessage} affiliateId={affiliateId} refetch={refetch} setIsDisabled={setIsDisabled} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    <PaymentInfo affiliate={data.affiliate} affiliateId={affiliateId} showSystemMessage={showSystemMessage} setIsDisabled={setIsDisabled} />
                                    <br></br>
                                    <ContactInfo affiliate={data.affiliate} setIsDisabled={setIsDisabled} />
                                    <br></br>
                                    <SiteTrafficInfo affiliate={data.affiliate} affiliateId={affiliateId} showSystemMessage={showSystemMessage} refetch={refetch} setIsDisabled={setIsDisabled} />
                                    <div className="card my-2">
                                        <div className="card-header text-right">
                                            <CustomButton name="Save" variant="success" type="submit" disabled={isDisabled} spin={submitting} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}

export default AffiliateSettings
