import { Card } from 'react-bootstrap'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import useCallApi from '../../../../hooks/useCallApi'
import { Link } from 'react-router-dom'
import LoginDetailsModal from './LoginDetailsModal'
import Loading from '../../../../templates/UI/Loading/Loading'
import { useAuth, useEditableContent } from '../../../../context/AuthContext'

const LoginDetails = ({ advertiserId, manager, showSystemMessage }) => {
    const auth = useAuth()
    const showLoginSettings = useEditableContent(auth.permissions, 'advertiser_login_settings')

    const { data, dataLoaded, setData, refetch, setDataLoaded } = useCallApi(`advertisers/${advertiserId}/logindetails`, 'GET', {}, [advertiserId])
    const [id, setId] = useState(0)
    return (
        <Card className="mb-3">
            {showLoginSettings && <LoginDetailsModal setDataLoaded={setDataLoaded} showSystemMessage={showSystemMessage} modalData={data} setModalData={setData} id={id} dataLoaded={dataLoaded} manager={manager} refetch={refetch} advertiserId={advertiserId} />}
            <Card.Header className="d-flex justify-content-between align-items-center">
                <span>
                    <span className="mr-1">Login Details</span>
                </span>
                {showLoginSettings && (
                    <Button variant="warning" size="sm" className="mr-2" onClick={() => setData({ ...data, isOpen: true, isCreate: true })}>
                        Add Login
                    </Button>
                )}
            </Card.Header>
            <Card.Body className="overflow-auto">
                {dataLoaded ? (
                    <>
                        {data.loginDetails.length === 0 ? (
                            <div>No Login Details Available</div>
                        ) : (
                            data.loginDetails.map((item, index) => {
                                return (
                                    <Card className="bg-light d-flex p-3 mb-3" key={index}>
                                        <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                            <div className="d-flex flex-column">
                                                <div>
                                                    Site: <Link to={item.url}>{item.url}</Link>
                                                </div>
                                                <div>Login: {item.login}</div>
                                                <div>Password: {item.password}</div>
                                            </div>
                                            {showLoginSettings && (
                                                <Button
                                                    variant="warning"
                                                    size="sm"
                                                    onClick={() => {
                                                        setData({ ...data, isOpen: true, isCreate: false })
                                                        setId(index)
                                                    }}
                                                    style={{ height: '2rem' }}
                                                >
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                        <hr />
                                        <div>Notes: {item.notes}</div>
                                    </Card>
                                )
                            })
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Card.Body>
        </Card>
    )
}

export default LoginDetails
