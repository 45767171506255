import { Card, Button } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'

import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import MerModal from './MerModal'
import { useAuth, useProtectedContent, useProtectedPage } from '../../../context/AuthContext'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Advertiser from '../../../templates/Advertiser/Advertiser'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

const FollowupSummaryReport = ({ showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'advertisers_list')
    const showCreateAdvertiser = useProtectedContent(auth.permissions, 'advertiser_create')
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const [isOpen, setIsOpen] = useState(false)
    const history = useHistory()

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const { data, dataLoaded, refetch } = useCallApi(buildUrl(`advertisers/followupSummaryReport?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`), 'GET', {}, [adminId], showSystemMessage)

    const refreshReport = (e) => {
        e.preventDefault()
        history.push(`/advertisers/followupSummaryReport?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        refetch()
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: (tableData) => {
                    return <Link to={`/admin/${tableData.row.original.adminId}/followups?startDate=${tableData.row.original.date}&endDate=${tableData.row.original.date}`}>{tableData.row.original.date}</Link>
                },
            },
            {
                Header: <div className="text-right">Pub Mgmt</div>,
                accessor: 'PM',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Drop Camp</div>,
                accessor: 'DC',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">IO</div>,
                accessor: 'IO',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Pixel</div>,
                accessor: 'PI',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Campaign</div>,
                accessor: 'CI',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Emailed</div>,
                accessor: 'EM',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Called Spoke</div>,
                accessor: 'CS',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Call LM/NA</div>,
                accessor: 'LM',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Status</div>,
                accessor: 'SC',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">FWD</div>,
                accessor: 'FW',
                className: 'text-right',
            },
            {
                Header: <div className="text-right">Other</div>,
                accessor: 'OT',
                className: 'text-right',
            },
        ]

        return <ReportTable data={data.report} columns={columns} />
    }

    return (
        <div>
            {showCreateAdvertiser && <MerModal showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} mgrId={auth.user.id} refetch={refetch} />}
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Advertisers</h1>
            </div>
            <Card className="mb-3">
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div>Followup Summary for:</div>
                        <div className="mx-2">
                            <AdminPicker className="w-100" adminId={adminId} setAdminId={setAdminId} role={['BIZDEV', 'MERMGR', 'ADVERTISER_DIRECTOR', 'ADOPS_MANAGER', 'ADOPS_TEAMLEAD']} />
                        </div>
                        <DateRangeFilter className="w-100" quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button variant="primary" size="sm" className="ml-2" onClick={refreshReport}>
                            Refresh
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body className="overflow-auto p-0">{dataLoaded ? !(data.report?.length > 0) ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</Card.Body>
            </Card>
        </div>
    )
}

export default FollowupSummaryReport
