import { Form, Modal, Table, Button } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Loading from '../../../templates/UI/Loading/Loading'
import callAPI from '../../../helpers/callAPI'

const CreativesModal = ({ data, refetch, creative, showSystemMessage, isOpen, setIsOpen, name, isCreate, dataLoaded, campaignId, reloadPage, setReloadPage }) => {
    const [formData, setFormData] = useState(
        isCreate
            ? {
                  creative_type: 'B',
                  status: 'A',
                  landing_url: undefined,
                  upload_file: undefined,
                  html_text: undefined,
                  creative_id: undefined,
                  preview: null,
              }
            : {
                  ...creative,
              }
    )
    const [tempFile, setTempFile] = useState(false)

    useEffect(() => {
        setFormData(
            isCreate
                ? {
                      creative_type: 'B',
                      status: 'A',
                      landing_url: undefined,
                      upload_file: undefined,
                      html_text: undefined,
                      creative_id: undefined,
                  }
                : {
                      ...creative,
                  }
        )
    }, [isOpen])

    const create = async () => {
        const tempFormData = new FormData()
        tempFormData.append('upload_file', formData.upload_file)
        tempFormData.append('creative_type', formData.creative_type)
        tempFormData.append('status', formData.status)
        tempFormData.append('landing_url', formData.landing_url)
        tempFormData.append('html_text', formData.html_text)
        tempFormData.append('merchant_id', data.advertiser.id)
        console.log('tempFormData', tempFormData)
        const response = await callAPI(`campaign/${campaignId}/creative/${creative.creative_id || 0}`, 'POST', tempFormData, true)
        if (response.success) {
            refetch()
            setReloadPage(!reloadPage)
            setIsOpen(false)
            showSystemMessage('success', 'Creative created successfully')
        } else {
            showSystemMessage('error', response.errors.message)
        }
    }

    const update = async () => {
        const tempFormData = new FormData()
        tempFormData.append('upload_file', formData.upload_file)
        tempFormData.append('creative_type', formData.creative_type)
        tempFormData.append('status', formData.status)
        tempFormData.append('landing_url', formData.landing_url)
        tempFormData.append('html_text', formData.html_text)
        tempFormData.append('merchant_id', data.advertiser.id)
        const response = await callAPI(`campaign/${campaignId}/creative/${creative.creative_id || 0}`, 'PUT', tempFormData, true)
        if (response.success) {
            refetch()
            setIsOpen(false)
            showSystemMessage('success', 'Creative updated successfully')
        } else {
            showSystemMessage('error', response.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? `Create new Creative for ${name}` : `Edit creative ${name} for ${data.success ? 'this campaign' : ''}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto">
                {dataLoaded ? (
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td>Creative Type:</td>
                                <td>
                                    <Form.Control as="select" value={formData.creative_type} onChange={(e) => setFormData({ ...formData, creative_type: e.target.value })}>
                                        <option value="B">Banner</option>
                                        <option value="E">Email</option>
                                        <option value="H">HTML</option>
                                        <option value="T">Text</option>
                                    </Form.Control>
                                </td>
                            </tr>
                            <tr>
                                <td>Status:</td>
                                <td>
                                    <Form.Control as="select" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })}>
                                        <option value="A">Active</option>
                                        <option value="P">Pending</option>
                                        <option value="C">Cancelled</option>
                                    </Form.Control>
                                </td>
                            </tr>
                            <tr>
                                <td>Alternate Landing URL:</td>
                                <td>
                                    <Form.Control type="text" value={formData.landing_url} onChange={(e) => setFormData({ ...formData, landing_url: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <td>Upload File:</td>
                                <td>
                                    <Form.Control
                                        type="file"
                                        required={formData.creative_type === 'B' ? true : false}
                                        onChange={(e) => {
                                            setTempFile(URL.createObjectURL(e.target.files[0]))
                                            setFormData({ ...formData, upload_file: e.target.files[0], width: 'auto', height: 'auto' })
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>HTML Code:</td>
                                <td>
                                    <Form.Control as="textarea" value={formData.html_text} onChange={(e) => setFormData({ ...formData, html_text: e.target.value })} />
                                </td>
                            </tr>
                            <tr>
                                <td>Preview:</td>
                                <td>{formData.creative_type === 'B' ? formData.upload_file && tempFile ? <img style={{ maxHeight: '300px' }} src={tempFile} alt="Uploaded File." /> : <img style={{ maxHeight: '300px' }} src={`${process.env.REACT_APP_API_URL}/campaign/${campaignId}/creatives/${data.advertiser.id}/preview/?creativeType=${creative.creative_type}&creativeId=${creative.creative_id}`} alt="Banner" crossOrigin={process.env.REACT_APP_PORTAL_URL} /> : <div dangerouslySetInnerHTML={{ __html: formData.html_text }} />}</td>
                            </tr>
                        </tbody>
                    </Table>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button size="sm" variant="primary" onClick={create}>
                        Save
                    </Button>
                ) : (
                    <Button size="sm" variant="primary" onClick={update}>
                        Update
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default CreativesModal
