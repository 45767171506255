import { useState } from 'react'

import CustomFlags from '../../../components/CustomFlags/CustomFlags'
import Countries from '../../Avatar/AvatarSurvey/Survey/templates/Countries'

import campaignStatuses from '../../../data/campaignStatuses'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'

import TrackingPlatforms from '../../../components/TrackingPlatforms/TrackingPlatforms'
import CampaignCategoriesSelect from '../../../components/CampaignCategoriesSelect/CampaignCategoriesSelect'
import TrackingTypes from '../../../data/CampaignTrackingTypes'
import CampaignTypes from '../../../data/CampaignTypes'

const SearchOptions = ({ traffic, setTraffic, status, setStatus, adminId, country, setCountry, cpaType, setCpaType, setAdminId, htmlPostbacks, setHtmlPostbacks, displayRates, setDisplayRates, mobileCampaign, setMobileCampaign, trackingPlatform, setTrackingPlatform, flagId, setFlagId, category, setCategory, trackingType, setTrackingType, campaignType, setCampaignType, showSystemMessage }) => {
    return (
        <table className="table table-card text-nowrap">
            <tbody style={{ lineHeight: '40px' }}>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Advertiser Manager &nbsp;&nbsp;&nbsp;
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role="MERMGR" />
                        </span>
                        {/* REFACTOR - could use value ='A' and so on instead of full text */}
                        <span className="d-flex align-items-center ml-3">
                            Status&nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="">Any</option>
                                <option value="A,B,H">Active / By Request / Hidden</option>
                                {campaignStatuses.campaignStatuses.map((status) => {
                                    return <option value={`${status.value}`}>{status.text}</option>
                                })}
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="d-flex align-items-center ml-3">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="traffic_search" name="traffic_search" defaultChecked={traffic.searchTraffic} onClick={() => setTraffic({ ...traffic, searchTraffic: !traffic.searchTraffic })} />
                                <label className="form-check-label" for="traffic_search">
                                    Search
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_email" name="traffic_email" defaultChecked={traffic.emailTraffic} onClick={() => setTraffic({ ...traffic, emailTraffic: !traffic.emailTraffic })} />
                                <label className="form-check-label" for="traffic_email">
                                    Email
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_incentive" name="traffic_incentive" defaultChecked={traffic.incentiveTraffic} onClick={() => setTraffic({ ...traffic, incentiveTraffic: !traffic.incentiveTraffic })} />
                                <label className="form-check-label" for="traffic_incentive">
                                    Incentive
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_contextual" name="traffic_contextual" defaultChecked={traffic.contextualTraffic} onClick={() => setTraffic({ ...traffic, contextualTraffic: !traffic.contextualTraffic })} />
                                <label className="form-check-label" for="traffic_contextual">
                                    Contextual
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_social" name="traffic_social" defaultChecked={traffic.socialTraffic} onClick={() => setTraffic({ ...traffic, socialTraffic: !traffic.socialTraffic })} />
                                <label className="form-check-label" for="traffic_social">
                                    Social
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_banner" name="traffic_banner" defaultChecked={traffic.bannerTraffic} onClick={() => setTraffic({ ...traffic, bannerTraffic: !traffic.bannerTraffic })} />
                                <label className="form-check-label" for="traffic_banner">
                                    Display
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_mobile" name="traffic_mobile" defaultChecked={traffic.mobileTraffic} onClick={() => setTraffic({ ...traffic, mobileTraffic: !traffic.mobileTraffic })} />
                                <label className="form-check-label" for="traffic_mobile">
                                    Mobile
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_native" name="traffic_native" defaultChecked={traffic.nativeTraffic} onClick={() => setTraffic({ ...traffic, nativeTraffic: !traffic.nativeTraffic })} />
                                <label className="form-check-label" for="traffic_native">
                                    Native
                                </label>
                            </div>
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="traffic_brand_bid" name="traffic_brand_bid" defaultChecked={traffic.brandBidTraffic} onClick={() => setTraffic({ ...traffic, brandBidTraffic: !traffic.brandBidTraffic })} />
                                <label className="form-check-label" for="traffic_brand_bid">
                                    Brand Bid Traffic
                                </label>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="d-flex">
                        <span className="d-flex align-items-center ml-3">
                            Campaign Type&nbsp;&nbsp;&nbsp;
                            <select name="campaignType" className="form-control form-control-sm" defaultValue={campaignType} onChange={(e) => setCampaignType(e.target.value)}>
                                <option value="">Any</option>
                                {CampaignTypes.types.map((campaignType) => {
                                    return <option value={campaignType.value}>{campaignType.text}</option>
                                })}
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            CPA Type&nbsp;&nbsp;&nbsp;
                            <select name="cpaType" className="form-control form-control-sm" defaultValue={cpaType} onChange={(e) => setCpaType(e.target.value)}>
                                <option value="">Any</option>
                                <option value="F">Fixed</option>
                                <option value="P">Revshare</option>
                                <option value="M">Mixed</option>
                                <option value="C">CPC</option>
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Tracking Type&nbsp;&nbsp;&nbsp;
                            <select name="cpaType" className="form-control form-control-sm" defaultValue={trackingType} onChange={(e) => setTrackingType(e.target.value)}>
                                <option value="">Any</option>
                                {TrackingTypes.types.map((trackingType) => {
                                    return <option value={trackingType.value}>{trackingType.text}</option>
                                })}
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            HTML Postbacks&nbsp;&nbsp;&nbsp;
                            <select name="postbacks" className="form-control form-control-sm" defaultValue={htmlPostbacks} onChange={(e) => setHtmlPostbacks(e.target.value === 'Y' ? true : false)}>
                                <option value="">Any</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Using Display Rates&nbsp;&nbsp;&nbsp;
                            <select name="display_rates" className="form-control form-control-sm" defaultValue={displayRates} onChange={(e) => setDisplayRates(e.target.value === 'Y' ? true : false)}>
                                <option value="">Any</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Tracking Platform&nbsp;&nbsp;&nbsp;
                            <select name="platforms" className="form-control form-control-sm" defaultValue={trackingPlatform} onChange={(e) => setTrackingPlatform(e.target.value)}>
                                <option value="">Any</option>
                                <TrackingPlatforms />
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Mobile Campaign&nbsp;&nbsp;&nbsp;
                            <select name="mobile_campaign" className="form-control form-control-sm" defaultValue={mobileCampaign} onChange={(e) => setMobileCampaign(e.target.value === 'Y' ? true : false)}>
                                <option value="">Any</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="d-flex">
                        <CampaignCategoriesSelect category={category} setCategory={setCategory} />
                        <span className="d-flex align-items-center ml-3">
                            Country&nbsp;&nbsp;&nbsp;
                            <select name="country" className="form-control form-control-sm" defaultValue={country} onChange={(e) => setCountry(e.target.value)}>
                                <option value="">Select Country</option>
                                <Countries />
                            </select>
                        </span>
                        <span className="d-flex align-items-center ml-3">
                            Custom Flag&nbsp;&nbsp;&nbsp;
                            <select className="form-control form-control-sm" name="flag" defaultValue={flagId} onChange={(e) => setFlagId(e.target.value)}>
                                <option value="">Select Flag</option>
                                <CustomFlags target="campaign" showSystemMessage={showSystemMessage} />
                            </select>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default SearchOptions
