import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import AffiliateNavbar from '../../Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import ContactCheck from './ContactCheck'
import ContactInfo from './ContactInfo'
import IDNumber from './IdNumber'
import LoginInformation from './LoginInformation'
import LoginIPCheck from './LoginIPCheck'
import PasswordCheck from './PasswordCheck'
import PaymentDetails from './PaymentDetails'
import PhoneCheck from './PhoneCheck'
import PostalAddressCheck from './PostalAddressCheck'
import PromotionalInformation from './PromotionalInformation'
import SignupIpCheck from './SignupIpCheck'

const AffiliateFraudCheck = ({ affiliateId, showSystemMessage }) => {
    const { data, dataLoaded } = useCallApi(`compliance/${affiliateId}/fraudcheck`, 'GET', {}, [affiliateId], showSystemMessage)

    document.title = `${dataLoaded ? `${data.info.name}: ` : ''}Login History`

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Fraud Check</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="row Affiliate">
                {dataLoaded ? (
                    <>
                        <div className="col-xl-6">
                            <ContactInfo affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <IDNumber affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <PromotionalInformation affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <PostalAddressCheck affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <SignupIpCheck affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                        </div>
                        <div className="col-xl-6 pb-0">
                            <LoginIPCheck affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <PasswordCheck affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            {data.paymentMethod === 'B' && <PaymentDetails affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />}
                            <PhoneCheck affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <LoginInformation affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                            <ContactCheck affiliateId={affiliateId} showSystemMessage={showSystemMessage} data={data} dataLoaded={dataLoaded} />
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </>
    )
}

export default AffiliateFraudCheck
