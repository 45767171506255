import React, { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import moment from 'moment'
import { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import EmployeePicker from '../../../components/EmployeePicker/EmployeePicker'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'

export default function MaxMoneyReport({ showSystemMessage, promoId, mgrId, mgrName }) {
    const [employee, setEmployee] = useState({ id: mgrId ? mgrId : 0, name: mgrName ? mgrName : '' })
    const { data, dataLoaded, refetch } = useCallApi(`maxmoney/${promoId}/report${employee.id > 0 ? `?mgrid=${employee.id}` : ''}`, 'GET', {}, [employee], showSystemMessage)

    const history = useHistory()
    useEffect(() => {
        history.push(`/maxmoney/${promoId}/report${employee.id > 0 ? `?mgrid=${employee.id}&&mgrname=${employee.name}` : ''}`)
    }, [employee])

    return (
        <Card className="mb-3 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center">
                MaxMoney Report for {dataLoaded ? `${data.reportInfo.promo_name} (${moment(data.reportInfo.start_date).format('MMM/DD')} - ${moment(data.reportInfo.end_date).format('MMM/DD')})` : ''}
                <EmployeePicker employee={employee} setEmployee={setEmployee} role={[]} className="ml-3 w-25" />
            </Card.Header>
            <Table striped bordered>
                {dataLoaded ? (
                    <>
                        {data.reportInfo.promo_type === 'OFFER_SPECIFIC' || data.reportInfo.promo_type === 'NEVER_RUN' ? (
                            <>
                                <thead>
                                    <tr>
                                        <th>Aff. ID</th>
                                        <th>Affiliate Name</th>
                                        <th>
                                            Total Earnings
                                            <div style={{ width: 'max-content' }}>
                                                ({moment(data.start_date).format('MMM/DD')} - {moment(data.end_date).format('MMM/DD')})
                                            </div>
                                        </th>
                                        <th>
                                            Total Earnings
                                            <div style={{ width: 'max-content' }}>
                                                ({moment(data.start_date).format('MMM/DD')} - {moment(data.end_date).format('MMM/DD')})
                                            </div>
                                        </th>
                                        <th>Delta</th>
                                        <th>
                                            Promo Earnings
                                            <div style={{ width: 'max-content' }}>
                                                ({moment(data.start_date).format('MMM/DD')} - {moment(data.end_date).format('MMM/DD')})
                                            </div>
                                        </th>
                                        <th>
                                            Promo Earnings
                                            <div style={{ width: 'max-content' }}>
                                                ({moment(data.start_date).format('MMM/DD')} - {moment(data.end_date).format('MMM/DD')})
                                            </div>
                                        </th>
                                        <th>Delta</th>
                                        <th>Margin</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.report.length > 0 ? (
                                        data.report.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.affiliate_id}</td>
                                                    <td>{<Affiliate aid={item.affiliate_id} name={item.name} />}</td>
                                                    <td>{<Dollars value={item.prev_earnings} />}</td>
                                                    <td>{<Dollars value={item.current_earnings} />}</td>
                                                    <td>{<Dollars value={item.current_earnings - item.prev_earnings} />}</td>
                                                    <td>{<Dollars value={item.prev_promo_earnings} />}</td>
                                                    <td>{<Dollars value={item.promo_earnings} />}</td>
                                                    <td>{<Dollars value={item.promo_earnings - item.prev_promo_earnings} />}</td>
                                                    <td>{<Dollars value={item.margin} />}</td>
                                                    <td>{item.mm_points.toFixed(2)}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <td colSpan="10">No data found.</td>
                                    )}
                                </tbody>
                            </>
                        ) : (
                            <>
                                <thead>
                                    <tr>
                                        <th>Aff. ID</th>
                                        <th>Affiliate Name</th>
                                        <th>Threshold</th>
                                        <th>Current Earnings</th>
                                        <th>To Next Tier</th>
                                        <th>Earnings / Threshold</th>
                                        <th>Reward tier %</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.report.length > 0 ? (
                                        data.report.map((item, index) => {
                                            let toNextTier = 0
                                            let reward_pct = 0
                                            for (let i = 0; i < data.rewardList.length; i++) {
                                                const reward_threshold = data.rewardList[i].reward_threshold
                                                const amount_more_to_earn = (1 + reward_threshold) * item.byb_threshold - item.promo_earnings
                                                if (amount_more_to_earn > 0) {
                                                    toNextTier = amount_more_to_earn
                                                    break
                                                }
                                            }
                                            for (let i = 0; i < data.rewardList.length; i++) {
                                                const reward_threshold = data.rewardList[i].reward_threshold
                                                const amount_more_to_earn = (1 + reward_threshold) * item.byb_threshold - item.promo_earnings
                                                if (amount_more_to_earn <= 0) {
                                                    reward_pct = data.rewardList[i].reward_pct * 100
                                                    break
                                                }
                                            }
                                            return (
                                                <tr key={index}>
                                                    <td>{item.affiliate_id}</td>
                                                    <td>{<Affiliate aid={item.affiliate_id} name={item.name} />}</td>
                                                    <td>{<Dollars value={item.byb_threshold} />}</td>
                                                    <td>{<Dollars value={item.promo_earnings} />}</td>
                                                    <td>{<Dollars value={toNextTier} />}</td>
                                                    <td>{((item.promo_earnings - item.byb_threshold) / item.byb_threshold) * 100 < 0 ? '0' : (((item.promo_earnings - item.byb_threshold) / item.byb_threshold) * 100).toFixed(2)}%</td>
                                                    <td>{reward_pct}%</td>
                                                    <td>{<Dollars value={(reward_pct / 100) * item.promo_earnings} />}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <td colSpan="8">No data found.</td>
                                    )}
                                </tbody>
                            </>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}
