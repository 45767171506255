import React, { useState, useEffect } from 'react'
import useCallApi from '../../hooks/useCallApi'

export default function MBDomainsSelect({ domain, setDomain }) {
    let apiUrl = `mbDomains`

    const { dataLoaded, data } = useCallApi(apiUrl, 'GET', {}, [])
    // TODO: need to fix correct domain not getting picked up on first load due to https:// in front
    return (
        <select
            name="mb_domains"
            className="form-control form-control-sm"
            value={domain}
            onChange={(e) => {
                setDomain(e.target.value)
            }}
        >
            {dataLoaded &&
                data.domains &&
                data.domains.map((domain_url) => {
                    return (
                        <option value={domain_url} key={domain_url}>
                            {domain_url}
                        </option>
                    )
                })}
        </select>
    )
}
