import { Card, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import Graph from '../../../../components/Graph/Graph'
import styles from './JAmSummary.module.scss'
import { Link } from 'react-router-dom'
import EmployeePicker from '../../../../components/EmployeePicker/EmployeePicker'
import TimeScalePicker from '../../../../components/TimeScalePicker/TimeScalePicker'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import Dollars from '../../../../templates/Dollars/Dollars'

import { useAuth, useSetManager } from '../../../../context/AuthContext'

export default function JAmSummary({ manager, setManager, showSetManager }) {
    const auth = useAuth()

    const [scale, setScale] = useState('Weekly')

    const [graphLoaded, setGraphLoaded] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [graphData, setGraphData] = useState({
        thisMonth: { in: 0, out: 0 },
        lastMonth: { in: 0, out: 0 },
        in: [
            { date: '---', value: 0 },
            { date: '---', value: 0 },
            { date: '---', value: 0 },
            { date: '---', value: 0 },
            { date: '---', value: 0 },
        ],
        out: [
            { date: '---', value: 0 },
            { date: '---', value: 0 },
            { date: '---', value: 0 },
            { date: '---', value: 0 },
            { date: '---', value: 0 },
        ],
    })

    const [summaryData, setSummaryData] = useState({
        affiliates: {
            high: { affiliate_manager: 0, network: 0 },
            medium: { affiliate_manager: 0, network: 0 },
            new: { affiliate_manager: 0, network: 0 },
            total: { affiliate_manager: 0, network: 0 },
        },
        followupsToday: 0,
        margin: 0.0,
        applications: { approved: 0, processed: 0 },
    })

    useEffect(() => {
        const fetchSummaryData = async () => {
            const data = await callAPI(`summary/juniorAffiliateManager/${manager.id}`, 'GET', null)
            if (data.success) {
                setSummaryData(data)
            } else {
                console.log(data.success)
            }
            setDataLoaded(true)
        }
        setDataLoaded(false)
        fetchSummaryData()
    }, [manager])

    useEffect(() => {
        const fetchGraphData = async () => {
            const data = await callAPI(`affiliate/transfers/mediumHigh/${manager.id}/${scale}`, 'GET', null)
            if (data.status === 'success') {
                setGraphData({
                    thisMonth: { in: data.report.thisMonth.in, out: data.report.thisMonth.out },
                    lastMonth: { in: data.report.lastMonth.in, out: data.report.lastMonth.out },
                    in: data.report.in,
                    out: data.report.out,
                })
            } else {
                console.log(data.success)
            }
            setGraphLoaded(true)
        }
        fetchGraphData()
    }, [manager, scale])

    return (
        <Card className="mb-3">
            <Card.Title className={`m-3 d-flex ${styles.customFlexDirection}`}>
                <div className="font-weight-bold text-secondary" style={{ fontSize: '1.5rem' }}>
                    Hello <span className="text-dark">{manager.name.split(' ')[0]}</span>
                </div>
                {useSetManager(auth.permissions, 'jr_am_dashboard') && showSetManager && <EmployeePicker employee={manager} setEmployee={setManager} role={'AM_JUNIOR'} />}
            </Card.Title>
            <hr className="my-0" />
            <Card.Body>
                {graphLoaded ? (
                    <>
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className="font-weight-bold mr-2">High/Medium Transfers</span>
                                <TimeScalePicker timeScale={scale} setTimeScale={setScale} options={['Weekly', 'Monthly']} />
                            </div>
                            <div>
                                This Month &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">{graphData.thisMonth.out} &nbsp;</span>
                                </span>
                            </div>
                            <div>
                                Last Month &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">{graphData.lastMonth.out} &nbsp;</span>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <Graph
                                data={{
                                    labels: graphData.in.map((item) => item.date),
                                    datasets: [
                                        {
                                            data: [graphData.out[0].transfers, graphData.out[1].transfers, graphData.out[2].transfers, graphData.out[3].transfers, graphData.out[4].transfers],
                                            fill: true,
                                            backgroundColor: 'rgba(16, 159, 182, 0.2)',
                                            borderColor: 'rgba(16, 159, 182, 1)',
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <Loading />
                )}

                <hr className="my-3" />

                {dataLoaded ? (
                    <>
                        <div className="d-flex justify-content-between">
                            <div>
                                High &nbsp;
                                <span className="mb-element green-highlight">
                                    <span className="name font-weight-bold">{summaryData.affiliates.high.affiliate_manager} &nbsp;</span>
                                </span>
                                /{summaryData.affiliates.high.network}
                            </div>
                            <div>
                                Medium &nbsp;
                                <span className="mb-element highlight">
                                    <span className="name font-weight-bold">{summaryData.affiliates.medium.affiliate_manager} &nbsp;</span>
                                </span>
                                /{summaryData.affiliates.medium.network}
                            </div>
                            <div>
                                New &nbsp;
                                <span className="mb-element purple-highlight">
                                    <span className="name font-weight-bold">{summaryData.affiliates.new.affiliate_manager} &nbsp;</span>
                                </span>
                                /{summaryData.affiliates.new.network}
                            </div>
                        </div>
                        <ProgressBar className="my-2">
                            <ProgressBar variant="green-highlight" now={(summaryData.affiliates.high.affiliate_manager / summaryData.affiliates.total.affiliate_manager) * 100} />
                            <ProgressBar variant="yellow-highlight" now={(summaryData.affiliates.medium.affiliate_manager / summaryData.affiliates.total.affiliate_manager) * 100} />
                            <ProgressBar variant="purple-highlight" now={(summaryData.affiliates.new.affiliate_manager / summaryData.affiliates.total.affiliate_manager) * 100} />
                        </ProgressBar>
                        <hr className="my-3" />
                        <div className="d-flex justify-content-between">
                            <div>
                                Month Margin &nbsp;
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Margin</Tooltip>}>
                                    <span className="mb-element active">
                                        <span className="name text-primary font-weight-bold">
                                            <Link to={`/reports/stats/affiliates?adminId=${manager.id}`}>
                                                <Dollars value={summaryData.margin} />
                                            </Link>
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                Followups Today &nbsp;
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Followups</Tooltip>}>
                                    <span>
                                        <span className="text-primary font-weight-bold">
                                            <Link to={`/affiliates/followups?adminId=${manager.id}`}>{summaryData.followups_today}</Link>
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                Apps Today &nbsp;
                                <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Approved/Processed</Tooltip>}>
                                    <span className="mb-element">
                                        <span className="name text-primary">
                                            <Link to={`/affiliates/applications?adminId=${manager.id}`}>
                                                <strong>{summaryData.applications.approved}</strong>/{summaryData.applications.processed}
                                            </Link>
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                            <div>
                                Affs with Leads &nbsp;
                                <strong>{summaryData.affiliates_with_leads}</strong>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </Card.Body>
            <div className={`d-flex justify-content-center p-2 ${styles.bgInfo25}`}>
                <Link to={`/admin/${manager.id}/AMPerformanceReport`} className="text-primary">
                    View performance report{' '}
                </Link>
            </div>
        </Card>
    )
}
