import React, { Component } from 'react'
import './Reports.css'
import queryString from 'query-string'
import moment from 'moment'

import ReportAffiliateEarnings from './ReportAffiliateEarnings/ReportAffiliateEarnings'
import ReportCampaignEarnings from './ReportCampaignEarnings/ReportCampaignEarnings'
import ReportAdvertiserEarnings from './ReportAdvertiserEarnings/ReportAdvertiserEarnings'
import ReportMerchantMargin from './ReportMerchantMargin/ReportMerchantMargin'
import ReportClicks from './ReportClicks/ReportClicks'
import ReportCreatives from './ReportCreatives/ReportCreatives'
import ReportLandingPages from './ReportLandingPages/ReportLandingPages'
import ReportAffSignup from './ReportAffSignup/ReportAffSignup'
import ReportSotu from './ReportSotu/ReportSotu'
import ReportMargin from './ReportMargin/ReportMargin'
import ReportAffPaymentTotals from './ReportAffPaymentTotals/ReportAffPaymentTotals'
import ReportAffPayTotalsDetails from './ReportAffPayTotalsDetails/ReportAffPayTotalsDetails'
import ReportTrends from './ReportTrends/ReportTrends'
import ReportTrendDetail from './ReportTrendDetail/ReportTrendDetail'
import ReportInvoice from './ReportInvoice/ReportInvoice'
import ReportInvoiceList from './ReportInvoiceList/ReportInvoiceList'
import ReportMerchantBalances from './ReportMerchantBalances/ReportMerchantBalances'
import ReportBizDevPipeline from './ReportBizDevPipeline/ReportBizDevPipeline'

import NameFilter from './Filters/NameFilter/NameFilter'
import DateRangeFilter from './Filters/DateRangeFilter/DateRangeFilter'
import CountryFilter from './Filters/CountryFilter/CountryFilter'
import ManagerDropdown from '../../templates/ManagerDropdown/ManagerDropdown'
import ReportReferringUrls from './ReportReferringUrls/ReportReferringUrls'
import ReportSubId from './ReportSubId/ReportSubId'
import Checkbox from './Filters/Checkbox/Checkbox'
import CampaignTrendFilters from './Filters/TrendFilters/TrendFilters'
import HeaderStats from '../../templates/Layout/HeaderStats/HeaderStats'
import ReportTestPostbacks from './TestPostbacks/TestPostbacks'

class Reports extends Component {
    state = {
        dataLoaded: false,
        type: '',
        mgrId: '',
        bizDevMgrId: '',
        timeFrame: 'd',
        filterTrend: 'b',
        minT1Margin: '',
        filterTrendDate: '',
        affiliateId: '',
        offerId: '',
        merchantId: '',
        creativeId: '',
        landingPageId: '',
        subId: '',
        affiliatePriority: '',
        affiliateStatus: '',
        totalAppsFlag: false,
        startDate: '',
        endDate: '',
        quickDate: 'month',
        selectedType: '',
        selectedStartDate: '',
        selectedEndDate: '',
        filterStartDate: '',
        filterEndDate: '',
        preparingCSV: false,
        CSVData: '',
        page: 1,
        totalPages: 1,
        resultsPerPage: 50,
        payable: false,
        waiting: false,
        revshare: false,
        perlead: false,
    }

    componentDidMount() {
        this._isMounted = true
        let urlParams = queryString.parse(this.props.location.search)
        let mgrId
        let bizDevMgrId
        let minT1Margin
        let filterTrendDate
        let timeFrame
        let filterTrend
        let affiliateId
        let offerId
        let merchantId
        let creativeId
        let landingPageId
        let subId
        let affiliatePriority
        let affiliateStatus
        let totalAppsFlag
        let startDate
        let endDate
        let quickDate
        let filterStartDate
        let filterEndDate
        let type
        let page
        let payable
        let waiting
        let perlead
        let revshare

        // Get the report type if specified. Get the offer ID if specified.
        this.props.type ? (type = this.props.type) : (type = 'affiliate_earnings')

        //Get the manager ID from the URL
        mgrId = urlParams.mgrid ? parseInt(urlParams.mgrid) : 0

        // Get the bizdev manager ID from the URL - Used in Campaign Trend Report
        bizDevMgrId = urlParams.bmid ? parseInt(urlParams.bmid) : 0

        timeFrame = urlParams.t ? urlParams.t.trim() : 'd'

        filterTrend = urlParams.filter_trend ? urlParams.filter_trend.trim() : 'b'

        minT1Margin = urlParams.mint1 ? urlParams.mint1.trim() : ''

        filterTrendDate = urlParams.date ? urlParams.date.trim() : ''
        // Get the affiliate ID from the URL.
        affiliateId = urlParams.aid ? parseInt(urlParams.aid) : 0

        // Get the offer ID from the URL.
        offerId = urlParams.oid ? parseInt(urlParams.oid) : 0

        // Get the offer ID from the URL.
        merchantId = urlParams.mid ? parseInt(urlParams.mid) : 0

        // Get the creative ID from the URL.
        creativeId = urlParams.cid ? parseInt(urlParams.cid) : ''

        // Get the landing page ID from the URL.
        landingPageId = urlParams.lpid ? parseInt(urlParams.lpid) : ''

        // Get the affiliate ID from the URL.
        subId = urlParams.subid ? urlParams.subid : ''

        // Get the affiliate priority from the URL.
        affiliatePriority = urlParams.ap ? parseInt(urlParams.ap) : ''

        // Get the affiliate status from the URL.
        affiliateStatus = urlParams.as ? urlParams.as : ''

        // Get the total apps flag from the URL.
        totalAppsFlag = urlParams.ta ? urlParams.ta : 'false'

        // Get the date from URL. If there's nothing, then use today.
        if (urlParams.startDate && urlParams.endDate) {
            startDate = urlParams.startDate
            endDate = urlParams.endDate
        } else {
            startDate = moment().startOf('month').format('YYYY-MM-DD')
            endDate = moment().endOf('day').format('YYYY-MM-DD')
        }

        // Get the filter dates from URL. If there's nothing, let it be empty.
        if (urlParams.filterStartDate && urlParams.filterEndDate) {
            filterStartDate = urlParams.filterStartDate
            filterEndDate = urlParams.filterEndDate
        }

        // Get payable, waiting, revshare, perlead for Margin Report

        payable = urlParams.payable ? true : false
        waiting = urlParams.waiting ? true : false
        revshare = urlParams.revshare ? true : false
        perlead = urlParams.perlead ? true : false

        /* Get the page number from the URL. If none, then use 1. NOTE: The API starts at page 0, so we'll need to subtract 1 before
           fetching from the API */
        if (urlParams.page) {
            page = parseInt(urlParams.page)
        } else {
            page = 1
        }

        let startRow = (page - 1) * this.state.resultsPerPage
        let endRow = page * this.state.resultsPerPage - 1

        // See if the dates are one of the quick dates
        if (startDate === moment().startOf('month').format('YYYY-MM-DD') && endDate === moment().endOf('month').format('YYYY-MM-DD')) {
            quickDate = 'month'
        } else if (startDate === moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') && endDate === moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')) {
            quickDate = 'lastMonth'
        } else if (startDate === moment().startOf('day').format('YYYY-MM-DD') && endDate === moment().startOf('day').format('YYYY-MM-DD')) {
            quickDate = 'today'
        } else if (startDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD') && endDate === moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD')) {
            quickDate = 'yesterday'
        } else if (startDate === moment().day(1).subtract(1, 'day').format('YYYY-MM-DD') && endDate === moment().day(1).add(5, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod'
        } else if (startDate === moment().day(1).subtract(8, 'days').format('YYYY-MM-DD') && endDate === moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')) {
            quickDate = 'thisPayPeriod'
        } else {
            quickDate = 'custom'
        }

        /* switch(type){
            case "affiliate_earnings":
                mgrType = "affiliate"
                break;
            case "campaign_earnings":
                mgrType = "merchant"
                break;
            case "advertiser_earnings":
                mgrType = "merchant"
                break;
            case "merchant_margin":
                mgrType = "merchant"
                break;
            case "affiliate_signup":
                mgrType = "affiliate"
                break;
            default:
                mgrType = ""
                break;
        } */

        // Update the state with the chosen dates
        this.setState({
            ...this.state,
            dataLoaded: true,
            type: type,
            mgrId: mgrId,
            bizDevMgrId: bizDevMgrId,
            minT1Margin: minT1Margin,
            filterTrendDate: filterTrendDate,
            affiliateId: affiliateId,
            timeFrame: timeFrame,
            filterTrend: filterTrend,
            offerId: offerId,
            merchantId: merchantId,
            creativeId: creativeId,
            landingPageId: landingPageId,
            subId: subId,
            affiliatePriority: affiliatePriority,
            affiliateStatus: affiliateStatus,
            totalAppsFlag: totalAppsFlag,
            startDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            quickDate: quickDate,
            selectedType: type,
            selectedStartDate: moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            selectedEndDate: moment(endDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            filterStartDate: filterStartDate ? moment(filterStartDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
            filterEndDate: filterEndDate ? moment(filterEndDate).startOf('day').format('YYYY-MM-DD HH:mm:ss') : '',
            page: page,
            startRow: startRow,
            endRow: endRow,
            payable: payable,
            waiting: waiting,
            revshare: revshare,
            perlead: perlead,
        })
    }

    handleManagerChange = (mgrid) => {
        if (this._isMounted) {
            this.setState({ ...this.state, mgrId: mgrid })
        }
    }

    // Need this separate function for Campaign Trend Report ONLY since it needs both mmId and BizDevId at the same time. For everything else, handleManagerChange would work.
    handleBizDevManagerChange = (bizMgrId) => {
        if (this._isMounted) {
            this.setState({ ...this.state, bizDevMgrId: bizMgrId })
        }
    }

    // Campaign Trend Report specific function
    handleFilterChange = (source, value) => {
        if (source === 'timeframe') {
            this.setState({ ...this.state, timeFrame: value })
        } else if (source === 'filter_trend') {
            this.setState({ ...this.state, filterTrend: value })
        }
    }

    // Campaign Trend Filters
    handleResetFilters = (e) => {
        this.setState({ ...this.state, timeFrame: 'd', filterTrend: 'b', mgrId: '', bizDevMgrId: '', minT1Margin: '', filterTrendDate: '' }, () => this.handleReport(e))
    }

    handleT1MinMarrgin = (minMargin) => {
        this.setState({ ...this.state, minT1Margin: minMargin })
    }

    handleDateSelect = (date) => {
        this.setState({ ...this.state, filterTrendDate: date })
    }

    handleChangeStart = (date) => {
        if (this._isMounted) {
            this.setState({
                ...this.state,
                selectedStartDate: date,
            })
        }
    }

    handleChangeEnd = (date) => {
        if (this._isMounted) {
            this.setState({
                ...this.state,
                selectedEndDate: date,
            })
        }
    }

    checkboxChangeHandler = (event) => {
        this.setState({ ...this.state, [event.target.id]: event.target.checked })
    }

    handleQuickDate = (event) => {
        let selectedStartDate = this.state.selectedStartDate
        let selectedEndDate = this.state.selectedEndDate

        switch (event.target.value) {
            case 'month':
                selectedStartDate = moment().startOf('month').format('YYYY-MM-DD')
                selectedEndDate = moment().endOf('month').format('YYYY-MM-DD')
                break
            case 'lastMonth':
                selectedStartDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                break
            case 'today':
                selectedStartDate = moment().startOf('day').format('YYYY-MM-DD')
                selectedEndDate = moment().endOf('day').format('YYYY-MM-DD')
                break
            case 'yesterday':
                selectedStartDate = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
                selectedEndDate = moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
                break
            case 'thisPayPeriod':
                selectedStartDate = moment().day(1).subtract(1, 'day').format('YYYY-MM-DD')
                selectedEndDate = moment().day(1).add(5, 'days').format('YYYY-MM-DD')
                break
            case 'lastPayPeriod':
                selectedStartDate = moment().day(1).subtract(8, 'days').format('YYYY-MM-DD')
                selectedEndDate = moment().day(1).subtract(2, 'days').format('YYYY-MM-DD')
                break
            default:
        }

        if (this._isMounted) {
            this.setState({
                ...this.state,
                quickDate: event.target.value,
                selectedStartDate: selectedStartDate,
                selectedEndDate: selectedEndDate,
            })
        }
    }

    /* This will apply the settings based on the selected options */
    handleReport = (e) => {
        e.preventDefault()
        let startDate = this.state.type !== 'campaignTrend' || this.state.type !== 'affiliateTrend' ? moment(this.state.selectedStartDate).startOf('day').format('YYYY-MM-DD') : ''
        let endDate = this.state.type !== 'campaignTrend' || this.state.type !== 'affiliateTrend' ? moment(this.state.selectedEndDate).startOf('day').format('YYYY-MM-DD') : ''

        let type = this.state.type
        let mgrId = this.state.mgrId
        let bizDevMgrId = this.state.bizDevMgrId
        let timeFrame = this.state.timeFrame
        let filterTrend = this.state.filterTrend
        let filterTrendDate = this.state.filterTrendDate
        let minT1Margin = this.state.minT1Margin
        let affiliateId = this.state.affiliateId
        let offerId = this.state.offerId
        let merchantId = this.state.merchantId
        let subId = this.state.subId
        let affiliatePriority = this.state.affiliatePriority
        let affiliateStatus = this.state.affiliateStatus
        let totalAppsFlag = this.state.totalAppsFlag
        let creativeId = this.state.creativeId
        let landingPageId = this.state.landingPageId
        let page = 1
        let startRow = (page - 1) * this.state.resultsPerPage
        let endRow = page * this.state.resultsPerPage - 1
        let filterStartDate = this.state.filterStartDate ? moment(this.state.filterStartDate).startOf('day').format('YYYY-MM-DD') : ''
        let filterEndDate = this.state.filterEndDate ? moment(this.state.filterEndDate).startOf('day').format('YYYY-MM-DD') : ''
        let payable = this.state.payable
        let waiting = this.state.waiting
        let revshare = this.state.revshare
        let perlead = this.state.perlead

        if (this._isMounted) {
            this.setState({
                ...this.state,
                startDate: startDate,
                endDate: endDate,
                type: type,
                page: page,
                startRow: startRow,
                endRow: endRow,
            })
        }

        let url = this.state.type !== 'campaignTrend' && this.state.type !== 'affiliateTrend' ? `/reports/${type}?startDate=${startDate}&endDate=${endDate}` : `/reports/${type}?t=${timeFrame}&filter_trend=${filterTrend}`

        if (mgrId > 0) {
            url = url + `&mgrid=${mgrId}`
        }

        if (bizDevMgrId > 0) {
            url = url + `&bmid=${bizDevMgrId}`
        }

        if (minT1Margin.trim() !== '') {
            url = url + `&mint1=${minT1Margin}`
        }

        if (filterTrendDate !== '') {
            url = url + `&date=${moment(filterTrendDate).startOf('day').format('YYYY-MM-DD')}`
        }

        if (affiliateId > 0) {
            url = url + `&aid=${affiliateId}`
        }

        if (offerId > 0) {
            url = url + `&oid=${offerId}`
        }

        if (merchantId > 0) {
            url = url + `&mid=${merchantId}`
        }

        if (creativeId > 0) {
            url = url + `&cid=${creativeId}`
        }

        if (landingPageId > 0) {
            url = url + `&lpid=${landingPageId}`
        }

        if (subId > 0) {
            url = url + `&subid=${subId}`
        }

        if (affiliatePriority > 0) {
            url = url + `&ap=${affiliatePriority}`
        }

        if (affiliateStatus !== '') {
            url = url + `&as=${affiliateStatus}`
        }

        if (totalAppsFlag === 'true') {
            url = url + `&ta=${totalAppsFlag}`
        }

        if (filterStartDate !== '') {
            url = url + `&filterStartDate=${filterStartDate}`
        }

        if (filterEndDate !== '') {
            url = url + `&filterEndDate=${filterEndDate}`
        }

        if (payable) {
            url = url + `&payable=true`
        }

        if (waiting) {
            url = url + `&waiting=true`
        }
        if (revshare) {
            url = url + `&revshare=true`
        }
        if (perlead) {
            url = url + `&perlead=true`
        }
        this.props.history.push(url)
    }

    render() {
        let useFilters = true
        let reportToDisplay
        let reportTitle
        let nameFilterDisplay = false
        let countryFilterDisplay = false
        let marginsFilterDisplay = false
        let managerDropdownDisplay = true
        let dateRangeDisplay = true
        let mgrType

        switch (this.state.type) {
            case 'affiliate_earnings':
                reportTitle = 'Affiliate Earnings'
                mgrType = 'affiliate'
                countryFilterDisplay = false
                reportToDisplay = <ReportAffiliateEarnings key={`${this.state.startDate} - ${this.state.endDate}`} mgrid={this.state.mgrId} oid={this.state.offerId} ap={this.state.affiliatePriority} as={this.state.affiliateStatus} ta={this.state.totalAppsFlag} startDate={this.state.startDate} endDate={this.state.endDate} filterStartDate={this.state.filterStartDate} filterEndDate={this.state.filterEndDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'campaign_earnings':
                reportTitle = 'Campaign Earnings'
                mgrType = 'bizdev'
                countryFilterDisplay = false
                reportToDisplay = <ReportCampaignEarnings key={`${this.state.startDate} - ${this.state.endDate}`} aid={this.state.affiliateId} mid={this.state.merchantId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'advertiser_earnings':
                reportTitle = 'Advertiser Earnings'
                countryFilterDisplay = false
                reportToDisplay = <ReportAdvertiserEarnings key={`${this.state.startDate} - ${this.state.endDate}`} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'merchant_margin':
                reportTitle = 'Advertiser Margin'
                mgrType = 'merchant'
                nameFilterDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportMerchantMargin key={`${this.state.startDate} - ${this.state.endDate}`} mgrid={this.state.mgrId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'sotu':
                reportTitle = 'State of the Union'
                reportToDisplay = <ReportSotu showSystemMessage={this.props.showSystemMessage} />
                useFilters = false
                break
            case 'clicks':
                reportTitle = 'Clicks'
                managerDropdownDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportClicks key={`${this.state.startDate} - ${this.state.endDate}`} aid={this.state.affiliateId} oid={this.state.offerId} cid={this.state.creativeId} lpid={this.state.landingPageId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'postbacks':
                reportTitle = 'Test Postbacks'
                managerDropdownDisplay = false
                reportToDisplay = <ReportTestPostbacks key={`${this.state.startDate} - ${this.state.endDate}`} oid={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} showSystemMessage={this.props.showSystemMessage} ta={false} />
                break
            case 'creatives':
                reportTitle = 'Creatives'
                managerDropdownDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportCreatives key={`${this.state.startDate} - ${this.state.endDate}`} aid={this.state.affiliateId} oid={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'landing_pages':
                reportTitle = 'Landing Pages'
                managerDropdownDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportLandingPages key={`${this.state.startDate} - ${this.state.endDate}`} aid={this.state.affiliateId} oid={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'referring_urls_clicks':
                reportTitle = 'Referring URLs'
                managerDropdownDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportReferringUrls key={`${this.state.startDate} - ${this.state.endDate}`} aid={this.state.affiliateId} oid={this.state.offerId} cid={this.state.creativeId} lpid={this.state.landingPageId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'subids':
                reportTitle = 'Sub IDs'
                managerDropdownDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportSubId key={`${this.state.startDate} - ${this.state.endDate}`} aid={this.state.affiliateId} oid={this.state.offerId} startDate={this.state.startDate} endDate={this.state.endDate} startRow={this.state.startRow} endRow={this.state.endRow} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'affiliate_signup':
                reportTitle = 'Affiliate Health'
                mgrType = 'affiliate'
                nameFilterDisplay = false
                countryFilterDisplay = false
                reportToDisplay = <ReportAffSignup key={`${this.state.startDate} - ${this.state.endDate}`} mgrid={this.state.mgrId} startDate={this.state.startDate} endDate={this.state.endDate} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'margin':
                reportTitle = 'Margin'
                nameFilterDisplay = false
                countryFilterDisplay = false
                marginsFilterDisplay = true
                reportToDisplay = <ReportMargin key={`${this.state.startDate} - ${this.state.endDate}`} mgrId={this.state.mgrId} startDate={this.state.startDate} endDate={this.state.endDate} handleDownloadData={this.handleDownloadData} showSystemMessage={this.props.showSystemMessage} />
                break
            case 'affiliatePaymentTotals':
                reportTitle = 'Affiliate Payment Totals'
                reportToDisplay = <ReportAffPaymentTotals showSystemMessage={this.props.showSystemMessage} />
                useFilters = false
                break
            case 'affiliatePaymentTotalsDetails':
                reportTitle = 'Affiliate Payment Totals Details'
                reportToDisplay = <ReportAffPayTotalsDetails showSystemMessage={this.props.showSystemMessage} />
                useFilters = false
                break
            case 'campaignTrend':
                reportTitle = 'Campaign Trend'
                managerDropdownDisplay = false
                nameFilterDisplay = false
                dateRangeDisplay = false
                reportToDisplay = <ReportTrends showSystemMessage={this.props.showSystemMessage} type="campaign" />
                break
            case 'campaignTrendDetail':
                reportTitle = 'Campaign Trend Detail'
                useFilters = false
                reportToDisplay = <ReportTrendDetail showSystemMessage={this.props.showSystemMessage} type="campaign" />
                break
            case 'affiliateTrend':
                reportTitle = 'Trend Report'
                managerDropdownDisplay = false
                nameFilterDisplay = false
                dateRangeDisplay = false
                reportToDisplay = <ReportTrends showSystemMessage={this.props.showSystemMessage} type="affiliate" />
                break
            case 'affiliateTrendDetail':
                reportTitle = 'Affiliate Trend Detail'
                useFilters = false
                reportToDisplay = <ReportTrendDetail showSystemMessage={this.props.showSystemMessage} type="affiliate" />
                break
            case 'invoice_report':
                reportTitle = 'Invoice Report'
                dateRangeDisplay = true
                managerDropdownDisplay = false
                reportToDisplay = <ReportInvoice showSystemMessage={this.props.showSystemMessage} startDate={this.state.startDate} endDate={this.state.endDate} />
                break
            case 'list_invoices':
                reportTitle = 'Current Invoices To Be Done'
                useFilters = false
                reportToDisplay = <ReportInvoiceList showSystemMessage={this.props.showSystemMessage} />
                break
            case 'advertiserBalances':
                reportTitle = 'Pre-paid Merchant Balances For'
                dateRangeDisplay = false
                reportToDisplay = <ReportMerchantBalances showSystemMessage={this.props.showSystemMessage} />
                break
            case 'bizdev_pipeline':
                reportTitle = 'Bizdev Pipeline'
                useFilters = false
                reportToDisplay = <ReportBizDevPipeline showSystemMessage={this.props.showSystemMessage} />
                break
            default:
                break
        }

        document.title = `${reportTitle} Report`

        let filterClasses = `d-flex align-items-center`

        let trendFilters = {
            timeFrame: this.state.timeFrame,
            filterTrend: this.state.filterTrend,
            mgrId: this.state.mgrId,
            bizDevMgrId: this.state.bizDevMgrId,
            minT1: this.state.minT1Margin,
            date: this.state.filterTrendDate,
        }

        return (
            <React.Fragment>
                <div className="page-header">
                    <span className="mb-h1">Reports</span>
                    {/* <HeaderStats showSystemMessage={this.props.showSystemMessage}></HeaderStats> */}
                </div>
                <div className="card">
                    <div className="card-body">
                        <form>
                            <div className={filterClasses}>
                                <div className="mr-4 d-flex align-items-center">
                                    <h1 className="mb-h1-dark">{reportTitle}</h1>
                                </div>
                                {useFilters && (
                                    <React.Fragment>
                                        {managerDropdownDisplay && <ManagerDropdown key={mgrType} mgrId={this.state.mgrId} mgrType={mgrType} mgrSelected={this.handleManagerChange} />}
                                        {nameFilterDisplay && <NameFilter />}
                                        {dateRangeDisplay && <DateRangeFilter startDate={this.state.startDate} endDate={this.state.endDate} quickDate={this.state.quickDate} selectedStartDate={this.state.selectedStartDate} selectedEndDate={this.state.selectedEndDate} handleChangeStart={this.handleChangeStart} handleChangeEnd={this.handleChangeEnd} handleQuickDate={this.handleQuickDate} />}
                                        {countryFilterDisplay && <CountryFilter />}
                                        {marginsFilterDisplay && (
                                            <div className="no-border pl-4 pr-4" align="left">
                                                <Checkbox id="payable" name="Payable" checkboxChanged={this.checkboxChangeHandler} isChecked={this.state.payable} />
                                                <Checkbox id="waiting" name="Waiting" checkboxChanged={this.checkboxChangeHandler} isChecked={this.state.waiting} />
                                                <Checkbox id="perlead" name="Fixed (per lead)" checkboxChanged={this.checkboxChangeHandler} isChecked={this.state.perlead} />
                                                <Checkbox id="revshare" name="Percentage (revshare)" checkboxChanged={this.checkboxChangeHandler} isChecked={this.state.revshare} />
                                            </div>
                                        )}
                                        <p />
                                        {(this.state.type === 'campaignTrend' || this.state.type === 'affiliateTrend') && <CampaignTrendFilters mmSelected={(mgrId) => this.handleManagerChange(mgrId)} bmSelected={(bizMgrId) => this.handleBizDevManagerChange(bizMgrId)} filtersChanged={(source, value) => this.handleFilterChange(source, value)} filters={trendFilters} minT1MarginSet={(minMargin) => this.handleT1MinMarrgin(minMargin)} resetFilters={(e) => this.handleResetFilters(e)} dateSelected={(date) => this.handleDateSelect(date)} type={this.state.type} />}
                                        <div>
                                            <button onClick={(e) => this.handleReport(e)} className="btn btn-sm btn-primary">
                                                Refresh Report
                                            </button>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </form>
                    </div>
                    {/* {(this.state.type === 'affiliate_earnings' ) &&
                            <Pagination
                                totalPages={this.state.totalPages}
                                currentPage={this.state.page}
                                handlePageChange={this.handlePageChange} />
                        } */}
                    {this.state.dataLoaded &&
                        /* Display the selected report */
                        reportToDisplay}
                    {/* {(this.state.type === 'affiliate_earnings' ) &&
                            <div className="mt-2">
                                <Pagination
                                    totalPages={this.state.totalPages}
                                    currentPage={this.state.page}
                                    handlePageChange={this.handlePageChange} />
                            </div>
                        } */}
                </div>
            </React.Fragment>
        )
    }
}

export default Reports
