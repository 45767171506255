import React, { Component } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class HeaderStats extends Component {
    state = {
        todayMargin: 0,
        monthMargin: 0,
        yearMargin: {
            margin: 0,
            margin_pct: 0,
        },
        dataLoaded: false,
    }

    componentDidMount() {
        const mbToken = localStorage.getItem('adm-auth-token')

        fetch(`${process.env.REACT_APP_API_URL}/reports/marginreport/marginstats`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                if (result['success'] === true) {
                    this.setState({ ...this.state, todayMargin: result.today_margin.margin, monthMargin: result.month_margin.margin, dataLoaded: true, yearMargin: { margin: result.year_margin.margin, margin_pct: result.year_margin.margin_pct } })
                } else if (result['success'] === false) {
                    if (result.code !== 401) {
                        this.props.showSystemMessage('error', 'There was an issue fetching margin stats.')
                    }
                } else {
                    this.props.showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log('Error while fetching margin stats: ', error)
                this.props.showSystemMessage('error', 'Connection Error')
            })
    }

    render() {
        // const todayDate = moment('2020-09-09').format('YYYY-MM-DD'); TEST DATE WITH DATA
        const todayDate = moment().startOf('day').format('YYYY-MM-DD')
        const monthStartDate = moment().startOf('month').format('YYYY-MM-DD')
        const monthEndDate = moment().endOf('month').format('YYYY-MM-DD')
        const spinner = <FontAwesomeIcon icon="spinner" spin />

        return (
            <div className="header-stats">
                <span className="pr-4">
                    Today:{' '}
                    <a className="text-info" href={`/reports/margin?startDate=${todayDate}&endDate=${todayDate}`}>
                        {this.state.dataLoaded ? `$${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.state.todayMargin)}` : spinner}
                    </a>
                </span>
                <span className="pr-3 border-right border-secondary">
                    Projected: <span className="text-white">Coming Soon...</span>
                </span>
                <span className="pl-3 pr-4">
                    This Month:{' '}
                    <a className="text-info" href={`/reports/margin?startDate=${monthStartDate}&endDate=${monthEndDate}`}>
                        {this.state.dataLoaded ? `$${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.state.monthMargin)}` : spinner}
                    </a>
                </span>
                <span>
                    Projected: <span className="text-white">Coming Soon...</span>
                </span>
                <span className="pl-3 pr-4">
                    YTD*: <span>{this.state.dataLoaded ? `$${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.state.yearMargin.margin)}` : spinner}</span>
                </span>
                <span>
                    Projected: <span className="text-white">Coming Soon...</span>
                </span>
            </div>
        )
    }
}

export default HeaderStats
