const PostbackElement = ({ campaignId, campaign, rate }) => {
    const displayLink = (rate, campaignId) => {
        if (campaign.tracking.type.value === 'S') {
            let link = `https://www.chant3rm1.com/lead_nc.asp?o=${campaignId}&r=${rate.rate_name}&d=XXXXX&i=ZZZZZZZ&s={sale_amount}`
            if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                if (rate.platform_supports_multiple_postback === 'Y') {
                    if (rate.merchant_supports_multiple_postback === 'N') {
                        return (
                            <div>
                                <div>{link}</div>
                                <br />
                                <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>
                                {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ&s={sale_amount}' width=1 height=1 frameborder=0></iframe>`}
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <div>{link}</div> <br />
                                <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>
                                {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ' width=1 height=1 frameborder=0></iframe>`}
                            </div>
                        )
                    }
                }
                return <div>{link}</div>
            }
            return <div>{link}</div>
        }
        if (campaign.tracking.type.value === 'G') {
            let link = `https://www.chant3rm1.com/lead_global.asp?i=ZZZZZZZ&s={sale_amount}`
            if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                if (rate.platform_supports_multiple_postback === 'Y') {
                    if (rate.merchant_supports_multiple_postback === 'N') {
                        return (
                            <div>
                                <div>{link}</div>
                                <br />
                                <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>
                                {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ&s={sale_amount}' width=1 height=1 frameborder=0></iframe>`}
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <div>{link}</div> <br />
                                <div className="bg-warning">Merchant does not support multiple postbacks at this time. The following code is for testing only:</div>
                                {`<iframe src='https://www.chant3rm1.com/mb_html_callback.asp?r=${rate.rate_name}&i=ZZZZZZZ' width=1 height=1 frameborder=0></iframe>`}
                            </div>
                        )
                    }
                }
                return <div>{link}</div>
            }
            return <div>{link}</div>
        }
        if (campaign.tracking.type.value === 'L') {
            let link = `<img src="https://www.maxbounty.com/lead.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx&s={sale_amount}" border=0 width=1 height=1>`
            if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                return <div>{link}</div>
            } else {
                let link = `<img src="https://www.maxbounty.com/lead.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx" border=0 width=1 height=1>`
                return <div>{link}</div>
            }
        }
        if (campaign.tracking.type.value === 'I') {
            let link = `<iframe src="https://www.maxbounty.com/lead_if.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx&s={sale_amount}" width=1 height=1 frameborder=0></iframe>`
            if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                return <div>{link}</div>
            } else {
                let link = `<iframe src="https://www.maxbounty.com/lead_if.asp?o=${campaignId}&r=${rate.rate_name}&d=xxxxx" width=1 height=1 frameborder=0></iframe>`
                return <div>{link}</div>
            }
        }
        if (campaign.tracking.type.value === 'M') {
            if (rate.rate_type === 'P' || rate.rate_type === 'M') {
                let link = `<script>mbc('track', {mbr:'${rate.rate_name}', mbs:'sale_amount', mbd:'XXXXX'})</script>`
                return <div>{link}</div>
            } else {
                let link = `<script>mbc('track', {mbr:'${rate.rate_name}', mbd:'XXXXX'})</script>`
                return <div>{link}</div>
            }
        }
    }

    return (
        <div className="alert alert-secondary">
            <pre className="p-0 m-0" style={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                {displayLink(rate, campaignId)}
            </pre>
        </div>
    )
}

export default PostbackElement
