import React, { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'

export default function RedirectAffiliateBlacklists({ showSystemMessage, data, dataLoaded, refetch }) {
    const [deleteIndex, setDeleteIndex] = useState(-1)

    const deleteBlacklist = async (type, notes, filter) => {
        const response = await callAPI(`compliance/blacklist`, 'DELETE', { type, notes, filter })
        if (response.success) {
            showSystemMessage('success', 'Redirect affiliate deleted successfully')
            setDeleteIndex(-1)
            refetch()
        } else {
            showSystemMessage('error', response.errors.message)
            setDeleteIndex(-1)
        }
    }

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">Redirect Affiliate Blacklist</Card.Header>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Notes</th>
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        <>
                            {data.blacklist.map((item, index) => {
                                if (item.type === 'redirect') {
                                    return (
                                        <tr key={index}>
                                            <td>{item.redirect_affiliate_id}</td>
                                            <td>{item.notes}</td>
                                            <td align="right">
                                                {index !== deleteIndex ? (
                                                    <Button variant="danger" onClick={() => setDeleteIndex(index)} size="sm">
                                                        Delete
                                                    </Button>
                                                ) : (
                                                    <Button variant="danger" onClick={() => deleteBlacklist(item.type, item.notes, item.redirect_affiliate_id)} size="sm">
                                                        Confirm Delete
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </>
                    ) : (
                        <Loading />
                    )}
                </tbody>
            </Table>
        </Card>
    )
}
