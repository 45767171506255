import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import getAffiliateStatus from '../../../helpers/getAffiliateStatus'
import Loading from '../../../templates/UI/Loading/Loading'

const ContactInfo = ({ data, dataLoaded }) => {
    const [address, setAddress] = useState([])
    useEffect(() => {
        if (dataLoaded) {
            let temp = []
            if (data.info.street_address_1) {
                temp.push(data.info.street_address_1)
            }
            if (data.info.street_address_2) {
                temp.push(data.info.street_address_2)
            }
            if (data.info.city || data.info.state || data.info.zip) {
                temp.push(`${data.info.city} ${data.info.state} ${data.info.zip}`)
            }
            if (data.info.country) {
                temp.push(data.info.country)
            }
            setAddress(temp)
        }
    }, [dataLoaded])

    const calcImCell = (type, id) => {
        switch (type) {
            case 'A':
                return `AOL - ${id}`
                break
            case 'M':
                return `MSN - ${id}`
                break
            case 'Y':
                return `Yahoo - ${id}`
                break
            case 'S':
                return `Skype - ${id}`
                break
            default:
                return 'NONE'
                break
        }
    }
    return (
        <Card className="mb-4">
            <Card.Header>
                <div>Contact Information</div>
            </Card.Header>
            <Table striped bordered>
                {dataLoaded ? (
                    <>
                        {data.success ? (
                            <tbody>
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Name</span>
                                    </td>
                                    <td>{data.info.name}</td>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Status</span>
                                    </td>
                                    <td>{data.info.aff_status}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Contact</span>
                                    </td>
                                    <td>
                                        {data.info.contact} (<a href={`mailto:${data.info.e_mail}`}>{data.info.e_mail}</a>)
                                    </td>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Status Date</span>
                                    </td>
                                    <td>{moment(data.info.status_date).format('DD/MM/YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Address:</span>
                                    </td>
                                    <td>{address[0] ? address[0] : null}</td>
                                    <td />
                                    <td />
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center"></span>
                                    </td>
                                    <td>{address[1] ? address[1] : null}</td>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">IP of Signup:</span>
                                    </td>
                                    <td>
                                        {data.info.remote_host}
                                        <a className="ml-2" target="_blank" href={`http://www.google.ca/search?hl=en&q=%2B${data.info.remote_host}`}>
                                            IP Search
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center"></span>
                                    </td>
                                    <td>{address[2] ? address[2] : null}</td>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Country of IP:</span>
                                    </td>
                                    <td>{data.info.country}</td>
                                </tr>
                                {address[3] ? (
                                    <tr>
                                        <td>
                                            <span className="d-flex justify-content-end align-items-center"></span>
                                        </td>
                                        <td>{address[3] ? address[3] : null}</td>
                                        <td />
                                        <td />
                                    </tr>
                                ) : null}
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Phone:</span>
                                    </td>
                                    <td>{data.info.phone}</td>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Last Login:</span>
                                    </td>
                                    <td>{moment(data.info.last_login).format('DD/MM/YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">IM:</span>
                                    </td>
                                    <td>{calcImCell(data.info.im_type, data.info.im_id)}</td>
                                    <td>
                                        <span className="d-flex justify-content-end align-items-center">Heard From:</span>
                                    </td>
                                    <td>
                                        {data.info.heard_from.source} {data.info.heard_from.source === 'other' && '-'} {data.info.heard_from.details}
                                    </td>
                                </tr>
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4">No data found</td>
                                </tr>
                            </tbody>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}

export default ContactInfo
