import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import '../../../templates/ReportTable/ReportTable.css'
import Loading from '../../../templates/UI/Loading/Loading'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import useCallApi from '../../../hooks/useCallApi'
import moment from 'moment'
import Dollars from '../../../templates/Dollars/Dollars'
import MarketingPlatformDropdown from '../Components/MarketingPlatformDropdown/MarketingPlatformDropdown'

export default function MarketingCampaignReport({ showSystemMessage, marketingCampaignId }) {

    document.title = 'Marketing Campaign Report'

    const location = useLocation()
    const history = useHistory()
    let urlParams = queryString.parse(location.search)

    const [marketingPlatformId, setMarketingPlatformId] = useState((urlParams.platformId && urlParams.platformId) > 0 ? urlParams.platformId : 0)

    const { data, dataLoaded, refetch } = useCallApi(`marketing/campaign/${marketingCampaignId}/report?platformId=${marketingPlatformId}`, 'GET', {}, [], showSystemMessage)

    useEffect(() => {
        if (marketingPlatformId > 0){
            let url = `/marketing/campaign/${marketingCampaignId}/report?platformId=${marketingPlatformId}`
            history.push(url)
            refetch()
        }
    }, [marketingPlatformId])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].aff_status_value} priority={tableData.data[tableData.row.index].aff_priority_value} />
                },
            },
            {
                Header: 'Registration Date',
                accessor: 'registration_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).format('YYYY-MM-DD')}</>
                },
            },
            {
                Header: 'Status',
                accessor: 'aff_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Marketing Platform',
                accessor: 'platform_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Affiliate Earnings</div>,
                accessor: 'affiliate_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{<Dollars value={value} />}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Network Earnings</div>,
                accessor: 'network_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{<Dollars value={value} />}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{<Dollars value={value} />}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.affiliates} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Marketing Campaign Report</h1>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex align-items-center">
                                <div className="p-3 font-weight-bold">{data.campaign ? data.campaign.name : ''}</div>
                                <div>
                                    <MarketingPlatformDropdown marketingPlatformId={marketingPlatformId} setMarketingPlatformId={setMarketingPlatformId} />
                                </div>
                            </div>
                            {dataLoaded && data.affiliates ? data.affiliates.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
