import React, { Component } from 'react'

class Checkbox extends Component {
    render() {
        let inputTag

        if (this.props.isChecked === 'true' || this.props.isChecked === true) {
            inputTag = <input type="checkbox" className="custom-control-input" id={this.props.id} name={this.props.name} onChange={this.props.checkboxChanged} checked={true} />
        } else if (this.props.isChecked === 'false' || this.props.isChecked === false) {
            inputTag = <input type="checkbox" className="custom-control-input" id={this.props.id} name={this.props.name} onChange={this.props.checkboxChanged} checked={false} />
        }

        return (
            <div className="mb-radio">
                <div className="custom-control custom-checkbox">
                    {inputTag}

                    <label className="custom-control-label pr-2 text-small" htmlFor={this.props.id} style={{ cursor: 'pointer' }}>
                        {this.props.name}
                    </label>
                </div>
            </div>
        )
    }
}
export default Checkbox
