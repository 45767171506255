import { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export default function useQueryParams() {
    const location = useLocation()
    let urlParams = queryString.parse(location.search)

    const [affiliateId, setAffiliateId] = useState(urlParams.affiliateId ? urlParams.affiliateId : 0)
    const [campaignId, setCampaignId] = useState(urlParams.campaignId ? urlParams.campaignId : 0)
    const [advertiserId, setAdvertiserId] = useState(urlParams.advertiserId ? urlParams.advertiserId : 0)
    const [landingPageId, setLandingPageId] = useState(urlParams.landingPageId ? urlParams.landingPageId : '')
    const [creativeId, setCreativeId] = useState(urlParams.creativeId ? urlParams.creativeId : 0)
    const [keyword, setKeyword] = useState(urlParams.keyword && urlParams.keyword !== '' ? urlParams.keyword : '')
    const [subid1, setSubid1] = useState(urlParams.subid1 && urlParams.subid1 !== 'notset' ? urlParams.subid1 : 'notset')
    const [adminId, setAdminId] = useState(urlParams.adminId ? urlParams.adminId : 0)
    const [withLeads, setWithLeads] = useState(urlParams.withLeads && urlParams.withLeads == 'true' ? 'true' : 'false')
    const [withAvatar, setWithAvatar] = useState(urlParams.withAvatar && urlParams.withAvatar == 'true' ? 'true' : 'false')
    const [navigation, setNavigation] = useState(urlParams.navigation ? urlParams.navigation : '')
    const [hideNetwork, setHideNetwork] = useState(urlParams.hideNetwork && urlParams.hideNetwork == 'true' ? 'true' : 'false')
    const [mode, setMode] = useState(urlParams.mode ? urlParams.mode : '')
    const [status, setStatus] = useState(urlParams.status && urlParams.status !== '' ? urlParams.status : '')

    useEffect(() => {
        setAffiliateId(urlParams.affiliateId ? urlParams.affiliateId : 0)
        setCampaignId(urlParams.campaignId ? urlParams.campaignId : 0)
        setAdvertiserId(urlParams.advertiserId ? urlParams.advertiserId : 0)
        setLandingPageId(urlParams.landingPageId ? urlParams.landingPageId : '')
        setCreativeId(urlParams.creativeId ? urlParams.creativeId : 0)
        setKeyword(urlParams.keyword && urlParams.keyword !== '' ? urlParams.keyword : '')
        setSubid1(urlParams.subid1 && urlParams.subid1 !== 'notset' ? urlParams.subid1 : 'notset')
        setAdminId(urlParams.adminId ? urlParams.adminId : 0)
        setWithLeads(urlParams.withLeads && urlParams.withLeads == 'true' ? 'true' : 'false')
        setWithAvatar(urlParams.withAvatar && urlParams.withAvatar == 'true' ? 'true' : 'false')
        setNavigation(urlParams.navigation ? urlParams.navigation : '')
        setHideNetwork(urlParams.hideNetwork && urlParams.hideNetwork == 'true' ? 'true' : 'false')
        setMode(urlParams.mode ? urlParams.mode : '')
        setStatus(urlParams.status && urlParams.status !== '' ? urlParams.status : '')
    }, [location])

    const buildUrl = (url) => {
        if (affiliateId > 0) {
            url = url + `&affiliateId=${affiliateId}`
        }

        if (campaignId > 0) {
            url = url + `&campaignId=${campaignId}`
        }

        if (advertiserId > 0) {
            url = url + `&advertiserId=${advertiserId}`
        }

        if (creativeId > 0) {
            url = url + `&creativeId=${creativeId}`
        }

        if (landingPageId !== '' && !isNaN(landingPageId)) {
            url = url + `&landingPageId=${landingPageId}`
        }

        if (subid1 !== 'notset') {
            url = url + `&subid1=${subid1}`
        }

        if (keyword !== '') {
            url = url + `&keyword=${keyword}`
        }

        if (status !== '') {
            url = url + `&status=${status}`
        }

        if (adminId > 0) {
            url = url + `&adminId=${adminId}`
        }

        if (withLeads == 'true') {
            url = url + `&withLeads=${withLeads}`
        }

        if (withAvatar == 'true') {
            url = url + `&withAvatar=${withAvatar}`
        }

        if (navigation !== '') {
            url = url + `&navigation=${navigation}`
        }

        if (hideNetwork == 'true') {
            url = url + `&hideNetwork=${hideNetwork}`
        }

        if (mode !== '') {
            // Used in the compliance followups component. Can be used for custom modes on other reports in the future.
            url = url + `&mode=${mode}`
        }

        return url
    }

    return { affiliateId, campaignId, advertiserId, landingPageId, creativeId, subid1, keyword, status, adminId, withLeads, withAvatar, setCampaignId, setLandingPageId, setCreativeId, setSubid1, setKeyword, setStatus, setAdminId, setWithLeads, setWithAvatar, navigation, setNavigation, mode, setMode, buildUrl, hideNetwork, setHideNetwork }
}
