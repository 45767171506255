import React from 'react'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Advertiser from '../../../templates/Advertiser/Advertiser'

const SearchResults = ({ searchResults }) => {
    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'name',
                Cell: (tableData) => {
                    return (
                        <>
                            <Campaign oid={tableData.data[tableData.row.index].id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].status.name} />
                        </>
                    )
                },
            },
            {
                Header: 'Advertiser',
                accessor: 'advertiser.name',
                Cell: (tableData) => {
                    return (
                        <>
                            <Advertiser id={tableData.data[tableData.row.index].advertiser.id} name={tableData.data[tableData.row.index].advertiser.name} />
                        </>
                    )
                },
            },
            {
                Header: 'Status',
                accessor: 'status.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>EPC</div>,
                accessor: 'epc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return (
                        <>
                            <Dollars value={value} />
                        </>
                    )
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Month Earnings</div>,
                accessor: 'month_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return (
                        <>
                            <Dollars value={value} />
                        </>
                    )
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Year Earnings</div>,
                accessor: 'year_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return (
                        <>
                            <Dollars value={value} />
                        </>
                    )
                },
            },
            {
                Header: 'Traffic',
                Cell: (tableData) => {
                    return (
                        <div className="d-flex">
                            {/* <ReactTooltip place="left" /> */}
                            <div data-tip="Search" className={getTrafficClass(tableData.data[tableData.row.index].traffic.search)}>
                                <FontAwesomeIcon icon="search" />
                            </div>
                            <div data-tip="Email" className={getTrafficClass(tableData.data[tableData.row.index].traffic.email)}>
                                <FontAwesomeIcon icon="envelope-square" />
                            </div>
                            <div data-tip="Incentive" className={getTrafficClass(tableData.data[tableData.row.index].traffic.incentive)}>
                                <FontAwesomeIcon icon="money-bill-wave" />
                            </div>
                            <div data-tip="Contextual" className={getTrafficClass(tableData.data[tableData.row.index].traffic.contextual)}>
                                <FontAwesomeIcon icon="crosshairs" />
                            </div>
                            <div data-tip="Social" className={getTrafficClass(tableData.data[tableData.row.index].traffic.social)}>
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                            </div>
                            <div data-tip="Display" className={getTrafficClass(tableData.data[tableData.row.index].traffic.display)}>
                                <FontAwesomeIcon icon="desktop" />
                            </div>
                            <div data-tip="Mobile" className={getTrafficClass(tableData.data[tableData.row.index].traffic.mobile)}>
                                <FontAwesomeIcon icon="mobile-alt" />
                            </div>
                            <div data-tip="Native" className={getTrafficClass(tableData.data[tableData.row.index].traffic.native)}>
                                <FontAwesomeIcon icon="images" />
                            </div>
                            <div data-tip="Brand Bid" className={getTrafficClass(tableData.data[tableData.row.index].traffic.brandbid)}>
                                <FontAwesomeIcon icon="gavel" />
                            </div>
                        </div>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={searchResults} columns={columns} />
            </React.Fragment>
        )
    }

    const getTrafficClass = (enabled) => {
        return !enabled ? 'ml-2 text-light' : 'ml-2'
    }

    return (
        <div className="card">
            <div className="card-body p-0">
                <ReactTooltip />
                {getReportOutput()}
            </div>
        </div>
    )
}

export default SearchResults
