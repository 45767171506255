import React, { useState, useEffect } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const AffPerformanceComp = ({ showSystemMessage }) => {
    document.title = 'Affiliate Performance Comparison'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_performance_comparison')

    const [adminId, setAdminId] = useState(0)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, refetch } = useCallApi(`affiliates/performanceComp?startDate=${selectedStartDate}&endDate=${selectedEndDate}&mgrId=${adminId}`, 'GET', {}, [], showSystemMessage)

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} status={tableData.data[tableData.row.index].affiliate.status.value} priority={tableData.data[tableData.row.index].affiliate.priority.value} />
                },
            },
            {
                Header: () => <div className="text-right">Date Range Earnings</div>,
                accessor: 'affiliateEarnings',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <Link to={`/reports/stats/campaigns?affiliateId=${tableData.data[tableData.row.index].affiliate.id}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`}>
                            <Dollars value={tableData.data[tableData.row.index].affiliateEarnings} />
                        </Link>
                    )
                },
            },
            {
                Header: () => <div className="text-right">30d Earnings</div>,
                accessor: 'thirtyDayAffiliateEarnings',
                className: 'text-right',
                Cell: (tableData) => {
                    return (
                        <Link to={`/reports/stats/campaigns?affiliateId=${tableData.data[tableData.row.index].affiliate.id}&startDate=${selectedStartDate}&endDate=${selectedEndDate}`}>
                            <Dollars value={tableData.data[tableData.row.index].thirtyDayAffiliateEarnings} />
                        </Link>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.report} columns={columns} totals={data.totals} />
            </React.Fragment>
        )
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="mb-h1-dark d-flex justify-content-start align-items-center">
                    <span className="d-flex mr-2 align-items-center">
                        Affiliate Performance Comparison
                        <span className="ml-2 w-25">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} />
                        </span>
                    </span>
                    <span className="d-flex ml-2">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button
                            variant="warning"
                            size="sm"
                            onClick={() => {
                                refetch()
                            }}
                        >
                            Refresh
                        </Button>
                    </span>
                </h1>
            </Card.Header>
            {dataLoaded ? data.report.length === 0 ? <div className="p-3">No stats for the current parameters</div> : getReportOutput() : <Loading />}
        </Card>
    )
}

export default AffPerformanceComp
