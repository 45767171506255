import moment from 'moment'
import React, { useEffect, useState } from 'react'

import FollowupForm from './FollowupForm/FollowupForm'
import FollowupListItem from './FollowupListItem/FollowupListItem'

import { Button } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import ScrollToBottom from '../../hoc/ScrollToBottom/ScrollToBottom'
import FollowupFormAdvertiser from '../../pages/Advertiser/AdvertiserInfo/FollowupFormAdvertiser/FollowupFormAdvertiser'
import Loading from '../../templates/UI/Loading/Loading'

// Followups section on Affiliate and Advertiser info pages

const Followups = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [nextFollowup, setNextFollowup] = useState({})
    const [lastFollowup, setLastFollowup] = useState([])
    const [callbacks, setCallbacks] = useState([])
    const [followupSchedule, setFollowupSchedule] = useState([])
    const [merchantFollowupSchedule, setMerchantFollowupSchedule] = useState([])
    const [bizdevFollowupSchedule, setBizdevFollowupSchedule] = useState([])
    const [preserveFollowups, setPreserveFollowups] = useState(false)

    const { showSystemMessage, id, setIsOpen } = props

    let followupType = props.type

    useEffect(() => {
        if (followupType === 'affiliate') {
            let url = `affiliate/${id}/followups`

            callAPI(url, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setNextFollowup(result.next_followup)
                        setLastFollowup(result.last_followup)
                        setFollowupSchedule(result.followup_schedule)
                        setCallbacks(result.affiliate_callbacks)
                        setPreserveFollowups(result.preserve_followups)
                        setDataLoaded(true)
                    } else if (result['success'] === false) {
                        if (result.code !== 401) {
                            showSystemMessage('error', 'There was an error while fetching the data. Please try again')
                        }
                    } else {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    console.log('Error in Followups: ', error)
                    showSystemMessage('error', 'Connection Error')
                })
        } else if (followupType === 'advertiser') {
            let url = `advertiser/${id}/followups`

            callAPI(url, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setNextFollowup(result.next_followup)
                        setBizdevFollowupSchedule(result.bizdev_followups_schedule)
                        setMerchantFollowupSchedule(result.merchant_followups_schedule)
                        setFollowupSchedule(result.bizdev_followups_schedule)
                        setCallbacks(result.merchant_callbacks)
                        setDataLoaded(true)
                    } else if (result['success'] === false) {
                        if (result.code !== 401) {
                            showSystemMessage('error', 'There was an error while fetching the data. Please try again')
                        }
                    } else {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    console.log('Error in Followups: ', error)
                    showSystemMessage('error', 'Connection Error')
                })
        }
    }, [dataLoaded])

    const updateList = () => {
        setDataLoaded(false)
        setDataLoaded(true)
    }

    let followupFormVersion

    if (followupType === 'affiliate') {
        followupFormVersion = <FollowupForm followupSchedule={followupSchedule} managerName={props.managerName} managerId={props.managerId} showSystemMessage={showSystemMessage} affiliateId={id} updateList={updateList} preserveFollowups={preserveFollowups} />
    } else if (followupType === 'advertiser') {
        followupFormVersion = <FollowupFormAdvertiser followupSchedule={merchantFollowupSchedule} bizdevFollowupSchedule={bizdevFollowupSchedule} managerName={props.managerName} bizdevManagerName={props.bizdevManagerName} managerId={props.managerId} bizdevManagerId={props.bizdevManagerId} showSystemMessage={showSystemMessage} advertiserId={id} updateList={updateList} />
    }

    return (
        <div className="card mb-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>Followups</div>
                {followupType === 'affiliate' ? <div className="text-secondary">Next Compliance - {nextFollowup.next_compliance_followup_date ? moment(nextFollowup.next_compliance_followup_date).utc().format('YYYY/MM/DD') : 'N/A'}</div> : <div className="text-secondary">Next BizDev - {nextFollowup.next_bizdev_followup_date ? moment(nextFollowup.next_bizdev_followup_date).utc().format('YYYY/MM/DD') : 'N/A'}</div>}
                <div>Next - {nextFollowup.next_followup_date ? moment(nextFollowup.next_followup_date).utc().format('YYYY/MM/DD') : 'N/A'}</div>
            </div>
            <div className="p-0 border-bottom" style={{ maxHeight: '350px', flexDirection: 'column', overflowY: 'auto' }}>
                {dataLoaded ? (
                    <div style={{ backgroundColor: 'rgba(0,0,0,.03)' }} className="p-2" id="followups">
                        {callbacks.length === 0 ? 'No Followups' : callbacks.map((followup, index) => <FollowupListItem type={followupType} followup={followup} key={`${followup.followup_date}-${index}`} />)}
                        <ScrollToBottom />
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
            <div className="p-2 text-secondary">
                *Only followups after <span className="font-italic">{moment().subtract(1, 'year').format('YYYY/MM/DD')}</span> are shown&nbsp;
                <Button variant="link" onClick={() => setIsOpen(true)} className="shadow-none">
                    List All
                </Button>
            </div>
            {dataLoaded ? followupFormVersion : <Loading></Loading>}
        </div>
    )
}

export default Followups
