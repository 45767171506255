import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import ReportTable from '../../../templates/ReportTable/ReportTable'
import '../../../templates/ReportTable/ReportTable.css'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import callAPI from '../../../helpers/callAPI'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportAppliedFilters from '../../../templates/ReportAppliedFilters/ReportAppliedFilters'

export default function ClickUserAgents({ affiliateId, showSystemMessage }) {
    const [dataLoaded, setDataLoaded] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [affiliate, setAffiliate] = useState({})
    const [campaign, setCampaign] = useState({})
    const [report, setReport] = useState([])

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { creativeId, landingPageId, subid1, buildUrl } = useQueryParams()

    if (affiliate.id){
        document.title = `${affiliate.name}: Click User Agents`
    } else if (campaign.id){
        document.title = `${campaign.name}: Click User Agents`
    } else {
        document.title = `Click User Agents`
    }

    useEffect(() => {
        getReport()
    }, [location])

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = buildUrl(`/affiliate/${affiliateId}/clickUserAgents?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        history.push(url)
    }

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`affiliate/${affiliateId}/clickUserAgents?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setAffiliate(result.affiliate)
                    setReport(result.report)
                    if (result.campaign) {
                        setCampaign(result.campaign)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching affiliate click agents: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching click agents')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'User Agent',
                accessor: 'user_agent',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Clicks</div>,
                accessor: 'clicks',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Leads</div>,
                accessor: 'leads',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    const CSVHeaders = ['User Agent', 'Clicks', 'Leads']
    const CSVDataLabels = ['user_agent', 'clicks', 'leads']

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Click User Agents</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">
                                            <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority ? affiliate.priority.value : ''} status={affiliate.status ? affiliate.status.value : ''} />
                                        </h1>
                                    </div>
                                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                    <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                        Refresh Report
                                    </button>
                                </div>
                                <div>
                                    <DownloadCSV filename={`Click User Agent - ${affiliate.name} (${affiliateId}) - ${initStartDate}-${initEndDate}`} headerLabels={CSVHeaders} data={report} dataLabels={CSVDataLabels} />
                                </div>
                            </div>
                            {dataLoaded && <ReportAppliedFilters campaign={campaign} creativeId={creativeId} landingPageId={landingPageId} subid1={subid1} />}
                            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
