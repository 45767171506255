import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import { colors } from '../../../../theme/variables'

import Loading from '../../../../templates/UI/Loading/Loading'
import Campaign from '../../../../templates/Campaign/Campaign'

const APILeadsReview = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [leads, setLeads] = useState([])

    const { showSystemMessage, manager } = props

    useEffect(async () => {
        let url = `reports/apiLeadsReview/${manager.id}`

        let result = await callAPI(url, 'GET')

        if (result.success === false) {
            showSystemMessage('error', 'There was an error while fetching API leads. Please try again.')
        }

        setLeads(result.leads)
        setDataLoaded(true)
    }, [manager.id])

    return (
        <div className="card mb-3">
            <div className="card-header justify-content-between d-flex">
                <span>
                    <span className="mr-1">API Leads to Review</span>
                </span>
                <span className="align-items-center">
                    In Queue{' '}
                    <Link to={`/advertisers/apiLeadsReview?adminId=${manager.id}`} className="ml-1">
                        {leads.length}
                    </Link>
                </span>
            </div>
            <div className="card-body p-0 overflow-auto">
                <table className="table table-striped table-card m-0 overflow-auto">
                    <thead>
                        <tr className="text-secondary">
                            <th>Campaign</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            leads.length === 0 ? (
                                <tr>
                                    <td colSpan={2}>No stats available</td>
                                </tr>
                            ) : (
                                leads.slice(0, 20).map((item, index) => {
                                    return (
                                        <tr key={`${item.id}-${index}`}>
                                            <td>
                                                <Campaign oid={item.campaign.id} name={item.campaign.name} status={item.campaign.status} />
                                            </td>
                                            <td>{item.note}</td>
                                        </tr>
                                    )
                                })
                            )
                        ) : (
                            <tr>
                                <td colSpan={2}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default APILeadsReview
