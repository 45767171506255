import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { MoreVertical, UserX } from 'react-feather'
import { Link } from 'react-router-dom'
import useDateRangeFilter from '../../../../hooks/useDateRangeFilter'
import PullAffiliateModal from '../../../../components/PullAffiliateModal/PullAffiliateModal'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'
import moment from 'moment'

export default function ReportsStatsDropdown({ affiliateId, campaignId, advertiserId, creativeId, landingPageId, subid1, date, startDate, endDate, withAvatar, navigation, showSystemMessage }) {
    const auth = useAuth()
    const showPullAff = useProtectedContent(auth.permissions, 'campaign_pull_aff')

    let [dropdownOpen, setDropdownOpen] = useState(false)
    let [pullAffiliateModal, setPullAffiliateModal] = useState({
        open: false,
        affiliate: {
            id: 0,
            name: '',
        },
        campaign: {
            id: 0,
            name: '',
        },
    })
    const { initStartDate, initEndDate } = useDateRangeFilter()

    const buildUrl = (url, showWithAvatar, customStartDate, customEndDate) => {
        if (date) {
            url = url + `?startDate=${date}&endDate=${date}`
        } else if (customStartDate || customEndDate) {
            let finalStartDate = customStartDate ? customStartDate : initStartDate
            let finalEndDate = customEndDate ? customEndDate : initEndDate
            url = url + `?startDate=${finalStartDate}&endDate=${finalEndDate}`
        } else {
            let finalStartDate = startDate ? startDate : initStartDate
            let finalEndDate = endDate ? endDate : initEndDate
            url = url + `?startDate=${finalStartDate}&endDate=${finalEndDate}`
        }

        if (subid1) {
            url = url + `&subid1=${subid1}`
        }

        if (affiliateId > 0) {
            url = url + `&affiliateId=${affiliateId}`
        }

        if (campaignId > 0) {
            url = url + `&campaignId=${campaignId}`
        }

        if (advertiserId > 0) {
            url = url + `&advertiserId=${advertiserId}`
        }

        if (creativeId > 0) {
            url = url + `&creativeId=${creativeId}`
        }

        if (landingPageId > 0) {
            url = url + `&landingPageId=${landingPageId}`
        }

        if (showWithAvatar && withAvatar === 'true') {
            url = url + `&withAvatar=true`
        }

        if (navigation) {
            url = url + `&navigation=${navigation}`
        }

        return url
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} direction="left">
            <DropdownToggle tag="a" onClick={() => setDropdownOpen(!dropdownOpen)} data-toggle="dropdown" aria-expanded={dropdownOpen} className="report-more-toggle">
                <MoreVertical size={20} />
            </DropdownToggle>
            {showPullAff && <PullAffiliateModal data={pullAffiliateModal} setData={setPullAffiliateModal} showSystemMessage={showSystemMessage} />}
            <DropdownMenu>
                {campaignId > 0 && !creativeId && !landingPageId && (
                    <>
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/creatives`)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Creative
                        </Link>
                    </>
                )}

                {campaignId > 0 && !creativeId && !landingPageId && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/landingPages`)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Landing Page
                        </Link>
                    </>
                )}

                {/* There is a campaign AND an affiliate */}
                {(campaignId > 0 || affiliateId > 0) && !creativeId && !landingPageId && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/subid`, true)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Subid1
                        </Link>
                    </>
                )}

                {/* There is a campaign or advertiser, and no affiliate */}
                {(campaignId > 0 || advertiserId > 0) && (!affiliateId || affiliateId === 0) && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/affiliates`)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Affiliate
                        </Link>
                    </>
                )}

                {((affiliateId > 0 && (!campaignId || campaignId === 0)) || (advertiserId > 0 && (!campaignId || campaignId === 0))) && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/campaigns`, true)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Campaign
                        </Link>
                    </>
                )}

                {!date && (campaignId > 0 || affiliateId > 0 || advertiserId > 0) && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/daily`, true)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Day
                        </Link>
                    </>
                )}

                {!date && (campaignId > 0 || affiliateId > 0 || advertiserId > 0) && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/monthly`, true)} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Month
                        </Link>
                    </>
                )}

                {!date && (campaignId > 0 || affiliateId > 0 || advertiserId > 0) && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <Link className="dropdown-item" to={buildUrl(`/reports/stats/monthly`, true, moment().startOf('year').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))} onClick={() => setDropdownOpen(!dropdownOpen)}>
                            By Month for Year
                        </Link>
                    </>
                )}

                {affiliateId > 0 && campaignId > 0 && showPullAff && (
                    <>
                        <DropdownItem divider className="m-0" />{' '}
                        <button
                            className="btn btn-link dropdown-item text-danger"
                            onClick={() => {
                                setDropdownOpen(!dropdownOpen)
                                setPullAffiliateModal({ open: true, affiliate: { id: affiliateId, name: '' }, campaign: { id: campaignId, name: '' } })
                            }}
                        >
                            <UserX size={14} /> Pull Affiliate
                        </button>
                    </>
                )}
            </DropdownMenu>
        </Dropdown>
    )
}
