import { Modal, Form, Button, Card, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import callAPI from '../../../../../helpers/callAPI'
import TriggerModal from '../MobileKPI/TriggerModal'
import useCallApi from '../../../../../hooks/useCallApi'

const RatesModal = ({ showSystemMessage, campaignId, campaign, isOpen, setIsOpen, selectedRate, isCreate, refetch, isMobileOffer, events, campaignData }) => {
    const [formData, setFormData] = useState({ selectedRate })
    const [conversionTypes, setConversionTypes] = useState([])
    const { data: triggerData, dataLoaded: triggerDataLoaded, refetch: triggerRefetch } = useCallApi(`campaign/${campaignId}/triggers?rate_name=${selectedRate.rate_name}&mode=rate`, 'GET', {}, [campaignId, campaignData, selectedRate])
    const [triggerModalData, setTriggerModalData] = useState({ isOpen: false, data: { event_mode: '', kpi_trigger_id: '', monetary_value: '', related_event_name: '', related_event_period: '', required_event_name: '' }, isCreate: false })
    useEffect(async () => {
        setFormData(selectedRate)

        if (conversionTypes.length === 0) {
            const result = await callAPI(`conversionTypes`, 'GET')
            if (result.success) {
                setConversionTypes(result.conversion_types)
            }
        }
        console.log(selectedRate)
    }, [selectedRate])

    const createTracking = async (e) => {
        e.preventDefault()
        const result = await callAPI(`campaign/${campaignId}/rate`, 'POST', formData)
        if (result.success) {
            //refetch()
            showSystemMessage('success', 'Rate created successfully')
           // setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const updateTracking = async (e) => {
        e.preventDefault()
        const result = await callAPI(`campaign/${campaignId}/rate/`, 'PUT', formData)
        if (result.success) {
            showSystemMessage('success', 'Rate updated successfully')
            setIsOpen(false)
            refetch()
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Form onSubmit={isCreate ? createTracking : updateTracking}>
                <Modal.Header closeButton>
                    <h1 className="mb-h1-dark">
                        {isCreate ? 'Create' : 'Edit'} Rate for Offer: {campaign.name}
                    </h1>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Rate Name: </span>
                        <div className="w-75">
                            <Form.Control disabled={isCreate ? false : true} type="text" value={formData.rate_name} onChange={(e) => setFormData({ ...formData, rate_name: e.target.value })} required />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Description: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} required />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span style={{ width: '20%' }}>Default Rate: </span>
                        <div className="w-75">
                            <Form.Check type="checkbox" checked={formData.default_flag} onChange={(e) => setFormData({ ...formData, default_flag: e.target.checked })} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span style={{ width: '20%' }}>Rate Type: </span>
                        <div className="w-75">
                            <Form.Control as="select" value={formData.rate_type} onChange={(e) => setFormData({ ...formData, rate_type: e.target.value })} required>
                                <option value=""></option>
                                <option value="F">$ per lead</option>
                                <option value="P">% of sale</option>
                                {campaign.type.value === 'A' && (
                                    <>
                                        <option value="M">$ aff / % net</option>
                                        <option value="C">$ per click</option>
                                    </>
                                )}
                            </Form.Control>
                        </div>
                    </div>
                    {(formData.rate_type === 'P' || formData.rate_type === 'M') && (
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span style={{ width: '20%' }}>Hide Sale Amount: </span>
                            <div className="w-75">
                                <Form.Check type="checkbox" checked={formData.hide_sale_amount} onChange={(e) => setFormData({ ...formData, hide_sale_amount: e.target.checked })} />
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Affiliate Rate: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.affiliate_rate} onChange={(e) => setFormData({ ...formData, affiliate_rate: e.target.value })} required />
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Network Rate: </span>
                        <div className="w-75">
                            <Form.Control type="text" value={formData.network_rate} onChange={(e) => setFormData({ ...formData, network_rate: e.target.value })} required />
                        </div>
                    </div>
                    {isMobileOffer && (
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span style={{ width: '20%' }}>Recurring: </span>
                            <div className="w-75">
                                <Form.Check type="checkbox" checked={formData.is_recurring} onChange={(e) => setFormData({ ...formData, is_recurring: e.target.checked })} />
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Status: </span>
                        <div className="w-75">
                            <Form.Control as="select" value={formData.status} onChange={(e) => setFormData({ ...formData, status: e.target.value })} required>
                                <option value=""></option>
                                <option value="A">Active (Visible)</option>
                                <option value="H">Inactive (Hidden)</option>
                            </Form.Control>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <span>Conversion Type: </span>
                        <div className="w-75">
                            <Form.Control as="select" value={formData.conversion_type_id} onChange={(e) => setFormData({ ...formData, conversion_type_id: e.target.value })} required>
                                <option value="">Select conversion type</option>
                                {conversionTypes.map((item, index) => (
                                    <option key={index} value={item.conversion_type_id}>
                                        {item.conversion_type}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">Affiliate Portal Displayed Rate Override</div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Rate: </span>
                                <div className="w-75">
                                    <Form.Control type="text" value={formData.display_rate} onChange={(e) => setFormData({ ...formData, display_rate: e.target.value })} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span style={{ width: '20%' }}>Rate Type: </span>
                                <div className="w-75">
                                    <Form.Control as="select" value={formData.display_rate_type} onChange={(e) => setFormData({ ...formData, display_rate_type: e.target.value })}>
                                        <option value=""></option>
                                        <option value="F">$ per lead</option>
                                        <option value="P">% of sale</option>
                                        {campaign.type.value === 'A' && (
                                            <>
                                                <option value="M">$ aff / % net</option>
                                                <option value="C">$ per click</option>
                                            </>
                                        )}
                                    </Form.Control>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span style={{ width: '20%' }}>Hide Sale Amnt: </span>
                                <div className="w-75">
                                    <Form.Check type="checkbox" checked={formData.hide_sale_amount} onChange={(e) => setFormData({ ...formData, hide_sale_amount: e.target.checked })} />
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <TriggerModal events={events} refetch={triggerRefetch} campaignId={campaignId} showSystemMessage={showSystemMessage} isOpen={triggerModalData.isOpen} closeModal={() => setTriggerModalData({ ...triggerModalData, isOpen: false })} data={triggerModalData.data} isCreate={triggerModalData.isCreate} rateName={formData.rate_name} />
                    {!isCreate && isMobileOffer && (
                        <Card className="m-2">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                Triggers
                                <Button
                                    variant="warning"
                                    size="sm"
                                    onClick={() => {
                                        setTriggerModalData({ isOpen: true, data: { event_mode: 'SUM', event_id: 0, monetary_value: '', related_event_id: 0, trigger_period: '' }, isCreate: true })
                                    }}
                                >
                                    Add Trigger
                                </Button>
                            </Card.Header>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Hours after</th>
                                        <th>Event</th>
                                        <th>Monetary Value</th>
                                        <th>Mode</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {triggerData?.triggers?.length > 0 &&
                                        triggerData.triggers.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.required_event_name}</td>
                                                <td>{item.trigger_period}</td>
                                                <td>{item.related_event_name}</td>
                                                <td>{item.monetary_value}</td>
                                                <td>{item.event_mode === 'SUM' ? 'Sum' : 'Individual'}</td>
                                                <td>
                                                    <Button
                                                        variant="warning"
                                                        size="sm"
                                                        onClick={() => {
                                                            let tempEventId = events.find((event) => event.name === item.related_event_name).id
                                                            let tempRelatedEventId = events.find((event) => event.name === item.related_event_name).id
                                                            setTriggerModalData({ isOpen: true, data: { ...item, event_id: tempEventId, related_event_id: tempRelatedEventId }, isCreate: false })
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </Card>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {isCreate ? (
                        <Button variant="primary" type="submit">
                            Create
                        </Button>
                    ) : (
                        <>
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default RatesModal
