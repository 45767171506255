import moment from 'moment'
import React, { useState } from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { useAuth, useProtectedPage } from '../../../../context/AuthContext'
import callAPI from '../../../../helpers/callAPI'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Campaign from '../../../../templates/Campaign/Campaign'
import Loading from '../../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../../Reports/Filters/DateRangeFilter/DateRangeFilter'

export default function ScheduledJobs({ showSystemMessage, setIsCreate, setJobId, setJobIsOpen, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, refetch, dataLoaded, data }) {    

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'scheduled_jobs')

    const history = useHistory()
    const [deleteIndex, setDeleteIndex] = useState(-1)
    const calcName = (name) => {
        switch (name) {
            case 'TEST':
                return 'Test Job'
            case 'RATE':
                return 'Change Campaign Rate'
            case 'PUBRATE':
                return 'Change Pub Rate'
            case 'CANCEL':
                return 'Cancel Campaign'
            case 'PUBPULL':
                return 'Remove Pub from Campaign'
            case 'MERPULL':
                return 'Remove Pub from Advertiser'
            default:
                return name
        }
    }

    const dateChange = () => {
        history.push(`/admin/jobs?startDate=${selectedStartDate}&endDate=${selectedEndDate}`)
        refetch()
    }
    const deleteJob = async (id) => {
        const res = await callAPI(`admin/job/${id}`, 'DELETE', {})
        if (res.success) {
            showSystemMessage('success', 'Job deleted')
            refetch()
            setDeleteIndex(-1)
        } else {
            showSystemMessage('error', res.errors.message)
        }
    }
    return (
        <Card className="mb-3 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center flex-row">
                <span className="d-flex align-items-center">
                    <span className="mr-2">Scheduled Jobs</span>
                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                    <Button variant="primary" size="sm" onClick={dateChange}>
                        {' '}
                        Refresh{' '}
                    </Button>
                </span>
                <Link
                    to="/admin/job/create"
                    size="sm"
                    className="btn btn-warning"
                    onClick={(event) => {
                        event.preventDefault()
                        setIsCreate(true)
                        setJobIsOpen(true)
                    }}
                >
                    Create Job
                </Link>
            </Card.Header>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Date/Time</th>
                        <th>Job</th>
                        <td width="25%">Campaign</td>
                        <td width="25%">Advertiser</td>
                        <td>Affiliate</td>
                        <td>Params</td>
                        <td>Status</td>
                        <td />
                    </tr>
                </thead>
                <tbody>
                    {dataLoaded ? (
                        <>
                            {data.jobs.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{moment(item.job_date).format('MM/DD/YYYY hh:mm A')}</td>
                                        <td>{calcName(item.job_name)}</td>
                                        <td>
                                            <Campaign oid={item.offer_id} name={item.offer_name} status={item.status_value} />
                                        </td>
                                        <td>
                                            <Advertiser id={item.merchant_id} name={item.merchant_name} />
                                        </td>
                                        <td>
                                            <Affiliate aid={item.affiliate_id} name={item.affiliate_name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                {item.param1 && <div>{item.param1}</div>}
                                                {item.param2 && <div>, {item.param2}</div>}
                                                {item.param3 && <div>, {item.param3}</div>}
                                            </div>
                                        </td>
                                        <td>{item.job_status === 'P' ? 'Pending' : 'Done'}</td>
                                        <td>
                                            <Link
                                                to={`/admin/job/${item.job_id}`}
                                                size="sm"
                                                variant="warning"
                                                className="mr-2"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setIsCreate(false)
                                                    setJobId(item.job_id)
                                                    setJobIsOpen(true)
                                                }}
                                            >
                                                {' '}
                                                Edit
                                            </Link>
                                            {deleteIndex === index ? (
                                                <Button variant="danger" size="sm" onClick={() => deleteJob(item.job_id)}>
                                                    Confirm Delete
                                                </Button>
                                            ) : (
                                                <Button variant="warning" size="sm" onClick={() => setDeleteIndex(index)}>
                                                    Delete
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    ) : (
                        <Loading />
                    )}
                </tbody>
            </Table>
        </Card>
    )
}
