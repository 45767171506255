import React, { Component } from 'react'
import Loading from '../../../templates/UI/Loading/Loading'
import { withRouter, Link } from 'react-router-dom'
import './AvatarSite.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import callAPI from '../../../helpers/callAPI'

class AvatarSite extends Component {
    constructor(props) {
        super(props)
        this.form = React.createRef()
        this.status = React.createRef()
        this.layout = React.createRef()
        this.metaTitle = React.createRef()
        this.metaDescription = React.createRef()
        this.metaKeywords = React.createRef()
        this.navBackgroundColor = React.createRef()
        this.logoText = React.createRef()
        this.logoImage = React.createRef()
        this.logoPosition = React.createRef()
        this.mainBackgroundColor = React.createRef()
        this.mainBackgroundImage = React.createRef()
        this.leftBackgroundColor = React.createRef()
        this.leftBackgroundImage = React.createRef()
        this.headerText = React.createRef()
        this.headerTextColor = React.createRef()
        this.headerWithBackground = React.createRef()
        this.descriptionText = React.createRef()
        this.descriptionTextColor = React.createRef()
        this.descriptionWithBackground = React.createRef()
        this.footerBackgroundColor = React.createRef()
        this.footerText = React.createRef()
        this.footerTextColor = React.createRef()
        this.privacyContent = React.createRef()
        this.termsContent = React.createRef()
        this.contactSupport = React.createRef()
        this.contactPrivacy = React.createRef()
    }

    state = {
        dataLoaded: false,
        siteId: this.props.siteId,
        submitting: false,
        action: 'create',
        status: 'A',
        layout: 1,
        metaTitle: '',
        metaDescription: '',
        metaKeywords: '',
        navBackgroundColor: '#000',
        logoText: '',
        logoImage: '',
        logoPosition: '',
        mainBackgroundColor: '#f1f1f1',
        mainBackgroundImage: '',
        leftBackgroundColor: '#ffffff',
        leftBackgroundImage: '',
        headerText: '',
        headerTextColor: '#000',
        headerWithBackground: 'N',
        descriptionText: '',
        descriptionTextColor: '#000',
        descriptionWithBackground: 'N',
        footerBackgroundColor: 'RGBA(0,0,0,0.8)',
        footerText: '',
        footerTextColor: '#fff',
        privacyContent: '',
        termsContent: '',
        contactSupport: '',
        contactPrivacy: '',
        active_on_properties: [],
    }

    componentDidMount() {
        const fetchData = async () => {
            let siteId = !isNaN(this.props.siteId) ? this.props.siteId : 0

            if (siteId > 0) {
                // If > 0 , it's editing an existing site. Use the PUT url
                // Fetch the info for this site and set the defaults!

                const authtoken = localStorage.getItem('adm-auth-token')

                const result = await callAPI(`avatar/site/${siteId}`, 'GET')
                if (result['success'] === true) {
                    let site = result.site

                    this.setState({
                        ...this.state,
                        dataLoaded: true,
                        status: site.status,
                        layout: parseInt(site.layout),
                        metaTitle: site.meta_title,
                        metaDescription: site.meta_description,
                        metaKeywords: site.meta_keywords,
                        navBackgroundColor: site.nav_background_color,
                        logoText: site.logo_text,
                        logoImage: site.logo_image,
                        logoPosition: site.logo_position,
                        mainBackgroundColor: site.main_background_color,
                        mainBackgroundImage: site.main_background_image,
                        leftBackgroundColor: site.left_background_color,
                        leftBackgroundImage: site.left_background_image,
                        headerText: site.content_header,
                        headerTextColor: site.header_color,
                        headerWithBackground: site.header_with_background,
                        descriptionText: site.content_description,
                        descriptionTextColor: site.description_color,
                        descriptionWithBackground: site.description_with_background,
                        footerBackgroundColor: site.footer_background_color,
                        footerText: site.footer_text,
                        footerTextColor: site.footer_color,
                        privacyContent: site.privacy_content,
                        termsContent: site.terms_content,
                        contactSupport: site.contact_support,
                        contactPrivacy: site.contact_privacy,
                        active_on_properties: site.active_on_properties,
                    })
                    document.title = `Avatar Site: ${site.meta_title}`
                } else if (result['success'] === false) {
                    this.props.showSystemMessage('error', result.errors.message)
                    document.title = `Avatar Site`
                } else {
                    this.props.showSystemMessage('error', 'Error fetching site data.')
                    document.title = `Avatar Site`
                }

                this.setState({
                    ...this.state,
                    action: 'edit',
                })
            } else {
                // If 0, it's a new site. Use the POST url
                this.setState({
                    ...this.state,
                    dataLoaded: true,
                    action: 'create',
                })
            }
        }
        fetchData()
    }

    handleChange = (input) => (event) => {
        this.setState({ [input]: event.target.value })
    }

    handleFileChange = (input) => (event) => {
        this.setState({ [input]: event.target.files[0] })
    }

    handleFileRemove = (input) => (event) => {
        event.preventDefault()

        const inputField = this[input].current

        inputField.value = ''

        this.setState({ [input]: '' })
    }

    saveAndContinue = async (e) => {
        e.preventDefault()

        if (this.validate()) {
            await this.saveSite(this.state)
        } else {
            /* Forces html5 form validation */
            const form = this.form.current
            form.reportValidity()
        }
    }

    validate = () => {
        let validated = true

        const metaTitle = this.metaTitle.current
        const metaDescription = this.metaDescription.current
        const metaKeywords = this.metaKeywords.current
        const logoText = this.logoText.current
        const headerText = this.headerText.current
        const descriptionText = this.descriptionText.current
        const footerText = this.footerText.current
        const logoImage = this.logoImage.current
        const mainBackgroundImage = this.mainBackgroundImage.current

        /* Reset any previous custom validity settings to valid */
        metaTitle.setCustomValidity('')
        metaDescription.setCustomValidity('')
        metaKeywords.setCustomValidity('')
        logoText.setCustomValidity('')
        headerText.setCustomValidity('')
        descriptionText.setCustomValidity('')
        footerText.setCustomValidity('')
        logoImage.setCustomValidity('')
        mainBackgroundImage.setCustomValidity('')

        if (this.state.logoImage !== null) {
            if (typeof this.state.logoImage === 'object') {
                if (!this.state.logoImage.type.match(/.(jpg|jpeg|png|gif)$/i)) {
                    /* Check the extensions from the state */
                    // File type is incorrect
                    logoImage.setCustomValidity('The image must be a jpg, png, or gif.')
                    validated = false
                } else if (this.state.logoImage.size > 25000000) {
                    logoImage.setCustomValidity('The image must be less than 25MB')
                    validated = false
                }
            }
        }

        if (this.state.mainBackgroundImage !== null) {
            if (typeof this.state.mainBackgroundImage === 'object') {
                if (!this.state.mainBackgroundImage.type.match(/.(jpg|jpeg|png|gif)$/i)) {
                    mainBackgroundImage.setCustomValidity('The image must be a jpg, png, or gif.')
                    validated = false
                } else if (this.state.mainBackgroundImage.size > 25000000) {
                    mainBackgroundImage.setCustomValidity('The image must be less than 25MB')
                    validated = false
                }
            }
        }

        console.log('validated', validated)

        if (metaTitle.checkValidity() === false || metaDescription.checkValidity() === false || metaKeywords.checkValidity() === false || headerText.checkValidity() === false || descriptionText.checkValidity() === false || footerText.checkValidity() === false) {
            validated = false
        }

        if (logoText.value === '' && (this.state.logoImage === null || typeof this.state.logoImage !== 'object')) {
            logoText.setCustomValidity('If you do not have a logo image, you must have logo text.')
            validated = false
        }

        return validated
    }

    saveSite = async (data) => {
        const authtoken = localStorage.getItem('adm-auth-token')

        // We have to submit this data as formData because we now have file uploads for identification.

        let formData = new FormData()

        for (var key in data) {
            formData.append(key, data[key])
        }

        let method = this.state.action === 'create' ? 'POST' : 'PUT'

        this.setState({
            ...this.state,
            submitting: true,
        })

        const result = await callAPI(`avatar/site`, method, formData)

        if (result['success'] === true) {
            // Redirect to the editing page
            if (this.state.action === 'create') {
                this.props.showSystemMessage('success', 'Site has been created.')
                this.props.history.push(`/avatar/site/${result['siteId']}`)
            } else {
                this.props.showSystemMessage('success', 'Site has been updated.')
                this.setState({
                    ...this.state,
                    submitting: false,
                })
            }
            return true
        } else {
            this.setState({
                ...this.state,
                submitting: false,
            })
            this.props.showSystemMessage('error', result.errors.message)
            return false
        }
    }

    getSubmitButton = () => {
        return this.state.submitting ? (
            <button className="btn btn-primary btn-sm" disabled>
                <FontAwesomeIcon icon="spinner" spin />
            </button>
        ) : (
            <button onClick={this.saveAndContinue} className="btn btn-primary btn-sm">
                {this.state.action === 'edit' ? 'Update Site Design' : 'Create Site Design'}
            </button>
        )
    }

    getActiveProperties = () => {
        return (
            <div className="alert alert-primary">
                <strong className="text-small">This site is being used on the following properties:</strong>
                {this.state.active_on_properties.map((property) => {
                    return (
                        <div className="badge badge-light text-left border ml-2">
                            <Link to={`/avatar/property/${property.property_id}`}>Property {property.property_id}</Link> {property.domain}
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Site Design</h1>
                </div>
                {this.state.dataLoaded ? (
                    <React.Fragment>
                        {this.state.active_on_properties.length > 0 && this.getActiveProperties()}
                        <form ref={this.form}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Page Settings</div>
                                            {this.getSubmitButton()}
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <select ref={this.layout} onChange={this.handleChange('layout')} name="layout" defaultValue={this.state.layout} className="form-control mb-maxw-500">
                                                    <option value={1}>Single Page</option>
                                                    <option value={2}>Split left-right</option>
                                                    <option value={3}>Split left-right - full screen page 2</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <select ref={this.status} onChange={this.handleChange('status')} name="status" defaultValue={this.state.status} className="form-control mb-maxw-500">
                                                    <option value="P">Pending</option>
                                                    <option value="A">Active</option>
                                                </select>
                                            </div>
                                        </div>
                                        <input type="hidden" name="siteId" value={this.props.siteId} />
                                    </div>

                                    <div className="card mt-3">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Header</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="alert alert-secondary">
                                                <div className="mb-3">
                                                    <u>Meta Information</u>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="metaTitle">Title</label>
                                                    <input ref={this.metaTitle} onChange={this.handleChange('metaTitle')} defaultValue={this.state.metaTitle} type="text" className="form-control mb-maxw-500" name="metaTitle" required />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="metaDescription">Description</label>
                                                    <input ref={this.metaDescription} onChange={this.handleChange('metaDescription')} defaultValue={this.state.metaDescription} type="text" className="form-control" name="metaDescription" required />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="metaKeywords">Keywords</label>
                                                    <input ref={this.metaKeywords} onChange={this.handleChange('metaKeywords')} defaultValue={this.state.metaKeywords} type="text" className="form-control" name="metaKeywords" required />
                                                </div>
                                            </div>
                                            <div className="alert alert-secondary">
                                                <div className="mb-3">
                                                    <u>Navigation Bar</u>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="navBackgroundColor">Background Color</label>
                                                    <div className="d-flex align-items-center">
                                                        <input ref={this.navBackgroundColor} onChange={this.handleChange('navBackgroundColor')} defaultValue={this.state.navBackgroundColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="navBackgroundColor" placeholder="RGBA/HEX" />
                                                        <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.navBackgroundColor }}></div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="logoText">Logo Text (If no logo image)</label>
                                                    <input ref={this.logoText} onChange={this.handleChange('logoText')} defaultValue={this.state.logoText} type="text" className="form-control mb-maxw-500" name="logoText" />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="logoImage">Logo image</label>
                                                    <div className="d-flex align-items-center">
                                                        <input ref={this.logoImage} onChange={this.handleFileChange('logoImage')} type="file" accept="image/png, image/jpeg" className="form-control mb-maxw-500" name="logoImage" />
                                                        <button className="btn btn-link" onClick={this.handleFileRemove('logoImage')}>
                                                            Remove File
                                                        </button>
                                                    </div>
                                                    {this.state.logoImage !== '' && (
                                                        <div className="alert alert-dark d-inline-block mt-2">
                                                            <img style={{ maxWidth: '150px' }} src={`${this.state.logoImage}`} alt="logo" crossOrigin="" />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="logoPosition">Logo Position</label>
                                                    <select ref={this.logoPosition} onChange={this.handleChange('logoPosition')} name="logoPosition" defaultValue={this.state.logoPosition} className="form-control mb-maxw-500">
                                                        <option value="left">Left</option>
                                                        <option value="center">Center</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Footer</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="footerBackgroundColor">Background Color</label>
                                                <div className="d-flex align-items-center">
                                                    <input ref={this.footerBackgroundColor} onChange={this.handleChange('footerBackgroundColor')} defaultValue={this.state.footerBackgroundColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="footerBackgroundColor" placeholder="RGBA/HEX" />
                                                    <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.footerBackgroundColor }}></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="footerText">Text</label>
                                                <textarea onChange={this.handleChange('footerText')} ref={this.footerText} name="footerText" className="form-control" defaultValue={this.state.footerText}></textarea>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="footerTextColor">Text Color</label>
                                                <div className="d-flex align-items-center">
                                                    <input ref={this.descriptionTextColor} onChange={this.handleChange('footerTextColor')} defaultValue={this.state.footerTextColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="footerTextColor" placeholder="RGBA/HEX" />
                                                    <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.footerTextColor }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Contact</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="footerBackgroundColor">Support Email</label>
                                                <div className="d-flex align-items-center">
                                                    <input ref={this.contactSupport} onChange={this.handleChange('contactSupport')} defaultValue={this.state.contactSupport} type="text" className="form-control mb-maxw-250 d-inline-block" name="contactSupport" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="footerBackgroundColor">Privacy Email</label>
                                                <div className="d-flex align-items-center">
                                                    <input ref={this.contactPrivacy} onChange={this.handleChange('contactPrivacy')} defaultValue={this.state.contactPrivacy} type="text" className="form-control mb-maxw-250 d-inline-block" name="contactPrivacy" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Main Content</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="mainBackgroundColor">Main Background Color (If no main background image is saved)</label>
                                                <div className="d-flex align-items-center">
                                                    <input ref={this.mainBackgroundColor} onChange={this.handleChange('mainBackgroundColor')} defaultValue={this.state.mainBackgroundColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="mainBackgroundColor" placeholder="RGBA/HEX" />
                                                    <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.mainBackgroundColor }}></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="mainBackgroundImage">Main Background Image</label>
                                                <div className="d-flex align-items-center">
                                                    <input ref={this.mainBackgroundImage} onChange={this.handleFileChange('mainBackgroundImage')} type="file" accept="image/png, image/jpeg" className="form-control mb-maxw-500" name="mainBackgroundImage" />
                                                    <button className="btn btn-link" onClick={this.handleFileRemove('mainBackgroundImage')}>
                                                        Remove File
                                                    </button>
                                                </div>
                                                {this.state.mainBackgroundImage !== '' && (
                                                    <div className="alert alert-dark d-inline-block mt-2">
                                                        <img style={{ maxWidth: '150px' }} src={`${this.state.mainBackgroundImage}`} alt="Main Background" crossOrigin="" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="alert alert-secondary my-3">
                                                <div className="mb-3">
                                                    <u>Header Paragraph</u>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="HeaderText">Text</label>
                                                    <input ref={this.headerText} onChange={this.handleChange('headerText')} defaultValue={this.state.headerText} type="text" className="form-control mb-maxw-500" name="HeaderText" required />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="headerTextColor">Text Color</label>
                                                    <div className="d-flex align-items-center">
                                                        <input ref={this.headerTextColor} onChange={this.handleChange('headerTextColor')} defaultValue={this.state.headerTextColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="headerTextColor" placeholder="RGBA/HEX" />
                                                        <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.headerTextColor }}></div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="headerWithBackground">Darkened background</label>
                                                    <select ref={this.headerWithBackground} onChange={this.handleChange('headerWithBackground')} name="headerWithBackground" defaultValue={this.state.headerWithBackground} className="form-control mb-maxw-500">
                                                        <option value="N">Off</option>
                                                        <option value="Y">On</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="alert alert-secondary my-3">
                                                <div className="mb-3">
                                                    <u>Body Paragraph</u>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="descriptionText">Text</label>
                                                    <input ref={this.descriptionText} onChange={this.handleChange('descriptionText')} defaultValue={this.state.descriptionText} type="text" className="form-control mb-maxw-500" name="descriptionText" required />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="descriptionTextColor">Text Color</label>
                                                    <div className="d-flex align-items-center">
                                                        <input ref={this.descriptionTextColor} onChange={this.handleChange('descriptionTextColor')} defaultValue={this.state.descriptionTextColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="descriptionTextColor" placeholder="RGBA/HEX" />
                                                        <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.descriptionTextColor }}></div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="descriptionTextColor">Darkened Background</label>
                                                    <select ref={this.descriptionWithBackground} onChange={this.handleChange('descriptionWithBackground')} name="descriptionWithBackground" defaultValue={this.state.descriptionWithBackground} className="form-control mb-maxw-500">
                                                        <option value="N">Off</option>
                                                        <option value="Y">On</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {(this.state.layout === '2' || this.state.layout === 2) && (
                                                <div className="alert alert-secondary my-3">
                                                    <div className="mb-3">
                                                        <u>Left Side Design</u>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="leftBackgroundColor">Left Background Color (If no main background image is saved)</label>
                                                        <div className="d-flex align-items-center">
                                                            <input ref={this.leftBackgroundColor} onChange={this.handleChange('leftBackgroundColor')} defaultValue={this.state.leftBackgroundColor} type="text" className="form-control mb-maxw-250 d-inline-block" name="leftBackgroundColor" placeholder="RGBA/HEX" />
                                                            <div className="d-inline-block ml-2 rounded site-background-preview" style={{ backgroundColor: this.state.leftBackgroundColor }}></div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="leftBackgroundImage">Left Background Image</label>
                                                        <div className="d-flex align-items-center">
                                                            <input ref={this.leftBackgroundImage} onChange={this.handleFileChange('leftBackgroundImage')} type="file" accept="image/png, image/jpeg" className="form-control mb-maxw-500" name="leftBackgroundImage" />
                                                            <button className="btn btn-link" onClick={this.handleFileRemove('leftBackgroundImage')}>
                                                                Remove File
                                                            </button>
                                                        </div>
                                                        {this.state.leftBackgroundImage !== '' && (
                                                            <div className="alert alert-dark d-inline-block mt-2">
                                                                <img style={{ maxWidth: '150px' }} src={`${this.state.leftBackgroundImage}`} alt="Left Background" crossOrigin="" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Privacy</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <textarea onChange={this.handleChange('privacyContent')} ref={this.privacyContent} name="privacyContent" className="form-control" defaultValue={this.state.privacyContent}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <div className="font-weight-bold">Terms of Service</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <textarea onChange={this.handleChange('termsContent')} ref={this.termsContent} name="termsContent" className="form-control" defaultValue={this.state.termsContent}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.active_on_properties.length > 0 && <div className="mt-3">{this.getActiveProperties()}</div>}
                                    <div className="text-right mt-3">{this.getSubmitButton()}</div>
                                </div>
                            </div>
                        </form>
                    </React.Fragment>
                ) : (
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(AvatarSite)
