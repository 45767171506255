import React, { useEffect, useState } from 'react'
import { User } from 'react-feather'

import useCallApi from '../../../hooks/useCallApi'
import Followups from '../../../components/Followups/Followups'
import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'
import AdvertiserContactInfo from './AdvertiserContactInfo/AdvertiserContactInfo'
import InvoiceInfo from './InvoiceInfo/InvoiceInfo'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import LoginAsAdvertiser from '../LoginAsAdvertiser/LoginAsAdvertiser'

const AdvertiserInfo = ({ advertiserId, showSystemMessage, setIsOpen, limitedCreditFlag }) => {
    // name, manager name
    // status, location
    // Name, Skype, Phone, email
    // IM handle, mobile, Email, website (link)
    // Today, Best Month
    const { data, dataLoaded } = useCallApi(`advertiser/${advertiserId}`, 'GET', {}, [advertiserId])

    return (
        <div className="card pt-2 mb-3">
            {dataLoaded ? (
                <React.Fragment>
                    <div className="d-flex justify-content-between border-bottom p-2 align-items-center">
                        <div className="d-flex">
                            <h5 className="font-weight-bold">
                                <Link to={`/advertiser/${advertiserId}/edit`}>{data.general_info.name}</Link>
                            </h5>
                            <div className="float-left text-secondary"> &nbsp;/ {data.adv_manager.name}</div>
                        </div>
                        <LoginAsAdvertiser advertiserId={advertiserId} showSystemMessage={showSystemMessage} />
                    </div>
                    <div className="d-flex align-items-center p-2 border-bottom">
                        <Pill text={data.general_info.status} />
                        <div className="align-middle text-secondary text-center pl-3" style={{ maxWidth: '250px' }}>
                            {data.general_info.state}, {data.general_info.country} <br></br>
                        </div>
                    </div>
                    <AdvertiserContactInfo contactInfo={data.contact_info} email={data.contact_info.e_mail} contact={data.general_info.contact} />
                    <InvoiceInfo invoiceFrequency={data.general_info.invoice_frequency} paymentTerms={data.general_info.payment_terms} balances={data.balances} limitedCreditFlag={limitedCreditFlag} />
                </React.Fragment>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default AdvertiserInfo
