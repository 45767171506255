import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import callAPI from '../../../../helpers/callAPI'
import Advertiser from '../../../../templates/Advertiser/Advertiser'
import Loading from '../../../../templates/UI/Loading/Loading'
import CreateInvoiceModal from '../../../../components/CreateInvoiceModal/CreateInvoiceModal'

import styles from './ThresholdAdvertiserBalances.module.scss'
import Dollars from '../../../../templates/Dollars/Dollars'

const ThresholdAdvertiserBalances = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [advertisers, setAdvertisers] = useState([])
    const [advertiser, setAdvertiser] = useState({})
    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false)

    const { showSystemMessage, manager, noAdmin } = props

    useEffect(async () => {
        fetchData()
    }, [manager.id])

    const fetchData = async () => {
        setDataLoaded(false)

        let url = `advertisers/balances?adminId=${manager.id}`

        if (noAdmin) {
            url = `advertisers/balances`
        }

        let result = await callAPI(url, 'GET')

        if (result.success === true) {
            setAdvertisers(result.advertisers)
            setDataLoaded(true)
        } else if (result.success === false) {
            showSystemMessage('error', result.errors.message)
            setDataLoaded(true)
            return
        } else {
            showSystemMessage('error', 'There was an error while fetching advertiser balances. Please try again.')
            setDataLoaded(true)
            return
        }
    }

    const handleClose = (isEdited = false) => {
        setShowCreateInvoiceModal(false)

        if (isEdited) {
            fetchData()
        }
    }

    const handleRequestClick = (advertiser) => {
        setAdvertiser(advertiser)
        setShowCreateInvoiceModal(true)
    }

    return (
        <div className="card mb-3 overflow-auto">
            {showCreateInvoiceModal && <CreateInvoiceModal isOpen={showCreateInvoiceModal} handleClose={handleClose} advertiser={advertiser} showSystemMessage={showSystemMessage} />}
            <div className="card-header">
                <div className="font-weight-bold float-left">Advertiser Balances</div>
                <div className="float-right">
                    <Link to={noAdmin ? `/advertisers/balances` : `/advertisers/balances?adminId=${manager.id}`} className="text-primary font-weight-normal">
                        List All
                    </Link>
                </div>
            </div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    advertisers.length === 0 ? (
                        <div className="p-2 border-top">No stats available </div>
                    ) : (
                        <table className="table table-striped table-hover table-card m-0 nowrap">
                            <thead>
                                <tr className="text-secondary">
                                    <th width="9%">ID</th>
                                    <th width="49%">Advertiser</th>
                                    <th className="text-right" width="10%">
                                        Balance
                                    </th>
                                    <th width="10%">Camps</th>
                                    <th width="10%">Terms</th>
                                    <th width="12%">Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {advertisers.slice(0, 20).map((item) => {
                                    let classes = ``

                                    if (item.balance > -2500 && !(item.balance === 0 && item.campaigns === 0)) {
                                        if (item.balance > 0 || (item.balance > -1000 && item.campaigns > 0 && item.invoice_status_letter === 'N')) {
                                            classes = 'bgDanger25'
                                        }
                                    }

                                    return (
                                        <tr key={item.advertiser.id} className={classes}>
                                            <td>{item.advertiser.id}</td>
                                            <td>
                                                <Advertiser id={item.advertiser.id} name={item.advertiser.name} status={item.advertiser.status} />
                                            </td>
                                            <td className="text-right">
                                                <Dollars value={item.balance} />
                                            </td>
                                            <td>{item.campaigns}</td>
                                            <td width={'10%'}>{item.terms}</td>
                                            <td>
                                                {item.invoice_status === 'Request Invoice' ? (
                                                    <button className="btn btn-link btn-md p-0" onClick={() => handleRequestClick(item.advertiser)}>
                                                        Request
                                                    </button>
                                                ) : (
                                                    item.invoice_status
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default ThresholdAdvertiserBalances
