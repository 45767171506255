import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import getAffiliateStatus from '../../../helpers/getAffiliateStatus'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'

const IDNumber = ({ data, dataLoaded }) => {
    return (
        <Card className="mb-4">
            <Card.Header>
                <div>ID Number (Government ID)</div>
            </Card.Header>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                {dataLoaded ? (
                    <>
                        {data.success && data.info.matchingId.length > 0 ? (
                            <>
                                <tbody>
                                    {data.info.matchingId.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.affiliate_id}</td>
                                                <td>
                                                    <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                                </td>
                                                <td>{item.aff_status}</td>
                                                <td>{item.followup_notes}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4">No data found</td>
                                </tr>
                            </tbody>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}

export default IDNumber
