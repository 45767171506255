import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import callAPI from '../../../helpers/callAPI'
import useQueryParams from '../../../hooks/useQueryParams'
import Loading from '../../../templates/UI/Loading/Loading'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import moment from 'moment'
import { Modal } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import useCallApi from '../../../hooks/useCallApi'

const SearchSearches = ({ showSystemMessage }) => {
    document.title = 'Affiliate Search Terms'

    const { adminId, setAdminId, buildUrl } = useQueryParams()

    const history = useHistory()
    const location = useLocation()

    const { dataLoaded, data, refetch } = useCallApi(`affiliates/searchSearches?adminId=${adminId}`, 'GET', {}, [adminId])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Pattern',
                accessor: 'pattern',
            },
            {
                Header: 'ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate_id} name={tableData.data[tableData.row.index].name} />
                },
            },
            {
                Header: 'Affiliate Mgr',
                accessor: 'full_name',
            },
            {
                Header: 'Times Searched',
                accessor: 'total_count',
            },
            {
                Header: 'Last Searched',
                accessor: 'last_search',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? moment(value).format('MM/DD/YYYY') : ''}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.searches} columns={columns} />
            </React.Fragment>
        )
    }

    useEffect(() => {
        let url = `/affiliates/searches?adminId=${adminId}`
        history.push(url)
    }, [adminId])

    return (
        <div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Affiliate Searches for: </h1>
                        </div>
                        <div className="mr-4">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                        </div>
                    </div>
                    {dataLoaded ? data.searches.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default SearchSearches
