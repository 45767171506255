import { Button, ButtonGroup, Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Loading from '../../../templates/UI/Loading/Loading'
import CreativesModal from './CreativesModal'
import { useState } from 'react'
import callAPI from '../../../helpers/callAPI'
import { create } from 'lodash'
import { useEffect } from 'react'
import BatchUploadBanners from './BatchUploadBanners'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'

const Creatives = ({ campaignId, showSystemMessage }) => {
    const { data, dataLoaded, setData, refetch } = useCallApi(`campaign/${campaignId}/creatives`, 'GET', {}, [campaignId], showSystemMessage)
    const [name, setName] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [batchIsOpen, setBatchIsOpen] = useState(false)
    const [selectedCreative, setSelectedCreative] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    const [state, setState] = useState('')
    const [reloadPage, setReloadPage] = useState(false)

    if (dataLoaded){
        document.title = `${data.campaign.name}: Creatives`
    } else {
        document.title = 'Creatives'
    }

    const calcName = (type, width = null, height = null, id) => {
        if (type === 'B') {
            return `Banner_${id} (${width}x${height})`
        } else if (type === 'E') {
            return `Email_${id}`
        } else if (type === 'H') {
            return `HTML_${id}`
        } else if (type === 'T') {
            return `Text_${id}`
        }
    }

    const getPreview = async (type, creativeId) => {
        const response = await callAPI(`campaign/${campaignId}/creatives/${data.advertiser.id}/preview/?creativeType=${type}&creativeId=${creativeId}`, 'GET')

        if (response.success) {
            return response.content
        }
    }

    useEffect(() => {
        const func = async () => {
            await data.creatives.map(async (creative, i) => {
                const preview = await getPreview(creative.creative_type, creative.creative_id)
                let tempData = data
                tempData.creatives[i].preview = preview
                if (creative.creative_type !== 'B') {
                    tempData.creatives[i].html_text = preview
                }
                setData(tempData)
                setState(i)
            })
        }
        func()
    }, [data, reloadPage])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Creatives</h1>
                <div className="ml-5">
                    <CampaignNavbar campaignId={campaignId} />
                </div>
            </div>
            <div className="card">
                {data.success && <BatchUploadBanners data={data} refetch={refetch} showSystemMessage={showSystemMessage} isOpen={batchIsOpen} setIsOpen={setBatchIsOpen} campaignId={campaignId} />}
                {data.success && <CreativesModal data={data} refetch={refetch} dataLoaded={dataLoaded} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} name={name} isCreate={isCreate} setIsCreate={setIsCreate} creative={selectedCreative} campaignId={campaignId} reloadPage={reloadPage} setReloadPage={setReloadPage} />}
                <div className="d-flex justify-content-between p-3 align-items-center">
                    <h1 className="mb-h1-dark mr-2">Creatives for {dataLoaded && data.success ? data.campaign.name : ''}</h1>
                    <div className="text-right">
                        <Button
                            variant="warning"
                            className="mr-2"
                            onClick={() => {
                                setSelectedCreative(false)
                                setIsCreate(true)
                                setName(data.campaign.name)
                                setIsOpen(true)
                            }}
                        >
                            Add Creative
                        </Button>
                        <Button
                            variant="warning"
                            onClick={() => {
                                setBatchIsOpen(true)
                            }}
                        >
                            Batch Upload Banners
                        </Button>
                    </div>
                </div>
                {dataLoaded && data.success ? (
                    <Table striped bordered hover>
                        <tbody>
                            {data.creatives.map((creative, i) => {
                                return (
                                    <tr key={creative.id}>
                                        <td width="20%">{calcName(creative.creative_type, creative.width, creative.height, creative.creative_id)}</td>
                                        <td>{creative.creative_type === 'B' ? <img style={{ maxHeight: '250px' }} src={`${process.env.REACT_APP_API_URL}/campaign/${campaignId}/creatives/${data.advertiser.id}/preview/?creativeType=${creative.creative_type}&creativeId=${creative.creative_id}`} alt="Banner" crossOrigin={process.env.REACT_APP_PORTAL_URL} /> : <div dangerouslySetInnerHTML={{ __html: creative.html_text }} />}</td>
                                        <td>{creative.status_name}</td>
                                        <td>
                                            <Button
                                                size="sm"
                                                variant="warning"
                                                className="mr-2"
                                                onClick={() => {
                                                    setSelectedCreative(creative)
                                                    setIsCreate(false)
                                                    setName(calcName(creative.creative_type, creative.width, creative.height, creative.creative_id))
                                                    setIsOpen(true)
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default Creatives
