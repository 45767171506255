import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import useQueryParams from '../../../hooks/useQueryParams'

import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'

import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import CampaignSearch from '../../../components/CampaignSearch/CampaignSearch'
import useCallApi from '../../../hooks/useCallApi'
import useDownloadCSV from '../../../hooks/useDownloadCSV'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Campaign from '../../../templates/Campaign/Campaign'
import Dollars from '../../../templates/Dollars/Dollars'
import DownloadCSV from '../../../templates/DownloadCSV/DownloadCSV'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
// import CampaignSearch from '../../Affiliate/CampaignPitches/CampainSearch/CampaignSearch'

export default function AffiliatePitchReport({ type, showSystemMessage }) {
    document.title = 'Affiliate Pitch Report'

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_pitch_report')

    const location = useLocation()
    const history = useHistory()

    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initStartDate2, initEndDate2, selectedStartDate2, selectedEndDate2, quickDate2, handleChangeStart2, handleChangeEnd2, handleQuickDate2 } = useDateRangeFilter()
    const { adminId, setAdminId, buildUrl, campaignId, setCampaignId } = useQueryParams()

    const [campaign, setCampaign] = useState({ id: -1, name: '' })

    const { CSVFilename, setCSVFilename, CSVHeaderLabels, setCSVHeaderLabels, CSVDataLabels, setCSVDataLabels, CSVReport, setCSVReport } = useDownloadCSV()

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()

        let url = `/affiliates/pitchReport?startDate=${selectedStartDate}&endDate=${selectedEndDate}&startDate2=${selectedStartDate2}&endDate2=${selectedEndDate2}&campaignId=${campaignId}&adminId=${adminId}`

        history.push(url)
    }

    let url = `reports/custom/affiliatePitchReport?startDate=${initStartDate}&endDate=${initEndDate}&startDate2=${initStartDate2}&endDate2=${initEndDate2}&campaignId=${campaignId}`

    // adminId= when no admin is selected, should be adminId=0, instead

    let fetchUrl = buildUrl(url)

    const { data, dataLoaded, refetch } = useCallApi(fetchUrl, 'GET', {}, [location])

    useEffect(() => {
        setCSVHeaderLabels(['Manager', 'Affiliate', 'Campaign ID', 'Campaign Date', 'Earnings', 'Margin'])
        setCSVDataLabels(['admin_name', 'affiliate_name', 'campaign_name', 'campaign_date', 'earnings', 'Margin'])
        setCSVReport(data.report)
        setCSVFilename(`Affiliate Pitch Report - Pitched: ${initStartDate}-${initEndDate} - Earnings: ${initStartDate2}-${initEndDate2}`)
    }, [data])

    const getReportOutput = (data) => {
        let columns = [
            {
                Header: 'Manager',
                accessor: 'admin_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Aff ID',
                accessor: 'affiliate_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate_name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} priority={tableData.data[tableData.row.index].affiliate.priority.value} status={tableData.data[tableData.row.index].affiliate.status.value} />
                },
            },
            {
                Header: 'Cam ID',
                accessor: 'campaign_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Campaign',
                accessor: 'campaign_name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: 'Campaign Date',
                accessor: 'campaign_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Earnings</div>,
                headerClassName: 'text-right',
                className: 'text-right',
                accessor: 'earnings',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: <div style={{ textAlign: 'right' }}>Margin</div>,
                headerClassName: 'text-right',
                className: 'text-right',
                accessor: 'margin',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={data.report} totals={data.totals} columns={columns} />
            </React.Fragment>
        )
    }

    // Instead of using setCampaign directly, I had to create a middleware function to setCampaignId (in useQueryParams()) as well. The reason for that is -  buildUrl() appends campaignId to the page URL which uses [campaignId, setCampaignId] to determine the ID. On this page, we use [campaign, setCampaign] to set campaignId which is coming from CampaignSearch. so they both need to be changed when ID from CampaignSearch changes.

    const setCampaignFromSearch = (value) => {
        setCampaign(value)
        setCampaignId(value.id)
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <div className="mr-4 d-flex align-items-center">
                                        <h1 className="mb-h1-dark mr-2">Affiliate Pitch Report</h1>
                                    </div>
                                </div>
                                <div>
                                    <DownloadCSV filename={CSVFilename} headerLabels={CSVHeaderLabels} data={CSVReport} dataLabels={CSVDataLabels} />
                                </div>
                            </div>
                            <div className="d-flex p-3 border-top">
                                <div className="mr-4 d-flex align-items-center">
                                    <span className="mr-2">Manager:</span>
                                    <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                                </div>
                                <div className="mr-4 d-flex align-items-center w-25">
                                    <span className="mr-2">Campaign:</span>
                                    <div className="w-100">
                                        <CampaignSearch setCampaign={setCampaignFromSearch} campaign={campaign.id > 0 ? campaign : campaignId > 0 ? { id: campaignId } : false} />
                                    </div>
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <span className="mr-2">Date range for pitches:</span>
                                    <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <span className="mr-2">Date range for leads:</span>
                                    <DateRangeFilter quickDate={quickDate2} selectedStartDate={selectedStartDate2} selectedEndDate={selectedEndDate2} handleChangeStart={handleChangeStart2} handleChangeEnd={handleChangeEnd2} handleQuickDate={handleQuickDate2} />
                                </div>
                                <div className="mr-4 d-flex align-items-center">
                                    <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                        Refresh Report
                                    </button>
                                </div>
                            </div>
                            <div>{dataLoaded ? data.report.length > 0 ? getReportOutput(data) : <div className="p-3">No stats for the current parameters.</div> : <Loading></Loading>}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
