import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal } from 'reactstrap'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useCallApi from '../../../hooks/useCallApi'
import useQueryParams from '../../../hooks/useQueryParams'
import ReportTable from '../../../templates/ReportTable/ReportTable'
import Loading from '../../../templates/UI/Loading/Loading'
import ReviewApplication from '../../Dashboard/components/Applications/ReviewApplication/ReviewApplication'

const Applications = ({ showSystemMessage }) => {
    const auth = useAuth()
    useProtectedPage(auth.permissions, 'affiliate_applications')

    const { adminId, setAdminId, buildUrl } = useQueryParams()
    const [affiliateId, setAffiliateId] = useState() // For Modal
    const [showApplicationModal, setShowApplicationModal] = useState(false)

    const history = useHistory()
    const location = useLocation()

    const { dataLoaded, data, refetch } = useCallApi(buildUrl(`affiliates/applications?z=1`), 'GET', {}, [])

    useEffect(() => {
        refetch()
    }, [location])

    const handleClick = (affiliateId) => {
        setAffiliateId(affiliateId)
        setShowApplicationModal(true)
    }

    const toggleModal = () => {
        setShowApplicationModal(false)
    }

    const handleClose = () => {
        setShowApplicationModal(false)
        refetch()
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'affiliate.id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate.name',
                Cell: (tableData) => {
                    return (
                        <>
                            <FontAwesomeIcon icon={faAddressCard} className="text-primary mr-1" />
                            <Link
                                to={{ pathname: '' }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    handleClick(tableData.data[tableData.row.index].affiliate.id)
                                }}
                            >
                                {tableData.data[tableData.row.index].affiliate.name}
                            </Link>
                            {(tableData.data[tableData.row.index].experience_level.value === 3 || tableData.data[tableData.row.index].experience_level.value === 2) && tableData.data[tableData.row.index].country_tier === 1 ? <span className="ml-2">⭐</span> : ''}
                        </>
                    )
                },
            },
            {
                Header: 'Manager',
                accessor: 'manager.name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Experience',
                accessor: 'experience_level.text',
                Cell: (tableData) => {
                    let customClass = tableData.data[tableData.row.index].experience_level.value === 3 || (tableData.data[tableData.row.index].experience_level.value === 2 && tableData.data[tableData.row.index].country_tier === 1) ? 'text-success font-weight-bold' : ''
                    return <span className={customClass}>{tableData.data[tableData.row.index].experience_level.text}</span>
                },
            },
            {
                Header: 'Country',
                accessor: 'country',
                Cell: (tableData) => {
                    let customClass = tableData.data[tableData.row.index].country_tier === 1 ? 'text-success font-weight-bold' : ''
                    return <span className={customClass}>{tableData.data[tableData.row.index].country}</span>
                },
            },
            {
                Header: 'Local/Best Time',
                accessor: 'contact_time',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'App Date',
                accessor: 'status_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY/MM/DD')}</>
                },
            },
            {
                Header: 'Last Followup',
                accessor: 'last_followup.followup_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? moment(value).utc().format('YYYY/MM/DD') : ''}</>
                },
            },
            {
                Header: () => <div className="text-center">Followups</div>,
                accessor: 'followup_count',
                className: 'text-center',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable
                    data={data.applications}
                    columns={columns}
                    getRowProps={(row) => {
                        if (row.original.experience_level.value === 3 || (row.original.experience_level.value === 2 && row.original.country_tier === 1)) {
                            return {
                                className: 'bgSuccess25',
                            }
                        }
                    }}
                />
            </React.Fragment>
        )
    }

    const refreshReport = (e) => {
        e.preventDefault()

        let url = buildUrl(`/affiliates/applications?z=1`)
        history.push(url)
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Reports</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="d-flex p-3">
                        <div className="mr-4 d-flex align-items-center">
                            <h1 className="mb-h1-dark">Affiliate Applications for:</h1>
                        </div>
                        <div className="mr-4">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                        </div>
                        <div className="d-flex align-items-center">
                            <button onClick={refreshReport} className="btn btn-sm btn-primary">
                                Refresh Report
                            </button>
                        </div>
                    </div>
                    <Modal isOpen={showApplicationModal} toggle={toggleModal} className="modal-lg">
                        <ReviewApplication showSystemMessage={showSystemMessage} affiliateId={affiliateId} handleReviewClose={handleClose} />
                    </Modal>
                    {dataLoaded ? data.applications.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
                </div>
            </div>
        </div>
    )
}

export default Applications
