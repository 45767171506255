import { Button, Form, Modal, Table } from 'react-bootstrap'
import Loading from '../../../../../templates/UI/Loading/Loading'
import { useState } from 'react'
import callAPI from '../../../../../helpers/callAPI'

const ScreenshotsModal = ({ isOpen, setIsOpen, dataLoaded, selectedLP, campaignData, showSystemMessage, campaignId, landingPages }) => {
    const [fullSize, setFullSize] = useState([])
    const [thumbnail, setThumbnail] = useState([])

    return (
        <Modal
            show={isOpen}
            size="lg"
            onHide={() => {
                setIsOpen(false)
            }}
        >
            {campaignData && dataLoaded && landingPages.length > 0 ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Load Landing Page screenshots for: {campaignData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Landing page Name: {selectedLP.lp_name}
                        <br />
                        Landing page URL: {selectedLP.lp_landing_url}
                        <br />
                        <Table striped>
                            <tbody>
                                <tr>
                                    <td>Full Size Screenshot: </td>
                                    <td>
                                        <Form.Control type="file" onChange={(e) => setFullSize(e.target.files[0])} />
                                    </td>
                                    <td>
                                        <img style={{ maxHeight: '250px' }} src={`${process.env.REACT_APP_API_URL}/image/screenshot/${campaignData.advertiser.id}/${campaignId}/${selectedLP.lp_record_id}`} alt="Screenshot" crossOrigin={process.env.REACT_APP_PORTAL_URL} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>600x340 thumbnail: </td>
                                    <td>
                                        <Form.Control type="file" onChange={(e) => setThumbnail(e.target.files[0])} />
                                    </td>
                                    <td>
                                        <img style={{ maxHeight: '250px' }} src={`${process.env.REACT_APP_API_URL}/image/screenshot/${campaignData.advertiser.id}/${campaignId}/${selectedLP.lp_record_id}?size=thumb`} alt="Screenshot" crossOrigin={process.env.REACT_APP_PORTAL_URL} />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={async () => {
                                let formData = new FormData()
                                formData.append('full_size', fullSize)
                                formData.append('thumbnail', thumbnail)
                                let response = await callAPI(`campaign/${campaignId}/screenshots/${selectedLP.lp_record_id}`, 'POST', formData, true)

                                if (response.success) {
                                    showSystemMessage('success', 'Landing pages uploaded.')
                                    setIsOpen(false)
                                } else {
                                    showSystemMessage('error', `There was a problem uploading screenshots: ${response.errors.message}`)
                                }
                            }}
                        >
                            Upload
                        </Button>
                    </Modal.Footer>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    )
}

export default ScreenshotsModal
