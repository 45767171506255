import { Card, Form, Button } from 'react-bootstrap'
import EmployeePicker from '../../../components/EmployeePicker/EmployeePicker'
import { useState } from 'react'
import { useAuth } from '../../../context/AuthContext'
import callAPI from '../../../helpers/callAPI'

const BatchTransferAffiliates = ({ showSystemMessage }) => {

    document.title = 'Batch Transfer Affiliates'

    const auth = useAuth()
    const [oldManager, setOldManager] = useState({ id: 0, name: '' })
    const [newManager, setNewManager] = useState({ id: 0, name: '' })
    const [reason, setReason] = useState('')
    const [manager, setManager] = useState(auth.user)

    const batchTransfer = async () => {
        const response = await callAPI('affiliates/batchTransfer', 'PUT', { oldManager, newManager, reason, manager })
        if (response.success) {
            showSystemMessage('success', 'Affiliates have been transferred successfully')
        } else {
            showSystemMessage('error', 'There was an error transferring the affiliates. Please try again.')
        }
    }

    return (
        <div className="row">
            <div className="col-md-7">
                <Card>
                    <Card.Header>
                        <div className="mb-h1-dark">Batch Transfer Affiliates</div>
                    </Card.Header>
                    <Card.Body>
                        <div className="alert alert-warning">
                            <i>Be very careful when using this option!!</i>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="mb-0" style={{ width: '10rem' }}>
                                Old Affiliate Manager:
                            </p>
                            <EmployeePicker employee={oldManager} setEmployee={setOldManager} role={['AM_JUNIOR', 'AM_SENIOR']} />
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="mb-0" style={{ width: '10rem' }}>
                                New Affiliate Manager:
                            </p>
                            <EmployeePicker employee={newManager} setEmployee={setNewManager} role={['AM_JUNIOR', 'AM_SENIOR']} />
                        </div>
                        <div className="d-flex align-items-center">
                            <p className="mb-0" style={{ width: '10rem' }}>
                                Reason For Transfer:
                            </p>
                            <Form.Control size="sm" as="select" className="w-50 ml-0" value={reason} onChange={(e) => setReason(e.target.value)}>
                                <option value="">---Select Reason for Transfer---</option>
                                <option value="Medium/High">Transfer from Jr. AM to Senior AM</option>
                                <option value="Staffing_Change">AM is no longer with company</option>
                                <option value="Aff_Request">Affiliate requested new AM</option>
                                <option value="Mgmt_Decision">Management Decision</option>
                            </Form.Control>
                        </div>
                        <b>A change of manager will update the followup Notes on the affiliate record</b>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-end">
                        <Button variant="primary" onClick={batchTransfer}>
                            Transfer
                        </Button>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default BatchTransferAffiliates
