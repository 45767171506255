import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import callAPI from '../../helpers/callAPI'
import AffiliateSearch from '../AffiliateSearch/AffiliateSearch'
import CampaignSearch from '../CampaignSearch/CampaignSearch'

const PullAffiliateModal = ({ data, setData, showSystemMessage }) => {
    let [affiliate, setAffiliate] = useState(data.affiliate)
    let [campaign, setCampaign] = useState(data.campaign)
    let [scheduledJob, setScheduledJob] = useState(false)

    useEffect(async () => {
        // Check to see if there is already a scheduled job to pull this affiliate, campaign, advertiser combo
        if (data.open) {
            // let affiliateResult = await callAPI(`affiliate/${data.affiliate.id}`, 'GET', {})
            // let campaignResult = await callAPI(`campaign/${data.campaign.id}`, 'GET', {})

            setAffiliate(data.affiliate)
            setCampaign(data.campaign)

            let result = await callAPI(`admin/jobs?startDate=1900-01-01&type[]=MERPULL&type[]=PUBPULL&affiliateId=${data.affiliate.id}&campaignId=${data.campaign.id}`, 'GET', {})

            if (result.success) {
                if (result.jobs && result.jobs.length > 0) {
                    setScheduledJob({
                        job_id: result.jobs[0].job_id,
                        job_date: result.jobs[0].job_date,
                        job_name: result.jobs[0].job_name,
                        param1: result.jobs[0].param1,
                        param2: result.jobs[0].param2,
                        param3: result.jobs[0].param3,
                        param4: result.jobs[0].param4,
                        job_status: result.jobs[0].job_status,
                        affiliate_id: result.jobs[0].affiliate_id,
                        affiliate_name: result.jobs[0].affiliate_name,
                        offer_id: result.jobs[0].offer_id,
                        offer_name: result.jobs[0].offer_name,
                        status: result.jobs[0].status,
                        verified_flag: result.jobs[0].verified_flag,
                        incentive_flag: result.jobs[0].incentive_flag,
                        aff_priority: result.jobs[0].aff_priority,
                    })
                }
            }
        }
    }, [data])

    const handleSubmit = async (e) => {
        e.preventDefault()

        let postBody = {
            affiliate_id: affiliate.id,
            campaign_id: campaign.id,
            date: e.target.date.value,
            reason: e.target.reason.value,
            pull_advertiser: e.target.pull_advertiser.checked,
            notes: e.target.notes.value,
        }

        callAPI(`admin/affiliatePull/create`, 'POST', postBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', 'Affiliate pulled successfully.')
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while pulling affiliate: ${error}`)
                showSystemMessage('error', 'An error occurred while pulling affiliate.')
            })
    }

    return (
        <Modal show={data.open} onHide={() => setData({ ...data, open: false })}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <div className="mb-h1-dark">Pull Affiliate</div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="w-100">
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Affiliate:</div>
                                <div className="w-100">
                                    <AffiliateSearch affiliate={affiliate} setAffiliate={setAffiliate} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Campaign:</div>
                                <div className="w-100">
                                    <CampaignSearch campaign={campaign} setCampaign={setCampaign} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Scheduled Date:</div>
                                <input required className="form-control" type="datetime-local" name="date"></input>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="w-25 text-right pr-2">Pull from Advertiser:</div>
                                <div className="w-100 text-left">
                                    <input type="checkbox" name="pull_advertiser"></input>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div required className="w-25 text-right pr-2">
                                    Reason:
                                </div>
                                <select className="form-control" name="reason">
                                    <option value={''}>Select Reason</option>
                                    <option value={'LOW_QUALITY'}>Low Quality</option>
                                    <option value={'BUDGET-CAP'}>Budget / Cap</option>
                                    <option value={'FRAUD'}>Fraud</option>
                                </select>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div required className="w-25 text-right pr-2">
                                    Notes:
                                </div>
                                <textarea className="form-control" name="notes"></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit">
                        Schedule Pull
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default PullAffiliateModal
