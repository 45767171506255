import useCallApi from '../../../../hooks/useCallApi'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Loading from '../../../../templates/UI/Loading/Loading'

const PendingFirstPayment = () => {
    const { dataLoaded, data } = useCallApi(`compliance/pendingFirstPayment`, 'GET', {}, [])

    return (
        <div className="card mb-3">
            <div className="card-header justify-content-between d-flex">
                <div>Pending First Payment</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped table-hover m-0">
                    <thead className="text-secondary">
                        <tr>
                            <th>ID</th>
                            <th>Affiliate</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataLoaded ? (
                            data.affiliates.length === 0 ? (
                                <tr>
                                    <td colSpan="3">No data.</td>
                                </tr>
                            ) : (
                                data.affiliates.slice(0, 10).map((item) => {
                                    return (
                                        <tr key={item.affiliate.id}>
                                            <td>{item.affiliate.id}</td>
                                            <td>
                                                <Affiliate aid={item.affiliate.id} name={item.affiliate.name} status={item.affiliate.status} />
                                            </td>
                                            <td>{item.followup_note}</td>
                                        </tr>
                                    )
                                })
                            )
                        ) : (
                            <tr>
                                <td colSpan={3}>
                                    <Loading />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PendingFirstPayment
