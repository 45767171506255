import linkifyHtml from 'linkify-html'
import { Link } from 'react-router-dom'

const Restrictions = ({ trafficTypes, restrictions, countries, ipFilteringFlag, trackingType, redirects }) => {
    let countryArray = countries.map((country) => country.country_name)

    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Restrictions</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-card table-striped table-hover m-0">
                    <tbody className="m-0">
                        <tr>
                            <td className="border-top-0">Reversal Criteria</td>
                            <td className="border-top-0 w-75">{restrictions.reversal_criteria}</td>
                        </tr>
                        {restrictions.expired_redirect_id !== 0 && (
                            <tr>
                                <td>Cancelled / Hit Cap Redirect</td>
                                <td>
                                    <Link to={`/campaign/${redirects.expired_redirect_id}`}>{redirects.expired_redirect_name}</Link> ({redirects.expired_redirect_id})
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>Daily cap per affiliate</td>
                            <td>{restrictions.default_daily_affiliate_cap === -1 ? 'Unlimited' : restrictions.default_daily_affiliate_cap}</td>
                        </tr>
                        <tr>
                            <td>Countries</td>
                            <td>{ipFilteringFlag ? countryArray.join(', ') : 'All countries allowed'}</td>
                        </tr>
                        {trackingType === 'C' && (
                            <tr>
                                <td>Web Enabled</td>
                                <td>{restrictions}</td>
                            </tr>
                        )}
                        {trafficTypes.allow_solo_emails_flag && (
                            <tr>
                                <td>Email Usage Rules</td>
                                <td>
                                    <span dangerouslySetInnerHTML={{ __html: linkifyHtml(restrictions.email_usage_rules) }} />
                                </td>
                            </tr>
                        )}
                        {trafficTypes.search_flag && (
                            <tr>
                                <td>Search Restrictions</td>
                                <td>
                                    <span dangerouslySetInnerHTML={{ __html: linkifyHtml(restrictions.search_restrictions) }} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Restrictions
