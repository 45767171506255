import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import getAffiliateStatus from '../../../helpers/getAffiliateStatus'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Loading from '../../../templates/UI/Loading/Loading'

const PromotionalInformation = ({ data, dataLoaded }) => {
    let formattedURL = data.info.url.substring(0, 4) === 'http' ? data.info.url : data.info.url.substring(0, 5) === 'https' ? data.info.url : `https://${data.info.url}`

    return (
        <Card className="mb-4">
            <Card.Header>
                <div>Site/Promotional Information</div>
            </Card.Header>
            {dataLoaded ? (
                <>
                    {data.success && data.info.matchingUrls.length > 0 ? (
                        <>
                            <div className="d-flex justify-content-start">
                                <div className="mr-2 font-weight-bold">Site URL: </div>
                                <div>
                                    <a className="mr-2" href={formattedURL} target="_blank">
                                        {formattedURL}
                                    </a>
                                    <a target="_blank" href={`http://www.whois.sc/${data.info.url}`}>
                                        WHOIS
                                    </a>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start">
                                <div className="mr-2 font-weight-bold">Incentives: </div>
                                <div>No</div>
                            </div>
                            <div className="d-flex justify-content-start">
                                <div className="mr-2 font-weight-bold">Promotional Information: </div>
                                <div>{data.info.site_description}</div>
                            </div>
                        </>
                    ) : (
                        <div colSpan="4">No data found</div>
                    )}
                </>
            ) : (
                <Loading />
            )}
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Affiliate ID</th>
                        <th>Affiliate</th>
                        <th>Status</th>
                        <th>Contact</th>
                    </tr>
                </thead>
                {dataLoaded ? (
                    <>
                        {data.success || data.info.matchingUrls.length > 0 ? (
                            <tbody>
                                {data.info.matchingUrls.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.affiliate_id}</td>
                                            <td>
                                                <Affiliate aid={item.affiliate_id} name={item.name} status={item.aff_status_value} priority={item.aff_priority_value} />
                                            </td>
                                            <td>{item.aff_status}</td>
                                            <td>{item.contact}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan="4">No data found</td>
                                </tr>
                            </tbody>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}

export default PromotionalInformation
