import React, { useState, useEffect } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

export default function AdvertiserDropdown() {
    const auth = useAuth()
    const showBalanceReport = useProtectedContent(auth.permissions, 'advertiser_balance_report')
    const showInvoices = useProtectedContent(auth.permissions, 'advertiser_invoices')
    const showListAdvertisers = useProtectedContent(auth.permissions, 'advertisers_list')
    const showAdvertiserApplications = useProtectedContent(auth.permissions, 'advertiser_applications')
    const showAdvertiserPlatforms = useProtectedContent(auth.permissions, 'advertiser_platforms')
    const showReversalReport = useProtectedContent(auth.permissions, 'advertiser_reversal_report')
    const showReversalStatusReport = useProtectedContent(auth.permissions, 'advertiser_reversal_status')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle">
                Advertisers
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                <Link className="dropdown-item" to="/advertisers/search" onClick={toggle}>
                    Search Advertisers
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/advertisers/mailer" onClick={toggle}>
                    Advertiser Mailer
                </Link>

                <DropdownItem divider className="m-0 dropdown-separator" />

                <Link className="dropdown-item" to="/reports/stats/advertisers" onClick={toggle}>
                    Advertiser Earnings
                </Link>

                {showListAdvertisers && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers" onClick={toggle}>
                            List Advertisers
                        </Link>
                    </>
                )}
                {showAdvertiserApplications && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/applications" onClick={toggle}>
                            Advertiser Applications
                        </Link>
                    </>
                )}
                {showBalanceReport && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/balances" onClick={toggle}>
                            Balance Report
                        </Link>
                    </>
                )}
                {showInvoices && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/invoices/report" onClick={toggle}>
                            Invoice Report
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/invoices/list" onClick={toggle}>
                            Invoice To Do List
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/missingInvoiceInfo" onClick={toggle}>
                            Missing Invoice Info
                        </Link>
                    </>
                )}
                {showAdvertiserPlatforms && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/platforms" onClick={toggle}>
                            Tracking Platforms
                        </Link>
                    </>
                )}

                {showReversalStatusReport && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/advertisers/reversalStatusReport" onClick={toggle}>
                            Reversal Status Report
                        </Link>
                    </>
                )}

                {showReversalReport && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/reports/reversals/advertisers" onClick={toggle}>
                            Advertiser Reversal Report
                        </Link>
                    </>
                )}
            </DropdownMenu>
        </Dropdown>
    )
}
