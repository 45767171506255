import React, { useState, useEffect } from 'react'

import Loading from '../../templates/UI/Loading/Loading'
import AffiliateNavbar from './Templates/AffiliateNavbar/AffiliateNavbar'
import AffiliateInfo from './AffiliateInfo/AffiliateInfo'
import EarningSummary from '../../components/EarningSummary/EarningSummary'
import AccountNotes from './AccountNotes/AccountNotes'
import Flags from '../../components/Flags/Flags'
import TrafficNotes from './TrafficNotes/TrafficNotes'
import TargetCountries from './TargetCountries/TargetCountries'
import ClickReferrersSummary from './ClickReferrersSummary/ClickReferrersSummary'
import OpenCampaignPromises from './OpenCampaignPromises/OpenCampaignPromises'
import CampaignPitches from './CampaignPitches/CampaignPitches'
import TopTrafficTypes from './TopTrafficTypes/TopTrafficTypes'
import RecentActivity from './RecentActivity/RecentActivity'
import Followups from '../../components/Followups/Followups'

import './Affiliate.css'
import useCallApi from '../../hooks/useCallApi'
import FollowupList from '../../components/FollowupList/FollowupList'

const Affiliate = ({ affiliateId, showSystemMessage }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { data, dataLoaded, refetch } = useCallApi(`affiliate/${affiliateId}/summary`, 'GET', {}, [affiliateId])

    useEffect(() => {
        if (dataLoaded) {
            document.title = data.affiliate_details.name
        } else {
            document.title = 'Affiliate'
        }
    }, [data, dataLoaded])

    return (
        <React.Fragment>
            <FollowupList affiliateId={affiliateId} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Manage Affiliate</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            {dataLoaded && data.affiliate_details ? (
                <React.Fragment>
                    <div className="row Affiliate">
                        <div className="col-xl-4">
                            <AffiliateInfo affiliateInfo={data.affiliate_details} showSystemMessage={showSystemMessage} setIsOpen={setIsOpen} />
                            <OpenCampaignPromises showSystemMessage={showSystemMessage} affiliateId={affiliateId} />
                            <CampaignPitches affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                            <AccountNotes notes={data.affiliate_details.extra_info} affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                            <TrafficNotes trafficNotes={data.affiliate_details.traffic_type_notes} affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                        </div>
                        <div className="col-xl-4 pb-0">
                            <EarningSummary showSystemMessage={showSystemMessage} affiliateId={affiliateId} target="affiliate" />
                            <ClickReferrersSummary showSystemMessage={showSystemMessage} affiliateId={affiliateId} />
                            <TopTrafficTypes />
                            <RecentActivity affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                            <Flags flags={data.flags} showSystemMessage={showSystemMessage} affiliateId={affiliateId} refetch={refetch} />
                        </div>
                        <div className="col-xl-4">
                            <Followups id={data.affiliate_details.affiliate_id} showSystemMessage={showSystemMessage} managerName={data.affiliate_details.manager_name} managerId={data.affiliate_details.manager_id} type="affiliate" setIsOpen={setIsOpen} />
                            <TargetCountries targetCountries={data.target_countries} />
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <Loading></Loading>
            )}
        </React.Fragment>
    )
}

export default Affiliate
