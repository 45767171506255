import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import callAPI from '../../helpers/callAPI'
import AffiliateNavbar from '../../pages/Affiliate/Templates/AffiliateNavbar/AffiliateNavbar'
import CampaignNavbar from '../../pages/Campaign/CampaignNavBar/CampaignNavBar'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Campaign from '../../templates/Campaign/Campaign'
import ReportTable from '../../templates/ReportTable/ReportTable'
import Loading from '../../templates/UI/Loading/Loading'
import AddDailyCap from './AddDailyCap/AddDailyCap'

const DailyCampaignCaps = ({ affiliateId, campaignId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliate, setAffiliate] = useState()
    const [campaign, setCampaign] = useState()
    const [caps, setCaps] = useState([])
    const [showAddCapModal, setShowAddCapModal] = useState(false)
    const [capAmount, setCapAmount] = useState(0)
    const [itemId, setItemId] = useState(0)

    const spinner = <FontAwesomeIcon icon="spinner" spin />    

    useEffect(() => {
        getReport()
    }, [affiliateId ? affiliateId : campaignId])

    const getReport = () => {
        setDataLoaded(false)

        let url = affiliateId ? `affiliate/${affiliateId}/campaignCaps?t=a` : `campaign/${campaignId}/campaignCaps`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    affiliateId ? setAffiliate(result.affiliate) : setCampaign(result.campaign)
                    document.title = `${affiliateId ? result.affiliate.name : result.campaign.name}: Daily Caps`
                    setCaps(result.list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    document.title = 'Daily Caps'
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching campaign caps: ${error}`)
                showSystemMessage('error', 'An error occurred while fetching campaign caps')
            })
    }

    const handleClose = (isEdited = false) => {
        setShowAddCapModal(false)

        if (isEdited) {
            getReport()
        }
    }

    const saveAction = (inputItemId, e) => {
        e.preventDefault()

        if (inputItemId === itemId) {
            const putBody = {
                item_id: itemId,
                cap_amount: capAmount,
            }

            let url = affiliateId ? `affiliate/${affiliateId}/campaignCaps` : `campaign/${campaignId}/campaignCaps`

            callAPI(url, 'PUT', putBody)
                .then((result) => {
                    if (result['success'] === true) {
                        let tempList = caps
                        let index = tempList.findIndex((item) => item.item_id === itemId)
                        tempList[index].cap_amount = capAmount

                        setCaps(tempList)
                        showSystemMessage('success', result.message)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                    }
                })
                .catch((error) => {
                    console.log(`Error while updating campaign approvals: ${error}`)
                    showSystemMessage('error', 'An error occurred while updating campaign approvals')
                })
        }
    }

    const handleInputChange = (itemId, e) => {
        // e.preventDefault();
        setItemId(itemId)
        setCapAmount(e.target.value)
    }

    const deleteAction = (itemId) => {
        const deleteBody = {
            item_id: itemId,
        }

        let url = affiliateId ? `affiliate/${affiliateId}/campaignCaps` : `campaign/${campaignId}/campaignCaps`

        callAPI(url, 'DELETE', deleteBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    let tempList = caps.filter((item) => item.item_id !== itemId)

                    setCaps(tempList)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(`Error while deleting campaign approvals: ${error}`)
                showSystemMessage('error', 'An error occurred while deleting campaign approvals')
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'ID',
                accessor: 'item_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Campaign' : 'Affiliate',
                accessor: 'name',
                Cell: (tableData) => {
                    return affiliateId ? <Campaign oid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].item_status} /> : <Affiliate aid={tableData.data[tableData.row.index].item_id} name={tableData.data[tableData.row.index].name} status={tableData.data[tableData.row.index].item_status} priority={tableData.data[tableData.row.index].priority} />
                },
            },
            {
                Header: affiliateId ? 'Campaign Status' : 'Affiliate Status',
                accessor: 'item_status',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: affiliateId ? 'Advertiser Manager' : 'Affiliate Manager',
                accessor: 'full_name',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Daily Cap',
                accessor: 'cap_amount',
                Cell: (tableData) => {
                    return (
                        <form>
                            <input value={tableData.data[tableData.row.index].item_id === itemId ? capAmount : tableData.data[tableData.row.index].cap_amount} className="form-control w-50" onChange={(e) => handleInputChange(tableData.data[tableData.row.index].item_id, e)} autoFocus={tableData.data[tableData.row.index].item_id === itemId ? true : false} />
                        </form>
                    )
                },
            },
            {
                Header: 'Leads Today',
                accessor: 'leads_today',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: '',
                accessor: 'offers',
                Cell: (tableData) => {
                    return (
                        <form onSubmit={(e) => saveAction(tableData.data[tableData.row.index].item_id, e)} className="d-flex align-items-center">
                            <input type="hidden" name="cap_amount" defaultValue={capAmount} />
                            <button type="submit" className="btn btn-primary" name="action">
                                Save
                            </button>{' '}
                            &nbsp; &nbsp; &nbsp;
                            <button type="button" className="btn btn-danger" onClick={() => deleteAction(tableData.data[tableData.row.index].item_id)}>
                                Delete
                            </button>
                        </form>
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={caps} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            {showAddCapModal && <AddDailyCap affiliate={affiliate ? affiliate : null} campaign={campaign ? campaign : null} showSystemMessage={showSystemMessage} handleClose={handleClose} showAddCapModal={showAddCapModal} />}

            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Daily Campaign Caps</h1>
                <div className="ml-5">{affiliateId ? <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} /> : <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>
            <div className="card">
                <div className="d-flex justify-content-between p-3 align-items-center">
                    <h1 className="mb-h1-dark mr-2">{dataLoaded ? affiliateId ? <Affiliate aid={affiliateId} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} /> : <Campaign oid={campaignId} name={campaign.name} status={campaign.status.value} /> : spinner}</h1>
                    <button type="button" className="btn btn-warning" onClick={() => setShowAddCapModal(true)}>
                        Add Daily Cap
                    </button>
                </div>
                {dataLoaded ? caps.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading />}
            </div>
        </div>
    )
}

export default DailyCampaignCaps
