import { Button, Card, Table } from 'react-bootstrap'
import RatesModal from './RatesModal'
import { useState } from 'react'
import GeneratePixelModal from './GeneratePixelModal'
import { Link } from 'react-router-dom'
import styles from './Rates.module.scss'

const Rates = ({ showSystemMessage, campaignId, campaign, rates, refetch, isMobileOffer, events, campaignData }) => {
    const [data, setData] = useState(rates)
    const [RmIsOpen, RmSetIsOpen] = useState(false)
    const [GpIsOpen, GpSetIsOpen] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    // const [selectedRate, setSelectedRate] = useState({ affiliate_rate: '', conversion_type: '', default_flag: '', description: '', has_trigger: '', is_recurring: '', network_rate: '', rate_name: '', rate_warning: 0, special_rate_count: 0 })

    const emptyRate = { is_recurring: false, rate_name: '', default_flag: false, affiliate_rate: '', network_rate: '', affiliate_rate_text: '', network_rate_text: '', description: '', conversion_type_id: '', display_rate: '', display_rate_type: '', hide_sale_amount: false }

    const [selectedRate, setSelectedRate] = useState(emptyRate)

    return (
        <Card className="mb-4">
            {RmIsOpen && <RatesModal isMobileOffer={isMobileOffer} showSystemMessage={showSystemMessage} campaign={campaign} isOpen={RmIsOpen} setIsOpen={RmSetIsOpen} selectedRate={selectedRate} isCreate={isCreate} setIsCreate={setIsCreate} campaignId={campaignId} refetch={refetch} events={events} campaignData={campaignData} />}
            {GpIsOpen && <GeneratePixelModal campaignId={campaignId} campaign={campaign} isOpen={GpIsOpen} setIsOpen={GpSetIsOpen} />}
            <Card.Header className="d-flex justify-content-between">
                <div>Rates</div>
                <div>
                    <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                            setIsCreate(true)
                            setSelectedRate(emptyRate)
                            RmSetIsOpen(true)
                        }}
                    >
                        Add Rate
                    </Button>
                    <Button variant="warning" size="sm" className="ml-2" onClick={() => GpSetIsOpen(true)}>
                        Generate Postbacks
                    </Button>
                    <Link to={`/campaign/${campaignId}/serverPostbackCalls`}>
                        <Button variant="warning" size="sm" className="ml-2">
                            View Test Postbacks
                        </Button>
                    </Link>
                </div>
            </Card.Header>
            <Table striped bordered hover>
                <>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Aff Rate</th>
                            <th>Net Rate</th>
                            <th>Display</th>
                            <th>Default</th>
                            <th>Description</th>
                            <th style={{ texthecoration: 'line-through' }}>Sale</th>
                            <th>Type</th>
                            {isMobileOffer && <th>Recurring</th>}
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((rate, index) => (
                            <tr key={index} className={rate.status !== 'A' ? styles.bgDanger25 : null}>
                                <td>{rate.rate_name}</td>
                                <td>{rate.affiliate_rate_text}</td>
                                <td>{rate.network_rate_text}</td>
                                <td>{rate.display_rate_text}</td>
                                <td>{rate.default_flag ? 'Yes' : ''}</td>
                                <td>{rate.description}</td>
                                <td>{rate.hide_sale_amount ? 'Yes' : ''}</td>
                                <td>{rate.conversion_type}</td>
                                {isMobileOffer && <td>{rate.is_recurring ? 'Yes' : 'No'}</td>}
                                <td>
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        onClick={() => {
                                            setSelectedRate(rate)
                                            setIsCreate(false)
                                            RmSetIsOpen(true)
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </>
                {/* ) : (
                    <Loading />
                )} */}
            </Table>
        </Card>
    )
}

export default Rates
