import moment from 'moment'
import { useEffect, useState } from 'react'

import callAPI from '../../../helpers/callAPI'
import getSourceQuestions from '../../../helpers/getSourceQuestions'
import getTrafficType from '../../../helpers/getTrafficTypes'
import Loading from '../../../templates/UI/Loading/Loading'
import Pill from '../../../templates/UI/Pill/Pill'

const RequestDetails = ({ requestId, showSystemMessage, handleClose }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [requestDetails, setRequestDetails] = useState({})

    useEffect(() => {
        let url = `affiliate/campaignRequests/${requestId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    setRequestDetails(result.list)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => showSystemMessage('error', 'Error while fetching request details'))
    }, [requestId])

    const getQuestions = () => {
        return getSourceQuestions(requestDetails.traffic_type)
    }

    const saveRequest = (e) => {
        e.preventDefault()

        let url = `affiliate/campaignRequests/${requestId}`

        let putBody = {
            affiliate_id: requestDetails.affiliate_id,
            offer_id: requestDetails.offer_id,
            results: e.target.results.value,
            notes: e.target.notes.value,
        }

        callAPI(url, 'PUT', putBody)
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while saving campaign request')
            })
    }

    const deleteRequest = (e) => {
        e.preventDefault()

        let url = `affiliate/campaignRequests/${requestId}`

        callAPI(url, 'DELETE', {})
            .then((result) => {
                if (result['success'] === true) {
                    showSystemMessage('success', result.message)
                    handleClose(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                }
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while saving campaign request')
            })
    }

    return (
        <div className="card">
            <div className="float-left border-bottom pb-2 card-header align-items-center">
                <div className="float-left">Request Details</div>
                <div className="float-right text-secondary "> {moment(requestDetails.request_date).format('YYYY-MM-DD')}</div>
            </div>

            <div className="card-body p-0">
                {dataLoaded ? (
                    <div>
                        <div className="d-flex p-2 border-bottom">
                            <div className="align-items-center d-flex">
                                <span className="text-secondary mr-2">{requestDetails.traffic_type === 'C' ? 'Qualified Calls/day' : 'Leads/day'}</span>
                                <span>{requestDetails.estimated_leads}</span>
                            </div>{' '}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div className="d-flex align-items-center">
                                <span className="d-flex text-secondary mr-2">Traffic Type</span>
                                <span>
                                    <Pill text={getTrafficType(requestDetails.traffic_type)} />
                                </span>
                            </div>
                        </div>
                        <form onSubmit={saveRequest}>
                            <table className="table table-striped table-borderless m-0">
                                <tbody style={{ lineHeight: '20px' }}>
                                    <tr>
                                        <td>
                                            <span className="d-block text-secondary">{getQuestions().question1}</span>
                                            <span>{requestDetails.source_answer_1}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="d-block text-secondary">{getQuestions().question2}</span>
                                            <span>{requestDetails.source_answer_2}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span className="d-block text-secondary">{getQuestions().question3}</span>
                                            <span>{requestDetails.source_answer_3 ? requestDetails.source_answer_3 : 'Left blank'}</span>
                                        </td>
                                    </tr>
                                    <tr className="d-block">
                                        <td>
                                            <span className="d-block text-secondary">Creatives to be used:</span>
                                            <span>{requestDetails.custom_creatives_flag === 'E' ? 'Existing creatives' : requestDetails.custom_creatives_flag === 'M' ? 'I will make my own' : ''}</span>
                                        </td>
                                    </tr>
                                    {requestDetails.custom_creatives_flag === 'M' && (
                                        <tr>
                                            <td>
                                                <img src={`${process.env.REACT_APP_RESOURCE_URI}/images/headshots/joef.jpg`} alt="creative" style={{ maxWidth: '150px', border: '2px solid' }} />
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <span className="d-block text-secondary">Additional Info:</span>
                                            <span>{requestDetails.description}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {(requestDetails.status === 'O' || requestDetails.status === 'N') && (
                                                <span>
                                                    <label className="d-flex">
                                                        <input type="radio" name="results" value="F" defaultChecked />
                                                        &nbsp;Forward: send to advertiser manager for review
                                                    </label>
                                                </span>
                                            )}

                                            {(requestDetails.status === 'O' || requestDetails.status === 'V') && (
                                                <span>
                                                    <label className="d-flex">
                                                        <input type="radio" name="results" value="N" />
                                                        &nbsp;Wait: need more information from affiliate
                                                    </label>
                                                </span>
                                            )}
                                            {(requestDetails.status === 'O' || requestDetails.status === 'V' || requestDetails.status === 'N') && (
                                                <span>
                                                    <label className="d-flex">
                                                        <input type="radio" name="results" value="W" />
                                                        &nbsp;Wait: need to get approval from merchant
                                                    </label>
                                                </span>
                                            )}

                                            {(requestDetails.status === 'O' || requestDetails.status === 'V' || requestDetails.status === 'W' || requestDetails.status === 'N') && (
                                                <span>
                                                    <label className="d-flex">
                                                        <input type="radio" name="results" value="A" />
                                                        &nbsp;Approve: allow affiliate to run campaign
                                                    </label>
                                                </span>
                                            )}

                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="I" />
                                                    &nbsp;Deny: insufficient info was provided
                                                </label>
                                            </span>

                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="G" />
                                                    &nbsp;Deny: gibberish answers were given
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="C" />
                                                    &nbsp;Deny: no cap is available to assign
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="M" />
                                                    &nbsp;Deny: promotional method is not allowed
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="H" />
                                                    &nbsp;Deny: more affiliate history is required
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="P" />
                                                    &nbsp;Deny: too many pulls on other campaigns
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="O" />
                                                    &nbsp;Deny: affiliate is not suitable for campaign
                                                </label>
                                            </span>
                                            <span>
                                                <label className="d-flex">
                                                    <input type="radio" name="results" value="R" />
                                                    &nbsp;Deny: see affiliate manager for more details
                                                </label>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Notes to sent to affiliate (included in e-mail):
                                            <textarea name="notes" rows={4} cols={40} className="form-control mt-2" placeholder="Add notes..." />
                                        </td>
                                    </tr>
                                    <tr className="float-right py-2">
                                        <td>
                                            {RequestDetails.status !== 'X' && (
                                                <button className="btn btn-danger" type="button" onClick={deleteRequest}>
                                                    Delete
                                                </button>
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            <button className="btn btn-primary" type="submit">
                                                Save
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default RequestDetails

// affiliate_id: 606420
// custom_answer_1: ""
// custom_answer_2: ""
// custom_answer_3: ""
// custom_creatives_flag: "E"
// description: "i will run this offer on taboola native ads network its paid native ads and quality traffic"
// estimated_leads: 100
// offer_id: 21827
// request_date: "2022-08-03T15:59:08.713Z"
// source_answer_1: "Taboola"
// source_answer_2: "Display"
// source_answer_3: ""
// status: "Open"
// traffic_type: "N"
