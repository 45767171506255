import React, { useState, useEffect } from 'react'
import moment from 'moment'
import useQueryParams from '../../../../hooks/useQueryParams'
import useDateRangeFilter from '../../../../hooks/useDateRangeFilter'
import { Modal } from 'reactstrap'

import ReportTable from '../../../../templates/ReportTable/ReportTable'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import { Link } from 'react-router-dom'
import Campaign from '../../../../templates/Campaign/Campaign'
import Affiliate from '../../../../templates/Affiliate/Affiliate'
import Dollars from '../../../../templates/Dollars/Dollars'
import IpDetailsContent from '../../../Affiliate/AffiliateLoginHistory/IpDetails/IpDetailsContent'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

export default function ConversionReport({ showSystemMessage, setAffiliate, setCampaign, setAdvertiser, setCSVFilename, setCSVHeaderLabels, setCSVDataLabels, setCSVReport }) {
    const auth = useAuth()
    const showReversal = useProtectedContent(auth.permissions, 'conversion_report_reversal')

    const { initStartDate, initEndDate } = useDateRangeFilter()
    const { buildUrl } = useQueryParams()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const [showIpDetailsModal, setShowIpDetailsModal] = useState(false)
    const [modalIp, setModalIp] = useState('')

    const [selectedLeads, setSelectedLeads] = useState([])

    useEffect(() => {
        setCSVHeaderLabels(['Lead ID', 'Click Id', 'Affiliate ID', 'Affiliate', 'Campaign ID', 'Campaign', 'Status', 'Subid1', 'Subid2', 'Subid3', 'Subid4', 'Subid5', 'Sales', 'Aff Rate', 'Net Rate', 'Click IP', 'Lead IP', 'Proxy', 'Date', 'Payment Date', 'Reverse Date'])
        setCSVDataLabels(['lead_id', 'click_action_id', 'affiliate_id', 'affiliate_name', 'campaign_id', 'campaign_name', 'status', 'subid1', 'subid2', 'subid3', 'subid4', 'subid5', 'sale_amount', 'affiliate_rate', 'network_rate', 'click_ip', 'lead_ip', 'proxy_score', 'db_date', 'payment_date', 'reverse_date'])
        getReport()
    }, [])

    const toggleLeadToReverse = (e) => {
        let leadId = e.target.value

        // Check if it's in selectedLeads
        let foundIndex = selectedLeads.findIndex((item) => item === leadId)

        if (foundIndex < 0) {
            // Add it
            setSelectedLeads((prevArray) => [...prevArray, leadId])
        } else {
            // remove it
            setSelectedLeads((prevArray) => prevArray.filter((item) => item !== leadId))
        }
    }

    const selectAll = () => {
        // We only want to select the ones that are reversable
        let leadsArray = report.filter((item) => item.reversable).map((item) => item.lead_id)
        setSelectedLeads(leadsArray)
    }

    const processSelectedLeads = async (type) => {
        // Create the queue ticket
        let url = type === 'reversal' ? 'ticket/leadReversal' : 'ticket/leadUnreversal'

        await callAPI(url, 'POST', selectedLeads)
            .then((result) => {
                if (result['success'] === true) {
                    // Do nothing
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                }
            })
            .catch((error) => {
                console.log(`Error creating ticket: ${error.message}`)
                showSystemMessage('error', `Error creating ticket: ${error.message}`)
            })

        setSelectedLeads([])
        getReport()
    }

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`reports/conversions?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                console.log(result)
                if (result['success'] === true) {
                    result.affiliate && setAffiliate(result.affiliate)
                    result.campaign && setCampaign(result.campaign)
                    result.advertiser && setAdvertiser(result.advertiser)
                    setReport(result.report)
                    setCSVReport(result.report)
                    setCSVFilename(`Conversion Report - ${initStartDate}-${initEndDate}`)
                    if (result.campaign) {
                        setCampaign(result.campaign)
                    }
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching conversion report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching conversion report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Lead ID',
                accessor: 'lead_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Click ID',
                accessor: 'click_action_id',
                Cell: ({ cell: { value } }) => {
                    return <>{value > 0 ? <Link to={`/searchId?actionId=${value}`}>{value}</Link> : value}</>
                },
            },
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate_id',
            },
            {
                Header: 'Affiliate',
                accessor: 'affiliate_name',
                Cell: (tableData) => {
                    return <Affiliate aid={tableData.data[tableData.row.index].affiliate.id} name={tableData.data[tableData.row.index].affiliate.name} priority={tableData.data[tableData.row.index].affiliate.priority.value} status={tableData.data[tableData.row.index].affiliate.status.value} />
                },
            },
            {
                Header: 'Campaign ID',
                accessor: 'campaign_id',
            },
            {
                Header: 'Campaign',
                accessor: 'campaign_name',
                Cell: (tableData) => {
                    return <Campaign oid={tableData.data[tableData.row.index].campaign.id} name={tableData.data[tableData.row.index].campaign.name} status={tableData.data[tableData.row.index].campaign.status.value} />
                },
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: (tableData) => {
                    return <div style={{ whiteSpace: 'pre-line' }}>{tableData.data[tableData.row.index].status === 'Waiting' ? `Waiting until \n ${moment(tableData.data[tableData.row.index].payment_date).utc().format('YYYY-MM-DD')}` : tableData.data[tableData.row.index].status}</div>
                },
            },
            {
                Header: 'Subid1',
                accessor: 'subid1',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Subid2',
                accessor: 'subid2',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Data',
                accessor: 'lead_data',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sale_amount',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Aff Rate</div>,
                accessor: 'affiliate_rate',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Net Rate</div>,
                accessor: 'network_rate',
                headerClassName: 'text-right',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: 'Click IP',
                accessor: 'click_ip',
                Cell: ({ cell: { value } }) => {
                    return (
                        <a
                            href={`/ipDetails?ip=${value}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setShowIpDetailsModal(true)
                                setModalIp(value)
                            }}
                        >
                            {value}
                        </a>
                    )
                },
            },
            {
                Header: 'Lead IP',
                accessor: 'lead_ip',
                Cell: ({ cell: { value } }) => {
                    return (
                        <a
                            href={`/ipDetails?ip=${value}`}
                            onClick={(e) => {
                                e.preventDefault()
                                setShowIpDetailsModal(true)
                                setModalIp(value)
                            }}
                        >
                            {value}
                        </a>
                    )
                },
            },
            {
                Header: 'Proxy',
                accessor: 'proxy_score',
                Cell: ({ cell: { value } }) => {
                    return <>{value}</>
                },
            },
            {
                Header: 'Date',
                accessor: 'db_date',
                Cell: ({ cell: { value } }) => {
                    return <>{moment(value).utc().format('YYYY-MM-DD HH:mm:ss')}</>
                },
            },
            {
                Header: 'Pay Date',
                accessor: 'payment_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? moment(value).utc().format('YYYY-MM-DD') : ''}</>
                },
            },
            {
                Header: 'Reverse Date',
                accessor: 'reverse_date',
                Cell: ({ cell: { value } }) => {
                    return <>{value ? moment(value).utc().format('YYYY-MM-DD') : ''}</>
                },
            },
            {
                Header: '',
                accessor: 'reversability',
                className: 'text-right',
                Cell: (tableData) => {
                    return tableData.data[tableData.row.index].reversable && tableData.data[tableData.row.index].queue.reversal_ticket_id === 0 && tableData.data[tableData.row.index].queue.unreversal_ticket_id === 0 ? (
                        showReversal && (
                            <div className="form-check">
                                <input style={{ marginTop: '-7px' }} type="checkbox" className="form-check-input" name="reverse" onChange={(e) => toggleLeadToReverse(e)} value={tableData.data[tableData.row.index].lead_id} defaultChecked={selectedLeads.findIndex((item) => item == tableData.data[tableData.row.index].lead_id) >= 0} />
                            </div>
                        )
                    ) : tableData.data[tableData.row.index].queue.reversal_ticket_id > 0 ? (
                        <div>Reversal ticket ID: {tableData.data[tableData.row.index].queue.reversal_ticket_id}</div>
                    ) : tableData.data[tableData.row.index].queue.unreversal_ticket_id > 0 ? (
                        <div>Unreversal ticket ID: {tableData.data[tableData.row.index].queue.unreversal_ticket_id}</div>
                    ) : (
                        ''
                    )
                },
            },
        ]

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <Modal isOpen={showIpDetailsModal} size="md" toggle={() => setShowIpDetailsModal(!showIpDetailsModal)}>
                <IpDetailsContent ipAddress={modalIp} handleClose={() => setShowIpDetailsModal(false)} isModal={true} />
            </Modal>
            {dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}
            <div className="m-3 text-center alert alert-light border">
                <div>
                    <button className="btn btn-sm btn-danger" disabled={selectedLeads.length > 0 ? false : true} onClick={() => processSelectedLeads('reversal')}>
                        Reverse Selected
                    </button>
                    <button className="btn btn-sm btn-primary ml-2" disabled={selectedLeads.length > 0 ? false : true} onClick={() => processSelectedLeads('unreversal')}>
                        Unreverse Selected
                    </button>
                </div>
                <div>
                    <button className="btn btn-sm btn-lnk m-1 text-primary" onClick={() => selectAll()}>
                        Select All
                    </button>
                    <button className="btn btn-sm btn-lnk m-1 text-primary" onClick={() => setSelectedLeads([])}>
                        Unselect All
                    </button>
                </div>
            </div>
        </div>
    )
}
