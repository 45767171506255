import moment from 'moment'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Dollars from '../../../templates/Dollars/Dollars'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import { useEffect, useState } from 'react'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import ReportTable from '../../../templates/ReportTable/ReportTable'

const Affiliates = ({ showSystemMessage, defaultAdminId, defaultStatus }) => {
    document.title = 'Affiliates'

    const [adminId, setAdminId] = useState(defaultAdminId)
    const [statusCode, setStatusCode] = useState(defaultStatus)
    const { selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate, initEndDate, initStartDate } = useDateRangeFilter()
    const { data, dataLoaded, refetch } = useCallApi(`affiliates?startDate=${selectedStartDate || ''}&endDate=${selectedEndDate || ''}&manager_id=${adminId || ''}&status=${statusCode || ''}`, 'GET', {}, [], showSystemMessage)
    const history = useHistory()

    useEffect(() => {
        history.push(`/affiliates?startDate=${selectedStartDate || ''}&endDate=${selectedEndDate || ''}&adminId=${adminId || ''}&status=${statusCode || ''}`)
    }, [selectedStartDate, selectedEndDate, adminId, statusCode])

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Affiliate ID',
                accessor: 'affiliate.id',
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ cell }) => <Affiliate aid={cell.row.original.affiliate.id} status={cell.row.original.affiliate.status} name={cell.row.original.affiliate.name} />,
            },
            {
                Header: 'Registration Date',
                accessor: 'registration_date',
                Cell: ({ cell }) => moment(cell.row.original.registration_date).format('YYYY-MM-DD'),
            },
            {
                Header: 'Status',
                accessor: 'affiliate.status',
            },
            {
                Header: 'Priority',
                accessor: 'affiliate.priority',
            },
        ]

        return (
            <>
                <ReportTable data={data.affiliates} columns={columns} />
            </>
        )
    }

    return (
        <Card>
            <Card.Header>
                <h1 className="mb-h1-dark d-flex justify-content-start align-items-center">
                    <span className="d-flex mr-2 align-items-center">
                        Affiliates
                        <span className="ml-2 ">
                            <AdminPicker adminId={adminId} setAdminId={setAdminId} role={['AM_JUNIOR', 'AM_SENIOR', 'AM_JR_TEAMLEAD']} />
                        </span>
                        <span className="ml-2 ">
                            <Form.Control as="select" value={statusCode} onChange={(e) => setStatusCode(e.target.value)} size="sm">
                                <option value="">All Statuses</option>
                                <option value="A">Active</option>
                                <option value="N">New Lead</option>
                                <option value="D">Discussion</option>
                                <option value="G">Negotiation</option>
                                <option value="C">Cancelled</option>
                            </Form.Control>
                        </span>
                    </span>
                    <span className="d-flex ml-2">
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => {
                                history.push(`/affiliates?adminId=${adminId}&startDate=${selectedStartDate}&endDate=${selectedEndDate}&status=${statusCode}`)
                                refetch()
                            }}
                        >
                            Refresh
                        </Button>
                    </span>
                </h1>
            </Card.Header>
            <Card.Body className="m-0 p-0">{dataLoaded ? getReportOutput() : <Loading />}</Card.Body>
        </Card>
    )
}

export default Affiliates
