import { Form, Modal, Button, Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import callAPI from '../../../../../helpers/callAPI'

const LPModal = ({ isOpen, setIsOpen, landingPages, refetch, selectedLP, isCreate, showSystemMessage, campaignId }) => {
    const [formData, setFormData] = useState(selectedLP)

    useEffect(() => {
        setFormData(selectedLP)
    }, [selectedLP])

    const validate = (isCreate) => {
        let passValidation = true
        if (isCreate ? formData.lp_name === '' : selectedLP.lp_name === '') {
            showSystemMessage('error', 'Landing Page Name is required')
            passValidation = false
        } else if (isCreate ? formData.lp_landing_url === '' : selectedLP.lp_landing_url === '') {
            showSystemMessage('error', 'Landing Page URL is required')
            passValidation = false
        }
        if (isCreate) {
            landingPages.forEach((page) => {
                if (page.lp_name === formData.lp_name) {
                    showSystemMessage('error', 'Landing Page Name already exists')
                    passValidation = false
                }
            })
        } else {
            landingPages.forEach((page) => {
                if (page.lp_record_id !== selectedLP.lp_record_id && page.lp_name === selectedLP.lp_name) {
                    showSystemMessage('error', 'Landing Page Name already exists')
                    passValidation = false
                }
            })
        }
        return passValidation
    }

    const createLandingPage = async () => {
        if (validate(true)) {
            const response = await callAPI(`campaign/${campaignId}/landingPages`, 'POST', formData)
            if (response.success) {
                console.log(response)
                refetch()
                setIsOpen(false)
                setFormData({ lp_name: '', lp_landing_url: '', lp_default_flag: 'N', lp_status: 'A' })
                showSystemMessage('success', 'Landing Page Created')
            } else {
                showSystemMessage('error', response.errors.message)
            }
        }
    }

    const updateLandingPage = async () => {
        if (validate()) {
            const response = await callAPI(`campaign/${campaignId}/landingPages/${selectedLP.lp_record_id}`, 'PUT', formData)
            if (response.success) {
                console.log(response)
                refetch()
                setIsOpen(false)
                showSystemMessage('success', 'Landing Page Updated')
            } else {
                showSystemMessage('error', response.errors.message)
            }
        }
    }

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                setIsOpen(false)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Add Landing Page' : 'Edit Landing Page'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between">
                    <div>Landing Page Name</div>
                    <Form.Control
                        className="w-75"
                        type="text"
                        placeholder="Enter Landing Page Name"
                        value={formData.lp_name}
                        onChange={(e) => {
                            setFormData({ ...formData, lp_name: e.target.value })
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div>Landing Page URL</div>
                    <Form.Control
                        className="w-75"
                        type="text"
                        placeholder="Enter Landing Page URL"
                        value={formData.lp_landing_url}
                        onChange={(e) => {
                            setFormData({ ...formData, lp_landing_url: e.target.value })
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div>Default Landing Page</div>
                    <Form.Check
                        type="checkbox"
                        className="w-75"
                        value={formData.lp_default_flag}
                        onChange={(e) => {
                            setFormData({ ...formData, lp_default_flag: e.target.checked ? 'Y' : 'N' })
                        }}
                    />
                </div>
                <div className="d-flex justify-content-between mt-3">
                    <div>Landing Page Status</div>
                    <Form.Control
                        className="w-75"
                        as="select"
                        value={formData.lp_status}
                        onChange={(e) => {
                            setFormData({ ...formData, lp_status: e.target.value })
                        }}
                    >
                        <option value="A">Active</option>
                        <option value="T">Testing</option>
                        <option value="C">Cancelled</option>
                    </Form.Control>
                </div>
                <h3 className="mt-4">Available Macros:</h3>
                <Table striped bordered hover size="sm">
                    <tbody>
                        <tr align="left" width="100%">
                            <td>Click ID</td>
                            <td>#ACID#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>Affiliate ID</td>
                            <td>#MBID#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>IP Address</td>
                            <td>#IP#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>Referring URL</td>
                            <td>#REFURL#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>SUB-ID 1</td>
                            <td>#S1#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>SUB-ID 2</td>
                            <td>#S2#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>SUB-ID 3</td>
                            <td>#S3#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>SUB-ID 4</td>
                            <td>#S4#</td>
                        </tr>
                        <tr align="left" width="100%">
                            <td>SUB-ID 5</td>
                            <td>#S5#</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="primary" size="sm" onClick={createLandingPage}>
                        Create
                    </Button>
                ) : (
                    <Button variant="primary" size="sm" onClick={updateLandingPage}>
                        Update
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default LPModal
