import { Button, Form, Modal } from 'react-bootstrap'

import moment from 'moment'
import { useCallback, useState } from 'react'
import callAPI from '../../../helpers/callAPI'

const UpdateBalancesModal = ({ showSystemMessage, isOpen, setIsOpen }) => {
    const [balances, setBalances] = useState('')
    const updateBalances = async () => {
        const result = await callAPI(`advertisers/balances`, 'POST', { balances: balances })
        if (result.success) {
            showSystemMessage('success', 'Balances updated successfully')
            setIsOpen(false)
            setBalances('')
        } else {
            showSystemMessage('error', 'There was an error updating the balances')
        }
    }

    return (
        <Modal show={isOpen} onHide={() => setIsOpen(false)}>
            <Modal.Header className="d-flex justify-content-start align-items-start flex-column">
                <Modal.Title>Reconcile Merchant Balances</Modal.Title>
                <div>
                    Upload Pre-Paid Merchant Balances in CSV format: Balance, Merchant ID, Balance date
                    <br />
                    <br />
                    <div className="text-danger">
                        *This process should be done at the beginning of each month, for the previous month. Balances MUST reflect balances as of (and no later than):
                        <strong>{moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')}</strong>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form.Control as="textarea" rows={3} value={balances} onChange={(e) => setBalances(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" size="sm" onClick={() => updateBalances()}>
                    Update Balances
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateBalancesModal
