import React, { useState, useEffect } from 'react'
import { colors } from '../../../../theme/variables'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Dollars from '../../../../templates/Dollars/Dollars'

export default function MarketingCampaignList({ selectedCampaign, setCampaign, data }) {
    return (
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Campaign</th>
                    <th>Date Created</th>
                    <th className="text-right">Signups</th>
                    <th className="text-right">Aff Earnings</th>
                    <th className="text-right">Net Earnings</th>
                    <th className="text-right">Margin</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {data &&
                    data.map((campaign) => {
                        return (
                            <tr style={selectedCampaign.id === campaign.id ? { backgroundColor: colors.blueHighlight } : {}}>
                                <td>{campaign.id}</td>
                                <td>{campaign.name}</td>
                                <td>{moment(campaign.date).utc().format('YYYY-MM-DD')}</td>
                                <td className="text-right">{Intl.NumberFormat('en-GB').format(campaign.signups)}</td>
                                <td className="text-right">
                                    <Dollars value={campaign.aff_earnings} />
                                </td>
                                <td className="text-right">
                                    <Dollars value={campaign.net_earnings} />
                                </td>
                                <td className="text-right">
                                    <Dollars value={campaign.margin} />
                                </td>
                                <td className="text-right">
                                    <Link to={`/marketing/campaign/${campaign.id}/report`} className="btn btn-link btn-sm">
                                        Affiliates
                                    </Link>
                                    {selectedCampaign.id !== campaign.id ? (
                                        <button
                                            className="btn btn-warning btn-sm ml-2"
                                            onClick={(e) => {
                                                setCampaign({ id: parseInt(campaign.id), name: campaign.name })
                                            }}
                                        >
                                            Manage
                                        </button>
                                    ) : (
                                        <button className="btn btn-secondary btn-sm ml-2" disabled>
                                            Manage
                                        </button>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
}
