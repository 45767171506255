import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import EmployeePicker from '../../../../components/EmployeePicker/EmployeePicker'
import callAPI from '../../../../helpers/callAPI'
import Loading from '../../../../templates/UI/Loading/Loading'
import styles from './AamSummary.module.scss'
import moment from 'moment'

import { useAuth, useProtectedPage, useSetManager } from '../../../../context/AuthContext'
import ReactTooltip from 'react-tooltip'

export default function AamSummary({ manager, setManager, showSetManager }) {
    const auth = useAuth()

    const [dataLoaded, setDataLoaded] = useState(false)

    const [summaryData, setSummaryData] = useState({
        affiliates: {
            high: { affiliate_manager: 0, network: 0 },
            medium: { affiliate_manager: 0, network: 0 },
            new: { affiliate_manager: 0, network: 0 },
            total: { affiliate_manager: 0, network: 0 },
        },
        followupsToday: 0,
        margin: 0.0,
        applications: { approved: 0, processed: 0 },
    })

    const [todayMargin, setTodayMargin] = useState(0)
    const [monthMargin, setMonthMargin] = useState(0)

    useEffect(async () => {
        const fetchSummaryData = async () => {
            const data = await callAPI(`summary/advertiserManager?adminId=${manager.id}`, 'GET', null)
            if (data.success) {
                setSummaryData(data)
            } else {
                console.log(data.message)
            }
        }

        const fetchDayMargin = async () => {
            const todayMargin = await callAPI(`reports/advertisers?adminId=${manager.id}&startDate=${moment().startOf('day').format('YYYY-MM-DD')}&endDate=${moment().add(1, 'day').startOf('day').format('YYYY-MM-DD')}`, 'GET', null)
            if (todayMargin.success) {
                setTodayMargin(todayMargin.totals.find((total) => total.header === 'margin').value)
            }
            return
        }

        const fetchMonthMargin = async () => {
            const monthMargin = await callAPI(`reports/advertisers?adminId=${manager.id}`, 'GET', null)
            if (monthMargin.success) {
                setMonthMargin(monthMargin.totals.find((total) => total.header === 'margin').value)
            }
            return
        }

        setDataLoaded(false)

        await fetchSummaryData()
        await fetchDayMargin()
        await fetchMonthMargin()

        setDataLoaded(true)
    }, [manager])

    return (
        <Card className="mb-3">
            <Card.Title className={`m-3 d-flex ${styles.customFlexDirection}`}>
                <div className="font-weight-bold text-secondary" style={{ fontSize: '1.5rem' }}>
                    Hello <span className="text-dark">{manager.name.split(' ')[0]}</span>
                </div>
                {useSetManager(auth.permissions, 'aam_dashboard') && showSetManager && <EmployeePicker employee={manager} setEmployee={setManager} role={'MERMGR'} />}
            </Card.Title>
            <hr className="my-0" />
            <Card.Body>
                {dataLoaded ? (
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <span className="font-weight-bold mr-2">Margin</span>
                            </div>
                            <div>
                                Today &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(todayMargin)} &nbsp;</span>
                                </span>
                            </div>
                            <div>
                                Month &nbsp;
                                <span className="mb-element transfers">
                                    <span className="name font-weight-bold">${Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(monthMargin)} &nbsp;</span>
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                Followups Today &nbsp;
                                <Link to={`/advertisers/followups?adminId=${manager.id}`} className="text-primary font-weight-bold">
                                    {summaryData.followups_today} &nbsp;
                                </Link>
                            </div>
                            <div className="d-flex">
                                <ReactTooltip />
                                Advertisers &nbsp;
                                <span className="font-weight-bold" data-tip="Active with Campaigns">
                                    {summaryData.advertisers.with_campaigns} &nbsp;
                                </span>
                                <span className="font-weight-bold" data-tip="Active">
                                    /{summaryData.advertisers.active}
                                </span>
                            </div>
                            <div className="d-flex">
                                Campaigns &nbsp;
                                <span className="font-weight-bold">{summaryData.campaigns} &nbsp;</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </Card.Body>
            <div className={`d-flex justify-content-center p-2 bg-info ${styles.bgInfo25}`}>
                <Link to={`/admin/${manager.id}/AAMPerformanceReport`} className="text-primary">
                    {' '}
                    View performance report{' '}
                </Link>
            </div>
        </Card>
    )
}
