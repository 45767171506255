import moment from 'moment'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'

import ManagerDropdown from '../../../../templates/ManagerDropdown/ManagerDropdown'

const OfferTrendFilters = (props) => {
    const [selectedDate, setSelectedDate] = useState(props.filters.date)
    const [minMargin, setMinMargin] = useState(props.filters.minT1)
    // Send everything together in one method rather than creating a separate one for each

    const handleMMChange = (mmId) => {
        props.mmSelected(mmId)
    }

    const handleBizDevChange = (bmId) => {
        props.bmSelected(bmId)
    }

    const handleDateChange = (date) => {
        // Handle date selection
        setSelectedDate(date)
        props.dateSelected(date)
    }

    const handleResetFilters = (e) => {
        // Reset the state to reset filters
        props.resetFilters(e)
    }

    // filter_trend & timeFrame dropdown
    const handleFilterDropdownChange = (source, e) => {
        props.filtersChanged(source, e.target.value)
    }

    const handleMarginInput = (e) => {
        setMinMargin(e.target.value)
        props.minT1MarginSet(e.target.value)
    }

    return (
        <div className="d-flex mb-3 align-items-center">
            <select name="timeframe" className="custom-select custom-select-sm" style={{ border: '1px solid #ced4da', maxWidth: '100px' }} value={props.filters.timeFrame} onChange={(e) => handleFilterDropdownChange('timeframe', e)}>
                <option value="d" key="d">
                    Day
                </option>
                <option value="w" key="w">
                    Week
                </option>
                <option value="m" key="m">
                    Month
                </option>
                <option value="y" key="y">
                    Year
                </option>
            </select>
            &nbsp;&nbsp;
            <select name="filter_trend" className="custom-select custom-select-sm" style={{ border: '1px solid #ced4da', maxWidth: '100px' }} value={props.filters.filterTrend} onChange={(e) => handleFilterDropdownChange('filter_trend', e)}>
                <option value="b" key="b">
                    All Trends
                </option>
                <option value="g" key="g">
                    Increasing Only
                </option>
                <option value="r" key="r">
                    Decreasing Only
                </option>
                <option value="n" key="n">
                    No Change
                </option>
            </select>
            &nbsp;&nbsp;
            <input type="text" style={{ width: '15%' }} className="form-control form-control-sm" placeholder="T-1 Margin Minimum" onChange={handleMarginInput} value={minMargin} />
            &nbsp;&nbsp;
            <ManagerDropdown key={props.type === 'campaignTrend' ? 'merchant' : 'affiliate'} mgrId={props.filters.mgrId} mgrType={props.type === 'campaignTrend' ? 'merchant' : 'affiliate'} mgrSelected={handleMMChange} />
            &nbsp;&nbsp;
            {props.type === 'campaignTrend' && <ManagerDropdown key="bizdev" mgrId={props.filters.bizDevMgrId} mgrType="bizdev" mgrSelected={handleBizDevChange} />}
            {props.type === 'campaignTrend' && (
                <>
                    <span className="text-small">Offers launched after date: </span>
                    &nbsp;
                    <div>
                        <DatePicker selectsStart placeholderText={'Choose a date'} className="form-control form-control-sm" selected={selectedDate ? moment(selectedDate).toDate() : null} onChange={handleDateChange} />
                    </div>
                </>
            )}
            &nbsp;
            <button type="button" className="text-small btn btn-link" onClick={handleResetFilters}>
                Reset Filters
            </button>
        </div>
    )
}

export default OfferTrendFilters
