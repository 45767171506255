import React, { Component } from 'react'
import { useHistory } from 'react-router-dom'
import './QuickSearch.css'

const QuickSearch = (props) => {
    const history = useHistory()

    const submit = (e) => {
        e.preventDefault()

        let searchTerm = e.target.search_term.value

        history.push(`/search?searchTerm=${searchTerm}`)
    }

    return (
        <form className="mr-3" onSubmit={submit}>
            <input type="text" className="form-control quick-search" placeholder="Quick Search" name="search_term" />
        </form>
    )
}

export default React.memo(QuickSearch)
