import { Line } from 'react-chartjs-2'
import { useState, useEffect } from 'react'

const options = {
    legend: {
        display: false,
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    display: true,
                },
            },
        ],
    },
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 5,
        },
    },
}

const Graph = ({ data }) => {
    const [graphData, setGraphData] = useState({
        labels: [1, 2, 3, 4, 5],
        datasets: [
            {
                label: 'first dataset',
                data: [1, 2, 3, 4, 5],
                fill: true,
                backgroundColor: data.bgColor,
                borderColor: data.borderColor,
            },
        ],
    })

    useEffect(() => {}, [data])

    return <Line data={data} options={options} />
}
export default Graph
