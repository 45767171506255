import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Card, Form, Modal, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'
import AddTransaction from '../AddTransaction/AddTransaction'
import AffiliateNavbar from '../Templates/AffiliateNavbar/AffiliateNavbar'
import AffiliateTransactionDetails from '../TransactionDetails/TransactionDetails'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'

const AccountHistoryForAffiliate = ({ affiliateId, showSystemMessage, maxMoney }) => {
    const auth = useAuth()
    const showAddTransaction = useProtectedContent(auth.permissions, 'add_affiliate_transaction')

    const [year, setYear] = useState(parseInt(moment().format('YYYY')))
    const [showModal, setShowModal] = useState(false)
    const [transactionId, setTransactionId] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const { dataLoaded, data, refetch } = useCallApi(`affiliate/${affiliateId}/${maxMoney ? 'maxmoney/' : ''}accountHistory`, 'GET', {}, [affiliateId])
    let balance = 0

    document.title = `${dataLoaded ? `${data.affiliate.name}: ` : ''}Account History${maxMoney ? ' Maxmoney' : ''}`

    useEffect(() => {
        refetch()
    }, [])

    const getBalanceInAndOut = (type) => {
        let selectedYear = data.balance_by_year.filter((item) => item.year == year)

        if (selectedYear.length > 0 && selectedYear[0].balance) {
            return (
                <tr>
                    <td>{type === 'in' ? `${year}/01/01` : `${year}/12/31`}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                        <Dollars value={type === 'in' ? selectedYear[0].balance.balance_in : selectedYear[0].balance.balance_out} />
                    </td>
                    <td>{type === 'in' ? 'Balance in' : 'Balance out'}</td>
                </tr>
            )
        } else {
            ;<tr>
                <td colSpan={5}>No data.</td>
            </tr>
        }
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Account {maxMoney && 'MaxMoney'} History</h1>
                <div className="ml-5">
                    <AffiliateNavbar affiliateId={affiliateId} showSystemMessage={showSystemMessage} />
                </div>
            </div>

            <Card>
                {isOpen && <AddTransaction maxMoney={maxMoney} isOpen={isOpen} setIsOpen={setIsOpen} affiliate={data.affiliate} showSystemMessage={showSystemMessage} refetch={refetch} />}
                <div>
                    <div className="d-flex justify-content-between p-3 align-items-center">
                        <h1 className="mb-h1-dark mr-2">
                            {maxMoney ? 'MaxMoney ' : null} Account History For Affiliate: {dataLoaded ? <Affiliate aid={data.affiliate.id} name={data.affiliate.name} priority={data.affiliate.priority.value} status={data.affiliate.status.value} /> : null}
                        </h1>
                        <div>
                            {showAddTransaction && (
                                <Button variant="warning" onClick={() => setIsOpen(true)}>
                                    Add Transaction
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="d-flex pl-3 align-items-center">
                        <div className="d-flex">Statement Year:</div>
                        <Form.Control as="select" className="ml-2" value={year} onChange={(e) => setYear(e.target.value)} style={{ width: '10%' }}>
                            {dataLoaded &&
                                data.balance_by_year.map((item) => (
                                    <option value={item.year} key={item.year}>
                                        {item.year}
                                    </option>
                                ))}
                        </Form.Control>
                    </div>
                </div>
                <Card.Body>
                    <Table striped hover className="m-0">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Balance</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataLoaded ? (
                                <>
                                    <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                                        <AffiliateTransactionDetails transactionId={transactionId} />
                                    </Modal>
                                    {data.history.length === 0 ? (
                                        <tr>
                                            <td colSpan={5}>No data.</td>
                                        </tr>
                                    ) : (
                                        <>
                                            {getBalanceInAndOut('in')}
                                            {data.history.map((item, index) => {
                                                if (moment(item.date).year() == year) {
                                                    let type = item.type === 'P' ? 'Payment' : item.type === 'E' ? 'Earned' : item.type === 'B' ? 'Bonus' : 'Unknown'
                                                    balance += item.amount
                                                    return (
                                                        <tr key={index}>
                                                            <td>{moment(item.date).format('YYYY/MM/DD')}</td>
                                                            <td>{type}</td>
                                                            <td>
                                                                {maxMoney ? (
                                                                    <Dollars value={item.amount} />
                                                                ) : item.type !== 'P' ? (
                                                                    <Link
                                                                        to={`/affiliate/${affiliateId}/transactionDetails?transactionId=${item.transactionId}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setShowModal(true)
                                                                            setTransactionId(item.transactionId)
                                                                        }}
                                                                    >
                                                                        <Dollars value={item.amount} />
                                                                    </Link>
                                                                ) : (
                                                                    <Dollars value={balance} />
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Dollars value={balance} />
                                                            </td>
                                                            <td>{item.notes}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                            {getBalanceInAndOut('out')}
                                        </>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan={5} align="center">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    )
}

export default AccountHistoryForAffiliate
