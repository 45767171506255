import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import Loading from '../../../templates/UI/Loading/Loading'

const ReportIncompleteApps = ({ showSystemMessage }) => {
    // const [dataLoaded, setDataLoaded] = useState(false)
    // const [report, setReport] = useState([])

    document.title = 'Incomplete Applications Summary'

    const { dataLoaded, data, refetch, setData } = useCallApi(`affiliates/incompleteAppsReport`, 'GET', {}, [])

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="page-header d-flex align-items-center">
                    <h1 className="mb-h1">Reports</h1>
                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <div className="d-flex p-3">
                            <div className="mr-4 d-flex align-items-center">
                                <h1 className="mb-h1-dark">
                                    Incomplete Applications Summary <span className="ml-2 font-weight-normal">(Period: {dataLoaded ? `${moment(data.report_dates.start_date).format('YYYY/MM/DD')} - ${moment(data.report_dates.end_date).format('YYYY/MM/DD')}` : spinner})</span>
                                </h1>
                            </div>
                        </div>
                        {dataLoaded ? (
                            <div>
                                <div className="px-5 py-2">
                                    <div className="p-2 font-weight-bold">Application by Action Taken</div>
                                    <table className="table table-card table-striped table-hover m-0 w-100 border">
                                        <thead>
                                            <tr>
                                                <th width="100%">Action Taken</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.reachout_count.map((item) => {
                                                return (
                                                    <tr key={item.action_to_take}>
                                                        <td>{item.action_to_take}</td>
                                                        <td>{item.cnt}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className="font-weight-bold">{data.totals.reachout_count}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="px-5 py-2">
                                    <div className="p-2 font-weight-bold">Application by Status</div>
                                    <table className="table table-card table-striped table-hover m-0 w-100 border">
                                        <thead>
                                            <tr>
                                                <th width="100%">Status</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.apps_by_status.map((item) => {
                                                return (
                                                    <tr key={item.status}>
                                                        <td>{item.status}</td>
                                                        <td>{item.cnt}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className="font-weight-bold">{data.totals.by_status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="px-5 py-2">
                                    <div className="p-2 font-weight-bold">Application by current Affiliate Status</div>
                                    <table className="table table-card table-striped table-hover m-0 w-100 border">
                                        <thead>
                                            <tr>
                                                <th width="100%">Status</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.apps_by_affiliate_status.map((item) => {
                                                return (
                                                    <tr key={item.status}>
                                                        <td>{item.status}</td>
                                                        <td>{item.cnt}</td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className="font-weight-bold">{data.totals.by_affiliate_status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="px-5 py-2">
                                    <div className="p-2 font-weight-bold">Lifetime Margin Earned by these Affiliates</div>
                                    <table className="table table-card table-striped table-hover m-0 w-100 text-center border">
                                        <thead>
                                            <tr>
                                                <th>Margin Earned</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Dollars value={data.apps_margin.tot_margin} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="px-5 py-2">
                                    <div className="p-2 font-weight-bold">Top Active Affiliates ({'>'}$1000 margin earned)</div>
                                    <table className="table table-card table-striped table-hover m-0 w-100 border">
                                        <thead>
                                            <tr>
                                                <th>Aff. ID</th>
                                                <th>Affiliate</th>
                                                <th>App. Date</th>
                                                <th>Aff. Manager</th>
                                                <th>Margin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.apps_top_margin.map((item) => {
                                                return (
                                                    <tr key={item.affiliate_id}>
                                                        <td>{item.affiliate_id}</td>
                                                        <td>
                                                            <Affiliate aid={item.affiliate_id} name={item.name} status={item.status} priority={item.aff_priority} />
                                                        </td>
                                                        <td>{moment(item.db_date).format('YYYY-MM-DD')}</td>
                                                        <td>{item.full_name}</td>
                                                        <td>
                                                            <Dollars value={item.tot_margin} />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportIncompleteApps
