import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import Priority from '../../../../templates/Affiliate/Priority'
import TransferAffiliateModal from '../../../Dashboard/components/TransferAffiliateModal/TransferAffiliateModal'

import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const GeneralInfo = ({ showSystemMessage, affiliate, affiliateId, refetch, setIsDisabled }) => {
    const auth = useAuth()
    const showStatusDropdown = useProtectedContent(auth.permissions, 'affiliate_status_dropdown')
    const showTransferAM = useProtectedContent(auth.permissions, 'affiliate_transfer_am')

    const [affTransferModalData, setAffTransferModalData] = useState({
        isOpen: false,
        affiliate: {
            id: null,
            name: null,
            priority: null,
        },
        adminId: null,
        adminName: '',
    })

    const inputChangeHandler = (e) => {
        e.preventDefault()
        setIsDisabled(false)
    }

    return (
        <div className="card">
            {showTransferAM && affTransferModalData.isOpen && <TransferAffiliateModal affTransferModalData={affTransferModalData} setAffTransferModalData={setAffTransferModalData} showSystemMessage={showSystemMessage} refetch={refetch} />}
            <ReactTooltip />
            <div className="card-header d-flex align-items-center justify-content-between">
                <div>Account Information</div>
            </div>
            <div className="card-body p-0">
                <table className="table table-striped m-0" width="100%">
                    <tbody>
                        <tr>
                            <td width="25%" className="border-right text-right">
                                Name
                            </td>
                            <td className="border-right">
                                <input name="name" className="form-control" type="text" defaultValue={affiliate.name} onChange={inputChangeHandler} required></input>
                            </td>
                            <td className="border-right text-right">Manager</td>
                            <td>
                                {affiliate.manager.name}
                                {showTransferAM && (
                                    <button className="btn btn-warning btn-sm ml-3" type="button" onClick={() => setAffTransferModalData({ isOpen: true, affiliate: { id: affiliateId, name: affiliate.name, priority: affiliate.priority.value }, adminId: affiliate.manager.id, adminName: affiliate.manager.name })}>
                                        Transfer
                                    </button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Priority</td>
                            <td className="border-right">
                                <Priority priority={affiliate.priority.name} />
                            </td>
                            <td className="border-right text-right">Score</td>
                            <td>{affiliate.account.score}</td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Status</td>
                            <td className="border-right">
                                {showStatusDropdown ? (
                                    <select name="status" defaultValue={affiliate.status.value} className="form-control" onChange={inputChangeHandler} required>
                                        <option value="A">Active</option>
                                        <option value="V">Validating Email</option>
                                        <option value="P">Pending Compliance Review</option>
                                        <option value="S">Suspended</option>
                                        <option value="R">Rejected</option>
                                        <option value="L">Closed</option>
                                        <option value="D">Duplicate</option>
                                        <option value="C">Cancelled</option>
                                    </select>
                                ) : (
                                    <div>{affiliate.status.name}</div>
                                )}
                            </td>
                            <td className="border-right text-right">Referrer</td>
                            <td>{affiliate.account.referrer > 0 ? <Link to={`/affiliate/${affiliate.account.referrer}`}>{affiliate.account.referrer}</Link> : 'None'}</td>
                        </tr>
                        <tr>
                            <td className="border-right text-right">Last Status Change</td>
                            <td className="border-right">
                                <span className="badge badge-light border">{affiliate.account.status_date}</span>
                            </td>
                            <td className="border-right text-right">Last Login</td>
                            <td>
                                <span className="badge badge-light border">{affiliate.account.last_login_date}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default GeneralInfo
