import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useCallApi from '../../../../hooks/useCallApi'
import Loading from '../../../../templates/UI/Loading/Loading'
import moment from 'moment'
import Dollars from '../../../../templates/Dollars/Dollars'
import callAPI from '../../../../helpers/callAPI'

export default function MarginTarget({ showSystemMessage }) {
    const { data, dataLoaded } = useCallApi(`marginTarget`, 'GET', {}, [], showSystemMessage)

    const [monthlyMarginTarget, setMonthlyMarginTarget] = useState(dataLoaded ? data.target_monthly_margin : 0)

    const handleMonthlyMarginTarget = async () => {
        let result = await callAPI(`marginTarget`, 'POST', { target_monthly_margin: monthlyMarginTarget })

        if (result.success) {
            showSystemMessage('success', 'Monthly margin target updated.')
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    return (
        <div className="card mb-2">
            <div className="card-header">Margin Target (Calculated Daily)</div>
            <div>
                <div className="p-2 form-inline">
                    <label htmlFor="target_monthly_margin">Monthly Margin Target</label>
                    <input name="target_monthly_margin" type="text" className="ml-2 form-control form-inline form-control-sm" defaultValue={dataLoaded ? data.target_monthly_margin : ''} onChange={(e) => setMonthlyMarginTarget(e.target.value)}></input>
                    <button className="btn btn-primary btn-sm ml-2" onClick={handleMonthlyMarginTarget}>
                        Update
                    </button>
                </div>
                {dataLoaded ? (
                    <>
                        <div className="d-flex border-top">
                            <div className="border-right p-2 w-50">
                                Report Date: <span className="ml-2 font-weight-bold">{moment(data.report.margin_date).utc().format('YYYY-MM-DD')}</span>
                            </div>
                            <div className="p-2 w-50">
                                Days elapsed:{' '}
                                <span className="ml-2 font-weight-bold">
                                    {moment(data.report.margin_date).utc().format('DD')}/{moment(data.report.margin_date).utc().daysInMonth()}
                                </span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <table className="table hover striped border-right mb-0 pb-0 w-50">
                                <tbody>
                                    <tr style={{ borderBottom: '2px solid RGBA(0,0,0,0.2)' }}>
                                        <td>Monthly Target Used</td>
                                        <td className="font-weight-bold text-right text-info">
                                            <Dollars value={data.report.MB_target_monthly_margin} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Average Daily Margin</td>
                                        <td className="font-weight-bold text-right">
                                            <Dollars value={data.report.MB_MTD_average_margin} />
                                        </td>
                                    </tr>
                                    <tr style={{ borderBottom: '2px solid RGBA(0,0,0,0.2)' }}>
                                        <td>Forecasted Monthly Margin</td>
                                        <td className="font-weight-bold text-right">
                                            <Dollars value={data.report.MB_MTD_average_margin * moment(data.report.margin_date).utc().daysInMonth()} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Average Daily Margin (Avatar)</td>
                                        <td className="font-weight-bold text-right">
                                            <Dollars value={data.report.AV_MTD_average_margin} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Forecasted Monthly Margin (Avatar)</td>
                                        <td className="font-weight-bold text-right">
                                            <Dollars value={data.report.AV_MTD_average_margin * moment(data.report.margin_date).utc().daysInMonth()} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="border-top w-50 text-center d-flex align-items-center">
                                <div className="p-2 m-auto">
                                    <div className="badge badge-danger">{data.report.MB_margin_trend}</div>
                                    <div>
                                        Margin Variation: <span className="ml-2 font-weight-bold">{data.report.MB_margin_variation}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}
