import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import queryString from 'query-string'
import { Collapse } from 'react-bootstrap'

import SearchOptions from './SearchOptions'
import SearchResults from './SearchResults'

const SearchCampaigns = ({ showSystemMessage }) => {
    document.title = 'Campaign Search'

    const [open, setOpen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState()
    const [searchResults, setSearchResults] = useState([])

    const location = useLocation()
    const history = useHistory()

    let urlParams = queryString.parse(location.search)

    const [traffic, setTraffic] = useState({
        searchTraffic: !urlParams.searchTraffic ? true : urlParams.searchTraffic === 'false' ? false : true,
        emailTraffic: !urlParams.emailTraffic ? true : urlParams.emailTraffic === 'false' ? false : true,
        incentiveTraffic: !urlParams.incentiveTraffic ? true : urlParams.incentiveTraffic === 'false' ? false : true,
        contextualTraffic: !urlParams.contextualTraffic ? true : urlParams.contextualTraffic === 'false' ? false : true,
        socialTraffic: !urlParams.socialTraffic ? true : urlParams.socialTraffic === 'false' ? false : true,
        bannerTraffic: !urlParams.bannerTraffic ? true : urlParams.bannerTraffic === 'false' ? false : true,
        mobileTraffic: !urlParams.mobileTraffic ? true : urlParams.mobileTraffic === 'false' ? false : true,
        nativeTraffic: !urlParams.nativeTraffic ? true : urlParams.nativeTraffic === 'false' ? false : true,
        brandBidTraffic: !urlParams.brandBidTraffic ? true : urlParams.brandBidTraffic === 'false' ? false : true,
    })

    const [status, setStatus] = useState(urlParams.status ? urlParams.status : '')

    const [adminId, setAdminId] = useState(0)
    const [country, setCountry] = useState(urlParams.country ? urlParams.country : '')
    const [cpaType, setCpaType] = useState(urlParams.cpaType ? urlParams.cpaType : '')
    const [htmlPostbacks, setHtmlPostbacks] = useState(urlParams.htmlPostbacks ? (urlParams.htmlPostbacks === 'true' ? true : false) : false)
    const [displayRates, setDisplayRates] = useState(urlParams.displayRates ? (urlParams.displayRates === 'true' ? true : false) : false)
    const [mobileCampaign, setMobileCampaign] = useState(urlParams.mobileCampaign ? (urlParams.mobileCampaign === 'true' ? true : false) : false)
    const [trackingPlatform, setTrackingPlatform] = useState(urlParams.trackingPlatform ? urlParams.trackingPlatform : '')
    const [flagId, setFlagId] = useState(urlParams.flagId ? urlParams.flagId : '')
    const [category, setCategory] = useState({
        categoryId: 0,
        subCategoryId: 0,
    })
    const [trackingType, setTrackingType] = useState(urlParams.trackingType ? urlParams.trackingType : '')
    const [campaignType, setCampaignType] = useState(urlParams.campaignType ? urlParams.campaignType : '')

    const buildUrl = (url) => {
        if (!traffic.searchTraffic) {
            url += `&searchTraffic=false`
        }

        if (!traffic.emailTraffic) {
            url += `&emailTraffic=false`
        }

        if (!traffic.incentiveTraffic) {
            url += `&incentiveTraffic=false`
        }

        if (!traffic.contextualTraffic) {
            url += `&contextualTraffic=false`
        }

        if (!traffic.socialTraffic) {
            url += `&socialTraffic=false`
        }

        if (!traffic.bannerTraffic) {
            url += `&bannerTraffic=false`
        }

        if (!traffic.mobileTraffic) {
            url += `&mobileTraffic=false`
        }

        if (!traffic.nativeTraffic) {
            url += `&nativeTraffic=false`
        }

        if (!traffic.brandBidTraffic) {
            url += `&brandBidTraffic=false`
        }

        if (status !== '') {
            url += `&status=${status}`
        }

        if (adminId !== 0 && adminId !== '') {
            url += `&adminId=${adminId}`
        }

        if (country !== '') {
            url += `&country=${country}`
        }

        if (cpaType !== '') {
            url += `&cpaType=${cpaType}`
        }

        if (htmlPostbacks) {
            url += `&htmlPostbacks=true`
        }

        if (displayRates) {
            url += `&displayRates=true`
        }

        if (mobileCampaign) {
            url += `&mobileCampaign=true`
        }

        if (trackingPlatform !== '') {
            url += `&trackingPlatform=${trackingPlatform}`
        }

        if (flagId !== '') {
            url += `&flagId=${flagId}`
        }

        if (parseInt(category.categoryId) > 0) {
            url += `&categoryId=${category.categoryId}`
        }

        if (parseInt(category.subCategoryId) > 0) {
            url += `&subCategoryId=${category.subCategoryId}`
        }

        if (trackingType !== '') {
            url += `&trackingType=${trackingType}`
        }

        if (campaignType !== '') {
            url += `&campaignType=${campaignType}`
        }

        return url
    }

    useEffect(() => {
        setDataLoaded(false)
        // Grab the settings from the URL Bar if location changes

        setTraffic({
            searchTraffic: !urlParams.searchTraffic ? (traffic.searchTraffic ? traffic.searchTraffic : false) : urlParams.searchTraffic === 'false' ? false : true,
            emailTraffic: !urlParams.emailTraffic ? (traffic.emailTraffic ? traffic.emailTraffic : false) : urlParams.emailTraffic === 'false' ? false : true,
            incentiveTraffic: !urlParams.incentiveTraffic ? (traffic.incentiveTraffic ? traffic.incentiveTraffic : false) : urlParams.incentiveTraffic === 'false' ? false : true,
            contextualTraffic: !urlParams.contextualTraffic ? (traffic.contextualTraffic ? traffic.contextualTraffic : false) : urlParams.contextualTraffic === 'false' ? false : true,
            socialTraffic: !urlParams.socialTraffic ? (traffic.socialTraffic ? traffic.socialTraffic : false) : urlParams.socialTraffic === 'false' ? false : true,
            bannerTraffic: !urlParams.bannerTraffic ? (traffic.bannerTraffic ? traffic.bannerTraffic : false) : urlParams.bannerTraffic === 'false' ? false : true,
            mobileTraffic: !urlParams.mobileTraffic ? (traffic.mobileTraffic ? traffic.mobileTraffic : false) : urlParams.mobileTraffic === 'false' ? false : true,
            nativeTraffic: !urlParams.nativeTraffic ? (traffic.nativeTraffic ? traffic.nativeTraffic : false) : urlParams.nativeTraffic === 'false' ? false : true,
            brandBidTraffic: !urlParams.brandBidTraffic ? (traffic.brandBidTraffic ? traffic.brandBidTraffic : false) : urlParams.brandBidTraffic === 'false' ? false : true,
        })
        setStatus(urlParams.status ? urlParams.status : status ? status : '')
        setAdminId(urlParams.adminId && parseInt(urlParams.adminId) > 0 ? parseInt(urlParams.adminId) : parseInt(adminId) > 0 ? adminId : 0)
        setCountry(urlParams.country ? urlParams.country : country ? country : '')
        setCpaType(urlParams.cpaType ? urlParams.cpaType : cpaType ? cpaType : '')
        setHtmlPostbacks(urlParams.htmlPostbacks ? urlParams.htmlPostbacks : htmlPostbacks ? htmlPostbacks : false)
        setDisplayRates(urlParams.displayRates ? urlParams.displayRates : displayRates ? displayRates : false)
        setMobileCampaign(urlParams.mobileCampaign ? urlParams.mobileCampaign : mobileCampaign ? mobileCampaign : false)
        setTrackingPlatform(urlParams.trackingPlatform ? urlParams.trackingPlatform : trackingPlatform ? trackingPlatform : '')
        setFlagId(urlParams.flagId ? urlParams.flagId : flagId ? flagId : '')
        setCategory({
            categoryId: urlParams.categoryId && parseInt(urlParams.categoryId) > 0 ? parseInt(urlParams.categoryId) : parseInt(category.categoryId) > 0 ? category.categoryId : 0,
            subCategoryId: urlParams.subCategoryId && parseInt(urlParams.subCategoryId) > 0 ? parseInt(urlParams.subCategoryId) : parseInt(category.subCategoryId) > 0 ? category.subCategoryId : 0,
        })
        setTrackingType(urlParams.setTrackingType ? urlParams.setTrackingType : setTrackingType ? setTrackingType : '')
        setCampaignType(urlParams.campaignType ? urlParams.campaignType : campaignType ? campaignType : '')

        getResults(urlParams.searchTerm ? urlParams.searchTerm : '')
    }, [location])

    const getResults = (searchTerm) => {
        if (location.search.length > 0) {
            // Only if there are query params

            let url = buildUrl(`campaigns/search?searchTerm=${searchTerm}`)

            callAPI(url, 'GET')
                .then((result) => {
                    if (result['success'] === true) {
                        setSearchResults(result.search_results)
                        setDataLoaded(true)
                    } else if (result['success'] === false) {
                        showSystemMessage('error', result.errors.message)
                        setDataLoaded(true)
                    }
                })
                .catch((error) => {
                    showSystemMessage('error', 'An error occurred while getting custom flags')
                    // setDataLoaded(true)
                })
        } else {
            setDataLoaded(true)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let url = buildUrl(`/campaigns/search?searchTerm=${e.target.search_term.value}`)
        history.push(url)
    }

    let results

    if (dataLoaded === false) {
        results = <Loading />
    } else {
        results = <SearchResults searchResults={searchResults} />
    }

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Campaign Search</h1>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <form className="form-group mb-0" onSubmit={handleSubmit}>
                        <div className="d-flex p-3 align-items-center justify-content-center" style={{ backgroundColor: '#f4f4f4' }}>
                            <input className="form-control mr-2 w-75" type="text" name="search_term" placeholder="Keyword..." autoComplete="off" defaultValue={urlParams.searchTerm ? urlParams.searchTerm : ''} />
                            <button type="submit" className="btn btn-primary mr-2">
                                Search
                            </button>
                            <button type="button" className="btn btn-link no-shadow" onClick={() => setOpen(!open)} aria-controls="example-collapse-text" aria-expanded={open}>
                                Search Options
                            </button>
                        </div>
                        <Collapse in={open}>
                            {/* Must wrap the component in <div> to make Collapse work*/}
                            <div>
                                <SearchOptions traffic={traffic} setTraffic={setTraffic} status={status} setStatus={setStatus} adminId={adminId} setAdminId={setAdminId} country={country} setCountry={setCountry} cpaType={cpaType} setCpaType={setCpaType} htmlPostbacks={htmlPostbacks} setHtmlPostbacks={setHtmlPostbacks} displayRates={displayRates} setDisplayRates={setDisplayRates} mobileCampaign={mobileCampaign} setMobileCampaign={setMobileCampaign} trackingPlatform={trackingPlatform} setTrackingPlatform={setTrackingPlatform} flagId={flagId} setFlagId={setFlagId} category={category} setCategory={setCategory} trackingType={trackingType} setTrackingType={setTrackingType} campaignType={campaignType} setCampaignType={setCampaignType} showSystemMessage={showSystemMessage} />
                            </div>
                        </Collapse>
                    </form>
                    {results}
                </div>
            </div>
        </div>
    )
}

export default SearchCampaigns
