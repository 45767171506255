import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Modal } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import ScheduleCancellation from '../ScheduleCancellation/ScheduleCancellation'
import CloneCampaignContent from './CloneCampaign/CloneCampaignContent'
import moment from 'moment'
import FraudWatchModal from '../../../components/FraudWatchModal/FraudWatchModal'
import { useAuth, useProtectedContent } from '../../../context/AuthContext'

const CampaignNavbar = ({ campaignId, campaignData, showSystemMessage }) => {
    /*
        PROPS
            campaignData: this is optional. If it's present, we will not make an API request to get campaign data since we already have it from the parent.
    */

    const auth = useAuth()
    const showEditCampaign = useProtectedContent(auth.permissions, 'campaign_settings')
    const showAddLeads = useProtectedContent(auth.permissions, 'add_leads')
    const showAddAvatarLeads = useProtectedContent(auth.permissions, 'avatar_add_leads')
    const showCloneCampaign = useProtectedContent(auth.permissions, 'campaign_clone')
    const showFilterOverrides = useProtectedContent(auth.permissions, 'affiliate_filter_overrides')

    const [statsOpen, setStatsOpen] = useState(false)
    const [campaignOpen, setCampaignOpen] = useState(false)
    const [complianceOpen, setComplianceOpen] = useState(false)
    const [mobileOpen, setMobileOpen] = useState(false)
    const [affiliateSettingsOpen, setAffiliateSettingsOpen] = useState(false)
    const [trafficOpen, setTrafficOpen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [data, setData] = useState(campaignData ? campaignData : {})

    const [showScheduleCancellationModal, setShowScheduleCancellationModal] = useState(false)
    const [cloneCampaignModalIsOpen, setCloneCampaignModalIsOpen] = useState(false)
    const [modalData, setModalData] = useState({
        isOpen: false,
        isCreate: false,
    })

    const [fraudWatch, setFraudWatch] = useState({
        id: '',
        campaign: {
            id: campaignId,
            name: data.campaign ? data.campaign.name : '',
            status: '',
        },
        reason: '',
        frequency: '',
        frequency_start_date: moment().format('YYYY-MM-DD'),
        notes: '',
    })

    const [refetch, setRefetch] = useState(false)

    useEffect(async () => {
        if (campaignId > 0 && !campaignData) {
            const mbToken = localStorage.getItem('adm-auth-token')
            if (mbToken) {
                let result = await callAPI(`campaign/${campaignId}`, 'GET')

                if (result.success) {
                    setData(result)
                    setFraudWatch({ ...fraudWatch, campaign: { ...fraudWatch.campaign, name: result.campaign.name } })
                }

                setDataLoaded(true)
            }
        } else if (campaignData.campaign) {
            setDataLoaded(true)
        }
    }, [campaignId, campaignData, refetch])

    return (
        <>
            <Modal show={showScheduleCancellationModal} size="md" onHide={() => setShowScheduleCancellationModal(false)}>
                <ScheduleCancellation name={dataLoaded ? data.campaign.name : ''} campaignId={campaignId} setShowScheduleCancellationModal={setShowScheduleCancellationModal} showSystemMessage={showSystemMessage} />
            </Modal>
            {showCloneCampaign && (
                <Modal show={cloneCampaignModalIsOpen} size="lg" onHide={() => setCloneCampaignModalIsOpen(false)}>
                    <CloneCampaignContent showSystemMessage={showSystemMessage} campaignId={campaignId} setCloneCampaignModalIsOpen={setCloneCampaignModalIsOpen} />
                </Modal>
            )}
            <FraudWatchModal isCampaign refetch={() => setRefetch(!refetch)} modalData={modalData} setModalData={setModalData} showSystemMessage={showSystemMessage} />
            <nav className="mb-header-nav">
                <ul>
                    <li style={{ zIndex: 999 }}>
                        <Dropdown isOpen={campaignOpen} toggle={() => setCampaignOpen(!campaignOpen)} direction="down">
                            <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={campaignOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                                Campaign
                            </DropdownToggle>
                            <DropdownMenu>
                                <Link className="dropdown-item" to={`/campaign/${campaignId}`}>
                                    Information
                                </Link>
                                {showEditCampaign && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <Link className="dropdown-item" to={`/campaign/${campaignId}/settings`}>
                                            Settings
                                        </Link>
                                    </>
                                )}
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/creatives`}>
                                    Creatives
                                </Link>
                                {showCloneCampaign && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <button className="dropdown-item" onClick={() => setCloneCampaignModalIsOpen(true)}>
                                            Clone
                                        </button>
                                    </>
                                )}
                                {dataLoaded && (data.campaign.status.name === 'Active' || data.campaign.status.name === 'Hidden' || data.campaign.status.name === 'By Request') && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <Link
                                            to={`/`}
                                            className="dropdown-item"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setShowScheduleCancellationModal(true)
                                            }}
                                        >
                                            Schedule Cancellation
                                        </Link>
                                    </>
                                )}

                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/advertiser/${dataLoaded && data.campaign.advertiser.id}`}>
                                    View Advertiser
                                </Link>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    <li style={{ zIndex: 999 }}>
                        <Dropdown isOpen={affiliateSettingsOpen} toggle={() => setAffiliateSettingsOpen(!affiliateSettingsOpen)} direction="down">
                            <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={affiliateSettingsOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                                Affiliates
                            </DropdownToggle>
                            <DropdownMenu>
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/campaignApprovals`}>
                                    Campaign Approvals
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/callbacks`}>
                                    Callbacks
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/paymentDelayOverrides`}>
                                    Payment Delay Overrides
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/specialRates`}>
                                    Special Rates
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/campaignCaps`}>
                                    Daily Caps
                                </Link>
                                {showAddLeads && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <Link className="dropdown-item" to={`/campaign/${campaignId}/addLeads`}>
                                            Add Leads
                                        </Link>
                                    </>
                                )}
                                {dataLoaded && data.campaign.type.name === 'Avatar' && showAddAvatarLeads && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <Link className="dropdown-item" to={`/campaign/${campaignId}/addLeadsAvatar`}>
                                            Add Leads (Avatar)
                                        </Link>
                                    </>
                                )}
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/campaignRequests`}>
                                    Campaign Requests
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/campaignViews`}>
                                    Campaign Views
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/campaignCountryOverrides`}>
                                    Country Blacklist
                                </Link>
                                {showFilterOverrides && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <Link className="dropdown-item" to={`/campaign/${campaignId}/filterOverrides`}>
                                            Link Filter Overrides
                                        </Link>
                                    </>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    <li style={{ zIndex: 999 }}>
                        <Dropdown isOpen={statsOpen} toggle={() => setStatsOpen(!statsOpen)} direction="down">
                            <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={statsOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                                Reports
                            </DropdownToggle>
                            <DropdownMenu>
                                <Link className="dropdown-item" to={`/reports/stats/affiliates?campaignId=${campaignId}&navigation=campaign`}>
                                    Affiliate Earnings
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/reports/stats/daily?campaignId=${campaignId}&navigation=campaign`}>
                                    Daily Earnings
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/reports/stats/monthly?campaignId=${campaignId}&navigation=campaign&startDate=${moment().add(-13, 'months').format('YYYY-MM-DD')}`}>
                                    Monthly Earnings
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/reports/stats/conversions?campaignId=${campaignId}&navigation=campaign`}>
                                    Conversions
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/reports/custom/campaignPromiseReport?campaignId=${campaignId}&navigation=campaign`}>
                                    Promise to Run
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/serverPostbackCalls`}>
                                    Test Postbacks
                                </Link>
                                {dataLoaded && data.campaign.tracking.is_mobile_offer && (
                                    <>
                                        <DropdownItem divider className="m-0" />
                                        <Link className="dropdown-item" to={`/campaign/${campaignId}/kpiReport`}>
                                            KPI Report
                                        </Link>
                                    </>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    <li style={{ zIndex: 999 }}>
                        <Dropdown isOpen={trafficOpen} toggle={() => setTrafficOpen(!trafficOpen)} direction="down">
                            <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={trafficOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                                Traffic
                            </DropdownToggle>
                            <DropdownMenu>
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/analytics`}>
                                    Analytics
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/clickReferrers?startDate=${moment().format('YYYY-MM-DD')}&endDate=${moment().format('YYYY-MM-DD')}`}>
                                    Click Referrers
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/leadUserAgents`}>
                                    Lead User Agents
                                </Link>
                                <DropdownItem divider className="m-0" />
                                <Link className="dropdown-item" to={`/campaign/${campaignId}/leadLocations`}>
                                    Lead Locations
                                </Link>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown isOpen={complianceOpen} toggle={() => setComplianceOpen(!complianceOpen)} direction="down">
                            <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={complianceOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                                Compliance
                            </DropdownToggle>
                            <DropdownMenu>
                                {data.campaign && !data.campaign.compliance.fraud_watch.enabled ? (
                                    <Link
                                        className="dropdown-item"
                                        to={`/`}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModalData({ isCreate: true, item: fraudWatch, isOpen: true })
                                        }}
                                    >
                                        Add to Fraud Watch
                                    </Link>
                                ) : (
                                    <Link
                                        className="dropdown-item"
                                        to={`/`}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModalData({ isCreate: false, item: data.campaign.compliance.fraud_watch.details, isOpen: true })
                                        }}
                                    >
                                        Edit Fraud Watch
                                    </Link>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    { (dataLoaded && data.campaign.tracking.is_mobile_offer) &&
                        <li>
                            <Dropdown isOpen={mobileOpen} toggle={() => setMobileOpen(!mobileOpen)} direction="down">
                                <DropdownToggle tag="a" data-toggle="dropdown" aria-expanded={mobileOpen} className="nav-link dropdown-toggle dropdown-toggle-blue dropdown-toggle-right text-white">
                                    Mobile
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Link className="dropdown-item" to={`/campaign/${campaignId}/KPIReport`}>
                                        KPI Report
                                    </Link>
                                    <DropdownItem divider className="m-0" />
                                    <Link className="dropdown-item" to={`/campaigns/mobileParameters`}>
                                        Mobile Parameters
                                    </Link>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                    }
                </ul>
            </nav>
        </>
    )
}

export default CampaignNavbar
