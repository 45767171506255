import React, { useState, useEffect } from 'react'
import moment from 'moment'
import callAPI from '../../helpers/callAPI'
import useCallApi from '../../hooks/useCallApi'
import Loading from '../../templates/UI/Loading/Loading'
import Affiliate from '../../templates/Affiliate/Affiliate'

const MailerRecipientList = ({ filters, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [affiliates, setAffiliates] = useState([])

    useEffect(async () => {
        let formattedFilters = { filters: filters }

        let recipientList = await callAPI('batchmail/affiliates/preview', 'POST', formattedFilters)

        if (!recipientList.success) {
            showSystemMessage('error', recipientList.errors.message)
        }

        setDataLoaded(true)
        setAffiliates(recipientList.affiliates)

        return
    }, [])

    return (
        <div className="card">
            <div className="card-header">Recipient List {dataLoaded && affiliates.length > 0 && `(${affiliates.length} affiliates)`}</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    affiliates.length > 0 ? (
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Affiliate</th>
                                    <th>Email</th>
                                    <th>Additional Contacts</th>
                                    <th>Status</th>
                                    <th>Priority</th>
                                </tr>
                            </thead>
                            <tbody>
                                {affiliates.map((affiliate) => (
                                    <tr>
                                        <td>{affiliate.id}</td>
                                        <td>
                                            <div className="pl-2">
                                                <Affiliate aid={affiliate.id} name={affiliate.name} status={affiliate.status.value} priority={affiliate.priority.value} />
                                            </div>
                                        </td>
                                        <td>{affiliate.email}</td>
                                        <td>{affiliate.additional_contacts}</td>
                                        <td>{affiliate.status.name}</td>
                                        <td>{affiliate.priority.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div>No affiliates</div>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default MailerRecipientList
