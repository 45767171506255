import React, { useState, useEffect } from 'react'
import { MoreVertical } from 'react-feather'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import FraudPullCheckModal from '../FraudPullCheckModal/FraudPullCheckModal'

const FraudPullDropdown = ({ item, refetch, showSystemMessage }) => {
    const [dropDown, setDropdown] = useState(false)
    const [showCheckModal, setShowCheckModal] = useState(false)

    const handleClose = () => {
        setShowCheckModal(false)
    }

    return (
        <>
            {showCheckModal && <FraudPullCheckModal isOpen={showCheckModal} handleClose={handleClose} item={item} refetch={refetch} showSystemMessage={showSystemMessage} />}
            <Dropdown
                isOpen={dropDown === item.affiliate.id}
                toggle={() => {
                    setDropdown(!dropDown ? item.affiliate.id : false)
                }}
                direction="left"
            >
                <DropdownToggle tag="a" onClick={() => setDropdown(item.affiliate.id)} data-toggle="dropdown" aria-expanded={dropDown === item.affiliate.id} className="report-more-toggle">
                    <MoreVertical size={20} />
                </DropdownToggle>
                <DropdownMenu>
                    <>
                        <button
                            className="dropdown-item btn btn-link"
                            onClick={() => {
                                setShowCheckModal(true)
                            }}
                        >
                            Mark as checked
                        </button>
                        <DropdownItem divider className="m-0" />
                    </>
                </DropdownMenu>
            </Dropdown>
        </>
    )
}

export default FraudPullDropdown
