import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import useCallApi from '../../hooks/useCallApi'
import { useLocation } from 'react-router-dom'
import Loading from '../../templates/UI/Loading/Loading'
import Affiliate from '../../templates/Affiliate/Affiliate'
import Advertiser from '../../templates/Advertiser/Advertiser'
import Campaign from '../../templates/Campaign/Campaign'
import { Link } from 'react-router-dom'

export default function Search({ key, showSystemMessage }) {    

    const location = useLocation()
    let urlParams = queryString.parse(location.search)
    const [searchTerm, setSearchTerm] = useState(urlParams.searchTerm ? urlParams.searchTerm : '')

    document.title = `Quick Search: ${searchTerm}`

    const { dataLoaded, data, refetch, setData } = useCallApi(`quickSearch?searchTerm=${searchTerm}`, 'GET', {}, [searchTerm])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Quick Search</h1>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <div>Affiliates</div>
                            <Link className="text-primary" to={`affiliates/search?z=1&searchTerm=${searchTerm}`}>
                                Full search
                            </Link>
                        </div>
                        <div className="card-body p-0">
                            {!dataLoaded ? (
                                <Loading />
                            ) : (
                                <table className="table table-striped table-hover m-0">
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Priority</th>
                                        <th>Manager</th>
                                    </tr>
                                    <tbody>
                                        {data.affiliates.map((affiliate) => {
                                            return (
                                                <tr>
                                                    <td>{affiliate.id}</td>
                                                    <td>
                                                        <Affiliate aid={affiliate.id} name={affiliate.name} priority={affiliate.priority.value} status={affiliate.status.value} />
                                                    </td>
                                                    <td>{affiliate.status.name}</td>
                                                    <td>{affiliate.priority.name}</td>
                                                    <td>{affiliate.admin.name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xl-6">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <div>Campaigns</div>
                            <Link className="text-primary" to={`campaigns/search?z=1&searchTerm=${searchTerm}`}>
                                Full search
                            </Link>
                        </div>
                        <div className="card-body p-0">
                            {!dataLoaded ? (
                                <Loading />
                            ) : (
                                <table className="table table-striped table-hover m-0">
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Manager</th>
                                    </tr>
                                    <tbody>
                                        {data.campaigns.map((campaign) => {
                                            return (
                                                <tr>
                                                    <td>{campaign.id}</td>
                                                    <td>
                                                        <Campaign oid={campaign.id} name={campaign.name} status={campaign.status.value} />
                                                    </td>
                                                    <td>{campaign.status.name}</td>
                                                    <td>{campaign.admin.name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                    <div className="card mt-2">
                        <div className="card-header d-flex justify-content-between">
                            <div>Advertisers</div>
                            <Link className="text-primary" to={`advertisers/search?z=1&searchTerm=${searchTerm}`}>
                                Full search
                            </Link>
                        </div>
                        <div className="card-body p-0">
                            {!dataLoaded ? (
                                <Loading />
                            ) : (
                                <table className="table table-striped table-hover m-0">
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th>Ad Manager</th>
                                        <th>Bizdev Manager</th>
                                    </tr>
                                    <tbody>
                                        {data.advertisers.map((advertiser) => {
                                            return (
                                                <tr>
                                                    <td>{advertiser.id}</td>
                                                    <td>
                                                        <Advertiser id={advertiser.id} name={advertiser.name} status={advertiser.status.value} />
                                                    </td>
                                                    <td>{advertiser.status.name}</td>
                                                    <td>{advertiser.admin_advertiser.name}</td>
                                                    <td>{advertiser.admin_bizdev.name}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
