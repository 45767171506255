import React from 'react'
import { Modal } from 'react-bootstrap'

import AffiliateInfo from './AffiliateInfo/AffiliateInformation'
import EarningSummary from '../EarningSummary/EarningSummary'
import RequestDetails from './RequestDetails/RequestDetails'
import CampaignInfo from './CampaignInfo/CampaignInfo'
import MerchantCampaignPulls from './MerchantCampaignPulls/MerchantCampaignPulls'
import PreviousCampaignRequests from './PreviousCampaignRequests/PreviousCampaignRequests'

const ReviewRequest = ({ requestId, affiliateId, campaignId, selectedOfferId, selectedAffiliateId, showSystemMessage, handleClose, type }) => {
    return (
        <React.Fragment>
            {type === 'modal' && (
                <Modal.Header closeButton>
                    <Modal.Title>Campaign Request</Modal.Title>
                </Modal.Header>
            )}
            <div className="card">
                {type === 'page' && <div className="card-header">Review Campaign Request</div>}
                <div className="row p-2">
                    <div className="col" style={{ paddingRight: '6px' }}>
                        <AffiliateInfo affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} />
                        <RequestDetails requestId={requestId} showSystemMessage={showSystemMessage} handleClose={handleClose} />
                    </div>
                    <div className="col" style={{ paddingLeft: '6px' }}>
                        <CampaignInfo offerId={campaignId ? campaignId : selectedOfferId} showSystemMessage={showSystemMessage} />
                        <MerchantCampaignPulls affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} />
                        <PreviousCampaignRequests requestId={requestId} affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} />
                        <EarningSummary affiliateId={affiliateId ? affiliateId : selectedAffiliateId} showSystemMessage={showSystemMessage} statPeriod={'month'} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReviewRequest
