import Loading from '../../../templates/UI/Loading/Loading'
import { Modal, Form, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import callAPI from '../../../helpers/callAPI'
import useCallApi from '../../../hooks/useCallApi'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'
import AdminRolePicker from '../../../components/AdminRolePicker/AdminRolePicker'

const AdminsModal = ({ showSystemMessage, isOpen, setIsOpen, isCreate, refetch, adminId }) => {
    const [formData, setFormData] = useState({ e_mail: '', im_id: '', full_name: '', contact_info: '', status: 'A', phone: '', role_id: '', password: '', change_password_flag: 'Y', remote_access_flag: '', vacation_accrual_rate: '', manager_id: 0, linkedin_url: '', mailer_quote: '', image_name: '', out_of_office: '', tawk_token: '', username: '' })
    const { data, dataLoaded, setData } = useCallApi(`admin/${adminId}`, 'GET', {}, [adminId], showSystemMessage)

    const [logoutDevices, setLogoutDevices] = useState(false)

    const createAdmin = async () => {
        const result = await callAPI('admin/createadmin', 'POST', formData)
        if (result.success) {
            refetch()
            showSystemMessage('success', 'Admin created successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const updateAdmin = async () => {
        const result = await callAPI(`admin/${adminId}`, 'PUT', data.admin)
        if (result.success) {
            refetch()
            showSystemMessage('success', 'Admin updated successfully')
            setIsOpen(false)
        } else {
            showSystemMessage('error', result.errors.message)
        }
    }

    const logUserOutFromAllDevices = async (adminId) => {
        if (adminId !== '') {
            // Call forcelogout
            const result = await callAPI('admin/logoutUserFromAllDevices', 'POST', { adminId: adminId }, false)
            if (result.success) {
                showSystemMessage('success', 'User has been logged out.')
                setIsOpen(false)
            } else {
                showSystemMessage('error', 'User could not be logged out.')
            }
        }
    }

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                setLogoutDevices(false)
                setIsOpen(false)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>{isCreate ? 'Create' : 'Edit'} Administrator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded && data.success ? (
                    <>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>E-mail: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.e_mail : data.admin.e_mail} onChange={(e) => (isCreate ? setFormData({ ...formData, e_mail: e.target.value }) : setData({ ...data, admin: { ...data.admin, e_mail: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Username: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.username : data.admin.username} onChange={(e) => (isCreate ? setFormData({ ...formData, username: e.target.value }) : setData({ ...data, admin: { ...data.admin, username: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Status: </span>
                            <div className="w-75">
                                <Form.Control as="select" value={isCreate ? formData.status : data.admin.status} onChange={(e) => (isCreate ? setFormData({ ...formData, status: e.target.value }) : setData({ ...data, admin: { ...data.admin, status: e.target.value } }))}>
                                    <option value="A">Active</option>
                                    <option value="C">Cancelled</option>
                                </Form.Control>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Admin Type: </span>
                            <div className="w-75">
                                <AdminRolePicker isCreate={isCreate} role={isCreate ? formData.role_id : data.admin.role_id} formData={formData} setFormData={setFormData} parentData={data} setParentData={setData} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Change Password: </span>
                            <div className="w-75">
                                <Form.Control as="select" value={isCreate ? formData.change_password_flag : data.admin.change_password_flag} onChange={(e) => (isCreate ? setFormData({ ...formData, change_password_flag: e.target.value }) : setData({ ...data, admin: { ...data.admin, change_password_flag: e.target.value } }))}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </div>
                        </div>
                        {isCreate ? (
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Password: </span>
                                <div className="w-75">
                                    <Form.Control type="password" value={isCreate ? formData.password : data.admin.password} onChange={(e) => (isCreate ? setFormData({ ...formData, password: e.target.value }) : setData({ ...data, admin: { ...data.admin, password: e.target.value } }))} />
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                <span>Failed Logins: </span>
                                <div className="w-75">{data.admin.failed_login_attempts}</div>
                            </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Full Name: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.full_name : data.admin.full_name} onChange={(e) => (isCreate ? setFormData({ ...formData, full_name: e.target.value }) : setData({ ...data, admin: { ...data.admin, full_name: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>IM Handle: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.im_id : data.admin.im_id} onChange={(e) => (isCreate ? setFormData({ ...formData, im_id: e.target.value }) : setData({ ...data, admin: { ...data.admin, im_id: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Tawk Token: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.tawk_token : data.admin.tawk_token} onChange={(e) => (isCreate ? setFormData({ ...formData, tawk_token: e.target.value }) : setData({ ...data, admin: { ...data.admin, tawk_token: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>LinkedIn URL: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.linkedin_url : data.admin.linkedin_url} onChange={(e) => (isCreate ? setFormData({ ...formData, linkedin_url: e.target.value }) : setData({ ...data, admin: { ...data.admin, linkedin_url: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Mailer Quote: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.mailer_quote : data.admin.mailer_quote} onChange={(e) => (isCreate ? setFormData({ ...formData, mailer_quote: e.target.value }) : setData({ ...data, admin: { ...data.admin, mailer_quote: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Phone: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.phone : data.admin.phone} onChange={(e) => (isCreate ? setFormData({ ...formData, phone: e.target.value }) : setData({ ...data, admin: { ...data.admin, phone: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Contact HTML: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.contact_info : data.admin.contact_info} onChange={(e) => (isCreate ? setFormData({ ...formData, contact_info: e.target.value }) : setData({ ...data, admin: { ...data.admin, contact_info: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Image Name: </span>
                            <div className="w-75">
                                <Form.Control type="text" value={isCreate ? formData.image_name : data.admin.image_name} onChange={(e) => (isCreate ? setFormData({ ...formData, image_name: e.target.value }) : setData({ ...data, admin: { ...data.admin, image_name: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Remote Access: </span>
                            <div className="w-75">
                                <Form.Control as="select" value={isCreate ? formData.remote_access_flag : data.admin.remote_access_flag} onChange={(e) => (isCreate ? setFormData({ ...formData, remote_access_flag: e.target.value }) : setData({ ...data, admin: { ...data.admin, remote_access_flag: e.target.value } }))}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Vacation Accrual: </span>
                            <div className="w-75">
                                <Form.Control type="number" value={isCreate ? formData.vacation_accrual_rate : data.admin.vacation_accrual_rate} onChange={(e) => (isCreate ? setFormData({ ...formData, vacation_accrual_rate: e.target.value }) : setData({ ...data, admin: { ...data.admin, vacation_accrual_rate: e.target.value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Out Of Office: </span>
                            <div className="w-75">
                                <Form.Control as="select" value={isCreate ? formData.out_of_office : data.admin.out_of_office} onChange={(e) => (isCreate ? setFormData({ ...formData, out_of_office: e.target.value }) : setData({ ...data, admin: { ...data.admin, out_of_office: e.target.value } }))}>
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </Form.Control>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Manager: </span>
                            <div className="w-75">
                                {/* <Form.Control as="select" value={isCreate ? formData.manager_id : data.admin.manager_id} onChange={(e) => (isCreate ? setFormData({ ...formData, manager_id: e.target.value }) : setData({ ...data, admin: { ...data.admin, manager_id: e.target.value } }))}>
                                    {admins.map((admin) => {
                                        return <option value={admin.admin_id}>{admin.full_name}</option>
                                    })}
                                </Form.Control> */}
                                <AdminPicker adminId={isCreate ? formData.manager_id : data.admin.manager_id} setAdminId={(value) => (isCreate ? setFormData({ ...formData, manager_id: value }) : setData({ ...data, admin: { ...data.admin, manager_id: value } }))} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                            <span>Devices: </span>
                            <div className="w-75">
                                {!logoutDevices ? (
                                    <button className="btn btn-outline-danger" onClick={() => setLogoutDevices(true)}>
                                        Log user out of all devices
                                    </button>
                                ) : (
                                    <div className="alert alert-danger">
                                        Using this feature will lock the user out from their account for the next 15 minutes. Press the confirm button below to finalize.
                                        <button className="btn btn-danger mt-2 d-block" onClick={() => logUserOutFromAllDevices(adminId)}>
                                            Confirm user logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <td>
                        <Loading />
                    </td>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isCreate ? (
                    <Button variant="warning" onClick={() => createAdmin()}>
                        Create
                    </Button>
                ) : (
                    <Button variant="warning" onClick={() => updateAdmin()}>
                        Update
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default AdminsModal
