import React from 'react'
import { Link } from 'react-router-dom'

export default function ReportAppliedFilters({ campaign, affiliate, advertiser, subid1, creativeId, landingPageId, keyword, withLeads }) {
    return (
        <React.Fragment>
            {advertiser && advertiser.name && (
                <div className="alert alert-info d-inline-block mx-3">
                    Advertiser: <Link to={`/advertiser/${advertiser.id}`}> {advertiser.name}</Link>
                </div>
            )}
            {campaign && campaign.name && (
                <div className="alert alert-info d-inline-block mx-3">
                    Campaign: <Link to={`/campaign/${campaign.id}`}> {campaign.name}</Link>
                </div>
            )}
            {affiliate && affiliate.name && (
                <div className="alert alert-info d-inline-block mx-3">
                    Affiliate: <Link to={`/affiliate/${affiliate.id}`}> {affiliate.name}</Link>
                </div>
            )}
            {creativeId > 0 && <div className="alert alert-info d-inline-block mx-3">Creative ID: {creativeId}</div>}
            {landingPageId > 0 && <div className="alert alert-info d-inline-block mx-3">Landing Page: {landingPageId}</div>}
            {subid1 && subid1 !== 'notset' && subid1 !== '' && <div className="alert alert-info d-inline-block mx-3">Subid1: {subid1}</div>}
            {keyword && keyword !== '' && <div className="alert alert-info d-inline-block mx-3">keyword: {keyword}</div>}
            {withLeads && withLeads == 'true' && <div className="alert alert-info d-inline-block mx-3">Clicks with leads</div>}
        </React.Fragment>
    )
}
