import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuth, useProtectedContent } from '../../../../context/AuthContext'

const AffiliateDropdown = (props) => {
    const auth = useAuth()
    const showDormantReport = useProtectedContent(auth.permissions, 'affiliate_dormant_report')
    const showDormantBalanceReport = useProtectedContent(auth.permissions, 'affiliate_dormant_balance_report')
    const showPerformanceComparison = useProtectedContent(auth.permissions, 'affiliate_performance_comparison')
    const showBonusCandidates = useProtectedContent(auth.permissions, 'affiliate_bonus_candidates')
    const showPaymentTotals = useProtectedContent(auth.permissions, 'affiliate_payment_totals')
    const showCreateNotification = useProtectedContent(auth.permissions, 'affiliate_notification')
    const showTopAffiliates = useProtectedContent(auth.permissions, 'affiliates_top')
    const showAffiliateSearches = useProtectedContent(auth.permissions, 'affiliates_searches')
    const showPitchReport = useProtectedContent(auth.permissions, 'affiliate_pitch_report')
    const showResources = useProtectedContent(auth.permissions, 'affiliate_resources')
    const showIncompleteApps = useProtectedContent(auth.permissions, 'affiliate_incomplete_apps')

    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" onClick={toggle} data-toggle="dropdown" aria-expanded={dropdownOpen} className="nav-link dropdown-toggle">
                Affiliates
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark">
                <Link className="dropdown-item" to="/affiliates/search" onClick={toggle}>
                    Search Affiliates
                </Link>
                {showCreateNotification && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/affiliateNews" onClick={toggle}>
                            Create Affiliate Notification
                        </Link>
                    </>
                )}
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/affiliates/mailer" onClick={toggle}>
                    Affiliate Mailer
                </Link>

                <DropdownItem divider className="m-0 dropdown-separator" />

                <Link className="dropdown-item" to="/reports/stats/affiliates" onClick={toggle}>
                    Affiliate Earnings
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/reports/affiliateTrend" onClick={toggle}>
                    Affiliate Earnings Trend Report
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/affiliates?status=A" onClick={toggle}>
                    Newest Affiliates
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                {showTopAffiliates && (
                    <>
                        <Link className="dropdown-item" to="/affiliates/top" onClick={toggle}>
                            Top Affiliates
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    </>
                )}
                <Link className="dropdown-item" to="/affiliates/topByCountry" onClick={toggle}>
                    Top By Country
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/affiliates/marginByPriority" onClick={toggle}>
                    Margin By Priority
                </Link>

                {showPitchReport && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/pitchReport" onClick={toggle}>
                            Affiliate Pitch Report
                        </Link>
                    </>
                )}

                {showAffiliateSearches && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/searches" onClick={toggle}>
                            Search Affiliate Searches
                        </Link>
                    </>
                )}

                <DropdownItem divider className="m-0 dropdown-separator" />
                <Link className="dropdown-item" to="/reports/affiliate_signup" onClick={toggle}>
                    Affiliate Health Report
                </Link>

                {showPerformanceComparison && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/performanceComparison" onClick={toggle}>
                            Affiliate Performance Comparison
                        </Link>
                    </>
                )}
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/affiliates/cancelled" onClick={toggle}>
                    Cancelled Affiliates Last 30 Days
                </Link>
                <DropdownItem divider className="m-0 dropdown-divider-dark" />
                <Link className="dropdown-item" to="/affiliates/trafficToday" onClick={toggle}>
                    Affiliate Traffic Today
                </Link>
                {showBonusCandidates && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/performanceBonus" onClick={toggle}>
                            Performance Bonus Candidates
                        </Link>
                    </>
                )}
                {showDormantReport && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/dormant" onClick={toggle}>
                            Dormant Affiliates
                        </Link>
                    </>
                )}
                {showDormantBalanceReport && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/dormant/balances" onClick={toggle}>
                            Dormant Balances
                        </Link>
                    </>
                )}
                <DropdownItem divider className="m-0 dropdown-separator" />
                <Link className="dropdown-item" to="/maxmoney" onClick={toggle}>
                    MaxMoney
                </Link>
                {showResources && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/resources" onClick={toggle}>
                            Affiliate Portal Resource Page
                        </Link>
                    </>
                )}
                {showIncompleteApps && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/reportIncompleteApps" onClick={toggle}>
                            Incomplete Applications
                        </Link>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/affiliates/incompleteApps" onClick={toggle}>
                            Manage Incomplete Apps
                        </Link>
                    </>
                )}
                {showPaymentTotals && (
                    <>
                        <DropdownItem divider className="m-0 dropdown-divider-dark" />
                        <Link className="dropdown-item" to="/reports/affiliatePaymentTotals" onClick={toggle}>
                            Affiliate Payment Totals
                        </Link>
                    </>
                )}
                <>
                    <DropdownItem divider className="m-0 dropdown-divider-dark" />
                    <Link className="dropdown-item" to="/reports/reversals/affiliates" onClick={toggle}>
                        Affiliate Reversal Report
                    </Link>
                </>
            </DropdownMenu>
        </Dropdown>
    )
}

export default AffiliateDropdown
