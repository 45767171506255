import React, { useState, useEffect } from 'react'
import callAPI from '../../../helpers/callAPI'

import Loading from '../../../templates/UI/Loading/Loading'
import BizYear from './BizYear'

const ReportBizDevPipeline = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])

    const showSystemMessage = props.showSystemMessage

    useEffect(() => {
        const fetchData = async () => {
            setDataLoaded(false)

            const mbToken = localStorage.getItem('adm-auth-token')

            let url = `reports/bizdev_pipeline`
            const result = await callAPI(url, 'GET')
            if (result['success'] === true) {
                setReport(result.report)
                setDataLoaded(true)
            } else if (result['success'] === false) {
                showSystemMessage('error', result.errors.message)
            } else {
                showSystemMessage('error', 'There was an error fetching the data. Please try again.')
            }
        }
        fetchData()
    }, [showSystemMessage])

    const getReportOutput = () => {
        return (
            <React.Fragment>
                <table className="table table-striped table-card table-hover mb-0 bizdev">
                    <thead>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            <th scope="col" className="text-right">
                                Active Adv.
                            </th>
                            <th scope="col" className="text-right">
                                Adv. with Sales
                            </th>
                            <th scope="col" className="text-right">
                                New
                            </th>
                            <th scope="col" className="text-right">
                                Discussion
                            </th>
                            <th scope="col" className="text-right">
                                Negotiation
                            </th>
                            <th scope="col" className="text-right">
                                Promoted to Active
                            </th>
                            <th scope="col" className="text-right">
                                Cancel-old
                            </th>
                            <th scope="col" className="text-right">
                                Cancel-new
                            </th>
                            <th scope="col" width="20px">
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {report.map((record) => {
                            return <BizYear record={record} key={record.db_date} />
                        })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {dataLoaded ? (
                <React.Fragment>
                    {report.length === 0 ? (
                        <table className="table table-striped table-card table-hover mb-0">
                            <tbody>
                                <tr>
                                    <td colSpan="11">No stats for this date range.</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <React.Fragment>{getReportOutput()}</React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <table className="table table-striped table-card table-hover mb-0">
                    <tbody>
                        <tr>
                            <td colSpan="11">
                                <Loading />
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </React.Fragment>
    )
}

export default ReportBizDevPipeline
