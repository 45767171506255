import React, { useEffect, useState } from 'react'
import { ModalBody, ModalHeader } from 'reactstrap'

import Loading from '../../../../../../templates/UI/Loading/Loading'

const PhotoIdModal = ({ affiliateId, side, showSystemMessage, handleClose }) => {
    // TODO: Need to transform this and the parent VerificationModal to React-bootstrap modal to add that x button at top conveniently

    const [dataLoaded, setDataLoaded] = useState(false)
    const [image, setImage] = useState('')
    const [rotation, setRotation] = useState('normal')

    useEffect(() => {
        const mbToken = localStorage.getItem('adm-auth-token')

        fetch(`${process.env.REACT_APP_API_URL}/affiliate/${affiliateId}/photoId/${side}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'x-app-token': mbToken,
            },
        })
            .then((res) => res.blob())
            .then((response) => {
                if (!response) {
                    showSystemMessage('error', 'No images found')
                    setDataLoaded(true)
                    return
                }

                setImage(URL.createObjectURL(response))
                setDataLoaded(true)
            })
            .catch((error) => {
                console.log(error)
                showSystemMessage('error', 'Error while getting ID images')
            })
    }, [])

    const getTransformationStyle = () => {
        if (rotation === 'normal') {
            return { transform: 'none' }
        } else if (rotation === 'clockwise') {
            return { transform: 'rotate(90deg)' }
        } else if (rotation === 'counter_clockwise') {
            return { transform: 'rotate(-90deg)' }
        } else if (rotation === 'flip') {
            return { transform: 'rotate(180deg)' }
        }
    }

    return (
        <React.Fragment>
            <ModalHeader>Identification Image - {side}</ModalHeader>
            <ModalBody>
                <div className="d-flex align-items-center">
                    Rotation:
                    <button className="btn btn-link shadow-none" onClick={() => setRotation('normal')}>
                        Normal
                    </button>
                    /
                    <button className="btn btn-link shadow-none" onClick={() => setRotation('clockwise')}>
                        Clockwise
                    </button>
                    /
                    <button className="btn btn-link shadow-none" onClick={() => setRotation('counter_clockwise')}>
                        Counter Clockwise
                    </button>
                    /
                    <button className="btn btn-link shadow-none" onClick={() => setRotation('flip')}>
                        Flip
                    </button>
                </div>
                <div className="mt-3 d-flex justify-content-center">{dataLoaded ? <img src={image} crossOrigin={process.env.REACT_APP_API_URL} alt="Identification Images" style={getTransformationStyle()} className="mt-4" width="400px" height="400px" /> : <Loading />}</div>
                <button className="btn btn-secondary" onClick={handleClose}>
                    Close
                </button>
            </ModalBody>
        </React.Fragment>
    )
}

export default PhotoIdModal
