import { useEffect, useState } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Loading from '../../templates/UI/Loading/Loading'

const KeywordSearch = ({ keywordListData, handleOnSearch, handleOnHover, handleOnFocus, handleOnSelect }) => {
    const [refresh, setRefresh] = useState(0)

    const formatResult = (item) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    const onSelect = (item) => {
        handleOnSelect(item)
        setRefresh(refresh + 1)
    }

    return (
        <div>
            <ReactSearchAutocomplete
                key={refresh}
                className="w-25 mt-2"
                items={keywordListData.keywordList}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={onSelect}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                styling={{
                    height: '27px',
                    borderRadius: '3px',
                    boxShadow: 'none',
                    border: '1px solid #ced4da',
                    fontSize: '1rem',
                    zIndex: 500,
                }}
            />
        </div>
    )
}

export default KeywordSearch
