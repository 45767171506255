import React, { Component } from 'react'
import Countries from '../templates/Countries'
import QuestionDisclaimer from '../templates/QuestionDisclaimer'
import { AlertTriangle } from 'react-feather'
import RedirectSettings from '../templates/RedirectSettings'

class ShortFormAddress extends Component {
    state = {}

    render() {
        let { order, disclaimer, question, questionId, callToAction, placeholder } = this.props.data

        return (
            <div>
                <div className="text-center text-secondary mb-2">Q{order}: Short Form - Address</div>
                <h3>
                    <textarea maxLength={600} cols="50" placeholder="Question Text." className="form-control" type="text" defaultValue={question} size="100" onChange={this.props.handleChange(questionId)}></textarea>
                </h3>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <h3 className="text-xlarge">Address</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-xlarge">&nbsp;</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                </div>
                <div className="mt-3 row">
                    <div className="col-md-6">
                        <h3 className="text-xlarge">City</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-xlarge">State</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                </div>
                <div className="mt-3 row">
                    <div className="col-md-6">
                        <h3 className="text-xlarge">Zipcode</h3>
                        <div className="mt-3">
                            <input className="form-control" type="text" />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <h3 className="text-xlarge">Country</h3>
                    <div className="mt-3">
                        {
                            <select className="form-control" defaultValue="US">
                                <Countries />
                            </select>
                        }
                    </div>
                </div>
                <div className="mt-4">
                    <div className="mt-3">
                        <input className="form-control mb-button-input-text" type="text" defaultValue={callToAction} placeholder="Custom Call to Action" onChange={this.props.handleChangeCallToAction(questionId)} />
                    </div>
                </div>
                <RedirectSettings questionId={questionId} handleChangePlaceholder={this.props.handleChangePlaceholder} placeholder={placeholder} callToAction={callToAction} />
                <QuestionDisclaimer disclaimer={disclaimer} handleChangeDisclaimer={this.props.handleChangeDisclaimer} questionId={questionId} />
            </div>
        )
    }
}

export default ShortFormAddress
