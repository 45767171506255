export default function TestLinks({ campaignId, landingPages }) {
    return (
        <div className="card mb-2">
            <div className="align-items-center p-2 card-header">
                <div className="font-weight-bold float-left">Test Links</div>
            </div>
            <div className="card-body p-2">
                {landingPages.length === 0 ? (
                    <div>No test links</div>
                ) : (
                    landingPages.map((landingPage) => {
                        return (
                            <div className="p-3 border rounded m-2" key={landingPage.name}>
                                <div>
                                    Landing Page: {landingPage.name} {landingPage.default_flag === 'Y' ? <span>(Default)</span> : ''}
                                </div>
                                <a rel="noopener noreferrer" target="_blank" href={`https://www.mbt3st3r1.com/lnk.asp?o=${campaignId}&a=1053&c=1&l=${landingPage.lp_record_id}`}>
                                    https://www.mbt3st3r1.com/lnk.asp?o={campaignId}&a=1053&c=1&l={landingPage.lp_record_id}
                                </a>
                                <br></br>
                                <a rel="noopener noreferrer" target="_blank" href={`https://www.mbt3st3r2.com/lnk.asp?o=${campaignId}&a=1053&c=1&l=${landingPage.lp_record_id}`}>
                                    https://www.mbt3st3r2.com/lnk.asp?o={campaignId}&a=1053&c=1&l={landingPage.lp_record_id}
                                </a>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

// URL COPY

// Tellwut Online Surveys (US,CA) DOI - ID: 20820
// Default Landing Page:
// https://www.tellwut.com/guard/register
// Commission Details:
// DOI : $2.80 per lead

// Full details found here: https://maxbounty.com/offerlink.cfm?id=20820

// IMAGE COPY

// Tellwut Online Surveys (US,CA) DOI - ID: 20820
// Landing Page Preview:
// https://maxbounty.com/getlp.asp?m=7354&o=20820&l=22249&s=full
// Commission Details:
// DOI : $2.80 per lead

// Full details found here: https://maxbounty.com/offerlink.cfm?id=20820
