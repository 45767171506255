import { useAuth } from '../../context/AuthContext'

export default function MailerFromSelect({ from, setFrom }) {
    const auth = useAuth()

    return (
        <select className="form-control" onChange={(e) => setFrom(e.target.value)} value={from}>
            <option value=""></option>
            <option value={auth.user.id}>{auth.user.name}</option>
            <option value="0">Account Manager</option>
            <option value="affiliates@maxbounty.com">affiliates@maxbounty.com</option>
            <option value="merchants@maxbounty.com">merchants@maxbounty.com</option>
            <option value="support@maxbounty.com">support@maxbounty.com</option>
            <option value="noreply@maxbounty.com">noreply@maxbounty.com</option>
            <option value="compliance@maxbounty.com">compliance@maxbounty.com</option>
            <option value="paychange@maxbounty.com">paychange@maxbounty.com</option>
            <option value="adamh@maxbounty.com">Adam Harrison - Director of Affiliate Relations</option>
            <option value="jordons@maxbounty.com">Jordon Schneiderman - Director of Advertiser Relations</option>
        </select>
    )
}
