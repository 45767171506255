import React, { useEffect, useState } from 'react'

import Loading from '../../templates/UI/Loading/Loading'
import { useAuth } from '../../context/AuthContext'
import CampaignNavbar from './CampaignNavBar/CampaignNavBar'
import useCallAPI from '../../hooks/useCallApi'
import CampaignInfo from './CampaignInfo/CampaignInfo'
import Rates from './Rates/Rates'
import Restrictions from './Restrictions/Restrictions'
import TrafficTypes from './TrafficTypes/TrafficTypes'
import DeviceOsAllowed from './DeviceOsAllowed/DeviceOsAllowed'
import TestLinks from './TestLinks/TestLinks'
import Flags from '../../components/Flags/Flags'
import TestPostbacks from './TestPostbacks/TestPostbacks'

export default function Campaign({ campaignId, showSystemMessage }) {
    const auth = useAuth()
    const [manager, setManager] = useState(auth.user)

    const { dataLoaded, data, refetch } = useCallAPI(`campaign/${campaignId}`, 'GET', {}, [campaignId])

    useEffect(() => {
        if (dataLoaded) {
            document.title = data.campaign.name
        } else {
            document.title = 'Campaign'
        }
    }, [data, dataLoaded])

    return (
        <React.Fragment>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">{dataLoaded && data.campaign.name}</h1>
                <div className="ml-5">{dataLoaded && <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />}</div>
            </div>

            <div className="row">
                {dataLoaded ? (
                    <React.Fragment>
                        <div className="col-xl-4">
                            <CampaignInfo campaign={data.campaign} refetch={refetch} showSystemMessage={showSystemMessage} />
                            <Rates rates={data.campaign.rates} />
                            <TestLinks campaignId={campaignId} landingPages={data.campaign.landing_pages} />
                            <TestPostbacks campaignId={campaignId} campaign={data.campaign} />
                        </div>
                        <div className="col-xl-4 pb-0">
                            <DeviceOsAllowed deviceOS={data.campaign.tracking.traffic.os_filtering.operating_systems} trafficTypes={data.campaign.tracking.traffic.traffic_types} />
                            <Restrictions trafficTypes={data.campaign.tracking.traffic.traffic_types} restrictions={data.campaign.restrictions} countries={data.campaign.tracking.traffic.country_filtering.countries} ipFilteringFlag={data.campaign.tracking.traffic.country_filtering.enabled} landingUrl={data.campaign.landing_url} redirects={data.campaign.redirects} />
                            <TrafficTypes trafficTypes={data.campaign.tracking.traffic.traffic_types} />
                            <Flags showSystemMessage={showSystemMessage} campaignId={campaignId} />
                        </div>
                    </React.Fragment>
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </React.Fragment>
    )
}
