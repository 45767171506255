import moment from 'moment'
import { useState, useEffect } from 'react'

import callAPI from '../../../helpers/callAPI'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import Advertiser from '../../../templates/Advertiser/Advertiser'

const MerchantCampaignPulls = ({ affiliateId, showSystemMessage }) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [pulls, setPulls] = useState([])

    useEffect(() => {
        getMerchantPulls()
    }, [affiliateId])

    const getMerchantPulls = () => {
        let url = `affiliate/merchantPulls/${affiliateId}`

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success']) {
                    setPulls(result.pulls)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                showSystemMessage('error', 'An error occurred while fetching advertiser campaign pulls')
                setDataLoaded(true)
            })
    }

    return (
        <div className="card mb-3">
            <div className="card-header">Last 15 Advertiser/Campaign Pulls</div>
            <div className="card-body p-0">
                {dataLoaded ? (
                    pulls.length === 0 ? (
                        <div className="p-3">No stats available.</div>
                    ) : (
                        <table className="table table-striped table-card m-0">
                            <tbody>
                                {pulls.slice(0, 15).map((item) => {
                                    return (
                                        <tr key={item.name}>
                                            <td>{item.record_type === 'O' ? 'Campaign' : 'Advertiser'}</td>
                                            <td>{item.record_type === 'O' ? <Campaign oid={item.id} name={item.name} status={item.status} /> : <Advertiser id={item.id} name={item.name} status={item.status} />}</td>
                                            <td className="text-right">{moment(item.status_date).format('YYYY/MM/DD')}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default MerchantCampaignPulls
