import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import callAPI from '../../../../helpers/callAPI'
import useDateRangeFilter from '../../../../hooks/useDateRangeFilter'
import useQueryParams from '../../../../hooks/useQueryParams'
import Dollars from '../../../../templates/Dollars/Dollars'
import ReportsStatsDropdown from '../../Templates/ReportsStatsDropdown/ReportsStatsDropdown'
import ReportTable from '../../../../templates/ReportTable/ReportTable'
import Loading from '../../../../templates/UI/Loading/Loading'

const MonthlyReport = ({ showSystemMessage, setAffiliate, setCampaign, setAdvertiser, setCSVFilename, setCSVHeaderLabels, setCSVDataLabels, setCSVReport }) => {
    const { initStartDate, initEndDate } = useDateRangeFilter()
    const { campaignId, affiliateId, advertiserId, creativeId, landingPageId, withAvatar, buildUrl, hideNetwork } = useQueryParams()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState([])

    useEffect(() => {
        if (hideNetwork === 'true') {
            setCSVHeaderLabels(['Date', 'Clicks', 'Conversions', 'Conversion Ratio', 'Aff Earnings', 'Sales', 'EPC'])
            setCSVDataLabels(['Date', 'clicks', 'conversions', 'conversion_ratio', 'affiliate_earnings', 'sales', 'epc'])
        } else {
            setCSVHeaderLabels(['Date', 'Clicks', 'Conversions', 'Conversion Ratio', 'Net Earnings', 'Aff Earnings', 'Sales', 'Margin', 'EPC', 'nEPC'])
            setCSVDataLabels(['Date', 'clicks', 'conversions', 'conversion_ratio', 'network_earnings', 'affiliate_earnings', 'sales', 'margin', 'epc', 'nepc'])
        }
        getReport()
    }, [])

    const getReport = async () => {
        setDataLoaded(false)

        // We grab the data using the INITIALIZED dates. Hitting refresh turns selected options into init options.
        let url = buildUrl(`reports/monthly?startDate=${initStartDate}&endDate=${initEndDate}`)

        callAPI(url, 'GET')
            .then((result) => {
                if (result['success'] === true) {
                    result.affiliate && setAffiliate(result.affiliate)
                    result.campaign && setCampaign(result.campaign)
                    result.advertiser && setAdvertiser(result.advertiser)
                    setReport(result.report)
                    setTotals(result.totals)
                    setCSVReport(result.report)
                    setCSVFilename(`Monthly Report - ${initStartDate}-${initEndDate}`)
                    setDataLoaded(true)
                } else if (result['success'] === false) {
                    showSystemMessage('error', result.errors.message)
                    setDataLoaded(true)
                } else {
                    showSystemMessage('error', 'Could not load report.')
                    setDataLoaded(true)
                }
            })
            .catch((error) => {
                console.log(`Error while fetching monthly report: ${error.message}`)
                showSystemMessage('error', `An error occurred while fetching monthly report: ${error.message}`)
                setDataLoaded(true)
            })
    }

    const getReportOutput = () => {
        let columns = [
            {
                Header: 'Date',
                accessor: 'date',
                Cell: ({ cell: { value } }) => {
                    return <>{`${moment(value).utc().format('YYYY-MM')}`}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                accessor: 'clicks',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{`${Intl.NumberFormat('en-GB').format(value)}`}</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                accessor: 'conversions',
                className: 'text-right',
                Cell: (tableData) => {
                    return <Link to={`/reports/stats/conversions?affiliateId=${affiliateId}&campaignId=${campaignId}&creativeId=${creativeId}&landingPageId=${landingPageId}&startDate=${moment(tableData.data[tableData.row.index].date).utc().startOf('month').format('YYYY-MM-DD')}&endDate=${moment(tableData.data[tableData.row.index].date).utc().endOf('month').format('YYYY-MM-DD')}`}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}`}</Link>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>CR %</div>,
                accessor: 'conversion_ratio',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <>{`${Intl.NumberFormat('en-GB').format(value)}`} %</>
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Net Earnings</div>,
                accessor: 'network_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Aff Earnings</div>,
                accessor: 'affiliate_earnings',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                accessor: 'sales',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>Margin</div>,
                accessor: 'margin',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>EPC</div>,
                accessor: 'epc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: () => <div style={{ textAlign: 'right' }}>nEPC</div>,
                accessor: 'nepc',
                className: 'text-right',
                Cell: ({ cell: { value } }) => {
                    return <Dollars value={value} />
                },
            },
            {
                Header: ' ',
                className: 'text-right',
                Cell: (tableData) => {
                    return <ReportsStatsDropdown reportType="earnings" affiliateId={affiliateId} campaignId={campaignId} advertiserId={advertiserId} startDate={moment(tableData.data[tableData.row.index].date).utc().startOf('month').format('YYYY-MM-DD')} endDate={moment(tableData.data[tableData.row.index].date).utc().endOf('month').format('YYYY-MM-DD')} withAvatar={withAvatar} monthly={true} showSystemMessage={showSystemMessage} />
                },
            },
        ]

        if (hideNetwork == 'true') {
            columns = [
                {
                    Header: 'Date',
                    accessor: 'date',
                    Cell: ({ cell: { value } }) => {
                        return <>{`${moment(value).utc().format('YYYY-MM')}`}</>
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Clicks</div>,
                    accessor: 'clicks',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <>{`${Intl.NumberFormat('en-GB').format(value)}`}</>
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Conversions</div>,
                    accessor: 'conversions',
                    className: 'text-right',
                    Cell: (tableData) => {
                        return <Link to={`/reports/stats/conversions?affiliateId=${affiliateId}&campaignId=${campaignId}&creativeId=${creativeId}&landingPageId=${landingPageId}&startDate=${moment(tableData.data[tableData.row.index].date).utc().startOf('month').format('YYYY-MM-DD')}&endDate=${moment(tableData.data[tableData.row.index].date).utc().endOf('month').format('YYYY-MM-DD')}`}>{`${Intl.NumberFormat('en-GB').format(tableData.data[tableData.row.index].conversions)}`}</Link>
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>CR %</div>,
                    accessor: 'conversion_ratio',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <>{`${Intl.NumberFormat('en-GB').format(value)}`} %</>
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Aff Earnings</div>,
                    accessor: 'affiliate_earnings',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <Dollars value={value} />
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>Sales</div>,
                    accessor: 'sales',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <Dollars value={value} />
                    },
                },
                {
                    Header: () => <div style={{ textAlign: 'right' }}>EPC</div>,
                    accessor: 'epc',
                    className: 'text-right',
                    Cell: ({ cell: { value } }) => {
                        return <Dollars value={value} />
                    },
                },
                {
                    Header: ' ',
                    className: 'text-right',
                    Cell: (tableData) => {
                        return <ReportsStatsDropdown reportType="earnings" affiliateId={affiliateId} campaignId={campaignId} advertiserId={advertiserId} startDate={moment(tableData.data[tableData.row.index].date).utc().startOf('month').format('YYYY-MM-DD')} endDate={moment(tableData.data[tableData.row.index].date).utc().endOf('month').format('YYYY-MM-DD')} withAvatar={withAvatar} monthly={true} showSystemMessage={showSystemMessage} />
                    },
                },
            ]
        }

        return (
            <React.Fragment>
                <ReportTable data={report} columns={columns} totals={totals} />
            </React.Fragment>
        )
    }

    return <div>{dataLoaded ? report.length === 0 ? <div className="p-3">No stats for the current parameters.</div> : getReportOutput() : <Loading></Loading>}</div>
}

export default MonthlyReport
