import { useEffect, useState } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { useAuth, useProtectedPage } from '../../../context/AuthContext'
import useCallApi from '../../../hooks/useCallApi'
import StatusCircle from '../../../templates/StatusCircle/StatusCircle'
import ReactTooltip from 'react-tooltip'
import Loading from '../../../templates/UI/Loading/Loading'
import AdminsModal from './AdminsModal'

const ListAdmins = ({ advertiserId, showSystemMessage }) => {

    document.title = 'Manage Administrators'

    const [mode, setMode] = useState('A')
    const [isCreate, setIsCreate] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [adminId, setAdminId] = useState(67)
    const { data, dataLoaded, refetch } = useCallApi(`admin/listadmins?mode=${mode}`, 'GET', {}, [mode], showSystemMessage)

    const auth = useAuth()
    useProtectedPage(auth.permissions, 'manage_administrators')

    return (
        <div className="row">
            <div className="col-lg-6">
                <Card>
                    <AdminsModal refetch={refetch} showSystemMessage={showSystemMessage} isOpen={isOpen} setIsOpen={setIsOpen} isCreate={isCreate} adminId={adminId} setAdminId={setAdminId} />
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div>Manage Administrators</div>
                        <div className="d-flex align-items-center">
                            <div className="custom-control custom-switch mr-2">
                                <input type="checkbox" className="custom-control-input" id="filterSwitch" onClick={() => setMode(mode === 'A' ? 'C' : 'A')} checked={mode === 'A' ? false : true} />
                                <label className="custom-control-label" for="filterSwitch">
                                    {mode === 'A' ? 'Show' : 'Hide'} Cancelled
                                </label>{' '}
                            </div>

                            <Button
                                className="ml-2"
                                onClick={() => {
                                    setIsCreate(true)
                                    setIsOpen(true)
                                }}
                                variant="primary"
                                size="sm"
                            >
                                Create Admin
                            </Button>
                        </div>
                    </Card.Header>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>ID</th>
                                <th>Email</th>
                                <th>Admin Type</th>
                                <th>Status</th>
                                <th>Out of office</th>
                            </tr>
                        </thead>
                        {dataLoaded ? (
                            <>
                                {data.success && data.adminsList.length > 0 ? (
                                    <tbody>
                                        <ReactTooltip />
                                        {data.adminsList.map((item, index) => {
                                            return (
                                                <tr key={index} className={item.status === 'C' ? 'bgDanger25' : ''}>
                                                    <td>
                                                        {
                                                            <button
                                                                className="btn btn-sm btn-link"
                                                                onClick={() => {
                                                                    setIsCreate(false)
                                                                    setAdminId(item.admin_id)
                                                                    setIsOpen(true)
                                                                }}
                                                            >
                                                                {item.full_name}
                                                            </button>
                                                        }
                                                    </td>
                                                    <td>{item.admin_id}</td>
                                                    <td>{item.e_mail}</td>
                                                    <td>{item.role ? <div>{item.role}</div> : <div className="text-danger">ROLE NOT SET</div>}</td>
                                                    <td>{item.status === 'A' ? 'Active' : 'Cancelled'}</td>
                                                    <td>
                                                        {item.out_of_office === 'Y' && (
                                                            <div className="ml-2" data-tip="Out of office">
                                                                <StatusCircle type="danger" />
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="4">No data found</td>
                                        </tr>
                                    </tbody>
                                )}
                            </>
                        ) : (
                            <Loading />
                        )}
                    </Table>
                </Card>
            </div>
        </div>
    )
}

export default ListAdmins
