import React, { useState } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import callAPI from '../../../helpers/callAPI'
import Loading from '../../../templates/UI/Loading/Loading'
import moment from 'moment'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import EmployeePicker from '../../../components/EmployeePicker/EmployeePicker'
import useCallApi from '../../../hooks/useCallApi'
import Affiliate from '../../../templates/Affiliate/Affiliate'
import Dollars from '../../../templates/Dollars/Dollars'
import { useQuery } from '../../../hooks/useQuery'
import AdminPicker from '../../../components/AdminPicker/AdminPicker'

export default function MaxMoneyAffs({ showSystemMessage, promoId }) {
    const query = useQuery()
    const [mgrId, setMgrId] = useState(query.get('mgrid') || 0)
    const [mgrName, setMgrName] = useState(query.get('mgrname') || '')
    const [employee, setEmployee] = useState({ id: mgrId ? mgrId : 0, name: mgrName ? mgrName : '' })
    const { data, setData, dataLoaded, refetch } = useCallApi(`maxmoney/${promoId}/affiliates${employee ? `?mgrid=${employee.id}` : ''}`, 'GET', {}, [employee], showSystemMessage)

    useEffect(() => {
        window.history.pushState({}, '', `/maxmoney/${promoId}/affiliates${employee ? `?mgrid=${employee.id}&mgrname=${employee.name}` : ''}`)
    }, [employee])

    const updateAffiliateStatus = async (affiliateId, status, index) => {
        const response = await callAPI(`maxmoney/${promoId}/affiliates/${affiliateId}`, 'PUT', { status })
        if (response.success) {
            showSystemMessage('success', 'Affiliate status updated successfully')
            let temp = data.report
            temp[index].status = status
            setData({ ...data, report: temp })
        } else {
            showSystemMessage('error', response.errors.message)
        }
    }

    return (
        <Card className="mb-3 overflow-auto">
            <Card.Header className="d-flex justify-content-between align-items-center">
                Affiliate Registrations for: {dataLoaded && data.success ? `${data.reportInfo.promo_name} (${moment(data.reportInfo.start_date).format('MMM/DD')} - ${moment(data.reportInfo.end_date).format('MMM/DD')})` : ''}
                <span className="ml-3 w-25">
                    <AdminPicker adminId={employee.id} setAdmin={() => setEmployee({ id: employee.id, name: employee.name })} role={['AM_JR_TEAMLEAD', 'AM_SENIOR']} />
                </span>
            </Card.Header>

            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Aff. ID</th>
                        <th>Affiliate</th>
                        <th>Registration Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                {dataLoaded ? (
                    data.report?.length > 0 ? (
                        <tbody>
                            {data.report.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.affiliate_id}</td>
                                        <td>
                                            <Affiliate aid={item.affiliate_id} name={item.name} />
                                        </td>
                                        <td>{moment(item.start_date).format('YYYY-MM-DD')}</td>
                                        <td>{moment(item.end_date).format('YYYY-MM-DD')}</td>
                                        <td>
                                            <Form.Control as="select" value={item.status} onChange={(e) => updateAffiliateStatus(item.affiliate_id, e.target.value, index)}>
                                                <option value="A">Active</option>
                                                <option value="D">Disabled</option>
                                            </Form.Control>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No Affiliates Found
                                </td>
                            </tr>
                        </tbody>
                    )
                ) : (
                    <Loading />
                )}
            </Table>
        </Card>
    )
}
