import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import useCallApi from '../../../hooks/useCallApi'
import useDateRangeFilter from '../../../hooks/useDateRangeFilter'
import Campaign from '../../../templates/Campaign/Campaign'
import Loading from '../../../templates/UI/Loading/Loading'
import DateRangeFilter from '../../Reports/Filters/DateRangeFilter/DateRangeFilter'
import CampaignNavbar from '../CampaignNavBar/CampaignNavBar'
import ByCity from './ByCity/ByCity'
import ByCountry from './ByCountry/ByCountry'
import ByCreative from './ByCreative/ByCreative'
import ByDeviceOS from './ByDeviceOS/ByDeviceOS'
import ByLandingPage from './ByLandingPage/ByLandingPage'
import ByRegion from './ByRegion/ByRegion'
import ByTimeOfDay from './ByTimeOfDay/ByTimeOfDay'
import ByTrafficTypes from './ByTrafficTypes/ByTrafficTypes'
import ByUserAgent from './ByUserAgent/ByUserAgent'

const CampaignAnalytics = ({ campaignId, showSystemMessage }) => {
    const location = useLocation()
    const history = useHistory()
    const { initStartDate, initEndDate, selectedStartDate, selectedEndDate, quickDate, handleChangeStart, handleChangeEnd, handleQuickDate } = useDateRangeFilter()
    const { dataLoaded, data, refetch } = useCallApi(`campaign/${campaignId}/analytics?startDate=${selectedStartDate}&endDate=${selectedEndDate}`, 'GET', {}, [])

    if (dataLoaded){
        document.title = `${data.campaign.name}: Traffic Analytics`
    } else {
        document.title = `Traffic Analytics`
    }

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const refreshReport = (e) => {
        // We want the URL in the url bar to update when refreshing via the button, so all it does is navigate you to a new page with the SELECTED options
        e.preventDefault()
        let url = `/campaign/${campaignId}/analytics?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
        history.push(url)
    }

    useEffect(() => {
        refetch()
    }, [location])

    return (
        <div>
            <div className="page-header d-flex align-items-center">
                <h1 className="mb-h1">Traffic Analytics</h1>
                <div className="ml-5">
                    <CampaignNavbar campaignId={campaignId} showSystemMessage={showSystemMessage} />
                </div>
            </div>
            <div>
                <div className="card mb-2">
                    <div className="d-flex p-3 align-items-center">
                        <h1 className="mb-h1-dark mr-2">{dataLoaded ? <Campaign oid={campaignId} name={data.campaign.name} status={data.campaign.status.value} /> : spinner}</h1>
                        <DateRangeFilter quickDate={quickDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} handleChangeStart={handleChangeStart} handleChangeEnd={handleChangeEnd} handleQuickDate={handleQuickDate} />
                        <button onClick={refreshReport} className="btn btn-sm btn-primary">
                            Refresh Report
                        </button>
                    </div>
                </div>
                {dataLoaded ? (
                    <div className="row Affiliate">
                        <div className="col-xl-6">
                            <ByCountry geographic={data.geographic} totalLeads={data.total_leads} />
                            <ByRegion region={data.region} totalLeads={data.total_leads} />
                            <ByCity city={data.city} totalLeads={data.total_leads} />
                            <ByDeviceOS deviceOS={data.device_os} totalLeads={data.total_leads} />
                        </div>
                        <div className="col-xl-6 pb-0">
                            <ByCreative creatives={data.creatives} totalLeadsFromStats={data.total_leads_from_stats} />
                            <ByLandingPage landingPages={data.landing_pages} totalLeadsFromLpStats={data.total_leads_from_lp_stats} />
                            <ByUserAgent userAgents={data.user_agent} totalLeads={data.total_leads} />
                            <ByTrafficTypes trafficTypes={data.traffic_types} />
                            <ByTimeOfDay timeOfDay={data.time_of_day} totalLeads={data.total_leads} />
                        </div>
                    </div>
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    )
}

export default CampaignAnalytics
