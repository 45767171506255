import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Advertiser extends Component {
    state = {}
    render() {
        /*
            Allowed props:
                status: highlight, active, cancelled
        */

        let classes = 'mb_element'

        if (this.props.green) {
            classes = `mb-element active`
        }

        return (
            <Link className={classes} to={`/advertiser/${this.props.id}`}>
                <span className="name">{this.props.name}</span>
            </Link>
        )
    }
}

export default Advertiser
