import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import callAPI from '../../../../../helpers/callAPI'
import useCallApi from '../../../../../hooks/useCallApi'
import Campaign from '../../../../../templates/Campaign/Campaign'
import Loading from '../../../../../templates/UI/Loading/Loading'

const CampaignWorksheetQuestions = ({ selectedCampaignId, handleClose }) => {
    const { dataLoaded, data, setData } = useCallApi(`campaign/${selectedCampaignId}/phaseQuestions`, 'GET', {}, [selectedCampaignId])

    const [answers, setAnswers] = useState([])

    const spinner = <FontAwesomeIcon icon="spinner" spin />

    const handleSubmit = (e) => {
        e.preventDefault()

        let postBody = {
            answers,
        }

        callAPI(`campaign/${selectedCampaignId}/phaseQuestions`, 'POST', postBody)
            .then((result) => {
                if (result.success) {
                    handleClose(true)
                } else if (!result.success) {
                    console.log('SHOW ERROR HERE')
                }
            })
            .catch((error) => error)
    }

    const handleChange = (question_id, type, value, secondType, secondValue) => {
        setData((prev) => {
            return {
                ...prev,
                phase_questions: prev.phase_questions.map((a, i) =>
                    question_id === a.question_id
                        ? {
                              ...a,
                              [type]: value,
                          }
                        : a
                ),
            }
        })

        if (answers.length === 0) {
            setAnswers([{ id: question_id, [type]: value, [secondType]: secondValue }])
        } else {
            let newArr = [...answers]

            const index = newArr.findIndex((item) => item.id === question_id)
            if (index === -1) {
                newArr.push({ id: question_id, [type]: value })
                setAnswers([...answers, { id: question_id, [type]: value, [secondType]: secondValue }])
            } else {
                newArr[index] = { ...newArr[index], [type]: value, [secondType]: secondValue }
                setAnswers(newArr)
            }
        }
    }

    return (
        <React.Fragment>
            <Modal.Header closeButton>
                <Modal.Title as="h5">Setup Phase Questions for: {dataLoaded ? <Campaign oid={selectedCampaignId} name={data.campaign.name} status={data.campaign.status.value} /> : spinner}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dataLoaded ? (
                    <form onSubmit={handleSubmit} name="questions_form">
                        <table className="table table-borderless table-hover">
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.phase_questions.map((question) => (
                                    <tr key={question.question_id}>
                                        <td>{question.question_text}</td>
                                        <td>
                                            <select name={`answer_${question.question_id}`} className="form-control" defaultValue={question.answer} onChange={(e) => handleChange(question.question_id, 'answer', e.target.value, 'notes', question.notes)}>
                                                <option value=""></option>
                                                <option value="Y">Yes</option>
                                                <option value="N">No</option>
                                                <option value="A">N/A</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" name={`notes_${question.question_id}`} defaultValue={question.notes} maxLength={50} className="form-control" onChange={(e) => handleChange(question.question_id, 'notes', e.target.value, 'answer', question.answer)} />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td colSpan={2}>
                                        <button type="submit" className="btn btn-primary">
                                            Save
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                            Cancel
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
        </React.Fragment>
    )
}

export default CampaignWorksheetQuestions
